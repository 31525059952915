
import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { ProficiencyLevel } from '../models';
import { BaseService } from './base.service';

import * as _ from 'lodash';

@Injectable()
export class ProficiencyLevelService extends BaseService {

  private baseServiceUrl: string;
  private currentProficiencyLevels: Observable<ProficiencyLevel[]>;

  constructor(
    private http: HttpClient) {
    super();
    this.baseServiceUrl = '/restapi/levels/';
  }

  getAll(refresh: boolean = false): Observable<ProficiencyLevel[]> {
    if (!this.currentProficiencyLevels || refresh) {

      let headers = this.getDefaultHttpHeaders();

      let url: string = this.baseServiceUrl;

      this.currentProficiencyLevels = this.http
        .get(url, { headers: headers }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(ProficiencyLevel, res)),
        publishReplay(1),
        refCount(),);
    }

    return this.currentProficiencyLevels;
  }

}
