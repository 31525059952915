<dx-scroll-view>
<div class="wb-upload-pictures-button">
  <dx-button
    text="{{'TXT_UPLOAD_PICTURES' | translate}}"
    class="wb-button"
    type="default"
    width="100%"
    height="45px"
    (onClick)="onUploadPicturesClick()">
  </dx-button>
</div>
  <div class="wb-gallery-list">
    <div class="wb-gallery" *ngFor="let gallery of galleries">
      <h4 class="wb-gallery-date">{{gallery.galleryCreatedAtUtc | date:'shortDate'}}</h4>
      <div class="wb-gallery-description">{{gallery.galleryDescription}}</div>
      <div class="wb-gallery-images row">
        <div class="wb-gallery-image col-xs-6 col-sm-4" *ngFor="let image of gallery.images; let i=index">
          <img [src]="image.thumbnailUrl" alt="thumbnail" width="100%" (click)="openLightBox(gallery, i)" />
        </div>
      </div>
    </div>
  </div>
</dx-scroll-view>
