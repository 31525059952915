<div class="header dx-card dx-theme-border-color-as-background-color">
  <div class="max-length-text" *ngIf="!!semester">
      <small>{{semester.name}} ({{semester.startDate | date:'shortDate'}} - {{semester.endDate | date:'shortDate'}})</small>
  </div>
</div>
<div class="under-header-list">
  <dx-list 
    [dataSource]="courses"
    pullRefreshEnabled="true"
    (onPullRefresh)="onPullRefresh()"
    (onItemClick)="onItemClick($event)">
    <div *dxTemplate="let itemData of 'item'; let i = index" class="course-item" [ngClass]="{ 'course-item-first': i == 0 }" >
      <h4 class="wordwrap">
        <span class="course-type-img">
          <img src="{{itemData.courseTypePicture}}" />
        </span>
        {{itemData.subjectName}}
      </h4>
      <h5 class="wordwrap"><span *ngIf="itemData.abbreviation">{{itemData.abbreviation}}&nbsp;</span>{{itemData.name}}</h5>  
      <div class="row dx-theme-accent-as-text-color">
        <div class="col-xs-6">
          <span *ngIf="tenantSettings.showScheduleInCourses && tenantSettings.showDateInClientCourses">{{itemData.firstLesson | date:'dd.MM.yy EE HH:mm' | uppercase }}</span>
          <span *ngIf="tenantSettings.showScheduleInCourses && !tenantSettings.showDateInClientCourses">{{itemData.firstLesson | date:'EE HH:mm' | uppercase }}</span>
        </div>
        <div class="col-xs-6 text-right">{{ itemData.filled }}</div>
      </div>
      <div class="row">
        <div class="col-xs-5">{{'TXT_TEACHER'| translate}}:</div>
        <div class="col-xs-7">{{itemData.teacher}}</div>      
      </div>
      <div class="row" *ngIf="itemData.classroomID">
        <div class="col-xs-5">{{'TXT_PLACE'| translate}}:</div>
        <div class="col-xs-7">{{itemData.classroomID | classroom }}</div>
      </div>
      <div class="row" *ngIf="tenantSettings.showAgeRangesInCourses">
        <div class="col-xs-5">{{'TXT_AGE'| translate}}:</div>
        <div class="col-xs-7">{{itemData.courseAgeRangeID | ageRange }}</div>
      </div>
      <div class="row">
        <div class="col-xs-5">{{'TXT_COMPENSATION_GROUP'| translate}}:</div>
        <div class="col-xs-7">{{ itemData.compensationGroupName }}</div>
      </div>    
    </div>
  </dx-list>
</div>

