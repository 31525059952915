import {TransformDate} from "../decorators";

export class AccountingBook {
  id: number;
  name: string;
  subjectName: string;
  subjectAddress: string;
  accountNo: string;
  organisationIdentifier: string;
  vATNumber: string;
  registeredBy: string;
  bankTransactionImportType: number;

  @TransformDate()
  bankTransactionImportStartDate: Date;
  bankTransactionImportAmountLimit: number;
  securityAccessToken: string;
  isVATPayer: boolean;
  vAT: number;
  isDefault: boolean;
  onlinePaymentConfigurationID: string;
  isssueTaxDocumentsWhenAmortizationScheduleIsFullyPaid: boolean;
  isssueTaxDocumentsForEveryPayment: boolean;
}
