<div class="attendance-type-selection">
    <dx-button
      *ngIf="courseAttendanceEnabled"
        text="{{'TXT_COURSES_ATTENDANCE' | translate}}"
        class="wb-button"
        type="normal"
        width="100%"
        height="60px"
        (onClick)="onCoursesClick($event)">
    </dx-button>
    <dx-button
      *ngIf="activityAttendanceEnabled"
      text="{{'TXT_KINDERGARTEN_ATTENDANCE' | translate}}"
      class="wb-button"
      type="normal"
      width="100%"
      height="60px"
      (onClick)="onActivitiesClick($event)">
    </dx-button>
    <dx-button
      *ngIf="reservationAttendanceEnabled"
      text="{{'TXT_RESERVATIONS_ATTENDANCE' | translate}}"
        class="wb-button"
        type="normal"
        width="100%"
        height="60px"
        (onClick)="onReservationsClick($event)">
    </dx-button>
</div>
