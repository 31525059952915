import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ActivityService} from "../../../shared/services";
import {PhotoAlbumInfo} from "../../../shared/models/activities";
import {LightboxService} from "../../../shared/services/lightbox.service";

@Component({
  selector: 'app-activity-attendance-picture-gallery',
  templateUrl: './activity-attendance-picture-gallery.component.html',
  styleUrls: ['./activity-attendance-picture-gallery.component.css']
})
export class ActivityAttendancePictureGalleryComponent implements OnInit {

  activityID: number;
  semesterID: number;

  galleries: PhotoAlbumInfo[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private activityService: ActivityService,
              private lightboxService: LightboxService) { }

  ngOnInit(): void {
    this.activityID = Number(this.route.snapshot.params["activityId"]);
    this.semesterID = Number(this.route.snapshot.params["semesterId"]);
    this.activityService.getPhotoAlbum(null, this.activityID)
      .subscribe(r => this.galleries = r);
  }

  openLightBox(gallery: PhotoAlbumInfo, index: number): void {
    let lightBoxAlbum = gallery.images.map(x => ({
      image: x.imageUrl,
      alt: '',
      title: ''
    }));

    this.lightboxService.showLightbox({
      lightBoxAlbum: lightBoxAlbum,
      lightBoxImageIndex: index
    });
  }

  onUploadPicturesClick() {
    this.router.navigate(["/attendance/activities/semester", this.semesterID, "activity", this.activityID, "picture-gallery", "upload"]);
  }

}
