import {Component, Input, OnInit} from '@angular/core';
import {SignInUser, User} from "../../models";
import {LoginService} from "../../services";

@Component({
  selector: 'app-sign-up-edit',
  templateUrl: './sign-up-edit.component.html',
  styleUrls: ['./sign-up-edit.component.css']
})
export class SignUpEditComponent implements OnInit {

  @Input()
  user: SignInUser;

  @Input()
  otpWasSend: boolean;

  constructor(
    private loginService: LoginService
  ) { }

  passwordValidationRegEx: RegExp;

  ngOnInit(): void {
    this.passwordValidationRegEx = this.loginService.passwordValidationRegEx();
  }

}
