<div class="dx-fieldset">
  <div class="dx-fieldset-header"
       >{{'TXT_CREATE_ACCOUNT_TITLE' | translate}}</div>
  <div class="dx-fieldset-header wb-sub-header"
      >{{'TXT_CREATE_ACCOUNT_SUB_TITLE' | translate}}</div>
  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_EMAIL' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="user.email"
                   mode="email"
                   [readOnly]="otpWasSend"
      >
        <dx-validator
          [validationRules]="[{ type: 'email', message: 'TXT_LOGIN_ERROR_INVALID_EMAIL' | translate }, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_EMAIL' | translate) }]">
        </dx-validator>
      </dx-text-box>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_PASSWORD' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="user.password"
                   mode="password"
                   [readOnly]="otpWasSend">
        <dx-validator
          [validationRules]="[{ type: 'pattern', pattern: passwordValidationRegEx, message: ('TXT_WEAK_PASSWORD' | translate)}, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PASSWORD' | translate) }]">
        </dx-validator>
      </dx-text-box>
    </div>
  </div>
  <div class="dx-field" *ngIf="!otpWasSend">
    <div class="dx-field-label"></div>
    <div class="dx-field-value wb-field-value-help">{{'TXT_PASSWORD_HELP' | translate}}</div>
  </div>
  <div
    *ngIf="otpWasSend"
    class="dx-fieldset-header wb-sub-header">{{'TXT_OTP_DESC' | translate}}</div>
  <div *ngIf="otpWasSend" class="dx-field">
    <div class="dx-field-label">{{ 'TXT_LAB_OTP' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="user.otp"
                   mode="text">
        <dx-validator
          [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_LAB_OTP' | translate) }, { type: 'stringLength', max: 6, min: 6 }]">
        </dx-validator>
      </dx-text-box>
    </div>
  </div>
</div>
