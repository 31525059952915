import { Type, Transform } from "class-transformer";
import { TransformDate } from '../decorators';

export class Membership {
    id: number;
    name: string;
    description: string;
    isMandatory: boolean;
    isClientAssignable: boolean;
    applyToActions: boolean;
    applyToCourses: boolean;
    price: number;
    discountPct: number;
    validityPeriodInDays: number;

    @TransformDate()
    validFrom: Date;

    @TransformDate()
    validTo: Date;
}