
import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import { Attendance } from '../models';
import { BaseService } from './base.service';
import { TranslateService } from '@ngx-translate/core'
import { notifySuccess } from './notifications';

import * as _ from 'lodash';

@Injectable()
export class AttendanceService extends BaseService {

  private baseServiceUrl: string;
  private courseID: number;
  private userID: number;
  private attendances: Observable<Attendance[]>;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService) {

    super();
    this.baseServiceUrl = '/Api/Attendance/';
  }

  getAllForCourseAndUser(
    courseID: number,
    userID: number,
    refresh: boolean = false): Observable<Attendance[]> {

    if (!this.attendances ||
      this.courseID !== courseID ||
      this.userID !== userID ||
      refresh) {

      this.userID = userID;
      this.courseID = courseID;

      let headers = this.getDefaultHttpHeaders();
      let params = new HttpParams()
        .set("courseID", courseID.toString())
        .append("userID", userID.toString());

      let url: string = this.baseServiceUrl + 'GetAllForCourseAndUser';


      this.attendances = this.http
        .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(Attendance, res)),
        publishReplay(1),
        refCount(),);
    }

    return this.attendances;
  }

  getLessonAttendance(
    courseScheduleID: number
  ): Observable<Attendance[]> {
    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("courseScheduleID", courseScheduleID.toString());
    
    let url: string = this.baseServiceUrl + 'GetLessonAttendance';
    return this.http
      .get(url, { headers: headers, params: params }).pipe(
      catchError((err, c) => this.handleErrorAndThrow(err)),
      map((res: Object[]) => plainToClass(Attendance, res)),
      publishReplay(1),
      refCount(),);      
  }

  updateStudentAttendance(attendance: Attendance): Observable<Object> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'Update';
    let plain = classToPlain(attendance);

    return new Observable<Object>(observer => {

      let self = this;

      self.http.post(url, plain, { headers: headers })
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            observer.next(value);
            observer.complete();
          },
          err => observer.error(self.handleError(err)))
    });
  }

  insertStudentAttendance(attendance: Attendance): Observable<Object> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'Insert';
    let plain = classToPlain(attendance);

    return new Observable<Object>(observer => {

      let self = this;

      self.http.post(url, plain, { headers: headers })
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            observer.next(value);
            observer.complete();
          },
          err => observer.error(self.handleError(err)))
    });
  }  

}
