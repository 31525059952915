<div class="wrapper padded-content"
  [ngClass]="{'panel-default': !student.isModified, 'panel-success': student.isModified}">

  <table style="width: 100%">
    <tr>
      <td>
        <h4>{{student.studentFullName}}</h4>
      </td>
      <td style="width: 110px;"
        class="text-right">
        <dx-switch
          *ngIf="!allowClientLessonSelection"
          [(value)]="student.attend"
          [disabled]="(!isAction && !student.isNew) || (isAction && student.isInThePast)" ></dx-switch>
      </td>
    </tr>
    <tr>
      <td *ngIf="!isAction">
        <div *ngIf="!student.isNew && student.attend"
          class="dx-button-mode-text dx-button-success">
          {{'TXT_STUDENT_IS_REGISTERED' | translate }}
        </div>
        <div *ngIf="!student.isNew && !student.attend"
          class="dx-button-mode-text dx-button-danger">
          {{'TXT_STUDENT_WILL_BE_EXCUSED' | translate }}
        </div>
        <div *ngIf="student.isNew && student.attend"
          class="dx-button-mode-text dx-button-danger">
          {{'TXT_STUDENT_WILL_BE_BOOKED' | translate}}
        </div>
      </td>
      <td *ngIf="isAction">
        <div *ngIf="!student.isNew && !student.attend && student.actionAttendanceStatus !== 0"
             class="dx-button-mode-text dx-button-success">
          {{'TXT_STUDENT_IS' | translate }} <span style="text-transform: lowercase">{{ student.actionAttendanceStatus | activityAttendanceStatus}}</span>
        </div>
        <div *ngIf="!student.isNew && student.attend && student.actionAttendanceStatus === 0"
             class="dx-button-mode-text dx-button-success">
          {{'TXT_STUDENT_IS_REGISTERED' | translate }}
        </div>
        <div *ngIf="(student.isNew && student.attend) || (!student.isNew && student.attend && student.actionAttendanceStatus !== 0)"
             class="dx-button-mode-text dx-button-danger">
          {{'TXT_STUDENT_WILL_BE_BOOKED' | translate}}
        </div>
        <div *ngIf="!student.isNew && !student.attend && student.actionAttendanceStatus === 0"
             class="dx-button-mode-text dx-button-danger">
          {{'TXT_STUDENT_WILL_BE_EXCUSED' | translate }}
        </div>
      </td>
      <td style="width: 110px;"
        class="text-right">
        <div *ngIf="student.isNew">
          {{ student.invoice.basePrice | wbcurrency }}
        </div>
      </td>
    </tr>
    <tr *ngIf="!isAction && allowClientLessonSelection && !courseInterestsSupport">
        <td colspan="2" class="left-space">
            <table style="width: 100%;">
              <tr>
                <td
                    class="lesson-switch bottom-space ">
                    <dx-switch [(value)]="student.isAllNewSelected" [disabled]="!student.isNew" ></dx-switch>
                </td>
                <td class="bottom-space"
                    [ngClass]="{'text-muted': (!student.isNew)}">
                    <strong>{{'TXT_SELECT_ALL' | translate}}</strong>
                </td>
              </tr>
            </table>
        </td>
    </tr>
    <tr *ngIf="student.allowMultiAttendance && student.isNew && student.attend">
      <td class="left-space">
        {{'TXT_RESERVED_PLACES' | translate }}
      </td>
      <td>
        <dx-number-box id="reservedPlaces"
          [disabled]="!student.isNew || !student.attend || (isAction && student.isInThePast)"
          [(value)]="student.reservedPlaces"
          [min]="0"
          [max]="1000">
          <dx-validator [validationRules]="[{ type: 'required', message: 'TXT_ERROR_REQUIRED' | translate }, { type: 'range', max: 100, min: 1 }]"></dx-validator>
        </dx-number-box>
      </td>
    </tr>

    <tr *ngIf="!isAction && courseInterestsSupport && showHealthInCourseRegistration">
        <td
        colspan="2"
        class="notes-title left-space"
        [ngClass]="{'text-muted' : (!student.isNew || !student.attend)}">
        {{'TXT_PROFICIENCY_LEVEL' | translate }}:
      </td>
    </tr>
    <tr *ngIf="!isAction && courseInterestsSupport && showHealthInCourseRegistration">
        <td colspan="2" class="notes-editor left-space">
            <app-proficiency-level-lookup
              [disabled]="!student.isNew || !student.attend"
              [(value)]="student.selfAssessedProficiencyLevelID"></app-proficiency-level-lookup>
          </td>
    </tr>
    <tr *ngIf="!isAction && courseInterestsSupport && showHealthInCourseRegistration">
        <td
        colspan="2"
        class="notes-title left-space"
        [ngClass]="{'text-muted' : (!student.isNew || !student.attend)}">
        {{'TXT_HEALTH' | translate }}:
      </td>
    </tr>

    <tr *ngIf="!isAction && courseInterestsSupport && showHealthInCourseRegistration">
        <td colspan="2" class="notes-editor left-space">
            <dx-text-area [disabled]="!student.isNew || !student.attend"
              [(value)]="student.health"
              [placeholder]="'TXT_HEALTH_OK' | translate"
              valueChangeEvent="keyup"
              autoResizeEnabled="true"></dx-text-area>
          </td>
    </tr>
    <tr *ngIf="!isAction && courseInterestsSupport && showHealthInCourseRegistration">
        <td
          colspan="2"
          class="notes-description left-space"
          [ngClass]="{'text-muted' : (!student.isNew || !student.attend)}" >
          <div>{{'TXT_HEALTH_DESCRIPTION' | translate }}</div>
        </td>
      </tr>

    <tr *ngIf="(showNote || (!isAction && courseInterestsSupport))">
      <td
        colspan="2"
        class="notes-title left-space"
        [ngClass]="{'text-muted' : (!student.isNew || !student.attend)}">
        {{'TXT_NOTES' | translate }}:
      </td>
    </tr>
    <tr *ngIf="(showNote || (!isAction && courseInterestsSupport)) && !!noteDescriptionInCourseRegistration">
      <td
        colspan="2"
        class="notes-description left-space"
        [ngClass]="{'text-muted' : (!student.isNew || !student.attend)}" >
        <div [innerHTML]="noteDescriptionInCourseRegistration"></div>
      </td>
    </tr>
    <tr *ngIf="(showNote || (!isAction && courseInterestsSupport))">
      <td colspan="2" class="notes-editor left-space">
        <dx-text-area [disabled]="!student.isNew || !student.attend || (isAction && student.isInThePast)"
          [(value)]="student.notes"
          valueChangeEvent="keyup"
          autoResizeEnabled="true"></dx-text-area>
      </td>
    </tr>
    <tr *ngIf="!isAction && allowClientLessonSelection && !courseInterestsSupport">
      <td colspan="2" class="left-space">
        <table style="width: 100%;">
          <tr *ngFor="let lesson of student.registeredAttendance">
            <td
                class="lesson-switch">
                <dx-switch [(value)]="lesson.isRegistered" [disabled]="!lesson.isUserEditable || !student.isNew" ></dx-switch>
            </td>
            <td [ngClass]="{'text-muted': (!lesson.isUserEditable || !student.isNew)}">
                {{lesson.startDate | date: 'dd.MM. EEE (HH:mm' | uppercase}} - {{lesson.endDate | date: 'HH:mm)' }}
            </td>
            <td class="lesson-capacity"
              [ngClass]="{'text-muted': (!lesson.isUserEditable || !student.isNew)}">
               {{lesson.freePlaces}}
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
