
import { map } from 'rxjs/operators';
import {EventEmitter, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import {defer, Observable, throwError} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

import { BaseService } from './base.service';
import { TenantService } from './tenant.service';
import { WindowWrapper } from '../windowWrapper';
import { ErrorResponse } from '../models/errorResponse/errorResponse';
import { Constants } from '../constants';
import { TenantSummary } from '../models';

import { notifySuccess } from './notifications';
import {AngularFireAuth} from "@angular/fire/auth";




@Injectable()
export class LoginService extends BaseService {

  constructor(
    private http: HttpClient,
    private _window: WindowWrapper,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private tenantService: TenantService,
    private location: Location,
    public afAuth: AngularFireAuth
  ) {
    super();
  }


  onSingInRequested = new EventEmitter<any>();

  requestSignIn(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.isAuthenticated().subscribe((auth) => {
        if (auth) {
          observer.next(false);
          observer.complete();
        } else {
          this.onSingInRequested.emit(null);
          observer.next(true);
          observer.complete();

        }
      })
    });
  }

  isAuthenticated(): Observable<boolean> {
    return this.tenantService.getTenantSummary().pipe(
      map((t: TenantSummary) => t && t.loggedInUser && t.loggedInUser.isAuthenticated));
  }

  translateError(error: any, verify: boolean = false): string {
    if (!!error) {
      if (error.status == 403) {
        if (verify) {
          return this.translate.instant('TXT_OTP_VERIFY_BUSY');
        } else {
          return this.translate.instant('TXT_OTP_GENERATE_BUSY');
        }
      } else if (error.status == 500) {
        if (!!error.error && !!error.error.title && error.error.title.includes("One time password does not exist or is expired.")) {
          return this.translate.instant('TXT_OTP_DOES_NOT_EXIST');
        }
      }
      switch(error.code) {
        case 'auth/wrong-password':
          return this.translate.instant('TXT_LOGIN_ERROR_WRONG_PASSWORD');
        case 'auth/user-not-found':
          return this.translate.instant('TXT_LOGIN_ERROR_USER_NOT_FOUND');
        case 'auth/too-many-requests':
        case 'auth/user-disabled':
          return this.translate.instant('TXT_LOGIN_ERROR_USER_DISABLED');
        case 'auth/invalid-email':
          return this.translate.instant('TXT_LOGIN_ERROR_INVALID_EMAIL');
        case 'auth/email-already-in-use':
          return this.translate.instant('TXT_EMAIL_IN_USE');
        case 'auth/weak-password':
          return this.translate.instant('TXT_WEAK_PASSWORD');
        case 'auth/expired-action-code':
          return error.message;
        case 'auth/invalid-action-code':
          return error.message;
        default:
          return error.message;
      }
    } else {
      return "Unexpected error";
    }
  }

  login(email: string, password: string): Observable<void> {
    return new Observable<void>(observer => {
      this.afAuth.setPersistence("none").then(() => {
        this.afAuth.signInWithEmailAndPassword(email, password).then((user) => {
          if (!user.user.emailVerified) {
            observer.error({code: 'wb/user-email-not-verified', message: 'User email is not verified.'});
          }
          user.user.getIdToken().then((token) => {
            this.http.post(
              '/login/signin',
              {},
              { headers:
                  new HttpHeaders()
                    .set('Authorization', `Bearer ${token}`)}
            ).subscribe(() => {
              observer.next();
              observer.complete();
            }, (error) => {
              observer.error(error);
            });
          }, (error) => {
            observer.error(error);
          });
        }).catch((error) => {
          observer.error(error);
        })
      })
      .catch((error) => {
        observer.error(error);
      })
    })
  }

  createUserWithEmailAndPassword(email: string, password: string): Observable<void> {
    return new Observable<void>(observer => {
      this.afAuth.createUserWithEmailAndPassword(email, password).then(user => {
        observer.next();
        observer.complete();
      }).catch((error) => {
        observer.error(error);
      })
    });
  }

  sendEmailVerificationAuthorizationCode(email: string): Observable<void> {
    return new Observable<void>(observer => {
      this.http.post(
        '/restapi/account/email-verification/send-otp',
        { Email: email },
        {
          headers:
            new HttpHeaders()
              .set('x-sec-api-key', 'sdPTPs3XHzB5Fa7KqcK928RN9Rm9zdE97nt3t4r5')
        }
      ).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => {
        observer.error(error);
      })
    });
  }

  confirmEmailVerification(email: string, otp: string): Observable<void> {
    return new Observable<void>(observer => {
      this.http.post(
        '/restapi/account/email-verification/validate-otp',
        { Email: email, Otp: otp },
        {
          headers:
            new HttpHeaders()
              .set('x-sec-api-key', 'sdPTPs3XHzB5Fa7KqcK928RN9Rm9zdE97nt3t4r5')
        }
      ).subscribe((success: any) => {
        this.afAuth.applyActionCode(success.oobCode).then(() => {
          observer.next();
          observer.complete();
        }).catch((error) => {
          observer.error(error);
        })
      }, (error) => {
        observer.error(error);
      });
    });
  }

  sendPasswordResetAuthorizationCode(email: string): Observable<void> {
    return new Observable<void>(observer => {
      this.http.post(
        '/restapi/account/password-reset/send-otp',
        { Email: email },
        {
          headers:
            new HttpHeaders()
              .set('x-sec-api-key', 'sdPTPs3XHzB5Fa7KqcK928RN9Rm9zdE97nt3t4r5')
        }
      ).subscribe(() => {
        observer.next();
        observer.complete();
      }, (error) => {
        observer.error(error);
      })
    });
  }

  confirmPasswordReset(email: string, password: string, otp: string): Observable<void> {
    return new Observable<void>(observer => {
      this.http.post(
        '/restapi/account/password-reset/validate-otp',
        { Email: email, Otp: otp },
        {
          headers:
            new HttpHeaders()
              .set('x-sec-api-key', 'sdPTPs3XHzB5Fa7KqcK928RN9Rm9zdE97nt3t4r5')
        }
      ).subscribe((success: any) => {
        this.afAuth.confirmPasswordReset(success.oobCode, password).then(() => {
          observer.next();
          observer.complete();
        }).catch((error) => {
          observer.error(error);
        })
      }, (error) => {
        observer.error(error);
      });
    });
  }

  logout(): Observable<void> {
    return new Observable<void>(observer => {
      this.afAuth.signOut().then(() => {
          this.http.post(
            '/Login/Logout',
            {},
            {}
          ).subscribe(() => {
            observer.next();
            observer.complete();
          }, (error) => {
            observer.error(error);
          });
        }
      ).catch((error) => {
        observer.error(error);
      })
    });
  }

  passwordValidationRegEx() {
    return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9_!@#$%^&*+=\-'\[\]";~,\.><]{8,40}$/;
  }

  newUserRegistration(returnUrl?: string): void {
    if (!!sessionStorage.getItem("registrationDone")) {
      sessionStorage.removeItem("registrationDone");
    }

    if (!String.prototype.isNullOrWhitespace(returnUrl)) {
      this.router.navigate(["/my-account/user-account"], { queryParams: { registration: true, returnUrl: this.router.url } } );
    } else {
      this.router.navigate(["/my-account/user-account"], { queryParams: { registration: true } } );
    }
  }



}
