
import { refCount, publishReplay, map, catchError, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Classroom } from '../models';
import { BaseService } from './base.service';

import * as _ from 'lodash';

@Injectable()
export class ClassroomService extends BaseService {

  private baseServiceUrl: string;
  private cacheEvictionTime: number = 60000; // 60s

  private currentClassrooms: Observable<Classroom[]>;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/Api/Classrooms/';
  }

  getAll(refresh: boolean = false): Observable<Classroom[]> {
    if (!this.currentClassrooms || refresh) {

      let headers = this.getDefaultHttpHeaders();

      let url: string = this.baseServiceUrl + 'GetAll';

      this.currentClassrooms = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(Classroom, res)),
          publishReplay(1, this.cacheEvictionTime),
          refCount(),
          take(1));
    }

    return this.currentClassrooms;
  }

  getAllActive(refresh: boolean = false): Observable<Classroom[]> {
    let self = this;

    return Observable.create(observer => {
      this.getAll(refresh).subscribe((res: Classroom[]) => {
        var active = _.filter(res, { active: true, isDeleted: false });
        observer.next(active);
        observer.complete();
      }, err => observer.error(self.handleError(err)))
    });

  }

  getByID(classroomID): Observable<Classroom> {
    let self = this;

    return Observable.create(observer => {
      this.getAll().subscribe((res: Classroom[]) => {
        var classroom = _.find(res, { id: +classroomID });
        if (!!classroom)
          observer.next(classroom);
        observer.complete();
      }, err => observer.error(self.handleError(err)))
    })
  }
}
