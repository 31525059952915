import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CompensationCourse } from 'app/shared/models';
import { CourseService } from 'app/shared/services';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import Query from 'devextreme/data/query';

import * as _ from 'lodash';

@Component({
  selector: 'app-compensation-course-lookup',
  templateUrl: './compensation-course-lookup.component.html',
  styleUrls: ['./compensation-course-lookup.component.css']
})
export class CompensationCourseLookupComponent implements OnInit, OnChanges {

  constructor(private courseService: CourseService) { 
    let self = this;

    self.compensationCourses = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            if (!!this.studentId) {
              self.courseService.getCompensationCourses(self.semesterId, self.studentId, self.value, self.lessonEndDate, true)
                .subscribe((data: CompensationCourse[]) => {
                  
                  let result = data;

                  let selectCandidate = null;

                  _.forEach(result, (c: CompensationCourse) => {
                    let compAllowed = c.compensationGroupID == self.compGroupId ||
                      (!!self.acceptingGroups && self.acceptingGroups.indexOf(c.compensationGroupID) !== -1);

                      if (!compAllowed) {
                        if (self.compOverride) {
                          c.requiresCompOverride = true;
                        } else {
                          c.disabled = true;
                        }
                      } else {
                        c.disabled = false;
                        c.requiresCompOverride = false;
                        selectCandidate = c;
                      }
                  });

                  // if (!self.value && !!selectCandidate) {
                  //   self.value = selectCandidate.id;
                  // } 

                  if (loadOptions.searchValue) {                
                    result = Query(result).filter([loadOptions.searchExpr[0], loadOptions.searchOperation, loadOptions.searchValue]).toArray();
                  }

                  resolve(result);
                }, (error) => {
                  reject(error);
                });
            } else {
              resolve([]);
            }
          });
        },
        byKey: (key: any) => {
          return new Promise((resolve, reject) => {
            self.courseService.getCompensationCourses(self.semesterId, self.studentId, self.value, self.lessonEndDate, false)
             .subscribe((data) => {

                let c = _.find(data, { id: key });
                if (!!c) {
                  let compAllowed = c.compensationGroupID == self.compGroupId ||
                    (!!self.acceptingGroups && self.acceptingGroups.indexOf(c.compensationGroupID) !== -1);
                    if (!compAllowed) {
                      if (self.compOverride) {
                        c.requiresCompOverride = true;
                      } else {
                        c.disabled = true;
                      }
                    } else {
                      c.disabled = false;
                      c.requiresCompOverride = false;
                    }                    
                }

                resolve(c);
              }, (error) => {
                reject(error);
              })
          })
        },
      })
    });    
  }


  private _value: number;
  @Output() valueChange = new EventEmitter<number>();
  @Input()
  get value(): number {
    return this._value;
  }
  set value(value: number) {    
    if (this._value !== value) {
      this._value = value;
      this.valueChange.emit(value);
    }
  }

  @Input()
  disabled: boolean;

  @Input()
  studentId: number;
  
  @Input()
  semesterId: number;
  
  @Input()
  compGroupId: number;

  @Input()
  compOverride: boolean;

  @Input()
  acceptingGroups: number[];

  @Input()
  lessonEndDate: Date;

  compensationCourses: DataSource;

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {    
    if ((!!changes.value && changes.value.firstChange) || !!changes.studentId) {
      this.compensationCourses.load();
    }
  }
}
