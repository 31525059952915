<dx-popup showTitle="true"
          title="{{'TXT_LOGIN' | translate}}"
          width="350"
          height="auto"
          showCloseButton="true"
          [(visible)]="signInVisible">
  <div *dxTemplate="let data of 'content'">
    <form>
      <dx-validation-group>
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">{{ 'TXT_EMAIL' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="user.email"
                           mode="email"
                           [readOnly]="emailVerificationMode">
                <dx-validator
                  [validationRules]="[{ type: 'email', message: 'TXT_LOGIN_ERROR_INVALID_EMAIL' | translate }, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_EMAIL' | translate) }]">
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ 'TXT_PASSWORD' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="user.password"
                           mode="password"
                           [readOnly]="emailVerificationMode">
                <dx-validator
                  [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PASSWORD' | translate) }]">
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <p *ngIf="emailVerificationMode" class="error-message">{{'TXT_NOT_VERIFIED_EMAIL_DESC' | translate}}</p>
          <p *ngIf="emailVerificationMode" class="otp-description">{{'TXT_OTP_DESC' | translate}}</p>
          <div *ngIf="emailVerificationMode" class="dx-field">
            <div class="dx-field-label">{{ 'TXT_LAB_OTP' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="user.otp"
                           mode="text">
                <dx-validator
                  [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_LAB_OTP' | translate) }, { type: 'stringLength', max: 6, min: 6 }]">
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
        </div>
        <dx-validation-summary></dx-validation-summary>
        <p class="error-message">{{errorMessage}}</p>
        <div class="text-center wb-button-margin">
          <dx-button text="{{'TXT_LOGIN' | translate}}"
                     class="wb-button"
                     [disabled]="isSigningIn"
                     type="success"
                     (onClick)="onSignIn($event)">
          </dx-button>
          <div class="forgot-password">
            <dx-button text="{{'TXT_DID_YOU_FORGET_YOUR_PASSWORD' | translate}}"
                       stylingMode="text"
                       [disabled]="isSigningIn"
                       type="default"
                       (onClick)="onPasswordReset()">
            </dx-button>
          </div>
        </div>
      </dx-validation-group>
    </form>
  </div>
</dx-popup>
<dx-popup showTitle="true"
          title="{{'TXT_PASSWORD_RESET' | translate}}"
          width="350"
          height="auto"
          showCloseButton="true"
          [(visible)]="passwordResetVisible">
  <div *dxTemplate="let data of 'content'">
    <form>
      <dx-validation-group>
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">{{ 'TXT_EMAIL' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="user.email"
                           mode="email">
                <dx-validator
                  [validationRules]="[{ type: 'email', message: 'TXT_LOGIN_ERROR_INVALID_EMAIL' | translate }, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_EMAIL' | translate) }]">
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <p *ngIf="authCodeSend" class="otp-description">{{'TXT_OTP_DESC' | translate}}</p>
          <div class="dx-field" *ngIf="authCodeSend">
            <div class="dx-field-label">{{ 'TXT_NEW_PASSWORD' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="user.password"
                           mode="password">
                <dx-validator
                  [validationRules]="[{ type: 'pattern', pattern: passwordValidationRegEx, message: ('TXT_WEAK_PASSWORD' | translate)}, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PASSWORD' | translate) }]">
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field" *ngIf="authCodeSend">
            <div class="dx-field-label"></div>
            <div class="dx-field-value wb-field-value-help">{{'TXT_PASSWORD_HELP' | translate}}</div>
          </div>
          <div *ngIf="authCodeSend" class="dx-field">
            <div class="dx-field-label">{{ 'TXT_LAB_OTP' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="user.otp"
                           mode="text">
                <dx-validator
                  [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_LAB_OTP' | translate) }, { type: 'stringLength', max: 6, min: 6 }]">
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
        </div>

      <dx-validation-summary></dx-validation-summary>
      <p class="error-message">{{errorMessage}}</p>
      <div class="text-center wb-button-margin row">
        <div class="col-xs-6 text-right">
          <dx-button text="{{'TXT_CANCEL' | translate}}"
                     class="wb-button"
                     type="normal"
                     (onClick)="onCancel()">
          </dx-button>
        </div>
        <div class="col-xs-6 text-left">
          <dx-button text="{{'TXT_NEXT' | translate}}"
                     class="wb-button"
                     [disabled]="isNextDisabled"
                     type="success"
                     (onClick)="onNext($event)">
          </dx-button>
        </div>
      </div>
      </dx-validation-group>
    </form>
  </div>
</dx-popup>
