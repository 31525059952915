import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {SemesterWithCompGroup} from "../../shared/models";

@Component({
  selector: 'app-activities-attendance',
  templateUrl: './activities-attendance.component.html',
  styleUrls: ['./activities-attendance.component.css']
})
export class ActivitiesAttendanceComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onSemesterSelect(data: SemesterWithCompGroup) {
    this.router.navigate(["/attendance/activities/semester", data.semesterID], { replaceUrl: data.isAutoRedirect });
  }

}
