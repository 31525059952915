import { Type } from "class-transformer";

import { FranchiseTenantInfo } from './franchiseTenantInfo';
import { FranchiseCentralTenantInfo } from './franchiseCentralTenantInfo';
import { FranchiseLevel } from './franchiseLevel';

export class TenantInfo {

  tenantID: number;
  friendlyName: string;
  mainWebPage: string;
  address: string;
  alias: string;
  domain: string;
  description: string;
  pisFranchiseCentral: boolean;
  isFranchise: boolean;
  isActive: boolean;
  currency: string;

  @Type(() => FranchiseTenantInfo)
  franchises: FranchiseTenantInfo[];

  @Type(() => FranchiseCentralTenantInfo)
  franchiseCentrals: FranchiseCentralTenantInfo[];

  @Type(() => FranchiseLevel)
  franchiseLevels: FranchiseLevel[];
};