
import { map, catchError, publishReplay, refCount, take, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Domain } from '../models';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class DomainService extends BaseService {

  private baseServiceUrl: string;

  private cacheEvictionTime: number = 60000; // 60s
  private domains: Observable<Domain[]>;

  constructor(
    private http: HttpClient
  ) {
    super();
    this.baseServiceUrl = '/restapi/domains/';
  }

  getAll(refresh: boolean = false): Observable<Domain[]> {
    if (!this.domains || refresh) {
      let headers = this.getDefaultHttpHeaders();
      let url: string = this.baseServiceUrl + '?expand=specializations';
      this.domains = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(Domain, res)
            .filter(x => !(x.disabledForClients &&
                             (x.specializations || []).reduce((acc, n) => acc && n.disabledForClients, true)
                          )
            ).map(x => {
                x.specializations = (x.specializations || []).filter(y => !y.disabledForClients);
                return x;
            })
          ),
          publishReplay(1, this.cacheEvictionTime),
          refCount(),
          take(1));
    }

    return this.domains;
  }
}
