<form style="height:100%; display: block;" >
    <dx-validation-group >
      <dx-tab-panel height="100%"
                    #targetTabPanel
        [(selectedIndex)]="selectedTabIndex"
        [dataSource]="tabPanelItems"
        [loop]="true"
        [animationEnabled]="true"
        [deferRendering]="false"
        [swipeEnabled]="true">
        <div *dxTemplate="let item of 'title'">
            <span>{{item.title | translate}}</span>
        </div>
        <div *dxTemplate="let template of 'template1'" [attr.data-tab-panel-index]="template.id">
          <dx-scroll-view>
            <app-sign-up-edit
              *ngIf="registrationMode && !isAuthenticated"
              [user]="signInUser"
              [otpWasSend]="signInOtpWasSend"
            >
            </app-sign-up-edit>
            <app-user-account-detail
              *ngIf="isAuthenticated"
             [user]="user"
             [showFieldsetHeaders]="true"
             [disableEmailEdit]="false">
            </app-user-account-detail>
            <dx-validation-summary (onItemClick)="onValidationSummaryItemClick($event)"></dx-validation-summary>
            <p class="error-message">{{errorMessage}}</p>
            <div class="wb-button-margin text-center">
                <dx-button
                    *ngIf="!registrationMode"
                    text="{{'TXT_SAVE' | translate}}"
                    icon="floppy"
                    type="success"
                    (onClick)="onUserSave($event)">
                </dx-button>
                <dx-button
                  *ngIf="registrationMode"
                  text="{{'TXT_NEXT' | translate}}"
                  icon="chevronnext"
                  type="success"
                  (onClick)="onSignUpNext($event)">
                </dx-button>
              </div>
          </dx-scroll-view>
        </div>
        <div *dxTemplate="let template of 'template2'" [attr.data-tab-panel-index]="template.id">
          <dx-scroll-view>
            <app-students-edit-detail
              *ngIf="isAuthenticated"
              [user]="user"
              [students]="students">
            </app-students-edit-detail>
            <dx-validation-summary (onItemClick)="onValidationSummaryItemClick($event)"></dx-validation-summary>
            <div class="wb-button-margin text-center">
              <dx-button text="{{'TXT_SAVE' | translate}}"
                  *ngIf="!registrationMode"
                  icon="floppy"
                  type="success"
                  [disabled]="isSavePanelVisible"
                  (onClick)="onStudentsSave($event)">
              </dx-button>
              <dx-button
                  *ngIf="registrationMode"
                  text="{{'TXT_NEXT' | translate}}"
                  icon="chevronnext"
                  type="success"
                  (onClick)="onNext($event)">
              </dx-button>
            </div>
          </dx-scroll-view>
        </div>
        <div *dxTemplate="let template of 'template3'" [attr.data-tab-panel-index]="template.id">
          <dx-scroll-view>
            <app-user-contact-edit-detail
              *ngIf="isAuthenticated"
              [user]="user"
              [showNonUserInfo]="true"
              [showContactFieldsetHeader]="true">
            </app-user-contact-edit-detail>
            <dx-validation-summary (onItemClick)="onValidationSummaryItemClick($event)"></dx-validation-summary>
            <div class="wb-button-margin text-center">
              <dx-button
                *ngIf="registrationMode && !hasConsents"
                text="{{'TXT_FINISH_REGISTRATION' | translate}}"
                icon="check"
                type="success"
                [disabled]="isSavePanelVisible"
                (onClick)="onFinishRegistration($event)">
              </dx-button>
              <dx-button text="{{'TXT_SAVE' | translate}}"
                  *ngIf="!registrationMode"
                  icon="floppy"
                  type="success"
                  [disabled]="isSavePanelVisible"
                  (onClick)="onUserSave($event)">
              </dx-button>
              <dx-button
                  *ngIf="registrationMode && !!hasConsents"
                  text="{{'TXT_NEXT' | translate}}"
                  icon="chevronnext"
                  type="success"
                  (onClick)="onNext($event)">
              </dx-button>
            </div>
          </dx-scroll-view>
        </div>
        <div *dxTemplate="let template of 'template4'" [attr.data-tab-panel-index]="template.id">
          <dx-scroll-view *ngIf="!registrationMode">
            <app-my-account-detail-due-payments>

            </app-my-account-detail-due-payments>
          </dx-scroll-view>
        </div>
        <div *dxTemplate="let template of 'template5'" [attr.data-tab-panel-index]="template.id">
          <dx-scroll-view *ngIf="!registrationMode">
            <app-my-account-consents>

            </app-my-account-consents>
            <div class="wb-button-margin text-center">
              <dx-button
                *ngIf="registrationMode && hasConsents"
                text="{{'TXT_FINISH_REGISTRATION' | translate}}"
                icon="check"
                type="success"
                [disabled]="isSavePanelVisible"
                (onClick)="onFinishRegistration($event)">
              </dx-button>
            </div>
          </dx-scroll-view>
        </div>
      </dx-tab-panel>
    </dx-validation-group>
  </form>
  <dx-load-panel
      [visible]="isSavePanelVisible"
      message="{{'TXT_SAVING' | translate}}">
  </dx-load-panel>
  <dx-load-panel [visible]="isLoadPanelVisible">
  </dx-load-panel>
