
import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { AgeRange } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class AgeRangeService extends BaseService {

  private baseServiceUrl: string;
  private currentAgeRanges: Observable<AgeRange[]>;

  constructor(
    private http: HttpClient) {
    super();
    this.baseServiceUrl = '/Api/AgeRanges/';

  }

  getAll(refresh: boolean = false): Observable<AgeRange[]> {
    if (!this.currentAgeRanges || refresh) {

      let headers = this.getDefaultHttpHeaders();

      let url: string = this.baseServiceUrl + 'GetAll';

      this.currentAgeRanges = this.http
        .get(url, { headers: headers }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(AgeRange, res)),
        publishReplay(1),
        refCount(),);
    }

    return this.currentAgeRanges;
  }

}
