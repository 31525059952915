import { Type, Exclude } from "class-transformer";
import { TransformDate } from '../../decorators';

import { EntityReferenceWithName } from './entityReferenceWithName';
import { GcpStudent } from './gcpStudent';

export class Reservation {
  id: string;
  employeeId: string;
  placeId: string;
  price: number;

  @TransformDate()
  registrationDate: Date;
  @TransformDate()
  reservationStartDate: Date;
  @TransformDate()
  reservationEndDate: Date;
  userId: string;
  studentIds: string[];
  notes: string;

  @Type(() => EntityReferenceWithName)
  domain: EntityReferenceWithName;

  @Type(() => EntityReferenceWithName)
  domainSpecialization: EntityReferenceWithName;

  invoiceId: string;

  @Type(() => GcpStudent)
  students: GcpStudent[];
}