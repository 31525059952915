import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';


import {CourseUserRegistration, TenantSummary} from '../../../shared/models';
import { CourseRegistrationService, TenantService } from '../../../shared/services';

import { Observable } from 'rxjs';
import {map} from "rxjs/operators";

@Component({
  selector: 'app-action-detail-register-confirm',
  templateUrl: './action-detail-register-confirm.component.html',
  styleUrls: ['./action-detail-register-confirm.component.css']
})
export class ActionDetailRegisterConfirmComponent implements OnInit {

  constructor(
    private courseRegistrationService: CourseRegistrationService,
    private tenantService: TenantService) { }

  @Input() courseUserRegistration: CourseUserRegistration;
  @Input()
  set shown(val: boolean) {
    if (val) {
      this.onCalculateAgreementShowHide().subscribe(t => {
        this.courseRegistrationService.getConfirmRegistrationDescription(
          this.courseUserRegistration,
          true
        ).subscribe(data => {
          this.registerDescription = data;

          if (!data || !data[0]) {
            this.requireTermsAndConditionsAgreementConfirmed = true;
            this.requireTradeTermsAgreementConfirmed = true;
            this.showAgreementSection = false;
          }
        })
      })
    }
  }
  @Output() onCancel = new EventEmitter<boolean>();
  @Output() onConfirm = new EventEmitter<boolean>();

  requireTermsAndConditionsAgreement: boolean;
  requireTradeTermsAgreement: boolean;

  showAgreementSection: boolean = false;

  requireTermsAndConditionsAgreementConfirmed: boolean = false;
  requireTradeTermsAgreementConfirmed: boolean = false;

  confirmVisible: boolean = false;

  registerDescription: [string, string];

  ngOnInit() {
  }

  onCalculateAgreementShowHide(): Observable<TenantSummary> {
    return this.tenantService.getTenantSummary().pipe(map(t => {

      this.requireTermsAndConditionsAgreement = t.tenantSetting.requireTermsAndConditionsAgreement;
      if (!this.requireTermsAndConditionsAgreement) {
        this.requireTermsAndConditionsAgreementConfirmed = true;
      } else {
        this.requireTermsAndConditionsAgreementConfirmed = false;
      }

      this.requireTradeTermsAgreement = t.tenantSetting.requireTradeTermsAgreement;
      if (!this.requireTradeTermsAgreement) {
        this.requireTradeTermsAgreementConfirmed = true;
      } else {
        this.requireTradeTermsAgreementConfirmed = false;
      }

      this.showAgreementSection = !!t.tenantSetting.requireTermsAndConditionsAgreement ||
        !!t.tenantSetting.requireTradeTermsAgreement;

      return t;
    }));
  }

  onNoClick(params) {
    this.onCancel.emit(true);
  }

  onYesClick(params) {
      if (!this.requireTradeTermsAgreementConfirmed ||
          !this.requireTermsAndConditionsAgreementConfirmed) {
      this.confirmVisible = true;
    } else {
      this.onConfirm.emit(true);
    }
  }

  onInformationConfirm(params) {
    this.confirmVisible = false;
  }

}
