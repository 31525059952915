import { Type, Expose, Transform, Exclude } from "class-transformer";
import { TransformDate } from '../../decorators';

import { UserScheduleAttendance } from './userScheduleAttendance';
import { UserScheduleCompensationWaitQueue } from './userScheduleCompensationWaitQueue';
import { AttendanceStatusIndication, SubjectCategory } from '../enums';

export class UserSchedule {

    courseScheduleID: number;
    courseID: number;

    @TransformDate()
    startDate: Date;

    @TransformDate()
    endDate: Date;

    capacity: number;
    filled: number;
    teacherName: string;

    employeeID: number;

    classroomID: number;
    semesterID: number;

    @Type(() => UserScheduleAttendance)
    attendance: UserScheduleAttendance[];

    @Type(() => UserScheduleCompensationWaitQueue)
    waitQueue: UserScheduleCompensationWaitQueue[];

    subject: string;
    subjectCategoryID: number;
    notes: string;

    teacherPhoto: string;

    subjectDescription: string;
    courseDescription: string;
    showWaitQueue: boolean;
    showActionNote: boolean;
    recurrenceType: number;
    status: AttendanceStatusIndication;
    type: SubjectCategory;

    @Exclude()
    get filledCapacity(): string {
        return `${this.filled} / ${this.capacity}`;
    }
}