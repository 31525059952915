import { Injectable } from '@angular/core';

import { NotificationType } from '../models/enums';
import { Constants } from '../constants';

import dxNotify from 'devextreme/ui/notify';

export function notifyError(message: string) {
  notify(message, NotificationType.Error);
}

export function notifySuccess(message: string) {
  notify(message, NotificationType.Success);
}

export function notifyWarning(message: string) {
  notify(message, NotificationType.Warning);
}

export function notify(message: string, notificationType: NotificationType) {
  let strNotificationType: string = Constants.notifySuccess;

  switch (notificationType) {
    case NotificationType.Error:
      strNotificationType = Constants.notifyError;
      break;
    case NotificationType.Success:
      strNotificationType = Constants.notifySuccess;
      break;
    case NotificationType.Warning:
      strNotificationType = Constants.notifyWarning;
      break;
  }

  dxNotify({
    message: message,
    type: strNotificationType,
    displayTime: Constants.defaultNotifyShowTime,
    closeOnSwipe: true,
    closeOnClick: true,
    closeOnOutsideClick: false
  });
}

