import { Type, Transform, Exclude } from "class-transformer";
import { CourseRegistrationInvoiceLine } from './courseRegistrationInvoiceLine'

import { InvoiceLineType } from '../enums/invoiceLineType';
import { CourseRegistrationRef } from './courseRegistrationRef';
import { CourseRegistrationInvoiceRef } from './courseRegistrationInvoiceRef';
import { Course } from '../course/course';

import { ICoursePriceService } from '../../services/icourse-price.service';

import { TransformDate } from '../../decorators';

import * as _ from 'lodash';
import * as moment from 'moment';

export class CourseRegistrationInvoice implements CourseRegistrationInvoiceRef {
    @Type(() => CourseRegistrationInvoiceLine)
    lines: CourseRegistrationInvoiceLine[];

    @TransformDate()
    dueDate: Date;

    // Non DTO properties
    @Exclude()
    get clientOnlyLines(): CourseRegistrationInvoiceLine[] {
        return _.filter(this.lines, { 'isClientVisible': true });
    }

    @Exclude()
    get noOfRegisteredAttendances(): number {
        return _.reduce(this.$courseRegistration.registeredAttendance, function (total, n) {
            if (n.isRegistered) {
                return total + (n.attendanceCount || 1);
            } else {
                return total;
            }
        }, 0);
    }

    @Exclude()
    get total(): number {
        return _.reduce(this.lines, function (total, n) {
            if (n.isValid) {
                return total + n.price;
            } else {
                return total;
            }
        }, 0);
    }

    @Exclude()
    get basePrice(): number {
        var courseFee = _.find(this.lines, { lineType: InvoiceLineType.CourseFee });
        return !!courseFee
            ? courseFee.price
            : 0;
    }


    @Exclude()
    get isModified(): boolean {
        // Is any of the current lines modified?
        return _.reduce(this.lines, function (total, n) {
            return total || n.isModified;
        }, false) || !!this.dueDate;
    }

    @Exclude()
    get membershipNow(): Date {
        let promotionNow = new Date();

        var registeredLessons = _.sortBy(_.filter(this.$courseRegistration.registeredAttendance, { 'isRegistered': true }), 'startDate');

        if (registeredLessons && registeredLessons.length) {
            promotionNow = registeredLessons[0].startDate;
        }

        return moment(promotionNow).startOf('day').toDate();
    }

    @Exclude()
    $courseRegistration: CourseRegistrationRef;

    @Exclude()
    $course: Course;

    @Exclude()
    $coursePriceService: ICoursePriceService;

    useAmortizationSchedule: boolean;

    amortizationScheduleSurchargePct: number;

    @Exclude()
    totalByAmortizationSchedule: number;
}