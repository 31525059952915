import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HtmlContentService } from '../shared/services';
import { HtmlContentType } from '../shared/models/enums';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-html-content',
  templateUrl: './html-content.component.html',
  styleUrls: ['./html-content.component.css']
})
export class HtmlContentComponent implements OnInit {

  htmlContentType: Observable<HtmlContentType>;  
  htmlContentUrl: SafeResourceUrl;

  constructor(
    private route: ActivatedRoute,
    private htmlContentService: HtmlContentService,
    private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    let self = this;

    self.htmlContentType = this.route
      .params
      .pipe(map(params => Number(params['htmlContentType'])));  
      
    self.htmlContentType.subscribe(id => {
      let htmlContent = self.htmlContentService.getById(id);
      htmlContent.subscribe(c => {
        self.htmlContentUrl = self.getBlobURL(c.htmlData);
      });
    });
  }

  getBlobURL(htmlContent: string): SafeResourceUrl {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(blob);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }

}
