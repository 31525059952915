
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Invoice } from '../models/invoice/invoice';
import { BaseService } from './base.service';





import * as _ from 'lodash';

@Injectable()
export class InvoiceService extends BaseService {

  private baseServiceUrl: string;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/Api/Invoices/';
  }


  getLatestByCourseRegistration(courseRegistrationID: number): Observable<Invoice> {
    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("courseRegistrationID", courseRegistrationID.toString());

    let url: string = this.baseServiceUrl + 'GetLatestByCourseRegistration';

    return this.http
      .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object) => plainToClass<Invoice, Object>(Invoice, res)))
  }
}
