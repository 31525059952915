export class StudentPromotion {
    studentID: number;
    buy: boolean;
    owns: boolean;
    isMandatory: boolean;
    membershipID: number;
    name: string;
    description: string;
    price: number;
    discountPct: number;
    validFrom: Date;
    validTo: Date;
}