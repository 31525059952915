export class CompensationGroup {

    compensationGroupID: number;

    name: string;

    description: string;

    isDefault: boolean;

    active: boolean;

    isDeleted: boolean;
}