
import { Type, Expose, Transform, Exclude } from "class-transformer";

import {
    CourseAttendanceType,
    CourseAttendanceStatus,
    CourseAttendanceFeeType
} from '../enums';

export class UserScheduleAttendance {

    studentID: number;

    @Expose({ name: "courseAttendanceTypeID" })
    courseAttendanceType: CourseAttendanceType;

    @Expose({ name: "courseAttendanceStatusID" })
    courseAttendanceStatus: CourseAttendanceStatus;

    @Expose({ name: "courseAttendanceFeeTypeID" })
    courseAttendanceFeeType: CourseAttendanceFeeType;
}