import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { BaseGuard } from './baseGuard';

import { TenantService, LoginService } from '../services';

import { Observable } from 'rxjs';

@Injectable()
export class CanActivateForActivitiesAttendanceGuard extends BaseGuard implements CanActivate {

    constructor(
        private tenantService: TenantService,
        loginService: LoginService,
        router: Router) {
        super(router, loginService);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let self = this;

        return new Observable<boolean>(observer => {
            self.tenantService.getTenantSummary().subscribe((t) => {
                if (t.loggedInUser && t.loggedInUser.isAuthenticated) {
                    if (t.isInRole("ActivityAttendance")) {
                        observer.next(true);
                        observer.complete();
                    }
                    else {
                        observer.next(false);
                        self.navigateToHome();
                        observer.complete();
                    }
                } else {
                    observer.next(false);
                    self.navigateToLogin(route);
                    observer.complete();
                }
            },
                err => {
                    observer.error(err);
                    self.navigateToLogin(route);
                });
        });
    }
}
