<div class="component-base">  
  <dx-lookup  
    class="classroom-lookup"
    [dataSource]="classrooms"
    valueExpr="id"
    fullScreen="true"
    displayExpr="name"
    [(value)]="classroomID"
    [searchEnabled]="true"
    [searchExpr]="['name']"
    (onPullRefresh)="onPullClassroomsRefresh()">    
  </dx-lookup>
  <dx-calendar
    class="calendar"
    width="100%"  
    height="250px"     
    cellTemplate="template" 
    [(value)]="selectedDate">
    <div *dxTemplate="let item of 'template'">       
      <span [style.font-weight]="getDayStyleWeight(item.date, item.text, item.view)">{{item.text}}</span>
    </div> 
  </dx-calendar>
</div>