
import { Observable, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { plainToClass, classToPlain } from "class-transformer";

import { BaseService } from './base.service';

import { AmortizationScheduleMode } from '../models/enums';

// services
import { StudentService } from './student.service';
import { AttendanceService } from './attendance.service';
import { InvoiceService } from './invoice.service';
import { PromotionService } from './promotion.service';
import { CourseService } from './course.service';
import { CourseScheduleService } from './course-schedule.service';
import { CoursePriceService } from './course-price.service';
import { ClassroomService } from './classroom.service';

import { TranslateService } from '@ngx-translate/core'

import { notifySuccess } from './notifications';

import {
  Student,
  Attendance,
  Invoice,
  InvoiceLineItem,
  CourseUserRegistration,
  CourseRegistration,
  CourseRegistrationAttendance,
  Course,
  CourseSchedule,
  StudentPromotion,
  CourseRegistrationInvoice,
  CourseRegistrationInvoiceLine,
  CourseUserRegistrationSchedule
} from '../models';

import {
  SubjectCategory,
  CourseAttendanceType,
  CourseAttendanceStatus,
  InvoiceLineType
} from '../models/enums';





import * as _ from 'lodash';
import * as moment from "moment";

export interface GetCourseUserRegistrationParams {
  courseID: number,
  userID: number,
  includeInterestsAsVirtual: boolean,
  allowClientLessonSelection: boolean,
  preselectSingleStudentAttendance: boolean,
  refresh: boolean
}

@Injectable()
export class CourseRegistrationService extends BaseService {

  private baseServiceUrl: string;

  private needAttendanceRefresh: boolean = false;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private studentService: StudentService,
    private attendanceService: AttendanceService,
    private invoiceService: InvoiceService,
    private promotionService: PromotionService,
    private courseService: CourseService,
    private courseScheduleService: CourseScheduleService,
    private coursePriceService: CoursePriceService,
    private classroomService: ClassroomService) {

    super();
    this.baseServiceUrl = '/Api/CourseRegistrations/';
  }

  saveActionRegistration(courseUserRegistration: CourseUserRegistration): Observable<Object> {

    this.needAttendanceRefresh = true;

    let attendOnlyRegistrations = _.filter(courseUserRegistration.courseRegistrations, { attend: true });

    let url: string = this.baseServiceUrl + 'InsertAll';

    let headers = this.getDefaultHttpHeaders();
    let self = this;

    return new Observable<Object>(observer => {
      let plain = classToPlain(attendOnlyRegistrations);
      self.http.post(url, {
        models: {
          model: plain,
          courseID: _.first(courseUserRegistration.courseRegistrations).courseID
        },
        sendEmailNotification: true,
        sendSmsNotification: false,
        subjectCategory: SubjectCategory.OneOffActions.toString()
      }, { headers: headers })
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            observer.next(value);
            observer.complete();
          },
          err => {
            self.handleError(err)
            observer.error(err);
          })
      /* complete */
    });
  }

  saveFullCourseInterest(courseUserRegistration: CourseUserRegistration, course: Course): Observable<Object> {

    let attendOnlyRegistrations = _.filter(courseUserRegistration.courseRegistrations, { attend: true, isNew: true });
    let url: string = '/restapi/course-registrations/full-course-interest';
    let headers = this.getDefaultHttpHeaders();
    let self = this;

    return new Observable<Object>(observer => {

      this.classroomService.getByID(course.classroomID)
        .subscribe(classroom => {
          let httpCalls = [];

          _.forEach(attendOnlyRegistrations, r => {
            httpCalls.push(self.http.post(url, {
              userId: r.parentID,
              studentId: r.studentID,
              courseId: course.courseID,
              semesterId: course.semesterID,
              subjectId: course.subjectID,
              classroomId: course.classroomID,
              teacherId: course.employeeID,
              courseName: course.name,
              courseAbbreviation: course.abbreviation,
              courseFullName: r.courseFullName,
              courseStartDate: course.firstLesson,
              courseEndDate: course.lastLesson,
              subjectName: course.subjectName,
              classroomName: classroom.name,
              teacherFullName: course.teacher,
              notes: r.notes,
            }, { headers: headers }));
          });

          forkJoin(httpCalls)
            .subscribe(
              (res: any[]) => { /* success */
                self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
                  notifySuccess(res);
                });

                observer.next(res);
                observer.complete();
              },
              err => observer.error(self.handleError(err)))
        },
          err => observer.error(self.handleError(err)));
    });
  }

  saveCourseRegistration(courseUserRegistration: CourseUserRegistration): Observable<Object> {

    let curClone = _.cloneDeep(courseUserRegistration);

    let attendOnlyRegistrations = _.filter(curClone.courseRegistrations, { attend: true, isNew: true });
    _.forEach(attendOnlyRegistrations, r => {
      r.registeredAttendance = _.filter(r.registeredAttendance, { isRegistered: true });
    });

    let url: string = this.baseServiceUrl + 'InsertAll';

    let headers = this.getDefaultHttpHeaders();

    let self = this;

    return new Observable<Object>(observer => {
      let plain = classToPlain(attendOnlyRegistrations);
      self.http.post(url, {
        models: {
          model: plain,
          courseID: _.first(curClone.courseRegistrations).courseID
        },
        sendEmailNotification: true,
        sendSmsNotification: false,
        subjectCategory: SubjectCategory.RegularCourses.toString()
      }, { headers: headers })
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            observer.next(value);
            observer.complete();
          },
          err => observer.error(self.handleError(err)))
      /* complete */
    });
  }

  // ret: [registering students description, unregistering students description]
  getConfirmRegistrationDescription(
    courseUserRegistration: CourseUserRegistration, isAction: boolean): Observable<[string, string]> {

    let self = this;

    let courseFullName: string = courseUserRegistration.courseRegistrations.length > 0
      ? courseUserRegistration.courseRegistrations[0].courseFullName
      : "";

    let regStudentsArray: string[] = _.map(
      _.filter(courseUserRegistration.courseRegistrations, { isModified: true, attend: true }),
      function (n) { return n.studentFullName; });

    let unRegStudentsArray: string[] = _.map(
      _.filter(courseUserRegistration.courseRegistrations, { isModified: true, attend: false }),
      function (n) { return n.studentFullName; });

    let regStudents: string = _.join(regStudentsArray, ', ');
    let unRegStudents: string = _.join(unRegStudentsArray, ', ');

    let regPlural: boolean = regStudentsArray.length > 1;
    let unRegPlular: boolean = unRegStudentsArray.length > 1;

    return new Observable<[string, string]>(observer => {

      forkJoin([
        this.translateService.get('TXT_STUDENT_PLULAR'),               // 0
        this.translateService.get('TXT_STUDENT_SINGULAR'),             // 1
        this.translateService.get('TXT_REGISTER_TO_ACTION_DESC'),      // 2
        this.translateService.get('TXT_UNREGISTER_FROM_ACTION_DESC'),  // 3
        this.translateService.get('TXT_REGISTER_TO_COURSE_DESC'),      // 4
        this.translateService.get('TXT_UNREGISTER_FROM_COURSE_DESC')   // 5
      ]).subscribe((res: String[]) => {

        let regResult: string = "";
        let unRegResult: string = "";

        if (regStudents) {
          if (regPlural) {
            regResult = res[0] + ' ' + regStudents;
          } else {
            regResult = res[1] + ' ' + regStudents;
          }
        }

        if (unRegStudents) {
          if (unRegPlular) {
            unRegResult = res[0] + ' ' + unRegStudents;
          } else {
            unRegResult = res[1] + ' ' + unRegStudents;
          }
        }

        if (isAction) {
          if (regResult) {
            regResult = res[2].f(regResult, courseFullName);
          }
          if (unRegResult) {
            unRegResult = res[3].f(unRegResult, courseFullName);
          }
        } else {
          if (regResult) {
            regResult = res[4].f(regResult, courseFullName);
          }
          if (unRegResult) {
            unRegResult = res[5].f(unRegResult, courseFullName);
          }
        }

        observer.next([regResult, unRegResult]);
        observer.complete();
      }, (error: any) => {
        observer.error(self.handleError(error));
      });
    });
  }

  getCourseUserRegistration(params: GetCourseUserRegistrationParams): Observable<CourseUserRegistration> {

    let self = this;

    return new Observable<CourseUserRegistration>(observer => {
      forkJoin([
        self.studentService.getByUserID(params.userID),
        self.getAllForCourseAndUser(params.courseID, params.userID, params.includeInterestsAsVirtual),
        self.attendanceService.getAllForCourseAndUser(params.courseID, params.userID, self.needAttendanceRefresh),
        self.courseService.getCourse(params.courseID, params.refresh),
        self.courseScheduleService.getAllForCourse(params.courseID, params.refresh)]
      ).subscribe((res: [Student[], CourseRegistration[], Attendance[], Course, CourseSchedule[]]) => {

        self.needAttendanceRefresh = false;

        let students = res[0];
        let studentsRegistrations = res[1];
        let studentsAttendance = res[2];
        let course = res[3];
        let courseSchedules = res[4];

        let promotionNow = self.getNearestLessonDate(courseSchedules);

        // Idealy, this should be nearest registered lesson, but this is important only
        // for course registration edits. New registrations have validity solved in the model
        let studentPromotionNow = self.getNearestLessonDate(courseSchedules);

        let nextObservables = [];
        nextObservables.push(
          self.promotionService.getPromotionsForUser(params.userID, course.subjectCategory, promotionNow, studentPromotionNow, false, params.refresh)
        );
        _.forEach(studentsRegistrations, function (r) {
          nextObservables.push(self.invoiceService.getLatestByCourseRegistration(r.courseRegistrationID));
        });

        forkJoin(nextObservables)
          .subscribe((res: any[]) => {
            let studentsPromotions: StudentPromotion[] = _.first(res);
            let invoices: Invoice[] = _.slice(res, 1);

            let result = self.buildCourseUserRegistrationModel(
              course,
              courseSchedules,
              students,
              studentsRegistrations,
              studentsAttendance,
              invoices,
              studentsPromotions,
              params.allowClientLessonSelection,
              params.preselectSingleStudentAttendance
            );

            observer.next(result);
            observer.complete();
          }, (error: any) => {
            observer.error(self.handleError(error));
          });
      }, (error: any) => {
        observer.error(self.handleError(error));
      });
    });
  }

  private getAllForCourseAndUser(
    courseID: number,
    userID: number,
    includeInterestsAsVirtual: boolean
  ): Observable<CourseRegistration[]> {

    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("courseID", courseID.toString())
      .append("userID", userID.toString())
      .append("includeInterestsAsVirtual", includeInterestsAsVirtual.toString());

    let url: string = this.baseServiceUrl + 'GetAllByCourseAndUser';

    return this.http
      .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(CourseRegistration, res)));
  }

  private getNearestLessonDate(courseSchedules: CourseSchedule[], now: Date = new Date()): Date {
    if (courseSchedules && courseSchedules.length) {
      let sorted = _.sortBy(_.filter(courseSchedules, (s) => moment(s.startDate).isAfter(now)), 'startDate');
      if (sorted.length) {
        return sorted[0].startDate;
      }
    }
    return now;
  }

  private buildCourseUserRegistrationModel(
    course: Course,
    courseSchedules: CourseSchedule[],
    students: Student[],
    studentRegistrations: CourseRegistration[],
    studentAttendances: Attendance[],
    studentInvoices: Invoice[],
    studentPromotions: StudentPromotion[],
    allowClientLessonSelection: boolean,
    preselectSingleStudentAttendance: boolean
  ): CourseUserRegistration {

    let self = this;

    let studentRegistrationsAll = studentRegistrations.slice(); // Copy

    _.forEach(students, function (student) {
      if (!_.find(studentRegistrations, { studentID: student.id })) {
        studentRegistrationsAll.push(self.createNewCourseRegistrationModel(
          student,
          course,
          courseSchedules,
          allowClientLessonSelection));
      }
    });

    _.forEach(studentRegistrationsAll, function (studentRegistration) {
      self.initializeCourseRegistrationModel(
        studentRegistration,
        _.find(studentInvoices, { customerStudentID: studentRegistration.studentID }),
        _.filter(studentPromotions, { studentID: studentRegistration.studentID }),
        _.filter(studentAttendances, { studentID: studentRegistration.studentID }),
        course,
        allowClientLessonSelection);
    });

    let allCourseRegistrationAttendance: CourseRegistrationAttendance[] = [];
    _.forEach(studentRegistrationsAll, function (cr) {
      allCourseRegistrationAttendance.push.apply(allCourseRegistrationAttendance, cr.registeredAttendance);
    });
    let allCourseRegistrationAttendanceMap = _.groupBy(allCourseRegistrationAttendance, 'courseScheduleID');

    _.forEach(courseSchedules, function (courseSchedule) {
      let scheduleCourseRegistrationAttendance = allCourseRegistrationAttendanceMap[courseSchedule.courseScheduleID];
      let courseUserRegistrationSchedule = new CourseUserRegistrationSchedule();
      courseUserRegistrationSchedule.capacity = courseSchedule.capacity;
      courseUserRegistrationSchedule.filled = courseSchedule.filled;
      courseUserRegistrationSchedule.courseScheduleID = courseSchedule.courseScheduleID;
      courseUserRegistrationSchedule.startDate = courseSchedule.startDate;
      courseUserRegistrationSchedule.endDate = courseSchedule.endDate;

      courseUserRegistrationSchedule.$courseRegistrationAttendance = scheduleCourseRegistrationAttendance;
      _.forEach(scheduleCourseRegistrationAttendance, function (studentAttendance) {
        studentAttendance.$courseUserRegistrationSchedule = courseUserRegistrationSchedule;
      })
    });

    let result = new CourseUserRegistration();
    result.courseRegistrations = _.orderBy(studentRegistrationsAll, [
      'studentSurname', 'studentName'
    ]);

    if (preselectSingleStudentAttendance && result.courseRegistrations.length === 1 && !allowClientLessonSelection) {
      let studentAtt = result.courseRegistrations[0];

      if ((course.subjectCategory === SubjectCategory.OneOffActions && !studentAtt.attend) ||
        studentAtt.isNew) {
        studentAtt.attend = true;
      }
    }

    return result;
  }

  private initializeCourseRegistrationModel(
    courseRegistration: CourseRegistration,
    invoice: Invoice,
    promotions: StudentPromotion[],
    attendances: Attendance[],
    course: Course,
    doNotPreselect: boolean
  ): CourseRegistration {

    let mNow = moment();

    courseRegistration.isNew = !!courseRegistration.isNew;
    courseRegistration.allowClientLessonSelection = course.allowClientLessonSelection;

    if (attendances) {
      let attendanceMap = _.zipObject(_.map(attendances, 'courseScheduleID'), attendances);
      courseRegistration.registeredAttendance = _.sortBy(courseRegistration.registeredAttendance, 'startDate');
      _.forEach(courseRegistration.registeredAttendance, function (registeredAttendance) {
        let attendance = attendanceMap[registeredAttendance.courseScheduleID];
        if (attendance) {
          registeredAttendance.courseAttendanceType = attendance.attendanceType;
          registeredAttendance.courseAttendanceStatus = attendance.attendanceStatus;
          registeredAttendance.attendanceCount = attendance.attendanceCount;
        }

        registeredAttendance.origCourseAttendanceType = registeredAttendance.courseAttendanceType;
        registeredAttendance.origCourseAttendanceStatus = registeredAttendance.courseAttendanceStatus;

        if (!registeredAttendance.isDisabled &&
          courseRegistration.isNew &&
          moment(registeredAttendance.startDate).isAfter(mNow) &&
          !doNotPreselect) {
          registeredAttendance.isRegistered = true;
        }
      });
    }

    if (course.subjectCategory === SubjectCategory.OneOffActions) {
      courseRegistration.attend = !courseRegistration.isNew &&
        courseRegistration.registeredAttendance[0].courseAttendanceStatus === CourseAttendanceStatus.Present ;
    } else {
      courseRegistration.attend = !courseRegistration.isNew;
    }

    if (!invoice) {
      invoice = new Invoice();
      invoice.lineItems = [];
    }

    courseRegistration.invoice = new CourseRegistrationInvoice();
    courseRegistration.invoice.$courseRegistration = courseRegistration;
    courseRegistration.invoice.$course = course;

    this.initializeCourseRegistrationInvoiceModel(
      course,
      courseRegistration.invoice,
      invoice,
      promotions);

    courseRegistration.arrearByAmortizationSchedule =
      Math.max(courseRegistration.invoice.totalByAmortizationSchedule - courseRegistration.paymentsSum, 0);

    return courseRegistration;
  }

  private initializeCourseRegistrationInvoiceModel(
    course: Course,
    courseRegistrationInvoice: CourseRegistrationInvoice,
    invoice: Invoice,
    promotions: StudentPromotion[]
  ): CourseRegistrationInvoice {

    if (!courseRegistrationInvoice.lines) {
      courseRegistrationInvoice.lines = [];
    }

    courseRegistrationInvoice.useAmortizationSchedule =
      (course.amortizationScheduleMode === AmortizationScheduleMode.ForcedToAllClients) ||
      (!!invoice.amortizationSchedules && !!invoice.amortizationSchedules.length);

    courseRegistrationInvoice.amortizationScheduleSurchargePct = course.amortizationScheduleSurchargePct;

    courseRegistrationInvoice.$coursePriceService = this.coursePriceService;

    // --- CourseFee ---
    let invoiceLineCourseFee = _.find(invoice.lineItems, { invoiceLineType: InvoiceLineType.CourseFee });
    let crInvoiceLineCourseFee = new CourseRegistrationInvoiceLine({
      $parent: courseRegistrationInvoice,
      $invoiceLineItem: invoiceLineCourseFee,
      lineType: InvoiceLineType.CourseFee,
      owns: !!invoiceLineCourseFee,
      buy: !!invoiceLineCourseFee,
      name: invoiceLineCourseFee ? invoiceLineCourseFee.name : '',
      price: invoiceLineCourseFee ? invoiceLineCourseFee.price : 0,
      discountPct: 0,
      isMandatory: false,
      membershipID: null,
      description: null,
      isValid: true
    });

    courseRegistrationInvoice.lines.push(crInvoiceLineCourseFee);

    // --- Memberships ---
    let invoiceMemberships = _.filter(invoice.lineItems, { invoiceLineType: InvoiceLineType.Membership });

    _.forEach(promotions, function (promotion) {
      let invoiceLineMembership = _.find(invoiceMemberships, { typeReferenceID: promotion.membershipID });
      let crInvoiceLineMembership = new CourseRegistrationInvoiceLine({
        $parent: courseRegistrationInvoice,
        $invoiceLineItem: invoiceLineMembership,
        lineType: InvoiceLineType.Membership,
        owns: promotion.owns,
        buy: promotion.buy,
        name: promotion.name,
        price: promotion.price,
        discountPct: promotion.discountPct,
        isMandatory: promotion.isMandatory,
        membershipID: promotion.membershipID,
        description: promotion.description,
        isValid: true,
        validFrom: promotion.validFrom,
        validTo: promotion.validTo
      });

      courseRegistrationInvoice.lines.push(crInvoiceLineMembership);
    });

    _.forEach(invoiceMemberships, function (invoiceLineMembership) {
      let promotion = _.find(promotions, { membershipID: invoiceLineMembership.typeReferenceID });
      if (!promotion) {
        let crInvoiceLineMembership = new CourseRegistrationInvoiceLine({
          $parent: courseRegistrationInvoice,
          $invoiceLineItem: invoiceLineMembership,
          lineType: InvoiceLineType.Membership,
          owns: true,
          buy: true,
          name: invoiceLineMembership.name,
          price: invoiceLineMembership.price,
          discountPct: 0,
          isMandatory: false,
          description: null,
          membershipID: invoiceLineMembership.typeReferenceID,
          isValid: false
        });

        courseRegistrationInvoice.lines.push(crInvoiceLineMembership);
      }
    });

    // Add discounts
    let crMemberships = _.filter(courseRegistrationInvoice.lines, { lineType: InvoiceLineType.Membership });
    _.forEach(crMemberships, function (crMembership) {
      let isInInvoice = _.find(invoice.lineItems, { invoiceLineType: InvoiceLineType.Discount, typeReferenceID: crMembership.membershipID });
      let crInvoiceLineDiscount = new CourseRegistrationInvoiceLine({
        $parent: courseRegistrationInvoice,
        $invoiceLineItem: isInInvoice,
        lineType: InvoiceLineType.Discount,
        owns: crMembership.owns,
        membershipID: crMembership.membershipID
      });

      courseRegistrationInvoice.lines.push(crInvoiceLineDiscount);
    });


    let invoiceDiscounts = _.filter(invoice.lineItems, { invoiceLineType: InvoiceLineType.Discount });
    _.forEach(invoiceDiscounts, function (discount) {
      let membership = _.find(crMemberships, { 'membershipID': discount.typeReferenceID });
      if (!membership) {
        // We do not have membership, this means the discount is in the invoice but
        // it is not valid anymore
        let crInvoiceLineDiscount = new CourseRegistrationInvoiceLine({
          $parent: courseRegistrationInvoice,
          $invoiceLineItem: discount,
          lineType: InvoiceLineType.Discount,
          owns: true,
          buy: false,
          name: discount.name,
          price: discount.price,
          discountPct: 0,
          isMandatory: false,
          membershipID: discount.typeReferenceID,
          description: null,
          isValid: false
        });

        courseRegistrationInvoice.lines.push(crInvoiceLineDiscount);
      }
    });


    let manualInInvoice = _.find(invoice.lineItems, { invoiceLineType: InvoiceLineType.ManualAdjustment });
    let crInvoiceLineManual = new CourseRegistrationInvoiceLine({
      $parent: courseRegistrationInvoice,
      $invoiceLineItem: manualInInvoice,
      owns: !!manualInInvoice,
      buy: !!manualInInvoice,
      name: !!manualInInvoice ? manualInInvoice.name : '',
      price: !!manualInInvoice ? manualInInvoice.price : 0,
      discountPct: 0,
      isMandatory: false,
      membershipID: null,
      description: null,
      isValid: true,
      lineType: InvoiceLineType.ManualAdjustment
    });

    courseRegistrationInvoice.lines.push(crInvoiceLineManual);

    if (!!invoice.amortizationSchedules && !!invoice.amortizationSchedules.length) {
      // Calc by amortization schedule
      var mNowDay = moment().startOf('day');
      var totalByAmortizationSchedule = _.reduce(invoice.amortizationSchedules, (total, n) => {
        if (moment(n.issueAt).isSameOrBefore(mNowDay)) {
          return total + n.amount;
        }
        return total;
      }, 0);

      if (totalByAmortizationSchedule == 0 && invoice.amortizationSchedules.length > 0) {
        totalByAmortizationSchedule = invoice.amortizationSchedules[0].amount;
      }

      courseRegistrationInvoice.totalByAmortizationSchedule = totalByAmortizationSchedule;

    }
    else {
      courseRegistrationInvoice.totalByAmortizationSchedule = invoice.total;
    }

    return courseRegistrationInvoice;
  }

  private createNewCourseRegistrationModel(
    student: Student,
    course: Course,
    courseSchedules: CourseSchedule[],
    doNotPreselect: boolean,
    mNow: moment.Moment = moment()
  ): CourseRegistration {

    let courseRegistration = new CourseRegistration();
    if (!!student) {
      courseRegistration.studentID = student.id;
      courseRegistration.parentID = student.userID;
      courseRegistration.studentFullName = student.fullName;
      courseRegistration.studentSurname = student.surname;
      courseRegistration.studentName = student.name;
      courseRegistration.studentMiddleName = student.middleName;
      courseRegistration.studentAge = student.age;
      courseRegistration.studentBirthday = student.birthday;
    }
    courseRegistration.isNew = true;
    courseRegistration.courseID = course.courseID;
    courseRegistration.courseFullName = course.courseFullName;
    courseRegistration.registrationDate = mNow.toDate();
    courseRegistration.preAmendsCount = course.preAmendsCount;
    courseRegistration.timeBoundAmendCount = course.timeBoundAmendCount;
    courseRegistration.timeBoundAmendExpiration = course.timeBoundAmendExpiration;
    courseRegistration.allowMultiAttendance = course.allowMultiAttendance;
    courseRegistration.reservedPlaces = 1;
    courseRegistration.registeredAttendance = [];

    _.forEach(courseSchedules, function (courseSchedule) {
      let isRegistered = course.subjectCategory == SubjectCategory.OneOffActions
        ? true
        : moment(courseSchedule.startDate).isAfter(mNow);

      if (doNotPreselect) {
        isRegistered = false;
      }

      let registeredAttendance = new CourseRegistrationAttendance();
      registeredAttendance.courseScheduleID = courseSchedule.courseScheduleID;
      registeredAttendance.startDate = courseSchedule.startDate;
      registeredAttendance.endDate = courseSchedule.endDate;
      registeredAttendance.isRegistered = isRegistered;
      registeredAttendance.courseAttendanceType = isRegistered ? CourseAttendanceType.Registered : null;
      registeredAttendance.courseAttendanceStatus = isRegistered ? CourseAttendanceStatus.Present : null;

      registeredAttendance.origCourseAttendanceType = registeredAttendance.courseAttendanceType;
      registeredAttendance.origCourseAttendanceStatus = registeredAttendance.courseAttendanceStatus;


      registeredAttendance.attendanceNotes = null;
      registeredAttendance.attendanceCount = 1;

      courseRegistration.registeredAttendance.push(registeredAttendance);
    });

    return courseRegistration;
  }

}
