import { Exclude } from "class-transformer";
import { CourseRegistration } from './courseRegistration';

import * as _ from 'lodash';

export class CourseUserRegistration {

    courseRegistrations: CourseRegistration[];

    @Exclude()
    get isModified(): boolean {
        let res: boolean = _.reduce(this.courseRegistrations, function (total, n) {
            return total || n.isModified;
        }, false);

        return res;
    }
}