import { Pipe, PipeTransform } from '@angular/core';

import { ClassroomService } from '../services';

@Pipe({
  name: 'classroom',
  pure: false
})
export class ClassroomPipe implements PipeTransform {

  private cachedValue: number;
  private cachedData: string = "";

  constructor(private classroomService: ClassroomService) {
  }

  transform(value: number, args?: any): any {
    if (this.cachedValue !== value) {
      this.cachedValue = value;
      this.cachedData = "";
      this.classroomService.getByID(value).subscribe(a => {
        this.cachedData = a.name;
      });
    }

    return this.cachedData;
  }

}
