import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CourseService, ClassroomService } from '../../shared/services';
import { Course, Classroom } from '../../shared/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-action-detail',
  templateUrl: './action-detail.component.html',
  styleUrls: ['./action-detail.component.css']
})
export class ActionDetailComponent implements OnInit {

  courseID: Observable<string>;
  action: Observable<Course>;
  classroom: Classroom = <Classroom>{};
  loading: boolean = true;
  Math = Math;

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private classroomService: ClassroomService) { }

  ngOnInit() {

    this.courseID = this.route
      .params
      .pipe(map(params => params['id']));

    this.courseID.subscribe((id) => {
      this.action = this.courseService.getAction(Number(id));
      this.action.subscribe(a => {
        this.loading = false;
        if (!!a.classroomID) {
          this.classroomService.getByID(a.classroomID).subscribe(c => {
            this.classroom = c;
          });
        }
      })

    });
  }

}
