import {TransformDateUtc} from "../decorators";

export class AuditEvent {

  @TransformDateUtc()
  eventTimeUtc: Date;

  userId: string;
  userFullName: string;
  eventType: number;
  revision: number;
}
