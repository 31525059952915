import { Pipe, PipeTransform } from '@angular/core';

import { CourseAttendanceType } from '../models/enums';

import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'courseAttendanceType'
})
export class CourseAttendanceTypePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, args?: any): any {
    let courseAttendanceType: CourseAttendanceType = value;
    switch (courseAttendanceType) {
      case CourseAttendanceType.Amend:
        return this.translateService.instant('TXT_AMEND');
      case CourseAttendanceType.OneOff:
        return this.translateService.instant('TXT_ONE_OFF');
      case CourseAttendanceType.Preview:
        return this.translateService.instant('TXT_PREVIEW');
      case CourseAttendanceType.Registered:
        return this.translateService.instant('TXT_REGISTERED');

    }
    return "";
  }

}
