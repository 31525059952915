
import {Transform, Type} from "class-transformer";

import * as moment from "moment";

export function TransformDate() {
  const typeFn = Type(() => String);
  const transformFn1 = Transform(({value}) => {
    let mValue = moment(value);
    return mValue.isValid() ? mValue.format('YYYY-MM-DDTHH:mm:ss') : null;
  }, { toPlainOnly: true });
  const transformFn2 = Transform(({value}) => {
    let m = moment(value, moment.ISO_8601);
    return m.isValid() ? m.toDate() : null;
  }, { toClassOnly: true });

  return function (target: any, key: string) {
    typeFn(target, key);
    transformFn1(target, key);
    transformFn2(target, key);
  }
}

export function TransformDateUtc() {
  const typeFn = Type(() => String);
  const transformFn1 = Transform(({value}) => {
    let mValue = moment(value);
    return mValue.isValid() ? mValue.toISOString() : value;
  }, {toPlainOnly: true});
  const transformFn2 = Transform(({value}) => {
    let m = moment(value, moment.ISO_8601);
    return m.isValid() ? m.toDate() : null;
  }, {toClassOnly: true});

  return function (target: any, key: string) {
    typeFn(target, key);
    transformFn1(target, key);
    transformFn2(target, key);
  }
}
