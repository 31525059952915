import { Type } from "class-transformer";

import { ErrorResponseGeneric } from './errorResponseGeneric';
import { ErrorResponseProperty } from './errorResponseProperty';

export class ErrorResponse {

    title: string;

    @Type(() => ErrorResponseGeneric)
    errors: ErrorResponseGeneric[];

    @Type(() => ErrorResponseProperty)
    propertyErrors: ErrorResponseProperty[];
}