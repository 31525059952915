import { Type, Transform } from "class-transformer";

import { PaymentType } from '../enums';

import { TransformDate } from '../../decorators';

export class CourseRegistrationPayment {
    paymentID: number;
    value: number;

    paymentType: PaymentType;

    @TransformDate()
    paymentDate: Date;

    cashReceiptNo: number;
}