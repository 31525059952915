import {Component, Input, OnInit} from '@angular/core';

import { TenantService } from '../../services';
import { Student, TenantSetting } from '../../models';
import {TranslateService} from "@ngx-translate/core";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-student-edit-detail',
  templateUrl: './student-edit-detail.component.html',
  styleUrls: ['./student-edit-detail.component.css']
})
export class StudentEditDetailComponent implements OnInit {

  public tenantSetting: TenantSetting;
  public genders = [{
    id: 'M',
    name: 'Muž'
  }, {
    id: 'F',
    name: 'Žena'
  }]

  personalIdPattern: any = /^(\d\d)(\d\d)(\d\d)(\d\d\d\d?)$/;


  @Input()
  student: Student;

  constructor(private tenantService: TenantService,
              private translateService: TranslateService
  ) { }

  ngOnInit() {
    let self = this;

    self.tenantService.getTenantSummary().subscribe(ts => {
      self.tenantSetting = ts.tenantSetting;
    });

    forkJoin([
      this.translateService.get('TXT_MALE'),
      this.translateService.get('TXT_FEMALE')]
    ).subscribe((res: string[]) => {
      this.genders[0].name = res[0];
      this.genders[1].name = res[1];
    });
  }

}
