import { Type, Transform, Exclude } from "class-transformer";
import { CourseRegistrationAttendance } from './courseRegistrationAttendance';

import * as _ from 'lodash';

import { TransformDate } from '../../decorators';

export class CourseUserRegistrationSchedule {
    capacity: number;
    filled: number;

    @TransformDate()
    startDate: Date;

    @TransformDate()
    endDate: Date;

    courseScheduleID: number;

    // Non DTO properties
    @Exclude()
    get freePlaces(): number {
        var origFreeCapacity = Math.max(0, this.capacity - this.filled);

        var placesRequiredByRegistration = _.reduce(this.$courseRegistrationAttendance, function (sum, a) {
            if (a.hasChanged) {
                return sum + (a.isRegistered ? 1 : 0);
            }

            return sum;
        }, 0);

        return Math.max(0, origFreeCapacity - placesRequiredByRegistration);
    }

    $courseRegistrationAttendance: CourseRegistrationAttendance[];

}