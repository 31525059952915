
import { map, catchError, publishReplay, refCount, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import {plainToClass, plainToInstance} from "class-transformer";

import { Student, User, StudentWithCompGroup } from '../models';
import { BaseService } from './base.service';
import { TenantService } from './tenant.service';

import { Constants } from '../constants';

import { TranslateService } from '@ngx-translate/core';

import { notifySuccess } from './notifications';

import * as _ from 'lodash';
import {StudentInfo} from "../models/users/stusentInfo";

@Injectable()
export class StudentService extends BaseService {

  private baseServiceUrl: string;
  private currentStudents: Observable<Student[]>;

  private allStudents: Observable<Student[]>;
  private allStudentsInfos: Observable<StudentInfo[]>;

  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private translateService: TranslateService
  ) {

    super();
    this.baseServiceUrl = '/Api/Students/';
  }

  createNew(user: User): Observable<Student> {
    let self = this;

    let newEntity = new Student();

    newEntity.gender = 'M';

    if (!!user) {
      newEntity.userID = user.id;
      if (user.nationality) {
        newEntity.nationality = user.nationality;
        return of(newEntity);
      } else {
        return Observable.create(observer => {
          self.tenantService.getTenantSummary().subscribe(ts => {
            newEntity.nationality = ts.tenantSetting.defaultNationality;

            observer.next(newEntity);
            observer.complete();
          }, err => observer.error(self.handleError(err)))
        });
      }
    }
  }

  getCurrent(refresh: boolean = false): Observable<Student[]> {
    if (!this.currentStudents || refresh) {

      let headers = this.getDefaultHttpHeaders();

      let url: string = this.baseServiceUrl + 'GetCurrent';

      this.currentStudents = this.http
        .get(url, {
          headers: headers
        }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(Student, res)),
          publishReplay(1),
          refCount());
    }

    return this.currentStudents;
  }

  saveCurrent(students: Student[]): Observable<Student[]> {
    let self = this;
    let url: string = this.baseServiceUrl + 'SaveCurrent';
    let headers = this.getDefaultHttpHeaders();

    return new Observable<Student[]>(observer => {
      self.http
        .post(url, students, {
          headers: headers
        }).pipe(
          map((res: Object[]) => plainToClass(Student, res)))
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            self.currentStudents = null;

            observer.next(value);
            observer.complete();
          }, err => observer.error(self.handleError(err)))
    })
  }

  update(user: Student, showSuccessNotification: boolean = true): Observable<Student> {
    let self = this;
    let url: string = this.baseServiceUrl + 'Update';
    let headers = this.getDefaultHttpHeaders();

    return new Observable<Student>(observer => {
      self.http
        .post(url, user, { headers: headers }).pipe(
        map((res: Object) => plainToClass<Student, Object>(Student, res)))
        .subscribe(
          value => { /* success */
            if (showSuccessNotification) {
              self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
                notifySuccess(res);
              });
            }

            self.currentStudents = null;

            observer.next(value);
            observer.complete();
          }, err => observer.error(self.handleError(err)))
    })
  }

  // TODO: Implement StudentService.getByUserID (now calls StudentService.getCurrent)
  getByUserID(userID: number): Observable<Student[]> {
    return this.getCurrent();
  }

  getMyStudentsWithCompGrp(): Observable<StudentWithCompGroup[]> {
    let self = this;

    return Observable.create(observer => {
      self.tenantService.getTenantSummary().subscribe(ts => {
        if (ts.loggedInUser.isAuthenticated) {
          let userID = ts.loggedInUser.userID;
          let headers = this.getDefaultHttpHeaders();
          let params = new HttpParams()
            .set("userID", userID.toString());
          let url: string = this.baseServiceUrl + 'GetStudentsWithCompGrpByUserID';
          self.http
            .get(url, { headers: headers, params: params }).pipe(
              catchError((err, c) => this.handleErrorAndThrow(err)),
              map((res: Object[]) => plainToClass(StudentWithCompGroup, res)))
            .subscribe(c => {
              observer.next(c);
              observer.complete();
            }, err => observer.error(self.handleError(err)))
        } else {
          observer.next([]);
          observer.complete();
        }
      }, err => observer.error(self.handleError(err)))

    });
  }

  getAll(refresh: boolean = false): Observable<Student[]> {
    if (!this.allStudents || refresh) {

      let headers = this.getDefaultHttpHeaders();

      let url: string = this.baseServiceUrl + 'GetAll';

      this.allStudents = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(Student, res)),
          publishReplay(1),
          refCount(),
          take(1));
    }

    return this.allStudents;
  }

  getAllInfo(refresh: boolean = false): Observable<StudentInfo[]> {
    if (!this.allStudentsInfos || refresh) {

      let headers = this.getDefaultHttpHeaders();

      let url: string = this.baseServiceUrl + 'GetAllInfo';

      this.allStudentsInfos = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToInstance(StudentInfo, res)),
          publishReplay(1),
          refCount(),
          take(1));
    }

    return this.allStudentsInfos;
  }

  getInfoByID(studentID): Observable<StudentInfo> {
    let self = this;

    return new Observable(observer => {
      this.getAllInfo().subscribe((res: StudentInfo[]) => {
        let student = _.find(res, { id: +studentID });
        if (!!student)
          observer.next(student);
        observer.complete();
      }, err => observer.error(self.handleError(err)))
    })
  }

  getByID(studentID): Observable<Student> {
    let self = this;

    return new Observable(observer => {
      this.getAll().subscribe((res: Student[]) => {
        let student = _.find(res, { id: +studentID });
        if (!!student)
          observer.next(student);
        observer.complete();
      }, err => observer.error(self.handleError(err)))
    })
  }

}
