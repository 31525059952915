import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import { SemesterService, StudentService } from '../../services';
import { Semester, StudentWithCompGroup, SemesterWithCompGroup } from '../../models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { of, forkJoin } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-semester-select',
  templateUrl: './semester-select.component.html',
  styleUrls: ['./semester-select.component.css']
})
export class SemesterSelectComponent implements OnInit {

  constructor(
    private semesterService: SemesterService,
    private studentService: StudentService
  ) { }

  semesters: DataSource;
  forceRefresh: boolean = false;
  detailDescriptionVisible: boolean = false;
  dataLoaded: boolean = false;
  clickedSemester: Semester;

  @Output() onClick = new EventEmitter<SemesterWithCompGroup>();

  private _showCompensationGroupSelect: boolean = false;
  @Input()
  get showCompensationGroupSelect(): boolean { return this._showCompensationGroupSelect; }
  set showCompensationGroupSelect(value: boolean) {
    if (this._showCompensationGroupSelect !== value) {
      this._showCompensationGroupSelect = value;
      if (value && this.dataLoaded) {
        this.semesters.load();
      }
    }
  }

  private _showPreferredBookingInfo: boolean = false;
  @Input()
  get showPreferredBookingInfo(): boolean { return this._showPreferredBookingInfo; }
  set showPreferredBookingInfo(value: boolean) {
    this._showPreferredBookingInfo = value;
  }

  private _showInAttendanceOnly: boolean = null;
  @Input()
  get showInAttendanceOnly(): boolean { return this._showInAttendanceOnly; }
  set showInAttendanceOnly(value: boolean) {
    this._showInAttendanceOnly = value;
  }

  private _showInCoursesOnly: boolean = null;
  @Input()
  get showInCoursesOnly(): boolean { return this._showInCoursesOnly; }
  set showInCoursesOnly(value: boolean) {
    this._showInCoursesOnly = value;
  }

  @Input()
  showInKindergarten: boolean = null;

  private _openForRegistrationOnly: boolean = null;
  @Input()
  get openForRegistrationOnly(): boolean { return this._openForRegistrationOnly; }
  set openForRegistrationOnly(value: boolean) {
    this._openForRegistrationOnly = value;
  }

  onPullRefresh() {
    this.forceRefresh = true;
    this.semesters.load();
  }

  onItemClick(params) {
    if (this.showCompensationGroupSelect) {
      let studentWithCompGroup = <StudentWithCompGroup>params.itemData;
      this.onClick.emit({
        semesterID: studentWithCompGroup.semesterID,
        compensationGroupID: studentWithCompGroup.compensationGroupID,
        isAutoRedirect: false
      });
    } else {
      this.onClick.emit({
        semesterID: params.itemData.id,
        compensationGroupID: 0,
        isAutoRedirect: false
      });
    }
  }

  showDescriptionDetail(params, semester) {
    this.detailDescriptionVisible = true;
    this.clickedSemester = semester;
    params.event.stopPropagation();
  }


  ngOnInit() {
    let self = this;

    self.semesters = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            forkJoin(
              self.semesterService.getAll(
                this.openForRegistrationOnly,
                this.showInAttendanceOnly,
                this.showInCoursesOnly,
                this.showInKindergarten,
                this.forceRefresh),
              self.showCompensationGroupSelect
                ? self.studentService.getMyStudentsWithCompGrp()
                : of([])
            ).subscribe((data: [Semester[], StudentWithCompGroup[]]) => {
              self.dataLoaded = true;
              self.forceRefresh = false;

              let semesters = data[0];
              if (self.showInCoursesOnly) {
                semesters = _.filter(semesters, { showInCourses: true });
              }

              if (self.showCompensationGroupSelect) {
                let compGrpsBySemester = _.groupBy(data[1], 'semesterID');

                let resData = _.filter(_.map(semesters, (semester) => {
                  let compGroups = compGrpsBySemester[semester.id] || [];
                  return {
                    id: semester.id,
                    name: semester.name,
                    startDate: semester.startDate,
                    endDate: semester.endDate,
                    description: semester.description,
                    items: _.orderBy(compGroups, ['studentFullName', 'compensationGroupID'])
                  };
                }), (i) => { return i.items && i.items.length > 0; });

                // If we have only one semester and either no comp group or single comp group
                // do an automatic pre-select
                if (resData.length === 1) {
                  let singleSemester = resData[0];
                  if (!singleSemester.items) {
                    self.onClick.emit({
                      semesterID: singleSemester.id,
                      compensationGroupID: 0,
                      isAutoRedirect: true
                    });
                  } else if (singleSemester.items.length === 1) {
                    self.onClick.emit({
                      semesterID: singleSemester.id,
                      compensationGroupID: singleSemester.items[0].compensationGroupID,
                      isAutoRedirect: true
                    });
                  }
                }

                resolve(resData);
              } else {
                // No need to alter data in any way
                if (semesters.length === 1) {
                  self.onClick.emit({
                    semesterID: semesters[0].id,
                    compensationGroupID: 0,
                    isAutoRedirect: true
                  });
                }
                resolve(semesters);
              }
            }, (error) => {
              reject(error);
            });
          })
        }
      })
    });
  }

}
