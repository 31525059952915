export class Activity {
  activityID: number;
  name: string;
  description: string;
  accountingBookID: number;
  semesterID: number;
  classroomID: number;
  defaultLunchPrice: number;
  defaultSnackPrice: number;
  defaultLatePickupPrice: number;
  forwardCompensationThresholdDays: number;
  backwardCompensationThresholdDays: number;
  classroomName: string;
  semesterName: string;
  enableOneOffAttendance: boolean;
  singlePeriodProgramOnly: boolean;
  kindergartenEnrollmentFormId: string;
}
