<dx-scroll-view>

  <dx-load-panel #loadPanel
    [(visible)]="loading"
    [showIndicator]="true"
    [showPane]="false"
    [shading]="false"
    closeOnOutsideClick="false">
  </dx-load-panel>

  <div id="action-detail-content"
    class="wb-content"
    style="position: relative">
    <div *ngIf="!loading" class="action-detail-header">
      <app-full-course-stamp  *ngIf="(action | async)?.capacity <= (action | async)?.filled"
        stampSize="small"
        [allowBooking]="false"
        ></app-full-course-stamp >
      <h4>{{(action | async)?.subjectName}}</h4>
      <h5>{{(action | async)?.name}}</h5>
      <div class="row dx-theme-accent-as-text-color">
        <div class="col-xs-5">{{(action | async)?.firstLesson | date:'EE dd.MM. HH:mm' | uppercase }}</div>
        <div class="col-xs-4">{{(action | async)?.price | wbcurrency}}</div>
        <div class="col-xs-3 text-right">{{ Math.max((action | async)?.capacity - (action | async)?.filled, 0) }} ({{(action | async)?.capacity}})</div>
      </div>

      <div class="row">
        <div class="col-xs-3">{{'TXT_TEACHER'| translate}}:</div>
        <div class="col-xs-9">{{(action | async)?.teacher}}</div>
      </div>
      <div class="row"
        *ngIf="classroom && classroom.address && classroom.address.formattedAddress && classroom.address.location.latitude">
        <div class="col-xs-3">{{'TXT_PLACE'| translate}}:</div>
        <div class="col-xs-9">
          <a title="Google maps: {{classroom.address.formattedAddress}}"
             href="https://maps.google.com/maps?z=12&t=m&q=loc:{{classroom.address.location.latitude}}+{{classroom.address.location.longitude}}" target="_blank">
            {{classroom.address.formattedAddress}}
          </a>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</dx-scroll-view>
