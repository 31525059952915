<div *ngIf="!loading">
  <h5 class="text-center header">
    {{'TXT_ACITIVITIES_DESCRIPTION' | translate}}
  </h5>
  <dx-scroll-view class="wb-under-header-list">

    <div class="semester"
      [ngClass]="{'first-semester': i === 0}"
      *ngFor="let semester of activitiesBySemester; let i = index">
      <h3 class="semester-header">{{semester.semesterName}}</h3>
      <div *ngFor="let activity of semester.activities">
        <h4 class="activity-header">{{'TXT_ACTIVITY' | translate}}: {{activity.name}}</h4>
        <p class="activity-description preformatted">{{activity.description}}</p>
        <div class="wb-button-margin text-center">
          <app-button-with-login text="{{'TXT_INTEREST_IN_KINDERGARTEN' | translate}}"
                                 type="success"
                                 [returnUrl]="returnUrl"
                                 (onClick)="register($event, semester.semesterID, activity)"></app-button-with-login>
        </div>
      </div>
    </div>
  </dx-scroll-view>
</div>
<dx-load-panel [visible]="loading">
  </dx-load-panel>
<dx-load-panel
  [(visible)]="saving"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  message="{{'TXT_SAVING' | translate}}"
  closeOnOutsideClick="false">
</dx-load-panel>
