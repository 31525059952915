import { ActivityAttendancePeriodType } from '../enums/activityAttendancePeriodType';

export class ActivityCompensationWaitQueue {
    activityCompensationWaitQueueID: number;
    studentID: number;
    activityScheduleID: number;
    period: ActivityAttendancePeriodType;
    studentFullName: string;
    parentFullName: string;
    position: number;
}