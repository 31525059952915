import { Component, OnInit } from '@angular/core';

import { ActivityService } from '../shared/services';
import {ActivitiesBySemester, Activity} from '../shared/models';
import {Router} from "@angular/router";


@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {

  activitiesBySemester: ActivitiesBySemester[] = [];
  loading: boolean = false;
  saving: boolean = false;
  returnUrl: string = '/activities';

  constructor(
    private activityService: ActivityService,
    private router: Router
  ) { }

  ngOnInit() {

    this.loading = true;

    this.activityService.getAllOpenForRegistration()
      .subscribe(r => {
        this.activitiesBySemester = r;
        this.loading = false;
      }, err => this.loading = false);
  }

  register(params: any, semesterID: number, activity: Activity) {
    this.saving = true;

    if (!!activity && !!activity.kindergartenEnrollmentFormId) {
      this.router.navigate(["/forms", activity.kindergartenEnrollmentFormId, "form-response"]);
    } else {
      this.activityService.registerActivityInterest(semesterID)
        .subscribe(r => this.saving = false, err => this.saving = false);
    }
  }

}
