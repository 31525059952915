import { Injectable } from '@angular/core';

function _window(): Window {
    // return the global native browser window object
    return window;
}

@Injectable()
export class WindowWrapper {
    get nativeWindow(): Window {
        return _window();
    }

    get location(): Location {
        return this.nativeWindow.location;
    }

    get history(): History {
        return this.nativeWindow.history;
    }

    encodeURIComponent(uri: string): string {
        return encodeURIComponent(uri);
    }
}
