
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { plainToClass } from "class-transformer";
import { Constants } from '../constants';

import { ErrorResponse } from '../models/errorResponse/errorResponse';

import { notifyError } from './notifications';

import * as moment from "moment";

@Injectable()
export class BaseService {

  constructor() {
  }

  protected getDefaultHttpHeaders(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }

  protected handleError(err: HttpErrorResponse): string {
    let errorMsg: string = "";

    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = err.error.message;
    } else {
      let errorResponse = plainToClass(ErrorResponse, err.error);
      if (!!errorResponse && !!errorResponse.errors && errorResponse.errors.length) {
        errorMsg = errorResponse.errors[0].message;
      } else if (!!errorResponse && !!errorResponse.title) {
        errorMsg = errorResponse.title;
      } else {
        if (err.message) {
          errorMsg = err.message;
        } else if (err.statusText) {
          errorMsg = err.statusText;
        } else {
          errorMsg = err.toString();
        }
      }
    }

    notifyError(errorMsg.substr(0, Math.min(200, errorMsg.length)));
    return errorMsg;
  }

  protected handleErrorAndThrow<T>(err: HttpErrorResponse): any {
    return observableThrowError(this.handleError(err));
  }
}
