<div *ngFor="let registration of courseRegistrations" style="margin-top: 20px">
  <table style="width: 100%">
    <tr >
      <td colspan="3" >
        <h4>{{registration.studentFullName}}</h4>
      </td>
    </tr>
    <ng-container *ngIf="!courseInterestsSupport">
      <ng-container *ngIf="!!showAmortizationSchedule[registration.studentID]">
          <tr>
              <td style="width: 45px;">
              </td>    
              <td style="width: 130px; padding: 5px 0">
                  {{'TXT_AMORTIZATION_SCHEDULE' | translate}}
              </td>  
              <td              
                class="text-right">        
                <dx-button 
                  text="{{'TXT_PRICE' | translate}}"                 
                  type="success"
                  (onClick)="onHideAmortizationSchedule(registration, $event)">
                </dx-button>
              </td>                                
          </tr>        
        <tr *ngFor="let line of amortizationSchedule[registration.studentID]; let first = first;"
            [style.border-top]="first ? '1px solid black' : '0px solid black'">
            <td style="width: 45px;">
            </td>
            <td style="padding: 5px 0">
              {{line.issueAt | date:short}}
            </td>
            <td style="width: 120px;"
              class="text-right">        
              {{line.amount | wbcurrency}}
            </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!showAmortizationSchedule[registration.studentID]">
          <tr *ngIf="!!registration.invoice.$course.amortizationScheduleMode && !!registration.invoice.$course.amortizationSchedules && !!registration.invoice.$course.amortizationSchedules.length">
              <td style="width: 45px;">
                  <dx-switch 
                    [disabled]="registration.invoice.$course.amortizationScheduleMode === 2"         
                    [(value)]="registration.invoice.useAmortizationSchedule"></dx-switch>
              </td>    
              <td style="width: 130px; padding: 5px 0">
                  {{'TXT_AMORTIZATION_SCHEDULE_FOR_COURSE_PAYMENT' | translate}}
              </td>  
              <td              
                class="text-right">        
                <dx-button 
                  text="{{'TXT_TAB_AMORTIZATION_SCHEDULE' | translate}}"
                  type="success"
                  (onClick)="onShowAmortizationSchedule(registration, $event)">
                </dx-button>
              </td>                                
          </tr>
          <tr *ngFor="let line of registration.invoice.clientOnlyLines; let first = first;"
              [style.border-top]="first ? '1px solid black' : '0px solid black'">
              <td style="width: 45px;">
                <dx-switch *ngIf="!line.isReadOnly"         
                  [(value)]="line.buy"></dx-switch>
              </td>
              <td style="padding: 5px 0">
                {{line.name}}
              </td>
              <td style="width: 120px;"
                class="text-right">        
                {{line.price | wbcurrency}}
              </td>
          </tr>
      </ng-container>
    </ng-container>
    <tr style="border-top: 1px solid black;">
      <td style="width: 45px;">
      </td>
      <td style="padding-top: 5px;">
        <strong>
          {{'TXT_SUMMARY' | translate}}
        </strong>
      </td>
      <td style="width: 120px;"
        class="text-right">
        <strong>
        {{registration.invoice.total | wbcurrency}}
        </strong>      
      </td>
    </tr>
  </table>
</div>