import { Component, HostListener, ViewChild, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ActivityService } from '../../shared/services';

import {PhotoAlbumInfo} from "../../shared/models/activities";
import {LightboxService} from "../../shared/services/lightbox.service";


@Component({
  selector: 'app-my-activities-picture-gallery',
  templateUrl: './my-activities-picture-gallery.component.html',
  styleUrls: ['./my-activities-picture-gallery.component.css']
})
export class MyActivitiesPictureGalleryComponent implements OnInit {


  studentID: number;
  activityID: number;

  galleries: PhotoAlbumInfo[];

  constructor(
    private route: ActivatedRoute,
    private activityService: ActivityService,
    private lightboxService: LightboxService) {
  }

  ngOnInit() {

    let self = this;

    self.activityID = Number(self.route.snapshot.queryParams["activityID"]);
    self.studentID = Number(self.route.snapshot.queryParams["studentID"]);

    self.activityService.getPhotoAlbum(self.studentID, self.activityID)
      .subscribe(r => self.galleries = r);


  }

  openLightBox(gallery: PhotoAlbumInfo, index: number): void {
    let lightBoxAlbum = gallery.images.map(x => ({
      image: x.imageUrl,
      alt: '',
      title: ''
    }));

    this.lightboxService.showLightbox({
      lightBoxAlbum: lightBoxAlbum,
      lightBoxImageIndex: index
    });
  }
}
