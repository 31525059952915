import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  AttendanceService,
  TenantService,
  SemesterService,
  CourseService,
  CourseScheduleService } from 'app/shared/services';

import {
  TenantSetting,
  TenantSummary,
  Attendance,
  Semester,
  Course,
  CourseSchedule } from 'app/shared/models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

@Component({
  selector: 'app-lesson-course-attendance',
  templateUrl: './lesson-course-attendance.component.html',
  styleUrls: ['./lesson-course-attendance.component.css']
})
export class LessonCourseAttendanceComponent implements OnInit {

  students: DataSource;
  semesterID: number;
  semester: Semester;
  courseID: number;
  course: Course;
  courseScheduleID: number;
  courseSchedule: CourseSchedule;
  tenantSettings: TenantSetting = new TenantSetting();
  lastClickWasOnSwitch: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private attendanceService: AttendanceService,
    private tenantService: TenantService,
    private semesterService: SemesterService,
    private courseService: CourseService,
    private courseScheduleService: CourseScheduleService) { }

  ngOnInit() {
    let self = this;

    this.route.params.subscribe(params => {
      self.courseID = Number(params['courseId']);
      self.semesterID = Number(params['semesterId']);
      self.courseScheduleID = Number(params['courseScheduleId']);

      combineLatest([
        self.semesterService.getAll(null, true, true),
        self.courseService.getCourse(self.courseID),
        self.courseScheduleService.getAllForCourse(self.courseID)])
          .subscribe((data: [Semester[], Course, CourseSchedule[]]) => {
            self.semester = _.find(data[0], { id: self.semesterID });
            self.course = data[1];
            self.courseSchedule = _.find(data[2], { courseScheduleID: self.courseScheduleID });
          });
    });

    let courseScheduleID = self.route
      .params
      .pipe(map(params => Number(params['courseScheduleId'])));

      let tenantSummaryObservable = self.tenantService.getTenantSummary();

    self.students = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            combineLatest([courseScheduleID, tenantSummaryObservable])
              .subscribe((res: [number, TenantSummary]) => {
                let tenantSummary = res[1];
                self.tenantSettings = tenantSummary.tenantSetting;
                self.courseScheduleID = res[0];

                self.attendanceService.getLessonAttendance(self.courseScheduleID)
                  .subscribe(s => {
                    resolve(s);
                  }, (error) => {
                    reject(error);
                  });
            });
          });
        }
      })
    });
  }

  onPullRefresh() {
    this.students.load();
  }

  onItemClick(params) {

    let self = this;

    if (self.lastClickWasOnSwitch) {
      self.lastClickWasOnSwitch = false;
      return;
    }

    self.lastClickWasOnSwitch = false;

    this.router.navigate([
      "/attendance/courses/semester/",
      self.semesterID,
      "course",
      self.courseID,
      "lesson",
      self.courseScheduleID,
      "student",
      (<Attendance>params.itemData).studentID
    ],{ replaceUrl: true });
  }

  onConfirmedChanged(s: Attendance) {
    this.attendanceService.updateStudentAttendance(s).subscribe();
  }

  onAddBtnClick(params) {
    let self = this;

    this.router.navigate([
      "/attendance/courses/semester/",
      self.semesterID,
      "course",
      self.courseID,
      "lesson",
      self.courseScheduleID,
      "student"],{ replaceUrl: true });
  }

  onSwitchMouseDown(e: any) {
    e.stopPropagation();
    this.lastClickWasOnSwitch = true;
  }

}
