<dx-scroll-view>

  <dx-load-panel #loadPanel
    [(visible)]="loading"
    [showIndicator]="true"
    [showPane]="false"
    [shading]="false"
    closeOnOutsideClick="false">
  </dx-load-panel>

  <div id="course-detail-content"
    class="wb-content"
    style="position: relative">
    <div *ngIf="!loading" class="course-detail-header">
      <app-full-course-stamp
        *ngIf="(course | async)?.isFull && (course | async)?.capacity != 0 && !tenantSettings.courseInterestsSupport && !tenantSettings.coursePreviewOnlyRegistration"
        stampSize="small"
        [allowBooking]="tenantSettings.enableFullCourseInterest">
      </app-full-course-stamp>
      <h4>{{(course | async)?.subjectName}}</h4>
      <h5>{{(course | async)?.name}}</h5>
      <div class="row dx-theme-accent-as-text-color">
        <div class="col-xs-5" *ngIf="tenantSettings.showScheduleInCourses">{{(course | async)?.nearestLesson | date:'EE dd.MM. HH:mm' | uppercase }}</div>
        <div class="col-xs-4" *ngIf="tenantSettings.useTotalPriceForCourses && tenantSettings.showPriceInCourseRegistration" >{{(course | async)?.remainingPrice | wbcurrency}}</div>
        <div class="col-xs-4" *ngIf="!tenantSettings.useTotalPriceForCourses && tenantSettings.showPriceInCourseRegistration" >{{(course | async)?.price | wbcurrency}} / {{'TXT_LESSON' | translate}}</div>
        <div class="col-xs-4" *ngIf="!tenantSettings.showPriceInCourseRegistration" ></div>
        <div class="col-xs-3 text-right" *ngIf="!tenantSettings.showLessonsRemaining">{{ (course | async)?.noOfFreePlaces }} ({{(course | async)?.capacity}})</div>
        <div class="col-xs-3 text-right" *ngIf="tenantSettings.showLessonsRemaining">{{ (course | async)?.noOfRemainingLessons }}</div>
      </div>
      <div class="row">
        <div class="col-xs-3">{{'TXT_TEACHER'| translate}}:</div>
        <div class="col-xs-9">{{(course | async)?.teacher}}</div>
      </div>
      <div class="row" *ngIf="(course | async)?.classroomID">
        <div class="col-xs-3">{{'TXT_PLACE'| translate}}:</div>
        <div class="col-xs-9">{{(course | async)?.classroomID | classroom }}</div>
      </div>
      <div class="row" *ngIf="tenantSettings.showAgeRangesInCourses">
        <div class="col-xs-3">{{'TXT_AGE'| translate}}:</div>
        <div class="col-xs-9">{{(course | async)?.courseAgeRangeID | ageRange }}</div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</dx-scroll-view>
