
import { refCount, catchError, map, publishReplay, take, share } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { plainToClass } from "class-transformer";

import { BaseService } from './base.service';
import { TenantService } from './tenant.service';
import { CompensationCourse, Course } from '../models';
import { SubjectCategory } from '../models/enums';

import * as _ from 'lodash';

@Injectable()
export class CourseService extends BaseService {

  private baseServiceUrl: string;

  private featuredActions: Observable<Course[]>;
  private singleCourse: Observable<Course>;
  private singleCourseID: number;

  constructor(
    private http: HttpClient,
    private tenantService: TenantService) {
    super();
    this.baseServiceUrl = '/Api/Courses/';
  }

  getFeaturedActions(refresh: boolean = false): Observable<Course[]> {

    if (!this.featuredActions || refresh) {

      let url: string = `${this.baseServiceUrl}GetFeaturedActions`;

      this.featuredActions = this.http
        .get(url, { headers: this.getDefaultHttpHeaders() }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(Course, res)),
          publishReplay(1),
          refCount());
    }

    return this.featuredActions;
  }

  getAllCoursesForSemesterAndTeacher(semesterID: number, employeeID: string) 
    : Observable<Course[]> {

    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("semesterID", semesterID.toString())
      .append("employeeID", employeeID);

      let url: string = this.baseServiceUrl + 'GetAllCoursesForSemesterAndTeacher';

      return this.http
      .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(Course, res)));      
  }

  getAllForSemester(semesterID: number, openForRegistrationOnly: boolean = true)
    : Observable<Course[]> {

    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("semesterID", semesterID.toString())
      .append("openForRegistrationOnly", openForRegistrationOnly.toString())
      .append("isAction", "false");

    let url: string = this.baseServiceUrl + 'Get';

    return this.http
      .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(Course, res)));
  }

  getCourse(courseID: number, refresh: boolean = false): Observable<Course> {
    if (!this.singleCourse || this.singleCourseID !== courseID || refresh) {

      let url: string = `${this.baseServiceUrl}GetByCourseID`;

      let params = new HttpParams()
        .set('courseID', courseID.toString());

      let headers = this.getDefaultHttpHeaders();

      this.singleCourseID = courseID;
      this.singleCourse = this.http
        .get(url, { headers: headers, params: params }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object) => plainToClass<Course, Object>(Course, res)),
          publishReplay(1),
          refCount());
    }

    return this.singleCourse;
  }

  getAction(actionID: number, refresh: boolean = false): Observable<Course> {
    return this.getCourse(actionID, refresh);
  }

  getEffectivePictures(course: Observable<Course>): Observable<string[]> {
    return new Observable(observer => {
      let effectivePictures: string[] = [];
      course.subscribe(a => {
        a.pictures.forEach(picture => {
          effectivePictures.push(picture.imageUrl);
        });

        if (a.subjectCategory === SubjectCategory.OneOffActions) {
          this.tenantService.getTenantSummary().subscribe(t => {
            if (t.tenantPictureUrl != a.pictureUrl &&
              !_.includes(effectivePictures, a.pictureUrl)) {
              effectivePictures.push(a.pictureUrl);
            }

            observer.next(effectivePictures);
            observer.complete();
          });
        } else {
          observer.next(effectivePictures);
          observer.complete();
        }

      });
    })
  }


  private cachedCompensationCourses: Observable<CompensationCourse[]>;
  private cachedCompensationCoursesParams: any;

  getCompensationCourses(semesterId: number, studentId: number, courseId: number, lessonEndDate: Date, refresh: boolean = false): Observable<CompensationCourse[]> {

    let parameters = {
      semesterId: semesterId,
      studentId: studentId,
      courseId: courseId,
      lessonEndDate: lessonEndDate
    };

    if (!this.cachedCompensationCourses || 
        refresh || 
        (this.cachedCompensationCoursesParams.semesterId != parameters.semesterId) ||
        (this.cachedCompensationCoursesParams.studentId != parameters.studentId) ||
        (this.cachedCompensationCoursesParams.courseId != parameters.courseId) ||
        (this.cachedCompensationCoursesParams.lessonEndDate != parameters.lessonEndDate)) {

      this.cachedCompensationCoursesParams = parameters;

      let headers = this.getDefaultHttpHeaders();
      let params = new HttpParams()
        .set("semesterID", semesterId.toString())
        .append("studentID", studentId.toString())      
        .append("lessonEndDate", lessonEndDate.toISOString());

      if (!!courseId) {
        params = params.append("courseID", courseId.toString());
      }

      let url: string = this.baseServiceUrl + 'GetCompensationCourses';

      this.cachedCompensationCourses = this.http
        .get(url, { headers: headers, params: params }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(CompensationCourse, res)),
          publishReplay(1),
          refCount(),
          take(1)
        );  
    }
    
    return this.cachedCompensationCourses;
  }
}

