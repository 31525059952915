import { Type } from "class-transformer";

import { FormQuestion } from './formQuestion';
import { AuditEvent } from '../auditEvent';

import { FormsAccessPermissionsType } from '../enums/formsAccessPermissionsType';

export class FormDefinition {
    id: string;
    name: string;
    descriptionHtml: string;
    pictureUrls: string[];
    primaryQuestionIndex: number;
    accessPermissions: FormsAccessPermissionsType;

    @Type(() => FormQuestion)
    questions: FormQuestion[];

    @Type(() => AuditEvent)
    auditEvent: AuditEvent;

    showFullEditableUserDetail: boolean;
    showFullEditableStudentDetail: boolean;
}
