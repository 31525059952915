import { Type, Transform } from "class-transformer";

import { TransformDate } from '../../decorators';

export class ActivitySummary {
    activityID: number;
    activityName: string;
    activityDescription: string;

    @TransformDate()
    activityStartDate: Date;

    @TransformDate()
    activityEndDate: Date;

    semesterID: number;
    semesterName: string;
    capacity: number;
}