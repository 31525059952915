
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";
import { TranslateService } from '@ngx-translate/core'

import { BaseService } from './base.service';
import { WindowWrapper } from '../windowWrapper';

import { OnlinePayment } from '../models';
import { DuePayment } from '../models/duePayments/duePayment';

import { WbcurrencyPipe } from '../pipes/wbcurrency.pipe';

@Injectable()
export class OnlinePaymentService extends BaseService {
  private baseServiceUrl: string;

  constructor(
    private http: HttpClient,
    private _window: WindowWrapper,
    private wbcurrencyPipe: WbcurrencyPipe,
    private translate: TranslateService
  ) {
    super();
    this.baseServiceUrl = '/Api/OnlinePayments/';
  }

  startOnlinePayment(invoiceIDs: string[], price: number) {
    let self = this;

    let headers = self.getDefaultHttpHeaders();
    let url: string = self.baseServiceUrl + 'StartOnlinePayment';

    self.http
      .post(
        url,
        {
          invoiceIDs: invoiceIDs,
          price: price,
          returnToUrl: self._window.location.href
        },
        { headers: headers }).pipe(
      catchError((err, c) => this.handleErrorAndThrow(err)),
      map((res: Object) => plainToClass<OnlinePayment, Object>(OnlinePayment, res)),)
      .subscribe(value => {
        self._window.location.href = value.redirectUrl;
      },
        err => self.handleError(err))
  };

  getTransactionState(onlinePaymentID: number): Observable<OnlinePayment> {
    let self = this;
    let headers = self.getDefaultHttpHeaders();
    let url: string = self.baseServiceUrl + 'GetTransactionState';

    return self.http.post(
      url,
      { onlinePaymentID: onlinePaymentID },
      { headers: headers }).pipe(
      catchError((err, c) => this.handleErrorAndThrow(err)),
      map((res: Object) => plainToClass<OnlinePayment, Object>(OnlinePayment, res)),);
  }

  getOnlinePaymentResultMessage(onlinePayment: OnlinePayment): string {

    let priceStr = this.wbcurrencyPipe.transform(onlinePayment.price);

    switch (onlinePayment.state) {
      case 2: // Pending
        return this.translate.instant('TXT_PAYMENT_RESULT_PENDING').f(priceStr);
      case 3: // Paid
        return this.translate.instant('TXT_PAYMENT_RESULT_PAID').f(priceStr);
      case 4: // Reversed
      case 5: // AutoReversed
        return this.translate.instant('TXT_PAYMENT_RESULT_REVERSED').f(priceStr);
      case 6: // Cancelled
        return this.translate.instant('TXT_PAYMENT_RESULT_CANCELLED').f(priceStr);
      case 7: // Declained
        return this.translate.instant('TXT_PAYMENT_RESULT_DECLAINED').f(priceStr);
      case 8: // Error
        return this.translate.instant('TXT_PAYMENT_RESULT_ERROR').f(priceStr, onlinePayment.resultMessage);
      default: // Unexpected
        return this.translate.instant('TXT_PAYMENT_RESULT_UNEXPECTED').f(priceStr);
    }

  }

}

