import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { plainToClass, classToPlain } from "class-transformer";

import { BaseService } from './base.service';

import {
  Reservation,
  ReservationPricingRequest,
  PricingResponse
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ReservationPriceService extends BaseService {

  private baseServiceUrl: string;

  constructor(
    private http: HttpClient) {
    super();
    this.baseServiceUrl = '/restapi/pricing/';
  }

  calculateReservationPrice(r: Reservation): Observable<PricingResponse> {
    let model: ReservationPricingRequest = {
      userId: r.userId,
      studentsIds: r.studentIds,
      employeeId: r.employeeId,
      placeId: r.placeId,
      reservationStartDate: r.reservationStartDate,
      reservationEndDate: r.reservationEndDate,
      domainId: r.domain.id,
      domainSpecializationId: !!r.domainSpecialization
        ? r.domainSpecialization.id
        : null
    };

    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'calculate-reservation-price';

    let dto = classToPlain(model);

    return this.http
      .post(url, dto,
        { headers: headers }).pipe(
          catchError((err) => this.handleErrorAndThrow(err)),
          map((res: Object) => plainToClass(PricingResponse, res)),
          take(1));

  }
}
