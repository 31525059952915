import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CourseService, TenantService } from '../../shared/services';

import { Course, TenantSetting } from '../../shared/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent implements OnInit {

  courseID: Observable<string>;
  course: Observable<Course>;
  loading: boolean = true;
  Math = Math;
  tenantSettings: TenantSetting = new TenantSetting();

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private tenantService: TenantService) { }

  ngOnInit() {
    let self = this;

    self.courseID = self.route
      .params
      .pipe(map(params => params['id']));

    self.tenantService.getTenantSummary().subscribe(ts => self.tenantSettings = ts.tenantSetting);

    self.courseID.subscribe((id) => {
      self.course = self.courseService.getCourse(Number(id));
      self.course.subscribe(a => {
        self.loading = false;
      })

    });
  }

}
