import { Type } from "class-transformer";
import { TransformDate } from '../../decorators';
import { PlaceEmployeesAvailabilityPeriod } from './placeEmployeesAvailabilityPeriod';

export class DayPlaceAvailability {

  @TransformDate()
  day: Date;

  @Type(() => PlaceEmployeesAvailabilityPeriod)
  periods: PlaceEmployeesAvailabilityPeriod[];

}