<dx-scroll-view>
    <div *ngIf="userScheduleDetail" class="schedule-wrapper">
        <div class="lesson-detail-wrapper">
            <h4>
                {{userScheduleDetail?.subject}}
            </h4>
            <div>
                {{userScheduleDetail?.startDate | date:'EE dd.MM. HH:mm' | uppercase }} - {{userScheduleDetail?.endDate | date:'HH:mm'}}
            </div>
        </div>
        <div>
            <div *ngIf="userScheduleDetail?.showNoStudentsHelp"
                class="bg-danger text-message">
                <p>{{'TXT_NO_STUDENTS_ATTENDANCE' | translate}}</p>
            </div>
        </div>

        <div *ngIf="!userScheduleDetail?.showNoStudentsHelp">
            <div *ngFor='let student of userScheduleDetail?.studentsAttendance; let first = first'
                class="student-attendance"
                [ngClass]="{ 'student-attendance-middle': !first }">
                <div class="student-detail">
                    <table class="full-width-table">
                        <tr>
                            <td>
                                <h4 class="dx-button-mode-text" [ngClass]="{ 'dx-button-danger' : student.isDirty }">{{student.fullName}}</h4>
                            </td>
                            <td class="modified-icon dx-button-mode-text dx-button-danger">
                                <span *ngIf="student.isDirty" class="dx-icon-edit icon"></span>
                            </td>
                        </tr>
                    </table>
                    <table class='full-width-table'>
                        <tr>
                            <td class='left-cell'>
                                <dx-switch [(value)]="student.present"
                                    [disabled]="student.presentReadOnly || disableClientCalendar"></dx-switch>
                            </td>
                            <td class='right-cell'>
                                <span *ngIf="userScheduleDetail?.showAttendanceTypeColumn && !student.oneOffDisabled">
                            {{'TXT_ATTENDANCE_TYPE' | translate}}:
                                <span>{{student.effectiveCourseAttendanceType | courseAttendanceType}}</span>
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="userScheduleDetail?.showAvailableAmendsColumn">
                            <td class='left-cell'></td>
                            <td class='right-cell'>
                                {{'TXT_AVAILABLE_AMENDS' | translate }}:
                                <span>{{student.actualAmendsCount}}</span>
                                <span *ngIf="student.actualPreAmendsCount > 0"
                                    class="text-success">(+{{student.actualPreAmendsCount}})</span>
                            </td>
                        </tr>
                        <tr *ngIf="userScheduleDetail?.showPriceColum && student.showPrice">
                            <td class='left-cell'></td>
                            <td class='right-cell'>
                                {{'TXT_PRICE' | translate }}:
                                <span>{{student.causalPrice | wbcurrency}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="userScheduleDetail?.showQueueColumn && student.waitQueueIndex">
                            <td class='left-cell'></td>
                            <td class='right-cell'>
                                <span>{{'TXT_WAIT_QUEUE_POSITION' | translate }}:</span>
                                <span *ngIf="showCourseWaitQueueIndexInClient">{{student.waitQueueIndex}}</span>
                                <span *ngIf="!showCourseWaitQueueIndexInClient"><i class="fa fa-check" aria-hidden="true"></i></span>
                            </td>
                        </tr>

                    </table>
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="userScheduleDetail?.showCompensationWaitQueueHelp"
                class="bg-warning text-message">
                {{'TXT_COMPENSATION_WAIT_QUEUE_HELP' | translate}}
            </div>

            <div *ngIf="userScheduleDetail?.showCompensationWaitQueueForActionHelp"
                class="bg-warning text-message">
                {{'TXT_COMPENSATION_WAIT_QUEUE_FOR_ACTION_HELP' | translate}}
            </div>

            <div *ngIf="!!userScheduleDetail && !userScheduleDetail?.attendanceChangesAllowed && !userScheduleDetail?.showNoStudentsHelp"
                class="bg-danger text-message">
                {{'TXT_WARNING_CANNOT_CHANGE_ATTENDANCE_DUE_TO_TIME_LIMIT' | translate}}
            </div>

            <div *ngIf="userScheduleDetail?.oneOffDisabledHelp"
                class="bg-danger text-message">
                {{'TXT_ONE_OFFS_ARE_DISABLED' | translate}}
            </div>

            <div *ngIf="userScheduleDetail?.showCompensationGroupHelp"
                class="text-message">
                {{compensationGrpHelp}}
            </div>

            <div *ngIf="userScheduleDetail?.notes"
                class="text-message">
                <span>{{'TXT_LESSON_NOTE' | translate }}</span>
                <span>{{userScheduleDetail?.notes}}</span>
            </div>
        </div>

        <div class="text-center wb-button-margin">
            <dx-button text="{{'TXT_SAVE' | translate}}"
                class="wb-button"
                [disabled]="!userScheduleDetail?.isDirty || disableClientCalendar"
                type="success"
                (onClick)="onSave($event)">
            </dx-button>
        </div>
    </div>
</dx-scroll-view>

<dx-load-panel
    [visible]="isLoadPanelVisible">
</dx-load-panel>
<dx-load-panel
    [visible]="isSavePanelVisible"
    message="{{'TXT_SAVING' | translate}}">
</dx-load-panel>
