import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {CourseService, SemesterService, TenantService} from '../../../shared/services';
import {Course, TenantSetting, TenantSummary} from '../../../shared/models';

import { TranslateService } from '@ngx-translate/core';

import {combineLatest, Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-course-detail-home',
  templateUrl: './course-detail-home.component.html',
  styleUrls: ['./course-detail-home.component.css']
})
export class CourseDetailHomeComponent implements OnInit {

  courseID: Observable<number>;
  course: Observable<Course>;
  pictures: string[] = [];
  hasPictures: boolean = false;
  loading: boolean = true;
  returnUrl: string = '';
  registerButtonText: string = '';
  disableRegisterButton: boolean = true;

   private resolvedCourse: Course;
  private tenantSetting: TenantSetting;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseService: CourseService,
    private tenantService: TenantService,
    private translate: TranslateService,
    private semesterService: SemesterService) { }

  ngOnInit() {
    this.translate.get('TXT_REGISTER_ON_COURSE').subscribe((res: string) => {
      this.registerButtonText = res;
    });

    this.courseID = this.route
      .params
      .pipe(map(params => +params['id']));

    combineLatest([
      this.courseID,
      this.tenantService.getTenantSummary()
    ]).subscribe((res: [number, TenantSummary]) => {
      let id = res[0];
      this.tenantSetting = res[1].tenantSetting;

      this.course = this.courseService.getCourse(id);
      this.returnUrl = `/courses/${id}/register`;

      this.course.subscribe(c => {
        this.resolvedCourse = c;
        this.semesterService.isSemesterOpenForCourseRegistration(c.semesterID, this.tenantSetting)
          .subscribe(s => {
            this.setEnableRegisterButton(this.resolvedCourse, this.tenantSetting, s);
          });

        this.courseService.getEffectivePictures(this.course).subscribe(
          res => {
            this.pictures.push.apply(this.pictures, res);
            this.hasPictures = this.pictures.length > 0;
            this.loading = false;
          });
      });
    });
  }

  register(params) {
    this.courseID.subscribe(id => {
      this.router.navigate(["/courses", id, "register"]);
    });
  }

  private setEnableRegisterButton(course: Course, ts: TenantSetting, isSemesterOpenForCourseRegistration: boolean) {
    if (!course || !ts || !isSemesterOpenForCourseRegistration) {
      this.disableRegisterButton = true;
      return;
    }

    if (ts.readOnlyClientCourseRegistration) {
      this.disableRegisterButton = true;
      return;
    }

    if (ts.courseInterestsSupport) {
      this.disableRegisterButton = !course.allowRegistration;
      return;
    }

    if (ts.allowClientCourseRegistration) {
      if (course.isFull) {
        if (ts.enableFullCourseInterest) {
          this.translate.get('TXT_FULL_COURSE_INTEREST').subscribe((res: string) => {
            this.registerButtonText = res;
          });
          this.disableRegisterButton = !course.allowRegistration;
          return;
        }
      } else {
        this.disableRegisterButton = !course.allowRegistration;
        return;
      }

      this.disableRegisterButton = true;
    }
  }
}
