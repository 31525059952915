import { Type, Expose, Transform, Exclude } from "class-transformer";

import { UserScheduleDetailRef } from './userScheduleDetailRef';
import { UserScheduleDetailAttendanceRef } from './userScheduleDetailAttendanceRef';
import { CourseAttendanceType } from '../enums';

import * as _ from 'lodash';

export class UserScheduleDetailAttendance implements UserScheduleDetailAttendanceRef {

    studentID: number;
    fullName: string;

    @Expose({ name: "courseAttendanceTypeID" })
    courseAttendanceType: CourseAttendanceType;

    noOfAvailableAmends: number;
    preAmendsCount: number;
    timeBoundAmendCount: number;
    waitQueueReservationsCount: number;
    causalPrice: number;

    @Exclude()
    private _present: boolean = null;
    @Exclude()
    private _presentOrig: boolean = null;

    @Exclude()
    get isDirty() {
        return this._presentOrig !== this.present;
    }

    @Exclude()
    get presentOrig(): boolean {
        return this._presentOrig;
    }

    @Expose()
    get present(): boolean {
        return this._present;
    }
    set present(value: boolean) {
        this._present = value;
        if (this._presentOrig === null) {
            this._presentOrig = value;
        }
    }

    @Exclude()
    userScheduleDetail: UserScheduleDetailRef;

    @Exclude()
    get waitQueuePresent(): boolean {
        return !!_.find(this.userScheduleDetail.waitQueue, { studentID: this.studentID });
    }

    @Exclude()
    get effectivePresent(): boolean {
        return this.present || this.waitQueuePresent;
    }

    @Exclude()
    effectiveCourseAttendanceType: CourseAttendanceType;

    @Exclude()
    oneOffDisabled: boolean;

    @Exclude()
    showPrice: boolean;

    @Exclude()
    presentReadOnly: boolean;

    @Exclude()
    get actualPreAmendsCount(): number {
        if ((this.effectiveCourseAttendanceType === CourseAttendanceType.Amend ||
            this.effectiveCourseAttendanceType === CourseAttendanceType.Registered) && (this.present !== this._presentOrig)) {
            if (this.present && !this._presentOrig) {
                if (this.waitQueueIndex > 0) {
                    return this.noOfAvailableAmends <= 0
                        ? Math.max(0, this.preAmendsCount + this.noOfAvailableAmends + this.timeBoundAmendCount)
                        : this.preAmendsCount + this.timeBoundAmendCount;
                } else {
                    return this.noOfAvailableAmends <= 0
                        ? Math.max(0, this.preAmendsCount + this.noOfAvailableAmends + this.timeBoundAmendCount - 1)
                        : this.preAmendsCount + this.timeBoundAmendCount - 1;
                }
            }

            if (!this.present && this._presentOrig) {
                if (this.noOfAvailableAmends < 0) {
                    if (this.userScheduleDetail.attendanceChangesInTime) {
                        var virtualWaitQueueIndex = this.userScheduleDetail.getVirtualWaitQueueIndex.call(this.userScheduleDetail, this, true, true);
                        if (virtualWaitQueueIndex > 0) {
                            return Math.max(0, this.preAmendsCount + this.noOfAvailableAmends + this.timeBoundAmendCount);
                        } else {
                            return Math.max(0, this.preAmendsCount + this.noOfAvailableAmends + this.timeBoundAmendCount + 1);
                        }
                    } else {
                        return Math.max(0, this.preAmendsCount + this.noOfAvailableAmends + this.timeBoundAmendCount);
                    }
                } else {
                    return this.preAmendsCount + this.timeBoundAmendCount;
                }
            }

        } else {
            return this.noOfAvailableAmends < 0
                ? Math.max(0, this.preAmendsCount + this.noOfAvailableAmends + this.timeBoundAmendCount)
                : this.preAmendsCount + this.timeBoundAmendCount;
        }

    }

    @Exclude()
    get actualAmendsCount(): number {
        if ((this.effectiveCourseAttendanceType === CourseAttendanceType.Amend ||
            this.effectiveCourseAttendanceType === CourseAttendanceType.Registered) && (this.present !== this._presentOrig)) {
            if (this.present && !this._presentOrig) {
                if (this.waitQueueIndex > 0) {
                    return this.noOfAvailableAmends;
                } else {
                    return Math.max(this.noOfAvailableAmends - 1, 0);
                }
            }

            if (!this.present && this._presentOrig) {
                if (this.noOfAvailableAmends < 0 || !this.userScheduleDetail.attendanceChangesInTime) {
                    return Math.max(this.noOfAvailableAmends, 0);
                } else {
                    var studentID = this.studentID;
                    var waitQueuePresent = _.find(this.userScheduleDetail.waitQueue, function (w) { return w.studentID === studentID });
                    if (waitQueuePresent) {
                        return this.noOfAvailableAmends;
                    } else {
                        return Math.max(this.noOfAvailableAmends + 1, 0);
                    }
                }
            }

        } else {
            return Math.max(this.noOfAvailableAmends, 0);
        }
    }

    @Exclude()
    get waitQueueIndex(): number {
        return this.userScheduleDetail.getVirtualWaitQueueIndex(this, this.present, this._presentOrig);
    }

    initializeModel(parent: UserScheduleDetailRef): void {
        this.userScheduleDetail = parent;

        this.effectiveCourseAttendanceType = this.present
            ? this.courseAttendanceType
            : this.noOfAvailableAmends > 0
                ? CourseAttendanceType.Amend
                : (this.preAmendsCount - this.noOfAvailableAmends + this.timeBoundAmendCount) > 0
                    ? CourseAttendanceType.Amend
                    : CourseAttendanceType.OneOff;

        this.oneOffDisabled = this.effectiveCourseAttendanceType === CourseAttendanceType.OneOff &&
            this.causalPrice == null;

        this.showPrice = this.effectiveCourseAttendanceType === CourseAttendanceType.OneOff && !!this.causalPrice &&
            (this.userScheduleDetail.attendanceChangesAllowed || this.present);

        this.presentReadOnly = !this.userScheduleDetail.attendanceChangesAllowed ||
            (this.oneOffDisabled && this.effectiveCourseAttendanceType === CourseAttendanceType.OneOff && !this.present);
    }
}