<div class="new-photo-album-section">
  <h3 class="dx-theme-accent-as-text-color">{{'TXT_NEW_PHOTO_ALBUM' | translate}}</h3>
  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_DATE' | translate }}</div>
    <div class="dx-field-value">
      <dx-date-box [(value)]="galleryCreatedAtUtc"
                   pickerType="native"
                   [disabled]="editDisabled"
                   type="date">
      </dx-date-box>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_DESCRIPTION' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-area [(value)]="galleryDescription"
                    [disabled]="editDisabled"
                    [placeholder]="'TXT_PHOTO_ALBUM_DESC_PLACEHOLDER' | translate"
                    [height]="60">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 120 }]"></dx-validator>
      </dx-text-area>
    </div>
  </div>
</div>
<div class="wb-back-button">
  <dx-button *ngIf="!uploadFinished"
             text="{{'TXT_CANCEL' | translate}}"
             class="wb-button"
             type="default"
             [disabled]="editDisabled"
             (onClick)="backBtnClick($event)">

  </dx-button>
  <dx-button *ngIf="uploadFinished"
               text="{{'TXT_PREV' | translate}}"
               class="wb-button"
               type="default"
               (onClick)="backBtnClick($event)">
  </dx-button>
</div>
<dx-file-uploader
  id="file-uploader"
  [multiple]="true"
  uploadMode="useButtons"
  [chunkSize]="8388508"
  [maxFileSize]="16777216"
  [allowedFileExtensions]="['.jpg', '.jpeg']"
  accept="image/jpeg"
  (onUploadStarted)="onUploadStarted($event)"
  (onFilesUploaded)="onFilesUploaded($event)"
  [uploadChunk]="uploadChunkBindThis()">

</dx-file-uploader>
