import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {Router, NavigationEnd, ActivatedRouteSnapshot} from "@angular/router";
import { TenantService, HtmlContentService } from '../../services';
import { HtmlContentType } from 'app/shared/models/enums';

@Component({
  selector: 'app-route-title',
  templateUrl: './route-title.component.html',
  styleUrls: ['./route-title.component.css']
})
export class RouteTitleComponent implements OnInit {

  constructor(
    private tenantService: TenantService,
    private router: Router,
    private htmlContentService: HtmlContentService
  ) { }

  routeTitle: string = "";
  upperTitle: string = "";
  lowerTitle: string = "";

  setTitle(root: ActivatedRouteSnapshot, navigationEndEvent:NavigationEnd) {
    let self = this;
    while (root) {
      if (root.children && root.children.length) {
        root = root.children[0];
      } else if (root.data && root.data["caption"]) {
        self.routeTitle = root.data["caption"];

        self.tenantService.getTenantSummary().subscribe(res => {
          self.upperTitle = res.tenantInfo.friendlyName;

          switch (self.routeTitle) {
            case "TXT_COURSES":
              if (res.tenantSetting.coursesMenuName) {
                self.routeTitle = res.tenantSetting.coursesMenuName;
              }
              break;
            case "TXT_EVENTS":
              if (res.tenantSetting.eventsMenuName) {
                self.routeTitle = res.tenantSetting.eventsMenuName;
              }
              break;
            case "TXT_EXCUSES":
              if (res.tenantSetting.compensationsMenuName) {
                self.routeTitle = res.tenantSetting.compensationsMenuName;
              }
              break;
            case "TXT_KINDERGARTEN":
              if (res.tenantSetting.kidergartenMenuName) {
                self.routeTitle = res.tenantSetting.kidergartenMenuName;
              }
              break;
            case "TXT_MY_KINDERGARTEN":
              if (res.tenantSetting.myKidergartenMenuName) {
                self.routeTitle = res.tenantSetting.myKidergartenMenuName;
              }
              break;
            case "HTML-CONTENT": {
              if (navigationEndEvent) {
                let htmlContentType = Number(/[^/]*$/.exec(navigationEndEvent.urlAfterRedirects)[0]);
                self.htmlContentService.getCaptionById(<HtmlContentType>htmlContentType).subscribe(title => self.routeTitle = title);
              }
            }
              break;
          }

        });
        return;
      } else {
        this.routeTitle = "";
        this.upperTitle = "";
        this.tenantService.getTenantSummary().subscribe(res => {
          this.routeTitle = res.tenantInfo.friendlyName;
        });
        return;
      }
    }

  }

  ngOnInit() {

    this.setTitle(this.router.routerState.snapshot.root, null);

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      let navigationEndEvent = <NavigationEnd>event;
      let root = this.router.routerState.snapshot.root;
      this.setTitle(root, navigationEndEvent);
    });

  }

}
