import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { instanceToPlain} from "class-transformer";

import { FormResponse } from '../models';
import { BaseService } from './base.service';
import { TranslateService } from '@ngx-translate/core'
import { notifySuccess } from './notifications';

@Injectable()
export class FormResponseService extends BaseService {

  private baseServiceUrl: string;


  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    super();
    this.baseServiceUrl = '/restapi/forms-responses/';
  }

  create(formResponse: FormResponse): Observable<Object> {
    let self = this;
    let url: string = this.baseServiceUrl;
    let headers = this.getDefaultHttpHeaders();

    return new Observable<Object>(observer => {
      let plain = instanceToPlain(formResponse);

      self.http.post(url, plain, { headers: headers })
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            observer.next(value);
            observer.complete();
          },
          err => observer.error(self.handleError(err)))
      /* complete */
    });
  }

}
