<div class="header dx-card dx-theme-border-color-as-background-color">
    <div class="max-length-text" *ngIf="!!semester">
        <small>{{semester.name}} ({{semester.startDate | date:'shortDate'}} - {{semester.endDate | date:'shortDate'}})</small>
    </div>
    <div class="max-length-text" *ngIf="!!course">
        <small>{{course.courseFullName}}</small>
    </div>
</div>
<div class="under-header-list">
    <dx-list 
    [dataSource]="lessons"
    pullRefreshEnabled="true"
    (onPullRefresh)="onPullRefresh()"
    (onItemClick)="onItemClick($event)">
    <div *dxTemplate="let itemData of 'item'; let i = index" class="lesson-item" [ngClass]="{ 'lesson-item-first': i == 0 }" >
        <h5 class="wordwrap">
            {{itemData.startDate | date:'dd.MM.yyyy EE' | uppercase }} ({{itemData.startDate | date:'HH:mm' }} - {{itemData.endDate | date:'HH:mm' }})
        </h5>
        <div class="row">
            <div class="col-xs-4">{{'TXT_LESSON_ATTENDANCE'| translate}}:</div>
            <div class="col-xs-8">{{itemData.filled}}</div>      
        </div>   
        <div class="row">
            <div class="col-xs-4">{{'TXT_NOTES'| translate}}:</div>     
            <div class="col-xs-8 edit-notes-button">
                <dx-button
                    icon="edit"
                    type="default"
                    (mousedown)="onNotesMouseDown($event)"
                    (onClick)="onNotesClick(itemData)">
                </dx-button>
            </div>      

        </div>       
        <pre *ngIf="!!itemData.notes" class="lesson-notes">
            {{itemData.notes}}
        </pre>
    </div>
    </dx-list>
</div>

<dx-popup showTitle="true"
  title="{{'TXT_NOTES' | translate}}"
  fullScreen="true"
  showCloseButton="true"
  [(visible)]="editNotesVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-text-area
        [autoResizeEnabled]="true"
        [minHeight]="90"
        [maxHeight]="350"
        placeholder="{{'TXT_ENTER_NOTE'| translate}}"
        [(value)]="editedNote">
    </dx-text-area>
    <div class="row buttons-area">
        <div class="col-xs-6 text-right">
            <dx-button      
                text="{{'TXT_CANCEL' | translate}}"     
                type="normal"                
                (onClick)="onNotesEditCancelClick()">
            </dx-button>
        </div>
        <div class="col-xs-6">
            <dx-button      
                text="{{'TXT_SAVE' | translate}}"     
                type="normal"                
                (onClick)="onNotesEditSaveClick()">
            </dx-button>            
        </div>
        <div class="save-and-send-button text-center">
            <dx-button      
                text="{{'TXT_SAVE_AND_SEND' | translate}}"     
                type="default"                
                (onClick)="onNotesEditSaveAndSendClick()">
            </dx-button>              
        </div>
    </div>
  </div>
</dx-popup>
