import { Observable, forkJoin } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { WindowWrapper } from '../../../shared/windowWrapper';
import { TranslateService } from '@ngx-translate/core'

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service'

const STORAGE_KEY = 'course-payment-history-index';

import {
  TenantService,
  CourseRegistrationService,
  CourseService,
  OnlinePaymentService, SemesterService
} from '../../../shared/services';
import { TenantSummary, CourseUserRegistration, Course, TenantSetting } from '../../../shared/models';

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

@Component({
  selector: 'app-course-detail-register',
  templateUrl: './course-detail-register.component.html',
  styleUrls: ['./course-detail-register.component.css']
})
export class CourseDetailRegisterComponent implements OnInit {

  private courseID: number;

  onlinePaymentConfirmItems: any[] = [];
  onlinePaymentConfirmVisible: boolean = false;

  userRegistration: CourseUserRegistration;
  course: Course;
  loading: boolean = true;
  saving: boolean = false;
  confirmVisible: boolean = false;
  showGoToScheduleHelp: boolean = false;
  semesterID: number;
  allowClientLessonSelection: boolean;
  maxFreePlacesForLessonSelection: number;
  courseInterestsSupport: boolean = false;
  coursePreviewOnlyRegistration: boolean = false;
  allowClientCourseRegistration: boolean = false;
  hasAnyStudents: boolean = false;
  private onlinePaymentID: number;
  confirmPaymentMessage: string = '';
  confirmPaymentVisible: boolean = false;
  confirmFullCourseInterestVisible: boolean = false;
  private savedStudentIDs = [];

  registerButtonText: string = '';
  disableRegisterButton: boolean = true;
  fullCourseInterestMode: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _window: WindowWrapper,
    private translate: TranslateService,
    private courseRegistrationService: CourseRegistrationService,
    private onlinePaymentService: OnlinePaymentService,
    private courseService: CourseService,
    private tenantService: TenantService,
    private location: Location,
    private semesterService: SemesterService,
    @Inject(SESSION_STORAGE) private storage: StorageService) { }

  ngOnInit() {
    this.translate.get('TXT_PAY_ONLINE').subscribe((res: string) => {
      this.onlinePaymentConfirmItems.push({ text: this.translate.instant('TXT_PAY_ONLINE') });

      this.registerButtonText = this.translate.instant('TXT_REGISTER_ON_COURSE');
    });

    this.loadData().subscribe(res => {});
  }

  private loadData(): Observable<Object> {

    return new Observable<Object>(observer => {
      let self = this;

      self.loading = true;

      let urlParams = combineLatest([
        this.route.parent.params,
        this.route.queryParams
      ]).pipe(map((params) => ({ ...params[0], ...params[1] })));

      combineLatest([
        urlParams,
        self.tenantService.getTenantSummary()
      ]).subscribe((res: [[string], TenantSummary]) => {
        let urlParams = res[0];
        let tenantSummary = res[1];

        self.courseID = +urlParams['id'];
        self.onlinePaymentID = +urlParams['onlinePaymentID'];

        self.courseInterestsSupport = tenantSummary.tenantSetting.courseInterestsSupport;
        self.coursePreviewOnlyRegistration = tenantSummary.tenantSetting.coursePreviewOnlyRegistration &&
          !tenantSummary.tenantSetting.readOnlyClientCourseRegistration;
        self.allowClientCourseRegistration = tenantSummary.tenantSetting.allowClientCourseRegistration &&
          !tenantSummary.tenantSetting.readOnlyClientCourseRegistration;

        self.courseService.getCourse(self.courseID).subscribe(c => {
          self.course = c;
          self.semesterService.isSemesterOpenForCourseRegistration(c.semesterID, tenantSummary.tenantSetting)
            .subscribe(s => {
              self.setEnableRegisterButton(c, tenantSummary.tenantSetting, s);
            });
          self.allowClientLessonSelection = c.allowClientLessonSelection;

          self.showGoToScheduleHelp = !tenantSummary.tenantSetting.disableClientCalendar &&
            !!c.causalPrice &&
            !self.courseInterestsSupport &&
            !self.allowClientLessonSelection;
          self.semesterID = c.semesterID;

          self.maxFreePlacesForLessonSelection = c.maxFreePlacesForLessonSelection;

          self.courseRegistrationService.getCourseUserRegistration({
            courseID: self.courseID,
            userID: tenantSummary.loggedInUser.userID,
            includeInterestsAsVirtual: self.courseInterestsSupport,
            allowClientLessonSelection: self.allowClientLessonSelection,
            preselectSingleStudentAttendance: true,
            refresh: true
          }).subscribe(ur => {
            self.userRegistration = ur;
            self.loading = false;
            self.hasAnyStudents = !!ur && !!ur.courseRegistrations && ur.courseRegistrations.length > 0;

            observer.next(self.userRegistration);
            observer.complete();
          }, err => {
            self.loading = false;
            observer.error(err);
          });
        });

        if (self.onlinePaymentID) {
          self.onlinePaymentService.getTransactionState(self.onlinePaymentID).subscribe((op) => {
            self.confirmPaymentMessage = self.onlinePaymentService.getOnlinePaymentResultMessage(op);
            self.confirmPaymentVisible = true;
          });
        }
      }, err => {
        self.loading = false;
        observer.error(err);
      });
    });
  }

  showConfirmRegistration(params: any) {
    let self = this;

    let valid = true;
    if (params.validationGroup) {
      valid = params.validationGroup.validate().isValid;
    }

    if (self.fullCourseInterestMode) {
      self.savedStudentIDs = _.map(_.filter(self.userRegistration.courseRegistrations, { attend: true, isNew: true }), 'studentID');
      self.courseRegistrationService.saveFullCourseInterest(self.userRegistration, self.course)
        .subscribe(() => {
          self.saving = false;
          self.confirmFullCourseInterestVisible = true;
          // self.loadData();
        }, err => {
          self.saving = false;
          //  self.loadData();
          self.savedStudentIDs = [];
        });
    }
    else {
      if (valid) {
        self.confirmVisible = true;
      }
    }
  }

  navigateToSchedule() {
    this.router.navigate(["/schedules/semester", this.semesterID]);
  }

  navigateToStudents() {
    this.router.navigate(["/my-account/students"], { queryParams: { returnUrl: this.router.url } } );
  }

  registrationCanceled(params) {
    this.confirmVisible = false;
  }

  registrationConfirmed(params) {
    let self = this;
    self.confirmVisible = false;
    self.saving = true;

    self.savedStudentIDs = _.map(_.filter(self.userRegistration.courseRegistrations, { attend: true, isNew: true }), 'studentID');

    self.courseRegistrationService.saveCourseRegistration(self.userRegistration)
      .subscribe(() => {
        self.saving = false;
        self.loadData().subscribe(res => {
          if (self.userRegistration) {
            let newRegistrations = _.intersectionWith(
              self.userRegistration.courseRegistrations, self.savedStudentIDs, (r, s) => {
                return r.studentID === s;
              });
            let price = _.sumBy(newRegistrations, 'arrearByAmortizationSchedule');

            if (self.course.supportsOnlinePayment &&
              !self.courseInterestsSupport && price > 0) {
              self.onlinePaymentConfirmVisible = true;
            }
          }
        })
    }, err => {
        self.saving = false;
        self.loadData().subscribe(res => {});
        self.savedStudentIDs = [];
      }
    );
  }

  onlinePaymentConfirmClick() {
    if (this.userRegistration) {

      let newRegistrations = _.intersectionWith(
        this.userRegistration.courseRegistrations, this.savedStudentIDs, (r, s) => {
          return r.studentID === s;
        });
      let invoiceIDs = _.map(newRegistrations, 'invoiceID');
      let price = _.sumBy(newRegistrations, 'arrearByAmortizationSchedule');

      this.storage.set(STORAGE_KEY, this._window.history.length);

      this.onlinePaymentService.startOnlinePayment(
        invoiceIDs,
        price);
    }
  }

  confirmFullCourseInterestResultClick(param: any) {
    let self = this;
    self.confirmFullCourseInterestVisible = false;
    self.location.back();
  }

  onConfirmPaymentResultClick(param: any) {
    let self = this;
    self.confirmPaymentVisible = false;
    self.confirmPaymentMessage = '';

    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['onlinePaymentID'] = null;

    self.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      replaceUrl: true
    });

    self.onlinePaymentID = null;

    // Navigate back in history prior to online payment
    let storedIndex = this.storage.get(STORAGE_KEY) || this._window.history.length;

    let noOfPagesToRemove = storedIndex - this._window.history.length;
    this._window.history.go(noOfPagesToRemove);
  }

  private setEnableRegisterButton(course: Course, ts: TenantSetting, isSemesterOpenForCourseRegistration: boolean) {
    if (!course || !ts || !isSemesterOpenForCourseRegistration) {
      this.disableRegisterButton = true;
      return;
    }

    if (ts.readOnlyClientCourseRegistration) {
      this.disableRegisterButton = true;
      return;
    }

    if (ts.courseInterestsSupport) {
      this.disableRegisterButton = !course.allowRegistration;
      return;
    }

    if (ts.allowClientCourseRegistration) {
      if (course.isFull) {
        if (ts.enableFullCourseInterest) {
          this.translate.get('TXT_FULL_COURSE_INTEREST').subscribe((res: string) => {
            this.registerButtonText = res;
            this.fullCourseInterestMode = true;
          });
          this.disableRegisterButton = !course.allowRegistration;
          return;
        }
      } else {
        this.disableRegisterButton = !course.allowRegistration;
        return;
      }

      this.disableRegisterButton = true;
    }
  }

}
