import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TenantService } from '../shared/services';
import { SemesterWithCompGroup } from '../shared/models';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class SchedulesComponent implements OnInit {

  showCompensationGroupSelect: boolean = false;

  constructor(
    private tenantService: TenantService,
    private router: Router) { }

  ngOnInit() {
    this.tenantService.getTenantSummary().subscribe(ts => {
      this.showCompensationGroupSelect = !ts.tenantSetting.showAllCoursesInClientSchedule;
    });
  }

  onSemesterSelect(data: SemesterWithCompGroup) {
    this.router.navigate(["/schedules/semester", data.semesterID], { queryParams: { compensationGroupID: data.compensationGroupID }, replaceUrl: data.isAutoRedirect });

  }

}
