export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyADihV0WMeB8HC0QUY4yBWBCA-VHe-SAPU",
    authDomain: "webooker.eu",
    projectId: "micro-environs-198121",
    storageBucket: "micro-environs-198121.appspot.com",
    messagingSenderId: "1234",
    appId: "1:570064795214:ios:a326d25a88f9cf331ab51f"
  }
};
