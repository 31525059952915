<dx-scheduler id="attendance-reservations-scheduler"
              [dataSource]="reservations"
              [views]='["day", "week"]'
              [groups]="['placeId']"
              currentView="day"
              startDateExpr="reservationStartDate"
              endDateExpr="reservationEndDate"
              [adaptivityEnabled]="true"
              [(currentDate)]="currentDate"
              cellDuration="15"
              [showAllDayPanel]="false"
              [editing]="editingOptions"
              [height]="'100%'"
              [useDropDownViewSwitcher]="false"
              [crossScrollingEnabled]="true"
              (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
              >
    <dxi-view
        type="day"
        [startDayHour]="displayRange.startDayHour"
        [endDayHour]="displayRange.endDayHour"
        appointmentTemplate="appointment-template"
        appointmentTooltipTemplate="tooltip-template">
    </dxi-view>
    <dxi-resource
        fieldExpr="placeId"
        valueExpr="id"
        displayExpr="name"
        label="Place"
        [allowMultiple]="false"
        [dataSource]="classrooms">
    </dxi-resource>

    <div *dxTemplate="let model of 'appointment-template'"
         class="dx-item-content dx-scheduler-appointment-content"
         [ngClass]="{'paid-reservation': (!!model.appointmentData.invoice && model.appointmentData.invoice.arrear === 0)}">
        <div class='dx-scheduler-appointment-content-details'>
            <div class="dx-scheduler-appointment-content-date">{{model.appointmentData.reservationStartDate| date:'HH:mm'}}-{{model.appointmentData.reservationEndDate| date:'HH:mm'}}
                <span *ngIf="!!model.appointmentData.domainSpecialization"> - {{model.appointmentData.domain.name}} - {{model.appointmentData.domainSpecialization.name}}</span>
                <span *ngIf="!model.appointmentData.domainSpecialization && !!model.appointmentData.domain"> - {{model.appointmentData.domain.name}}</span>
            </div>
            <div *ngFor="let student of model.appointmentData.students">
                {{student.fullName}} <span *ngIf="!!student.reducedAge">({{student.reducedAge}})</span>
            </div>
          <div class="dx-scheduler-info-icon-area">
            <span *ngIf="!!model.appointmentData.notes"><i class="fa fa-sticky-note"></i></span>
          </div>
        </div>
    </div>

    <div *dxTemplate="let model of 'tooltip-template'">
        <div class='reservation-tooltip'>
            <div class="reservation-payment-indicator"
                [ngClass]="{'paid-reservation': (!!model.appointmentData.invoice && model.appointmentData.invoice.arrear === 0), 'dx-theme-accent-as-background-color': (!model.appointmentData.invoice || model.appointmentData.invoice.arrear !== 0)}"></div>
            <div class="reservation-tooltip-header">
                <div><b>{{model.appointmentData.reservationStartDate| date:'HH:mm'}}-{{model.appointmentData.reservationEndDate| date:'HH:mm'}}</b></div>
                <div class="domain-name">
                    <div *ngIf="!!model.appointmentData.domainSpecialization">{{model.appointmentData.domain.name}} - {{model.appointmentData.domainSpecialization.name}}</div>
                    <div *ngIf="!model.appointmentData.domainSpecialization && !!model.appointmentData.domain">{{model.appointmentData.domain.name}}</div>
                </div>
            </div>
            <ul *ngFor="let student of model.appointmentData.students" class="students-list">
                <li>{{student.fullName}} <span *ngIf="!!student.reducedAge">({{student.reducedAge}})</span></li>
            </ul>
            <div *ngIf="!!model.appointmentData.notes" class="reservation-notes">
                <span><b>{{'TXT_NOTES' | translate}}:</b></span>
                {{model.appointmentData.notes}}
            </div>
        </div>
    </div>

</dx-scheduler>
