<div class="padded-content">
  {{'TXT_STUDENTS_HELP' | translate}}
</div>
<div class="dx-fieldset">
    <div *ngFor="let student of students; let i = index">
      <div class="dx-fieldset-header" [ngClass]="{'middle-student': i !== 0}" >
        {{i + 1}}. {{'TXT_STUDENT' | translate}}
        <div class="delete_student_button">
          <dx-button
            icon="trash"
            type="danger"
            (onClick)="onDeleteStudent(student)">
          </dx-button>
        </div>
      </div>
      <app-student-edit-detail
        [student]="student">
      </app-student-edit-detail>
    </div>

</div>
<div class="add-student-button text-center">
    <dx-button
        text="{{'TXT_ADD_STUDENT' | translate}}"
        icon="add"
        type="success"
        (onClick)="onAddStudent()">
    </dx-button>
<div>
