import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core'

import {
  AttendanceService,
  TenantService,
  SemesterService,
  CourseService,
  CourseScheduleService,
  StudentService} from 'app/shared/services';

import {
  TenantSetting,
  TenantSummary,
  Attendance,
  Semester,
  Course,
  CourseSchedule,
  Student} from 'app/shared/models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import Query from 'devextreme/data/query';

import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';
import { CourseAttendanceStatus, CourseAttendanceType } from 'app/shared/models/enums';

@Component({
  selector: 'app-student-course-attendance',
  templateUrl: './student-course-attendance.component.html',
  styleUrls: ['./student-course-attendance.component.css']
})
export class StudentCourseAttendanceComponent implements OnInit {

  semesterID: number;
  semester: Semester;
  courseID: number;
  course: Course;
  courseScheduleID: number;
  courseSchedule: CourseSchedule;
  studentID: number;
  student: Student;
  hasStudentIdParam: boolean = false;
  tenantSettings: TenantSetting = new TenantSetting();
  selectedStudent: Attendance = new Attendance();
  compensationGroupOverride: boolean = false;
  allowMultiAmends: boolean = false;
  clientAttendanceShowStudentNote: boolean = false;
  saving: boolean = false;
  areWeAfterLoad: boolean = false;

  public attendanceStatuses = [{
    id: 0,
    name: 'Present'
  }, {
    id: 1,
    name: 'NotPresentNotExcused'
  }, {
    id: 2,
    name: 'NotPresentExcused'
  }, {
    id: 3,
    name: 'NotPresentExcusedNoCompensation'
  }];

  public attendanceTypes = [{
    id: 0,
    name: 'Registered',
    disabled: true
  }, {
    id: 1,
    name: 'OneOff'
  }, {
    id: 2,
    name: 'Amend'
  }, {
    id: 3,
    name: 'Preview'
  }];

  public students: DataSource;
  private studentsForceRefresh: boolean = false;
  private originalCompensationCourseID: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private attendanceService: AttendanceService,
    private tenantService: TenantService,
    private semesterService: SemesterService,
    private courseService: CourseService,
    private courseScheduleService: CourseScheduleService,
    private studentService: StudentService,
    private translateService: TranslateService) { }

  ngOnInit() {
    let self = this;

    combineLatest([
      this.translateService.get('TXT_STATUS_PRESENT'),
      this.translateService.get('TXT_STATUS_NOT_PRESENT_NOT_EXCUSED'),
      this.translateService.get('TXT_STATUS_NOT_PRESENT_EXCUSED'),
      this.translateService.get('TXT_STATUS_NOT_PRESENT_EXCUSED_NO_COMPENSATION'),

    ]).subscribe((res: string[]) => {
      this.attendanceStatuses[0].name = res[0];
      this.attendanceStatuses[1].name = res[1];
      this.attendanceStatuses[2].name = res[2];
      this.attendanceStatuses[3].name = res[3];

      this.attendanceTypes[0].name = this.translateService.instant('TXT_REGISTERED');
      this.attendanceTypes[1].name = this.translateService.instant('TXT_ONE_OFF');
      this.attendanceTypes[2].name = this.translateService.instant('TXT_AMEND');
      this.attendanceTypes[3].name = this.translateService.instant('TXT_PREVIEW');
    });

    this.route.params.subscribe(params => {
      self.courseID = Number(params['courseId']);
      self.semesterID = Number(params['semesterId']);
      self.courseScheduleID = Number(params['courseScheduleId']);
      self.studentID = Number(params['studentId']);
      self.hasStudentIdParam = !!self.studentID;

      combineLatest([
        self.semesterService.getAll(null, true, true),
        self.courseService.getCourse(self.courseID),
        self.courseScheduleService.getAllForCourse(self.courseID),
        self.attendanceService.getLessonAttendance(self.courseScheduleID),
        self.tenantService.getTenantSummary()])
          .subscribe((data: [Semester[], Course, CourseSchedule[], Attendance[], TenantSummary]) => {
            self.semester = _.find(data[0], { id: self.semesterID });
            self.course = data[1];
            self.courseSchedule = _.find(data[2], { courseScheduleID: self.courseScheduleID });
            if (self.hasStudentIdParam) {
              self.selectedStudent = _.find(data[3], { studentID: self.studentID });
            } else {
              self.selectedStudent = new Attendance();
              self.selectedStudent.courseID = self.courseID;
              self.selectedStudent.courseScheduleID = self.courseScheduleID;
              self.selectedStudent.attendanceStatus = CourseAttendanceStatus.Present;
              self.selectedStudent.attendanceType = CourseAttendanceType.Amend;
              self.selectedStudent.attendanceCount = 1;
              self.selectedStudent.compensationCourseID = null;
            }

            self.originalCompensationCourseID = self.selectedStudent.compensationCourseID;

            self.compensationGroupOverride = data[4].isInRole('CompensationGroupOverride');
            self.allowMultiAmends = data[4].tenantSetting.allowMultiAmends;
            self.clientAttendanceShowStudentNote = data[4].tenantSetting.clientAttendanceShowStudentNote;

            self.areWeAfterLoad = true;
          });
    });

    self.students = new DataSource({
      paginate: true,
      pageSize: 10,
      store: new CustomStore({
        loadMode: 'raw',
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.studentService.getAllInfo(self.studentsForceRefresh).subscribe((data) => {
              let result =  _.orderBy(data, ['surname', 'name', 'middleName']);
              if (loadOptions.searchValue) {
                result = Query(result).filter([loadOptions.searchExpr[0], loadOptions.searchOperation, loadOptions.searchValue]).toArray();
              }

              resolve(result);
              self.studentsForceRefresh = false;
            }, (error) => {
              reject(error);
            });
          });
        },
        // byKey: (key: any) => {
        //   return new Promise((resolve, reject) => {
        //     self.studentService.getInfoByID(key).subscribe((data) => {
        //       resolve(data);
        //     }, (error) => {
        //       reject(error);
        //     })
        //   })
        // },
      })
    });
  }

  onPullStudentsRefresh() {
    this.studentsForceRefresh = true;
    this.students.load();
  }

  onPrevBtnClick() {
    let self = this;

    this.router.navigate([
      "/attendance/courses/semester/",
      self.semesterID,
      "course",
      self.courseID,
      "lesson",
      self.courseScheduleID],
      { replaceUrl: true });
  }

  onSaveBtnClick() {
    let self = this;
    self.saving = true;
    if (!self.hasStudentIdParam) {
      this.attendanceService.insertStudentAttendance(self.selectedStudent)
        .subscribe(s => {
          self.saving = false;
          self.onPrevBtnClick();
        }, (error) => {
          self.saving = false;
        });
    } else {
      this.attendanceService.updateStudentAttendance(self.selectedStudent)
        .subscribe(s => {
          self.saving = false;
          self.onPrevBtnClick();
        }, (error) => {
          self.saving = false;
        });
    }
  }

  onStudentValueChanged(data) {
    if (!!data.previousValue) {
      this.selectedStudent.compensationCourseID = null;
      this.selectedStudent.attendanceCount = 1;
      this.originalCompensationCourseID = null;
    }
  }

  onAttendanceTypeValueChanged(data) {
    if (!!data.previousValue) {
      this.selectedStudent.compensationCourseID = this.originalCompensationCourseID;
      this.selectedStudent.attendanceCount = 1;
    }
  }

}
