<dx-list [dataSource]="userConsents"
  [grouped]="true"
  [keyExpr]="'validity'"
  [groupTemplate]="'validity'"
  [itemTemplate]="'consents'"
  pullRefreshEnabled="true"
  [collapsibleGroups]="true"
  (onPullRefresh)="onPullRefresh()"
  >
  <div *dxTemplate="let groupData of 'validity'"
    class="validity-wrapper">
    <h4>
      {{groupData.validityText}}
    </h4>
  </div>
  <div *dxTemplate="let itemData of 'consents'"
      class="consent-wrapper">
    <div class="row" 
         [ngClass]="{'bg-danger': itemData.consentRevokedAt, 'bg-success': itemData.consentRatifiedAt, 'bg-info':  !itemData.consentRevokedAt && !itemData.consentRatifiedAt }">
      <h5 class="wordwrap">
        {{itemData.consentName}}</h5>    
    </div>
    <div class="row">
      <div class="col-xs-5">{{'TXT_VALIDITY'| translate}}:</div>
      <div class="col-xs-7 text-right">{{itemData.validFrom | date:'shortDate'}} - {{itemData.validTo | date:'shortDate'}}</div>
    </div>
    <div class="row">
      <div class="wordwrap col-xs-12">
        {{itemData.consentText}}
      </div>
    </div>
    <div class="row" 
         *ngIf="itemData.isValid"
         style="margin-top: 10px; margin-bottom: 10px;">
      <div class="col-xs-6 text-right">
        <dx-button text="{{'TXT_APPROVE' | translate}}"
          class="wb-button"
          type="success"
          [disabled]="itemData.consentRatifiedAt"
          (onClick)="approveConsent(itemData)">
        </dx-button>        
      </div>
      <div class="col-xs-6 text-left">
          <dx-button text="{{'TXT_DENY' | translate}}"
          class="wb-danger-button"
          type="danger"
          [disabled]="itemData.consentRevokedAt"
          (onClick)="rejectConsent(itemData)">
        </dx-button>            
      </div>
    </div>    
    <div *ngIf="itemData.consentRevokedAt"
         class="row">
         <div class="col-xs-5">{{'TXT_DENIED_AT'| translate}}:</div>
         <div class="col-xs-7 text-right">{{itemData.consentRevokedAt | date:'short'}}</div>
    </div>  
    <div *ngIf="itemData.consentRevokedAt"
         class="row bg-danger">
         <div class="col-xs-5">{{'TXT_DENIED_BY'| translate}}:</div>
         <div class="col-xs-7 text-right">{{itemData.consentRevokedByFullName}}</div>
    </div>    
    <div *ngIf="itemData.consentRatifiedAt"
         class="row">
         <div class="col-xs-5">{{'TXT_APPROVED_AT'| translate}}:</div>
         <div class="col-xs-7 text-right">{{itemData.consentRatifiedAt | date:'short'}}</div>
    </div>  
    <div *ngIf="itemData.consentRatifiedAt"
         class="row bg-success">
         <div class="col-xs-5">{{'TXT_APPROVED_BY'| translate}}:</div>
         <div class="col-xs-7 text-right">{{itemData.consentRatifiedByFullName}}</div>
    </div>       
  </div>
</dx-list>
