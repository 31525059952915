import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TenantService } from '../../services';

@Component({
  selector: 'app-button-with-login',
  templateUrl: './button-with-login.component.html',
  styleUrls: ['./button-with-login.component.css']
})
export class ButtonWithLoginComponent implements OnInit {

  constructor(
    private tenantService: TenantService) { }

  loginAdvertDisplayed: boolean = false;
  isLoggedIn: boolean = false;

  @Input() text: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() returnUrl: string = '';
  @Output() onClick = new EventEmitter<any>();

  ngOnInit() {
    this.tenantService.getTenantSummary().subscribe(res => {
      this.isLoggedIn = res.loggedInUser.isAuthenticated;
    });
  }

  onButtonClick(params) {
    if (!this.isLoggedIn) {
      this.loginAdvertDisplayed = true;
    } else {
      this.onClick.emit(params);
    }
  }

}
