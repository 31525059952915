
import { publishReplay, refCount, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClientModule, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import {plainToInstance, instanceToPlain} from "class-transformer";

import { BaseService } from './base.service';
import { TenantService } from './tenant.service';
import { ActivityService } from './activity.service';
import { notifySuccess } from './notifications';

import { Constants } from '../constants';

import { TranslateService } from '@ngx-translate/core'

import * as moment from "moment";

import {
  ActivityAttendance,
  ActivityReservation,
  ActivitySchedule,
  StudentActivityDetails,
  StudentActivityDetail,
  ActivityAttendanceModel,
  ActivityExcuseStudentRequest,
  Activity,
  TenantSetting,
  TenantSummary
} from '../models';


import * as _ from 'lodash';
import {ActivitiesBySemesterWithSchedules} from "../models/activities";

@Injectable()
export class ActivityAttendanceService extends BaseService {

  private baseServiceUrl: string;
  private activityDetail: Observable<ActivityAttendanceModel[]>;
  private lastStudentID: number;
  private lastActivityID: number;

  constructor(
    private http: HttpClient,
    private activityService: ActivityService,
    private translateService: TranslateService,
    private tenantService: TenantService
  ) {

    super();
    this.baseServiceUrl = '/Api/ActivityAttendance/';
  }

  // for teacher attendance
  // should be moved to Api
  getActivitySchedules(semesterID: number): Observable<ActivitiesBySemesterWithSchedules> {
    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("semesterID", semesterID.toString());

    let url: string = '/ActivityAttendance/GetActivitySchedules';
    return this.http.get(url, { headers: headers, params: params }).pipe(
      catchError((err, c) => this.handleErrorAndThrow(err)),
      map((res: Object) => plainToInstance(ActivitiesBySemesterWithSchedules, res))
    );
  }

  getStudentAttendanceDetail(activityID: number, studentID: number, refresh: boolean = false): Observable<ActivityAttendanceModel[]> {
    let self = this;

    if (!self.activityDetail || self.lastStudentID !== studentID || self.lastActivityID !== activityID || refresh) {

      self.lastActivityID = activityID;
      self.lastStudentID = studentID;

      let headers = self.getDefaultHttpHeaders();
      let params = new HttpParams()
        .set("activityID", activityID.toString())
        .append("studentID", studentID.toString());

      let url: string = self.baseServiceUrl + 'GetAttendanceDetail';

      self.activityDetail = new Observable<ActivityAttendanceModel[]>(observer => {

        forkJoin(
          self.tenantService.getTenantSummary(),
          self.activityService.get(activityID)
        ).subscribe((ats: [TenantSummary, Activity]) => {
          let data = self.http
            .get(url, { headers: headers, params: params }).pipe(
              catchError((err, c) => self.handleErrorAndThrow(err)),
              map(res => {
                let studentActivityDetails = plainToInstance<StudentActivityDetails, Object>(StudentActivityDetails, res);
                return self.buildAttendanceModelBySchedules(studentActivityDetails, ats[1], ats[0].tenantSetting, studentID);
              })).subscribe(data => {
                observer.next(data);
                observer.complete();
              }, err => observer.error(self.handleError(err)));
        }, err => observer.error(self.handleError(err)));
      }).pipe(
        publishReplay(1),
        refCount());
    }

    return self.activityDetail;
  }

  excuseStudent(activityExcuseStudentRequest: ActivityExcuseStudentRequest): Observable<ActivityAttendanceModel[]> {

    let url: string = this.baseServiceUrl + 'ExcuseStudent';
    let headers = this.getDefaultHttpHeaders();

    let self = this;

    return new Observable<ActivityAttendanceModel[]>(observer => {
      forkJoin(
        self.tenantService.getTenantSummary(),
        self.activityService.get(activityExcuseStudentRequest.activityID)
      ).subscribe(ts => {
        self.http.post(url, activityExcuseStudentRequest, { headers: headers }).pipe(
          map(res => {
            let studentActivityDetails = plainToInstance<StudentActivityDetails, Object>(StudentActivityDetails, res);
            return self.buildAttendanceModelBySchedules(studentActivityDetails, ts[1], ts[0].tenantSetting);
          }))
          .subscribe(data => {
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            observer.next(data);
            observer.complete();

          }, err => observer.error(self.handleError(err)))
      }, err => observer.error(self.handleError(err)))
    });
  }

  saveAttendance(activityAttendanceModel: ActivityAttendanceModel, checkCapacity: boolean): Observable<ActivityAttendanceModel[]> {
    let url: string = this.baseServiceUrl + 'Save';
    let headers = this.getDefaultHttpHeaders();

    let self = this;

    let activityID = activityAttendanceModel.activityID;
    let studentID = activityAttendanceModel.studentID;

    return new Observable<ActivityAttendanceModel[]>(observer => {

      forkJoin(
        self.tenantService.getTenantSummary(),
        self.activityService.get(activityAttendanceModel.activityID)
      ).subscribe(ts => {
        let plain = instanceToPlain(activityAttendanceModel.getActivityAttendance());
        self.http.post(url, {
          model: plain,
          checkCapacity: checkCapacity
        }, { headers: headers }).pipe(
          map(res => {
            let studentActivityDetails = plainToInstance<StudentActivityDetails, Object>(StudentActivityDetails, res);
            return self.buildAttendanceModelBySchedules(studentActivityDetails, ts[1], ts[0].tenantSetting, activityAttendanceModel.studentID);
          }))
          .subscribe(data => {
            self.translateService.get('TXT_ATTENDANCE_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            // update getStudentAttendanceDetail cache
            self.lastActivityID = activityID;
            self.lastStudentID = studentID;
            self.activityDetail = of(data);

            observer.next(data);
            observer.complete();

          }, err => observer.error(self.handleError(err)))
      }, err => observer.error(self.handleError(err)))
    });

  }

  private buildAttendanceModelBySchedules(studentActivityDetails: StudentActivityDetails, activity: Activity, tenantSetting: TenantSetting, studentID: number = null)
    : ActivityAttendanceModel[] {

    let rdict = _.keyBy(studentActivityDetails.reservations, 'activityScheduleID');
    let adict = _.keyBy(studentActivityDetails.attendances, 'activityScheduleID');
    let excusesDict = _.keyBy(studentActivityDetails.excusesByDate, (s) => +moment(s.date).startOf('day'));
    let result: ActivityAttendanceModel[] = [];

    _.forEach(studentActivityDetails.schedules, function (schedule: ActivitySchedule) {

      let excuse = excusesDict[+moment(schedule.startDate).startOf('day')];

      result.push(new ActivityAttendanceModel(
        schedule,
        rdict[schedule.activityScheduleID],
        adict[schedule.activityScheduleID],
        studentActivityDetails.setting,
        activity,
        tenantSetting,
        excuse != null ? excuse.noOfExcuses : 0,
        studentID));
    });

    return result;
  }

}
