import { TransformDate } from '../../decorators';
import { Exclude } from "class-transformer";

export class Employee {
    id: string;
    name: string;
    surname: string;
    middlename: string;
    aboutMe: string;
    academicDegree: string;

    @TransformDate()
    birthday: Date;
    children: string;
    education: string;
    groupsIDs: string[];
    isActive: boolean;
    isDeleted: boolean;
    jobDescription: string;
    rfidCardIDs: string[];
    smallImage: string;
    userID: string;
    workExperience: string;

    @Exclude()
    get fullName(): string {
        let result = "";
        if (this.name)
            result = result + this.name;
        if (this.middlename)
            result = result + ' ' + this.middlename;
        if (this.surname)
            result = result + ' ' + this.surname;
        return result;
    }
}