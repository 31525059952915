import { TransformDate } from '../../decorators';

export class User {
  id: number;
  securityRoleID: number;
  userName: string;
  name: string;
  surname: string;
  middlename: string;

  fullName: string;
  address: string;
  phone: string;
  email: string;

  identityCardNumber: string;
  personalIDNumber: string;

  referenceSourceID: number;

  @TransformDate()
  lastActivity: Date;

  allowEmailContact: boolean;
  bankAccountNo: string;
  paymentSymbol: string;
  notes: string;
  nationality: string;

  name2: string;
  surname2: string;
  middleName2: string;
  address2: string;
  phone2: string;
  email2: string;
  identityCardNumber2: string;
  personalIDNumber2: string;
  nationality2: string;

  @TransformDate()
  birthday: Date;
  @TransformDate()
  birthday2: Date;

}
