import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Semester} from "../../../shared/models";

import * as _ from 'lodash';
import {ActivityAttendanceService, SemesterService, TenantService} from "../../../shared/services";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-semester-activities-attendance',
  templateUrl: './semester-activities-attendance.component.html',
  styleUrls: ['./semester-activities-attendance.component.css']
})
export class SemesterActivitiesAttendanceComponent implements OnInit {

  semesterID: number;
  semester: Semester;
  activities: DataSource;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tenantService: TenantService,
    private semesterService: SemesterService,
    private activityAttendanceService: ActivityAttendanceService) { }

  ngOnInit(): void {
    let semesterIDObservable = this.route
      .params
      .pipe(map(params => Number(params['semesterId'])));

    semesterIDObservable.subscribe((res: number) => {
      this.semesterID = res;
      this.semesterService.getAll(null, true, null, true)
        .subscribe((data: Semester[]) => {
          this.semester = _.find(data, {id: +this.semesterID});
        });
    });

    this.activities = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            semesterIDObservable.subscribe((res: number) => {
              this.semesterID = res;
              this.activityAttendanceService.getActivitySchedules(this.semesterID)
                .subscribe(activities => {
                  resolve(activities.activities);
                }, (error) => {
                  reject(error);
                });
            });
          });
        }
      })
    })
  }

  onPullRefresh() {
    this.activities.load();
  }

  // attendance/activities/semester/:semesterId/activity/:activityId/picture-gallery
  onPictureGalleryClick(activityID: number) {
    this.router.navigate(["/attendance/activities/semester", this.semesterID, "activity", activityID, "picture-gallery"]);
  }

}
