import { TransformDate } from '../../decorators';

export class Student {
    id: number;
    userID: number;
    name: string;
    surname: string;
    middleName: string;
    gender: string;

    @TransformDate()
    birthday: Date;
    age: string;
    health: string;
    nationality: string;
    rfidCardIDs: string[];
    groups: number[];
    fullName: string;
    parentFullName: string;
    email: string;
    phone: string;
    address: string;
    active: boolean;
    personalIDNumber: string;

    healthInsurance: string;
    studentAddress: string;
    allergies: string;
    placeOfBirth: string;
    admiNotes: string;
}
