import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ActivityService } from '../shared/services';
import { ActivitySummary, ActivityStudentSummary } from '../shared/models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import * as _ from 'lodash';
import * as moment from "moment";

@Component({
  selector: 'app-my-activities',
  templateUrl: './my-activities.component.html',
  styleUrls: ['./my-activities.component.css']
})
export class MyActivitiesComponent implements OnInit {

  constructor(
    private activityService: ActivityService,
    private router: Router) { }

  studentSummary: DataSource;
  studentSummaryRaw: ActivityStudentSummary[];
  private forceReload: boolean = false;
  private pictureGalleryButtonClicked = false;

  ngOnInit() {
    let self = this;

    self.studentSummary = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.activityService.getSummary(self.forceReload).subscribe(data => {
              self.studentSummaryRaw = []; //data;
              let clone = _.cloneDeep(data);
              _.forEach(clone, s => {
                let filtered = _.filter(s.items, i => moment(i.activityEndDate).isSameOrAfter(moment()));
                if (filtered.length === 0) {
                  s.items = [s.items[0]]; // Take first one
                } else {
                  s.items = filtered;
                }
                self.studentSummaryRaw.push(s);
              })
              resolve(self.studentSummaryRaw);
            }, (error) => {
              reject(error);
            });
            self.forceReload = false;
          })
        }
      })
    });
  }

  onPullRefresh() {
    this.forceReload = true;
    this.studentSummary.load();
  }

  onItemClick(params: any) {
    let activitySummary: ActivitySummary = params.itemData;
    let activityStudentSummary = this.studentSummaryRaw[params.itemIndex.group];

    if (this.pictureGalleryButtonClicked) {
      this.pictureGalleryButtonClicked = false;
      this.router.navigate(["/my-activities/picture-gallery"],
      { queryParams: { activityID: activitySummary.activityID, studentID: activityStudentSummary.studentID } });
    } else {     
      this.router.navigate(["/my-activities/attendance"],
        { queryParams: { activityID: activitySummary.activityID, studentID: activityStudentSummary.studentID } });
    }
  }

  onPictureGalleryClick() {
    this.pictureGalleryButtonClicked = true;
  }

}
