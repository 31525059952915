<div *ngIf="!isLoggedIn && displayStudentEdit && !!userInfo">
  <div class="dx-field-label wb-filed-label-large">{{'TXT_STUDENT_CHILD' | translate}}</div>
  <div class="dx-field"
    *ngIf="tenantSetting?.mandatoryStudentName < 2">
    <div class="dx-field-label">{{'TXT_NAME' | translate}}
      <span *ngIf="tenantSetting?.mandatoryStudentName == 1"
        class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryStudentName == 1"
        [(value)]="userInfo.students[0].name">
        <dx-validator
          [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_NAME' | translate) }, { type: 'stringLength', max: 30 }]">
        </dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryStudentName == 0"
        [(value)]="userInfo.students[0].name">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_SURNAME' | translate }}
      <span class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="userInfo.students[0].surname">
        <dx-validator
          [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_SURNAME' | translate) }, { type: 'stringLength', max: 30 }]">
        </dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field"
    *ngIf="tenantSetting?.mandatoryStudentBirthday < 2">
    <div class="dx-field-label">{{ 'TXT_BIRTHDAY' | translate }}
      <span *ngIf="tenantSetting?.mandatoryStudentBirthday == 1"
        class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-date-box *ngIf="tenantSetting?.mandatoryStudentBirthday == 1"
        [(value)]="userInfo.students[0].birthday"
        pickerType="native"
        type="date">
        <dx-validator
          [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_BIRTHDAY' | translate) }]">
        </dx-validator>
      </dx-date-box>
      <dx-date-box *ngIf="tenantSetting?.mandatoryStudentBirthday == 0"
        [(value)]="userInfo.students[0].birthday"
        type="date"
        pickerType="native"
        [showClearButton]="true">
      </dx-date-box>
    </div>
  </div>

  <div class="dx-field"
    *ngIf="tenantSetting?.showHealthInCourseRegistration">
    <div class="dx-field-label">{{ 'TXT_HEALTH' | translate }}</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="userInfo.students[0].health"
        placeholder="{{'TXT_HEALTH_OK' | translate}}">
      </dx-text-box>
    </div>
  </div>

</div>

<div *ngIf="!isLoggedIn && !!userInfo">
    <div class="dx-field-label wb-filed-label-large">{{'TXT_LEGAL_REPRESENTATIVE' | translate}}</div>
  <div class="dx-field">
    <div class="dx-field-label">{{'TXT_NAME' | translate}}</div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="userInfo.name">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_SURNAME' | translate }}
      <span class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="userInfo.surname">
        <dx-validator
          [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_SURNAME' | translate) }, { type: 'stringLength', max: 30 }]">
        </dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_EMAIL' | translate }}
      <span class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="userInfo.email"
        mode="email">
        <dx-validator
          [validationRules]="[{ type: 'email', message: 'TXT_LOGIN_ERROR_INVALID_EMAIL' | translate }, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_EMAIL' | translate) }]">
        </dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_PHONE' | translate }}
      <span class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box [(value)]="userInfo.phone"
                   placeholder="+420 999 999 999"
                   mask="+999 999 999 999"
                   showMaskMode="onFocus"
                   [showClearButton]="true">
        <dx-validator
          [validationRules]="[{ type: 'pattern', pattern: phoneValidationRegEx, message: ('TXT_PHONE_FORMAT_INVALID' | translate)}, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PHONE' | translate) }]">
        </dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserAddress < 2">
    <div class="dx-field-label">{{ 'TXT_ADDRESS' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserAddress == 1"
        class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-area
        *ngIf="tenantSetting?.mandatoryUserAddress == 1"
        [(value)]="userInfo.address"
        [height]="60">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 255 }, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_BIRTHDAY' | translate) }]"></dx-validator>
      </dx-text-area>
      <dx-text-area
        *ngIf="tenantSetting?.mandatoryUserAddress == 0"
        [(value)]="userInfo.address"
        [height]="60">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 255 }]"></dx-validator>
      </dx-text-area>
    </div>
  </div>

  <div class="dx-field"
    *ngIf="tenantSetting?.allowReferenceSource < 2 && isReferenceSourceMandatory">
    <div class="dx-field-label">{{ 'TXT_REFERENCE_SOURCE' | translate }}<span class="dx-field-item-required-mark"> *</span></div>
    <div class="dx-field-value">
      <dx-lookup [(value)]="userInfo.referenceSourceId"
        [dataSource]="referenceSources"
        valueExpr="id"
        displayExpr="name"
        fullScreen="true"
        [placeholder]="referenceSourcesPlaceholder">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_REFERENCE_SOURCE' | translate) }]"></dx-validator>
      </dx-lookup>
    </div>
  </div>

  <div class="dx-field"
    *ngIf="tenantSetting?.allowReferenceSource < 2 && !isReferenceSourceMandatory">
    <div class="dx-field-label">{{ 'TXT_REFERENCE_SOURCE' | translate }}</div>
    <div class="dx-field-value">
      <dx-lookup [(value)]="userInfo.referenceSourceId"
        [dataSource]="referenceSources"
        valueExpr="id"
        displayExpr="name"
        fullScreen="true"
        [placeholder]="referenceSourcesPlaceholder">
      </dx-lookup>
    </div>
  </div>

</div>
