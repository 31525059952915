import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '../../shared/services';

import { Course } from '../../shared/models/course/course';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import * as _ from 'lodash';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {

  Math = Math;

  constructor(
    private router: Router,
    private actionService: CourseService) { }

  actions: DataSource;
  forceRefresh: boolean = false;

  searchValueChanged(e: any) {
    this.actions.searchValue(e.value);
    this.actions.load();
  }

  applyFilter(actions: Course[], filter: string) {
    return _.filter(actions, function (action) {
      return action.name.indexOf(filter) != -1 ||
        action.subjectName.indexOf(filter) != -1 ||
        action.shortDescription.indexOf(filter) != -1;
    });
  }

  onPullRefresh() {
    this.forceRefresh = true;
    this.actions.load();
  }

  onItemClick(params) {
    this.router.navigate(["/actions", (<Course>params.itemData).courseID]);
  }

  ngOnInit() {
    this.actions = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            this.actionService.getFeaturedActions(this.forceRefresh).subscribe((data) => {
              if (!!loadOptions.searchValue) {
                resolve(this.applyFilter(data, <string>loadOptions.searchValue));
              } else {
                resolve(data);
              }
              this.forceRefresh = false;
            }, (error) => {
              reject(error);
            });
          })
        }
      })
    });
  }

}
