import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  CourseService,
  TenantService,
  EmployeeService,
  CompensationGroupService,
  SemesterService} from '../../../shared/services';
import {
  Course,
  TenantSetting,
  TenantSummary,
  Employee,
  CompensationGroup,
  Semester} from '../../../shared/models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import * as _ from 'lodash';

import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-semester-course-attendance',
  templateUrl: './semester-course-attendance.component.html',
  styleUrls: ['./semester-course-attendance.component.css']
})
export class SemesterCourseAttendanceComponent implements OnInit {

  semesterID: number;
  courses: DataSource;
  tenantSettings: TenantSetting = new TenantSetting();
  semester: Semester;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseService: CourseService,
    private employeeService: EmployeeService,
    private compensationGroupService: CompensationGroupService,
    private tenantService: TenantService,
    private semesterService: SemesterService) { }

  ngOnInit() {
    let self = this;

    this.route.params.subscribe(params => {
      self.semesterID = Number(params['semesterId']);

      combineLatest([
        self.semesterService.getAll(null, true, true)])
          .subscribe((data: [Semester[]]) => {
            self.semester = _.find(data[0], { id: self.semesterID });
          });
    });

    let semesterID = this.route
      .params
      .pipe(map(params => Number(params['semesterId'])));

    let tenantSummaryObservable = self.tenantService.getTenantSummary();

    self.courses = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {

            combineLatest([semesterID, tenantSummaryObservable])
              .subscribe((res: [number, TenantSummary]) => {
                let tenantSummary = res[1];
                self.semesterID = res[0];
                self.tenantSettings = tenantSummary.tenantSetting;

                if (tenantSummary.isInRole("ClientAttendanceAdmin")) {
                  combineLatest([
                    self.courseService.getAllForSemester(self.semesterID, false),
                    self.compensationGroupService.getAll()
                  ]).subscribe((data: [Course[], CompensationGroup[]]) => {
                    let courses = data[0];
                    let compensationGroups = data[1];
                    let mapped: Course[] = _.map(courses, (c: Course) => {
                      let compensationGroup = _.find(compensationGroups, { compensationGroupID: c.compensationGroupID });
                      c.compensationGroupName = !!compensationGroup ? compensationGroup.name : null;
                      return c;
                    });

                    let filtered = _.filter(mapped, (c: Course) => !!c.firstLesson);
                    resolve(filtered);
                  }, (error) => {
                    reject(error);
                  });
                } else {
                  self.employeeService.getByUserId(tenantSummary.loggedInUser.userID + '')
                    .subscribe(employee => {
                      if (!employee) {
                        resolve([]);
                      } else {
                        combineLatest([
                          self.courseService.getAllCoursesForSemesterAndTeacher(self.semesterID, employee.id),
                          self.compensationGroupService.getAll()
                        ]).subscribe((data: [Course[], CompensationGroup[]]) => {
                          let courses = data[0];
                          let compensationGroups = data[1];
                          let mapped: Course[] = _.map(courses, (c: Course) => {
                            let compensationGroup = _.find(compensationGroups, { compensationGroupID: c.compensationGroupID });
                            c.compensationGroupName = !!compensationGroup ? compensationGroup.name : null;
                            return c;
                          });

                          let filtered = _.filter(mapped, (c: Course) => !!c.firstLesson);
                          resolve(filtered);
                        }, (error) => {
                          reject(error);
                        });
                      }
                    }, (error) => {
                      reject(error);
                    });
                }
            });
          });
        }
      })
    })
  };

  onPullRefresh() {
    this.courses.load();
  }

  onItemClick(params) {
    this.router.navigate(["/attendance/courses/semester/", (<Course>params.itemData).semesterID, "course", (<Course>params.itemData).courseID]);
  }
}
