import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { BaseGuard } from './baseGuard';

import { LoginService } from '../services';

import { Observable } from 'rxjs';

@Injectable()
export class CanActivateForUserSignUpGuard extends BaseGuard implements CanActivate {

  constructor(
    loginService: LoginService,
    router: Router) {
    super(router, loginService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    let self = this;

    return new Observable<boolean>(observer => {

      if(route.queryParams["registration"] == "true") {
        observer.next(true);
      } else {
         self.loginService.isAuthenticated().subscribe((a) => {
            if (a) {
              observer.next(true);
            } else {
              observer.next(false);
              self.navigateToLogin(route);
            }
            observer.complete();
          },
          err => {
            observer.error(err);
            self.navigateToLogin(route);
          });
      }
    });
  }

}
