import { Pipe, PipeTransform } from '@angular/core';

import { AgeRangeService } from '../services';

import * as _ from 'lodash';

@Pipe({
  name: 'ageRange',
  pure: false
})
export class AgeRangePipe implements PipeTransform {

  private cachedValue: number;
  private cachedData: string = "";

  constructor(private ageRangeService: AgeRangeService) {
  }

  transform(value: number, args?: any): any {

    if (this.cachedValue !== value) {
      this.cachedValue = value;
      this.cachedData = "";
      this.ageRangeService.getAll().subscribe(a => {
        let ageRange = _.find(a, { ageRangeID: value });
        if (!!ageRange) {
          this.cachedData = ageRange.name;
        }
      });
    }

    return this.cachedData;
  }

}
