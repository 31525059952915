import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


import { ProficiencyLevelService } from '../../services';
import { ProficiencyLevel } from '../../models';

@Component({
  selector: 'app-proficiency-level-lookup',
  templateUrl: './proficiency-level-lookup.component.html',
  styleUrls: ['./proficiency-level-lookup.component.css']
})
export class ProficiencyLevelLookupComponent implements OnInit {

  constructor(private proficiencyLevelService: ProficiencyLevelService) { }

  private _value: number = 0;
  @Output() valueChange = new EventEmitter<number>();
  @Input()
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    if (this._value !== value) {
      this._value = value;
      this.valueChange.emit(value);
    }
  }

  @Input()
  disabled: boolean;

  proficiencyLevels: ProficiencyLevel[] = [];

  ngOnInit() {
    this.proficiencyLevelService.getAll().subscribe(p => this.proficiencyLevels = p);
  }

}
