export class TenantSetting {
  actionExcuseThreshold: number;
  actionExcuseThresholdType: number;
  activityExcuseThreshold: number;
  activityExcuseThresholdType: number;
  activityExcuseWithoutFoodThreshold: number;
  activityExcuseWithoutFoodThresholdType: number;
  allowClientActionRegistration: boolean;
  allowClientCourseRegistration: boolean;
  allowDiscounts: boolean;
  allowMultiAmends: boolean;
  allowReferenceSource: number;
  allowShareOnFBForActions: boolean;
  allowShareOnFBForCourses: boolean;
  allowTeacherEmailInClientSection: boolean;
  calcSnacksOnlyForPresent: boolean;
  cashReceiptPurposeForCourses: string;
  clientAttendanceShowStudentNote: boolean;
  clientScheduleWeekView: boolean;
  courseExcuseThreshold: number;
  courseExcuseThresholdType: number;
  courseInterestsSupport: boolean;
  coursePaymentEvidenceCCTeacher: boolean;
  coursePreviewOnlyRegistration: boolean;
  courseRegistrationAgreement: string;
  courseRegistrationQuestionCCToTeacher: boolean;
  coursesSortByDate: boolean;
  defaultNationality: string;
  disableAllEmails: boolean;
  disableAllEmailsExceptUserRegistration: boolean;
  disableCashReceiptNumbering: boolean;
  disableClientCalendar: boolean;
  disableExcuseInMyCourses: boolean;
  disableInformTeachers: boolean;
  enableActivityAttendanceFoodTakenNotification: boolean;
  enableActivityAttendanceChangeNotification: boolean;
  enableActivityFoodOrderNotification: boolean;
  enableActivityReconciliationCreatedNotification: boolean;
  enableActivityReconciliationPaymentEvidenceNotification: boolean;
  enableActivityScheduleNoteChangeNotification: boolean;
  enableAttendanceActionExcusedCancelledNotification: boolean;
  enableAttendanceActionExcusedNoCompensationNotification: boolean;
  enableAttendanceActionExcusedNotification: boolean;
  enableAttendanceExcusedCancelledNotification: boolean;
  enableAttendanceExcusedNoCompensationNotification: boolean;
  enableAttendanceExcusedNotification: boolean;
  enableAttendanceNewAmendNotification: boolean;
  enableAttendanceNewOneOffNotification: boolean;
  enableAttendanceNoteChangedNotification: boolean;
  enableAttendancePreviewInterestNotification: boolean;
  enableAttendancePreviewNotification: boolean;
  enableCoursePaymentEvidenceNotification: boolean;
  enableCourseRegistrationQuestionNotification: boolean;
  enableCourseScheduleDateChangedNotification: boolean;
  enableCourseScheduleNoteChangedNotification: boolean;
  enableCourseScheduleTeacherChangedNotification: boolean;
  enableDataExport: boolean;
  enableKindergartenSupport: boolean;
  enableNotExcusedNotification: boolean;
  enableOnlineLessonReminderNotification: boolean;
  enableOnlineLessonStartedNotification: boolean;
  enableRegisterActivityInterestNotification: boolean;
  enableRfidCardSupport: boolean;
  enableSendRequestForPaymentNotification: boolean;
  enableScheduledInvoiceIssuedNotification: boolean;
  enableSMSInWaitQueueAutoBooking: boolean;
  enableSMSSupport: boolean;
  enableStudentActionRegistrationDeleteNotification: boolean;
  enableStudentActionRegistrationNotification: boolean;
  enableStudentActionRegistrationPriceChange: boolean;
  enableStudentAddedToActionWaitQueueNotification: boolean;
  enableStudentAddedToWaitQueueNotification: boolean;
  enableStudentCourseInterestRegistrationNotification: boolean;
  enableStudentCourseRegistrationNotification: boolean;
  enableStudentCourseRegistrationPriceChangeNotification: boolean;
  enableStudentRemovedFromActionWaitQueueNotification: boolean;
  enableStudentRemovedFromWaitQueueNotification: boolean;
  enableSurveyRequestNotification: boolean;
  enableUserCreatedNotification: boolean;
  enableUserPasswordChangedNotification: boolean;
  enableUserRegistration: boolean;
  enableZoomIntegration: boolean;
  invoiceDueDate: Date;
  invoiceDueDates: number;
  invoiceDueDateType: number;
  mandatoryStudentBirthday: number; // Mandatory: 0 - Not mandatory but visible, 1 - Mandatory, 2 - Not visible, not mandatory, 3 - not visible even in admin
  mandatoryStudentName: number; // Mandatory: 0 - Not mandatory but visible, 1 - Mandatory, 2 - Not visible, not mandatory, 3 - not visible even in admin
  mandatoryStudentNationality: number; // Mandatory: 0 - Not mandatory but visible, 1 - Mandatory, 2 - Not visible, not mandatory, 3 - not visible even in admin
  mandatoryUserAddress: number; // Mandatory: 0 - Not mandatory but visible, 1 - Mandatory, 2 - Not visible, not mandatory, 3 - not visible even in admin
  mandatoryUserNationality: number; // Mandatory: 0 - Not mandatory but visible, 1 - Mandatory, 2 - Not visible, not mandatory, 3 - not visible even in admin
  mandatoryEmail2: number;
  mandatoryPhone2: number;
  noOfAllowedOneOffs: number;
  noOfDaysToShowAction: number;
  noOfHoursToUpdateAttendance: number;
  noteDescriptionInCourseRegistration: string;
  printedInvoiceName: string;
  quickUserRegistrationMode: boolean;
  readOnlyClientCourseRegistration: boolean;
  registrationOpenPicture: string;
  sendApplicationForm: boolean;
  sendEmailsToClients: boolean;
  sendInvoicesToClients: boolean;
  showAgeInClientAttenandace: boolean;
  showAllCoursesInClientSchedule: boolean;
  showArrearInClientAttendance: boolean;
  showArrearInMyCourses: boolean;
  showArrearInsteadOfPayments: boolean;
  showAttendantHealthInCourseRegistration: boolean;
  showClassroomFilterInCourses: boolean;
  showContatInClientAttendance: boolean;
  showCourseTypeFilterInCourses: boolean;
  showHealthInCourseRegistration: boolean;
  showLessonsRemaining: boolean;
  showNearestLessonInCourses: boolean;
  showNoteInClientAttendance: boolean;
  showNoteInCourseRegistration: boolean;
  showPriceInCourseRegistration: boolean;
  showQuickNavOnHomePage: boolean;
  scheduleDayViewResourcesPerPage: number;
  scheduleEnableAutoHourRange: boolean;
  scheduleTimeScale: number;
  scheduleVisibleTimeEnd: number;
  scheduleVisibleTimeStart: number;
  sortNamesBySurname: boolean;
  useTotalPriceForCourses: boolean;
  zoomApiKeyOverride: string;
  zoomApiSecretOverride: string;
  zoomUserTypeToCreate: number;
  enableRfidAccessGroups: boolean;
  autoPaymentConfirmationPrintForCardPayments: boolean;
  rfidBreakTresholdIntervalInMinutes: number;
  rfidValidateESFByLaw: boolean;
  sendInfoCardWithCourseRegistration: boolean;
  sendApplicationFormAction: boolean;
  showExcusesAndAmendsInMyActivities: boolean;
  facebookPixelID: string;
  requireTeacherFranchiseLicenses: boolean;
  showAgeFilterInCourses: boolean;
  showScheduleInCourses: boolean;
  showAgeRangesInCourses: boolean;
  cashReceiptPurposeForActions: string;
  cashReceiptPurposeForActivitiesReconciliation: string;
  cashReceiptPurposeForActivitiesReccuring: string;
  showInvoiceNoInCashReceipt: boolean;
  enablePaymentGateways: boolean;
  coursesMainPageButton: string;
  eventMainPageButton: string;
  compensationsMainPageButton: string;
  kidergartenMainPageButton: string;
  coursesMenuName: string;
  eventsMenuName: string;
  compensationsMenuName: string;
  kidergartenMenuName: string;
  enableDuePaymentsInClient: boolean;
  showOverpaymentsInClientDuePayments: boolean;
  maxNumberOfActivityWaitQueueReservations: number;
  showDateInClientCourses: boolean;
  showAskQuestionInCourseRegistration: boolean;
  maxNumberOfCourseWaitQueueReservations: number;
  createTaxDocuments: boolean;
  enableTaxDocumentCreatedNotification: boolean;
  enableNewConsentAssignedNotification: boolean;
  enableAmortizationSchedules: boolean;
  enableAmortizationSchedulePaymentNotification: boolean;
  customContentTypeText: string;
  maxNoOfPreviewStudentsOnOneLesson: number;
  putStudentAgeIntoInvoices: boolean;
  enableReservationSupport: boolean;
  showCourseWaitQueueIndexInClient: boolean;
  reservationExcuseThresholdType: number;
  reservationExcuseThreshold: number;
  reservationTeacherHourSingleRate: number;
  reservationTeacherHourGroupOneRate: number;
  reservationTeacherGroupOneSizeFrom: number;
  reservationTeacherGroupOneSizeTo: number;
  reservationTeacherHourGroupTwoRate: number;
  reservationTeacherGroupTwoSizeFrom: number;
  reservationTeacherGroupTwoSizeTo: number;
  reservationCreateThresholdType: number;
  reservationCreateThreshold: number;
  enableFormResponseCreatedNotification: boolean;
  enableFullCourseInterestNotification: boolean;
  enableFullCourseInterest: boolean;
  reservationsMainPageButton: string;
  formsMainPageButton: string;
  myKidergartenMenuName: string;
  mandatoryStudentPersonalIDNumber: number; // Mandatory: 0 - Not mandatory but visible, 1 - Mandatory, 2 - Not visible, not mandatory, 3 - not visible even in admin
  sendPaymentConfirmationForm: boolean;
  enableRentSupport: boolean;
  facebookPageUrl: string;
  enableStudentActionRegistrationAutoDeleteNotification: boolean;
  enableOnlineMeetingRecordingCompletedNotification: boolean;
  requireTermsAndConditionsAgreement: boolean;
  requireTradeTermsAgreement: boolean;
  googleAnalyticsId: string;
  allowClientActionUnRegistrationInsteadOfExcuse: boolean;
  mandatoryStudentHealthInsurance: number;
  mandatoryStudentAddress: number;
  mandatoryStudentAllergies: number;
  mandatoryStudentPlaceOfBirth: number;
  mandatoryUserIdentityCardNumber: number;
  mandatoryUserPersonalIDNumber: number;
  mandatoryUserNationality2: number;
  mandatoryUserName2: number;
  mandatoryUserSurname2: number;
  mandatoryUserMiddleName2: number;
  mandatoryUserAddress2: number;
  mandatoryUserIdentityCardNumber2: number;
  mandatoryUserPersonalIDNumber2: number;
  mandatoryUserBirthday: number;
  mandatoryUserBirthday2: number;
  mandatoryStudentNotes: number;
  enableReservationPaymentEvidenceNotification: boolean;
  showNoteInEventRegistration: boolean;
  enableClientUserSchedules: boolean;
  enableQrCodeOnInvoices: boolean;
  enableNewClientDesign: boolean;
  customerLogos: string;
  clientTitlePictureUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  newClientDesignThemeCode: number;
  newClientDesignThemeData: number;
  rfidLogoType: number;
}
