<div 
  [ngSwitch]="question.questionType">

  <div *ngSwitchCase="0">
    <!-- SingleSelectOption -->
    <dx-radio-group [items]="question.options"
      (onValueChanged)="onValueChanged($event)"
      [displayExpr]="'name'">
      <dx-validator>
        <dxi-validation-rule type="required"
          message="{{'TXT_SELECT_EXACTLY_ONE_VALUE' | translate}}"></dxi-validation-rule>
      </dx-validator>
    </dx-radio-group>
  </div>

  <div *ngSwitchCase="1"
    class="wb-checkbox-list">
    <!-- MultiSelectOption -->
    <dx-check-box *ngFor="let option of question.options"
      class="wb-checkbox-list-item"
      [isValid]="isValid"
      (onValueChanged)="onValueChanged($event, option)"
      [text]="option.name">
    </dx-check-box>
    <dx-validator [adapter]="atLeastOneValueAdapterConfig">
      <dxi-validation-rule type="required"
        message="{{'TXT_SELECT_AT_LEAST_ONE_VALUE' | translate}}">
      </dxi-validation-rule>
    </dx-validator>
  </div>

  <div *ngSwitchCase="2">
    <!-- SingleSelectOptionPlusFreeFormText -->
    <dx-radio-group [items]="question.options"
      (onValueChanged)="onValueChanged($event)"
      [displayExpr]="'name'">
      <dx-validator>
        <dxi-validation-rule type="required"
          message="{{'TXT_SELECT_EXACTLY_ONE_VALUE' | translate}}"></dxi-validation-rule>
      </dx-validator>
    </dx-radio-group>
    <dx-text-area [width]="'100%'"
      [(value)]="answer.textAnswer">
    </dx-text-area>
  </div>

  <div *ngSwitchCase="3"
    class="wb-checkbox-list">
    <!-- MultiSelectOptionPlusFreeFormText -->
    <dx-check-box *ngFor="let option of question.options"
      class="wb-checkbox-list-item"
      [isValid]="isValid"
      (onValueChanged)="onValueChanged($event, option)"
      [text]="option.name">
    </dx-check-box>
    <dx-text-area [width]="'100%'"
      [(value)]="answer.textAnswer">
    </dx-text-area>
    <dx-validator [adapter]="atLeastOneValueAdapterConfig">
      <dxi-validation-rule type="required"
        message="{{'TXT_SELECT_AT_LEAST_ONE_VALUE' | translate}}">
      </dxi-validation-rule>
    </dx-validator>
  </div>

  <div *ngSwitchCase="4">
    <!-- FreeFormTextOnly -->
    <dx-text-area [width]="'100%'"
      [(value)]="answer.textAnswer">
      <dx-validator>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dx-validator>
    </dx-text-area>
  </div>
</div>