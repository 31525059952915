import { Component, OnInit, Input /*, Output, EventEmitter*/ } from '@angular/core';
import { User /*, ReferenceSource, TenantSetting, TenantSummary*/ } from '../../models';

@Component({
  selector: 'app-user-account-detail',
  templateUrl: './user-account-detail.component.html',
  styleUrls: ['./user-account-detail.component.css']
})
export class UserAccountDetailComponent implements OnInit {

  // public referenceSources: ReferenceSource[] = [];
  // public referenceSourcesPlaceholder: string;
  // public tenantSetting: TenantSetting;
  // public isReferenceSourceMandatory: boolean = false;

  @Input()
  user: User;

  @Input()
  showFieldsetHeaders: boolean;

  @Input()
  disableEmailEdit: boolean;

  phoneValidationRegEx: any = /\d{3}\d{3}\d{3}\d{3}/;
//
  constructor(
  ) {
  }

  ngOnInit() {
  }
}
