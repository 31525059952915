import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { PercentPipe, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { CookieModule } from 'ngx-cookie';

import { AppComponent } from './app.component';
import { CoursesComponent } from './courses/courses.component';
import { ActionsComponent } from './actions/list/actions.component';
import { ActionDetailComponent } from './actions/action-detail/action-detail.component';
import { ActionDetailHomeComponent } from './actions/action-detail/action-detail-home/action-detail-home.component';
import { ActionDetailRegisterComponent } from './actions/action-detail/action-detail-register/action-detail-register.component';
import { SchedulesComponent } from './schedule/schedule.component';
import { MyActivitiesComponent } from './my-activities/my-activities.component';
import { HomeComponent } from './home/home.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyAccountDetailDuePaymentsComponent } from './my-account/my-account-detail-due-payments/my-account-detail-due-payments.component';
import { MyAccountConsentsComponent } from './my-account/my-account-consents/my-account-consents.component';

import { ActivitiesComponent } from './activities/activities.component';
import { MyActivitiesAttendanceComponent } from './my-activities/my-activities-attendance/my-activities-attendance.component';

import { RouteTitleComponent } from './shared/components/route-title/route-title.component';
import { ButtonWithLoginComponent } from './shared/components/button-with-login/button-with-login.component';
import { StudentCourseRegistrationComponent } from './shared/components/student-course-registration/student-course-registration.component';
import { PaymentSummaryComponent } from './shared/components/payment-summary/payment-summary.component';
import { ActionDetailRegisterConfirmComponent } from './actions/action-detail/action-detail-register-confirm/action-detail-register-confirm.component';
import { SemesterSelectComponent } from './shared/components/semester-select/semester-select.component';
import { CoursesListComponent } from './courses/list/courses-list.component';
import { CourseDetailComponent } from './courses/course-detail/course-detail.component';
import { CourseDetailHomeComponent } from './courses/course-detail/course-detail-home/course-detail-home.component';
import { SemesterScheduleComponent } from './schedule/semester-schedule/semester-schedule.component';
import { SemesterScheduleDetailComponent } from './schedule/semester-schedule-detail/semester-schedule-detail.component';
import { ClassroomCalendarComponent } from './shared/components/classroom-calendar/classroom-calendar.component';
import { RequestLoginActionSheetComponent } from './shared/components/request-login-action-sheet/request-login-action-sheet.component';
import { UserAccountDetailComponent } from './shared/components/user-account-detail/user-account-detail.component';
import { UserContactEditDetailComponent } from './shared/components/user-contact-edit-detail/user-contact-edit-detail.component';
import { StudentsEditDetailComponent } from './shared/components/students-edit-detail/students-edit-detail.component';
import { LoginEditDetailComponent } from './shared/components/login-edit-detail/login-edit-detail.component';
import { ActivityCalendarComponent } from './shared/components/activity-calendar/activity-calendar.component';

import { Angulartics2Module } from 'angulartics2';
import { StorageServiceModule } from 'ngx-webstorage-service';

import {
  CanActivateIfAuthenticated,
  CanActivateIfNotAuthenticated,
  CanActivateForCourseRegistrationGuard,
  CanActivateForActionRegistrationGuard,
  CanActivateForSchedulesGuard,
  CanActivateForActivitiesGuard,
  CanActivateForFormsGuard,
  CanActivateForReservationAttendanceGuard,
  CanActivateForCoursesAttendanceGuard,
  CanActivateForAttendanceGuard,
  CanActivateForReservationsGuard,
  CanActivateForActivitiesAttendanceGuard,
  CanActivateForUserSignUpGuard,
  CanActviateNewDesignGuardGuard
} from './shared/guards';

import {
  TenantService,
  LanguageService,
  LoginService,
  CourseService,
  StudentService,
  CourseRegistrationService,
  CourseScheduleService,
  MembershipService,
  InvoiceService,
  MembershipStudentService,
  AttendanceService,
  CoursePriceService,
  PromotionService,
  SemesterService,
  ClassroomService,
  UserScheduleService,
  UserService,
  ValidationService,
  ReferenceSourceService,
  ActivityService,
  ActivityAttendanceService,
  AgeRangeService,
  AccountingBookService,
  ProficiencyLevelService,
  DuePaymentsService,
  OnlinePaymentService,
  ConsentsService,
  FormDefinitionService,
  FormResponseService,
  EmployeeService,
  ReservationService,
  DomainService,
  CompensationGroupService
} from './shared/services';

import { WindowWrapper } from './shared/windowWrapper';

import { WbcurrencyPipe } from './shared/pipes/wbcurrency.pipe';
import { CourseAttendanceTypePipe } from './shared/pipes/course-attendance-type.pipe';
import { ActivityAttendanceStatusPipe } from './shared/pipes/activity-attendance-status.pipe';
import { AgeRangePipe } from './shared/pipes/age-range.pipe';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import * as moment from "moment";

const appRoutes: Routes = [
  {
    path: 'courses',
    component: CoursesComponent,
    data: { caption: "TXT_COURSES" },
    canActivate: [CanActivateForCourseRegistrationGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'courses/:id',
    component: CourseDetailComponent,
    data: { caption: "TXT_COURSES" },
    children: [
      {
        path: '',
        component: CourseDetailHomeComponent,
        canActivate: [CanActivateForCourseRegistrationGuard, CanActviateNewDesignGuardGuard]
      },
      {
        path: 'register',
        component: CourseDetailRegisterComponent,
        canActivate: [CanActivateForCourseRegistrationGuard, CanActivateIfAuthenticated, CanActviateNewDesignGuardGuard]
      }
    ],
    canActivate: [CanActivateForCourseRegistrationGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'courses/semester/:semesterId',
    component: CoursesListComponent,
    data: { caption: "TXT_COURSES" },
    canActivate: [CanActivateForCourseRegistrationGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'actions',
    component: ActionsComponent,
    data: { caption: "TXT_EVENTS" },
    canActivate: [CanActivateForActionRegistrationGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'actions/:id',
    component: ActionDetailComponent,
    data: { caption: "TXT_EVENTS" },
    children: [
      {
        path: '',
        component: ActionDetailHomeComponent,
        canActivate: [CanActivateForActionRegistrationGuard, CanActviateNewDesignGuardGuard]
      },
      {
        path: 'register',
        component: ActionDetailRegisterComponent,
        canActivate: [CanActivateForActionRegistrationGuard, CanActivateIfAuthenticated, CanActviateNewDesignGuardGuard]
      }
    ],
    canActivate: [CanActivateForActionRegistrationGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'schedules',
    component: SchedulesComponent,
    data: { caption: "TXT_EXCUSES" },
    canActivate: [CanActivateForSchedulesGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'schedules/semester/:semesterId',
    component: SemesterScheduleComponent,
    data: { caption: "TXT_EXCUSES" },
    canActivate: [CanActivateForSchedulesGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'schedules/:courseScheduleID',
    component: SemesterScheduleDetailComponent,
    data: { caption: "TXT_EXCUSES" },
    canActivate: [CanActivateIfAuthenticated, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'my-activities',
    component: MyActivitiesComponent,
    data: { caption: "TXT_MY_KINDERGARTEN" },
    canActivate: [CanActivateForActivitiesGuard, CanActivateIfAuthenticated]
  },
  {
    path: 'my-activities/attendance',
    component: MyActivitiesAttendanceComponent,
    data: { caption: "TXT_KINDERGARTEN_ATTENDANCE" },
    canActivate: [CanActivateForActivitiesGuard, CanActivateIfAuthenticated]
  },
  {
    path: 'my-activities/picture-gallery',
    component: MyActivitiesPictureGalleryComponent,
    data: { caption: "TXT_KINDERGARTEN_PICTURE_GALLERY" },
    canActivate: [CanActivateForActivitiesGuard, CanActivateIfAuthenticated]
  },
  {
    path: 'activities',
    component: ActivitiesComponent,
    data: { caption: "TXT_KINDERGARTEN" },
    canActivate: [CanActivateForActivitiesGuard]
  },
  {
    path: 'my-account',
    redirectTo: 'my-account/user-account',
    pathMatch: 'full'
  },
  {
    path: 'my-account/:tab',
    component: MyAccountComponent,
    data: { caption: "TXT_MY_ACCOUNT" },
    canActivate: [CanActivateForUserSignUpGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'forms',
    component: FormsComponent,
    data: { caption: "TXT_FORMS" },
    canActivate: [CanActivateForFormsGuard, CanActviateNewDesignGuardGuard]
  },
  {
    path: 'attendance',
    component: AttendanceComponent,
    data: { caption: "TXT_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForAttendanceGuard]
  },
  {
    path: 'attendance/reservations',
    component: ReservationsAttendanceComponent,
    data: { caption: "TXT_RESERVATIONS_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForReservationAttendanceGuard]
  },
  {
    path: 'attendance/courses',
    component: CoursesAttendanceComponent,
    data: { caption: "TXT_COURSES_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForCoursesAttendanceGuard]
  },
  {
    path: 'attendance/courses/semester/:semesterId',
    component: SemesterCourseAttendanceComponent,
    data: { caption: "TXT_COURSES_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForCoursesAttendanceGuard]
  },
  {
    path: 'attendance/courses/semester/:semesterId/course/:courseId',
    component: CourseCourseAttendanceComponent,
    data: { caption: "TXT_COURSES_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForCoursesAttendanceGuard]
  },
  {
    path: 'attendance/courses/semester/:semesterId/course/:courseId/lesson/:courseScheduleId',
    component: LessonCourseAttendanceComponent,
    data: { caption: "TXT_COURSES_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForCoursesAttendanceGuard]
  },
  {
    path: 'attendance/courses/semester/:semesterId/course/:courseId/lesson/:courseScheduleId/student/:studentId',
    component: StudentCourseAttendanceComponent,
    data: { caption: "TXT_COURSES_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForCoursesAttendanceGuard]
  },
  {
    path: 'attendance/courses/semester/:semesterId/course/:courseId/lesson/:courseScheduleId/student',
    component: StudentCourseAttendanceComponent,
    data: { caption: "TXT_COURSES_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForCoursesAttendanceGuard]
  },
  {
    path: 'attendance/activities',
    component: ActivitiesAttendanceComponent,
    data: { caption: "TXT_KINDERGARTEN_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForActivitiesAttendanceGuard]
  },
  {
    path: 'attendance/activities/semester/:semesterId',
    component: SemesterActivitiesAttendanceComponent,
    data: { caption: "TXT_KINDERGARTEN_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForActivitiesAttendanceGuard]
  },
  {
    path: 'attendance/activities/semester/:semesterId/activity/:activityId/picture-gallery',
    component: ActivityAttendancePictureGalleryComponent,
    data: { caption: "TXT_KINDERGARTEN_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForActivitiesAttendanceGuard]
  },
  {
    path: 'attendance/activities/semester/:semesterId/activity/:activityId/picture-gallery/upload',
    component: ActivityAttendancePictureGalleryUploadComponent,
    data: { caption: "TXT_KINDERGARTEN_ATTENDANCE" },
    canActivate: [CanActivateIfAuthenticated, CanActivateForActivitiesAttendanceGuard]
  },
  {
    path: 'forms/:id/form-response',
    component: FormResponseComponent,
    data: { caption: "TXT_FORM_RESPONSE" },
    canActivate: [CanActviateNewDesignGuardGuard]
  },
  {
    path: 'reservations',
    component: ReservationsComponent,
    data: { caption: "TXT_RESERVATIONS" },
    canActivate: [CanActivateForReservationsGuard]
  },
  {
    path: 'reservations/place/:placeId',
    component: ReservationsPlaceComponent,
    data: { caption: "TXT_RESERVATIONS" },
    canActivate: [CanActivateForReservationsGuard]
  },
  {
    path: 'reservations/place/:placeId/domain/:domainId',
    component: ReservationsPlaceDomainComponent,
    data: { caption: "TXT_RESERVATIONS" },
    canActivate: [CanActivateForReservationsGuard, CanActivateIfAuthenticated]
  },
  {
    path: 'reservations/place/:placeId/domain/:domainId/specialization/:specializationId',
    component: ReservationsPlaceDomainComponent,
    data: { caption: "TXT_RESERVATIONS" },
    canActivate: [CanActivateForReservationsGuard, CanActivateIfAuthenticated]
  },
  {
    path: 'html-content/:htmlContentType',
    component: HtmlContentComponent,
    data: { caption: "HTML-CONTENT" },
    canActivate: [CanActviateNewDesignGuardGuard]
  },

  {
    path: '', component: HomeComponent,
    canActivate: [CanActviateNewDesignGuardGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxGalleryModule } from 'devextreme-angular/ui/gallery';

import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxCalendarModule } from 'devextreme-angular/ui/calendar';
import { DxLookupModule } from 'devextreme-angular/ui/lookup';
import { DxActionSheetModule } from 'devextreme-angular/ui/action-sheet';

import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxValidationSummaryModule } from 'devextreme-angular/ui/validation-summary';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';

import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxProgressBarModule } from 'devextreme-angular/ui/progress-bar';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxMapModule } from 'devextreme-angular/ui/map';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';


import { CourseDetailRegisterComponent } from './courses/course-detail/course-detail-register/course-detail-register.component';
import { CourseDetailRegisterConfirmComponent } from './courses/course-detail/course-detail-register-confirm/course-detail-register-confirm.component';
import { ProficiencyLevelLookupComponent } from './shared/components/proficiency-level-lookup/proficiency-level-lookup.component';
import { ClassroomPipe } from './shared/pipes/classroom.pipe';
import { FormsComponent } from './forms/forms.component';
import { FormResponseComponent } from './forms/form-response/form-response.component';
import { FormQuestionEditComponent } from './shared/components/form-question-edit/form-question-edit.component';
import { NotRegisteredUserEditComponent } from './shared/components/not-registered-user-edit/not-registered-user-edit.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { ReservationsAttendanceComponent as ReservationsAttendanceComponent } from './attendance/reservations-attendance/reservations-attendance.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { ClassroomSelectComponent } from './shared/components/classroom-select/classroom-select.component';
import { ReservationsPlaceComponent } from './reservations/reservations-place/reservations-place.component';
import { DomainSelectComponent } from './shared/components/domain-select/domain-select.component';
import { ReservationsPlaceDomainComponent } from './reservations/reservations-place-domain/reservations-place-domain.component';
import { FullCourseStampComponent } from './shared/components/full-course-stamp/full-course-stamp.component';
import { HtmlContentComponent } from './html-content/html-content.component';
import { MyActivitiesPictureGalleryComponent } from './my-activities/my-activities-picture-gallery/my-activities-picture-gallery.component';
import { CoursesAttendanceComponent } from './attendance/courses-attendance/courses-attendance.component';
import { SemesterCourseAttendanceComponent } from './attendance/courses-attendance/semester-course-attendance/semester-course-attendance.component';
import { CourseCourseAttendanceComponent } from './attendance/courses-attendance/course-course-attendance/course-course-attendance.component';
import { LessonCourseAttendanceComponent } from './attendance/courses-attendance/lesson-course-attendance/lesson-course-attendance.component';
import { StudentCourseAttendanceComponent } from './attendance/courses-attendance/student-course-attendance/student-course-attendance.component';
import { CompensationCourseLookupComponent } from './shared/components/compensation-course-lookup/compensation-course-lookup.component';
import {StudentEditDetailComponent} from "./shared/components/student-edit-detail/student-edit-detail.component";
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { ActivitiesAttendanceComponent } from './attendance/activities-attendance/activities-attendance.component';
import { SemesterActivitiesAttendanceComponent } from './attendance/activities-attendance/semester-activities-attendance/semester-activities-attendance.component';
import { ActivityAttendancePictureGalleryComponent } from './attendance/activities-attendance/activity-attendance-picture-gallery/activity-attendance-picture-gallery.component';
import { ActivityAttendancePictureGalleryUploadComponent } from './attendance/activities-attendance/activity-attendance-picture-gallery-upload/activity-attendance-picture-gallery-upload.component';
import {DxFileUploaderModule, DxLoadIndicatorModule} from "devextreme-angular";

import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import localeEn from '@angular/common/locales/en';
import { SignInComponent } from './shared/components/sign-in/sign-in.component';
import { SignUpEditComponent } from './shared/components/sign-up-edit/sign-up-edit.component';

registerLocaleData(localeCs, 'cs');
registerLocaleData(localeEn, 'en');

export function getWindow() { return window; }

export function localeFactory(tenantService: TenantService) {
  return tenantService.getLanguage();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        CoursesComponent,
        ActionsComponent,
        HomeComponent,
        SchedulesComponent,
        MyActivitiesComponent,
        WbcurrencyPipe,
        RouteTitleComponent,
        ActionDetailComponent,
        ActionDetailHomeComponent,
        ActionDetailRegisterComponent,
        ButtonWithLoginComponent,
        StudentCourseRegistrationComponent,
        PaymentSummaryComponent,
        ActionDetailRegisterConfirmComponent,
        SemesterSelectComponent,
        CoursesListComponent,
        CourseDetailComponent,
        CourseDetailHomeComponent,
        SemesterScheduleComponent,
        SemesterScheduleDetailComponent,
        ClassroomCalendarComponent,
        RequestLoginActionSheetComponent,
        UserAccountDetailComponent,
        UserContactEditDetailComponent,
        StudentsEditDetailComponent,
        LoginEditDetailComponent,
        MyAccountComponent,
        ActivitiesComponent,
        MyActivitiesAttendanceComponent,
        ActivityCalendarComponent,
        CourseAttendanceTypePipe,
        ActivityAttendanceStatusPipe,
        CourseDetailRegisterComponent,
        CourseDetailRegisterConfirmComponent,
        AgeRangePipe,
        ProficiencyLevelLookupComponent,
        ClassroomPipe,
        MyAccountDetailDuePaymentsComponent,
        MyAccountConsentsComponent,
        FormsComponent,
        FormResponseComponent,
        FormQuestionEditComponent,
        NotRegisteredUserEditComponent,
        AttendanceComponent,
        ReservationsAttendanceComponent,
        ReservationsComponent,
        ClassroomSelectComponent,
        ReservationsPlaceComponent,
        DomainSelectComponent,
        ReservationsPlaceDomainComponent,
        FullCourseStampComponent,
        HtmlContentComponent,
        MyActivitiesPictureGalleryComponent,
        CoursesAttendanceComponent,
        SemesterCourseAttendanceComponent,
        CourseCourseAttendanceComponent,
        LessonCourseAttendanceComponent,
        StudentCourseAttendanceComponent,
        CompensationCourseLookupComponent,
        StudentEditDetailComponent,
        StudentEditDetailComponent,
        ActivitiesAttendanceComponent,
        SemesterActivitiesAttendanceComponent,
        ActivityAttendancePictureGalleryComponent,
        ActivityAttendancePictureGalleryUploadComponent,
        SignInComponent,
        SignUpEditComponent
    ],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        CookieModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
        Angulartics2Module.forRoot(),
        // DX
        DxButtonModule,
        DxPopupModule,
        DxLoadPanelModule,
        DxScrollViewModule,
        DxGalleryModule,
        DxValidationGroupModule,
        DxTextBoxModule,
        DxListModule,
        DxFormModule,
        DxCalendarModule,
        DxLookupModule,
        DxActionSheetModule,
        DxSwitchModule,
        DxNumberBoxModule,
        DxValidatorModule,
        DxTextAreaModule,
        DxValidationSummaryModule,
        DxTabPanelModule,
        DxTabsModule,
        DxDateBoxModule,
        DxDrawerModule,
        DxSelectBoxModule,
        DxToolbarModule,
        DxProgressBarModule,
        DxRadioGroupModule,
        DxCheckBoxModule,
        StorageServiceModule,
        DxSchedulerModule,
        DxMapModule,
        DxTreeListModule,
        NgImageFullscreenViewModule,
        DxFileUploaderModule,
        DxLoadIndicatorModule
    ],
  providers: [
    { provide: WindowWrapper, useFactory: getWindow },
    {
      provide: LOCALE_ID,
      deps: [TenantService],
      useFactory: localeFactory
    },
    TenantService,
    LanguageService,
    LoginService,
    CourseService,
    StudentService,
    CourseRegistrationService,
    CourseScheduleService,
    MembershipService,
    InvoiceService,
    MembershipStudentService,
    AttendanceService,
    CoursePriceService,
    PromotionService,
    SemesterService,
    ClassroomService,
    UserScheduleService,
    ValidationService,
    UserService,
    ReferenceSourceService,
    ActivityService,
    ActivityAttendanceService,
    AgeRangeService,
    AccountingBookService,
    ProficiencyLevelService,
    DuePaymentsService,
    OnlinePaymentService,
    ConsentsService,
    FormDefinitionService,
    FormResponseService,
    EmployeeService,
    ReservationService,
    DomainService,
    CompensationGroupService,
    PercentPipe,
    CurrencyPipe,
    WbcurrencyPipe,
    CanActivateIfAuthenticated,
    CanActivateIfNotAuthenticated,
    CanActivateForCourseRegistrationGuard,
    CanActivateForActionRegistrationGuard,
    CanActivateForSchedulesGuard,
    CanActivateForActivitiesGuard,
    CanActivateForFormsGuard,
    CanActivateForReservationAttendanceGuard,
    CanActivateForCoursesAttendanceGuard,
    CanActivateForAttendanceGuard,
    CanActivateForReservationsGuard,
    CanActivateForActivitiesAttendanceGuard,
    CanActivateForUserSignUpGuard,
    CanActviateNewDesignGuardGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private languageService: LanguageService,
    ) {
    Date.prototype.toISOString = function () {
      return moment(this).format('YYYY-MM-DDTHH:mm:ss');
    };

    String.prototype.format = String.prototype.f = function (...args: string[]) {
      let s = this,
        i = args.length;

      while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), args[i]);
      }
      return s;
    };

    String.prototype.isNullOrWhitespace = function (input: string): boolean {
      if (input === null || typeof input === 'undefined') return true;
      return input.replace(/\s/g, '').length < 1;
    };
  }
}
