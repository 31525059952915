import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TenantService, EmployeeService } from '../shared/services';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {

  public reservationAttendanceEnabled: boolean = false;
  public courseAttendanceEnabled: boolean = false;
  public activityAttendanceEnabled: boolean = false;

  constructor(
    private tenantService: TenantService,
    private employeeService: EmployeeService,
    private router: Router) { }

  ngOnInit() {
    let self = this;

    self.tenantService.getTenantSummary().subscribe((t) => {
      if (t.loggedInUser && t.loggedInUser.isAuthenticated) {
        if (t.tenantSetting.enableReservationSupport) {
          if (t.isInRole("Reservations")) {
            self.reservationAttendanceEnabled = true;
          } else {
            self.employeeService.getByUserId(t.loggedInUser.userID + '').subscribe(e => {
              self.reservationAttendanceEnabled = !!e;
            });
          }
        }
        if (t.tenantSetting.enableKindergartenSupport) {
          if (t.isInRole("ActivityAttendance")) {
            self.activityAttendanceEnabled = true;
          }
        }
        if (t.isInRole("ClientAttendance")) {
          self.courseAttendanceEnabled = true;
        }


        if (self.courseAttendanceEnabled && (!self.reservationAttendanceEnabled && !self.activityAttendanceEnabled)) {
          self.router.navigate(["/attendance/courses"]);
        }
        if (!self.courseAttendanceEnabled && (self.reservationAttendanceEnabled && !self.activityAttendanceEnabled)) {
          self.router.navigate(["/attendance/reservations"]);
        }
        if (!self.activityAttendanceEnabled && (self.reservationAttendanceEnabled && !self.courseAttendanceEnabled)) {
          self.router.navigate(["/attendance/activities"]);
        }
      }
    });
  }

  onCoursesClick(e: any) {
    this.router.navigate(["/attendance/courses"]);
  }

  onReservationsClick(e: any) {
    this.router.navigate(["/attendance/reservations"]);
  }

  onActivitiesClick(e: any) {
    this.router.navigate(["/attendance/activities"]);
  }

}
