import { Type } from "class-transformer";

import { Activity } from './activity';
import {ActivitySchedule} from "./activitySchedule";

export class ActivitiesBySemesterWithSchedules {

  @Type(() => Activity)
  activities: Activity[];

  @Type(() => ActivitySchedule)
  activitySchedules: ActivitySchedule[];
}
