<h4 class="text-center">{{'TXT_SELECT_CLASSROOM' | translate}}</h4>
<dx-list class="wb-under-header-list" [dataSource]="classrooms" [itemTemplate]="'classroomTemplate'" [keyExpr]="'id'"
    pullRefreshEnabled="true" (onPullRefresh)="onPullRefresh()" (onItemClick)="onItemClick($event)">
    <div *dxTemplate="let classroom of 'classroomTemplate'" class="classroom-wrapper">
        <div>
            <h4 class="wordwrap" style="margin-bottom: 0">
                {{classroom.name | uppercase}}
            </h4>
            <div class="classroom-detail">
                <div class="wordwrap classroom-description" *ngIf="classroom.description">
                    {{classroom.description}}
                </div>
                <div class="classroom-address" *ngIf="classroom.address.addressID">
                    <table style="width:100%">
                        <tr>
                            <td>
                                <div class="wordwrap">
                                    {{classroom.address.formattedAddress}}
                                </div>
                            </td>
                            <td style="width: 32px;">
                                <dx-button type="normal" icon="fa fa-map-o" (onClick)="showMapDetail($event, classroom)">
                                </dx-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</dx-list>

<dx-popup showTitle="true"
  [title]="selectedClassroomName"
  showCloseButton="true"
  [(visible)]="mapVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-map 
        [center]="selectedAddress" 
        [markers]="markers"
        provider="google"
        [zoom]="14" 
        width="100%" 
        height="100%" 
    >
    <dxo-key google="AIzaSyDgac1ahn5_WNYnb1b0TgRf7Q_JhM3ZRQc"></dxo-key>
    </dx-map>
  </div>
</dx-popup>