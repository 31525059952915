import { Type } from "class-transformer";
import { Address } from '../address'
import { ReservationStartTimeType } from '../enums/reservationStartTimeType';

export class Classroom {
    id: number;
    name: string;
    description: string;
    capacity: number;
    active: boolean;
    isDeleted: boolean;
    addressID: number;

    @Type(() => Address)
    address: Address;

    showInCourses: boolean;
    showInReservations: boolean;

    reservationStartTimeType: ReservationStartTimeType;
}