import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { LanguageDefinition } from '../models/languageDefinition';
export * from '../models/languageDefinition';

import { TenantSummary } from '../models/tenantSummary';
import { TenantService } from '../services/tenant.service';

import { locale, loadMessages } from 'devextreme/localization';

import { registerLocaleData, Location } from '@angular/common';
import localeCs from '@angular/common/locales/cs';

import * as _ from 'lodash';
import * as moment from "moment";
import { Observable } from 'rxjs';


@Injectable()
export class LanguageService {

  private langCookieName: string = 'culture';
  private defaultLanguage: string = 'en';
  private tenantSummary: Observable<TenantSummary>;
  private supportedLanguages: LanguageDefinition[] = [
    {
      languageAlias: 'cs',
      localizationToken: 'TXT_LANGUAGE_CZECH',
      pictureUri: 'assets/img/flag_czech_republic.png'
    }, {
      languageAlias: 'en',
      localizationToken: 'TXT_LANGUAGE_ENGLISH',
      pictureUri: 'assets/img/flag_united_kingdom.png'
    }];

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private tenantService: TenantService) { }

  init(): LanguageDefinition {
    let self = this;

    self.translate.setDefaultLang(self.defaultLanguage);

    self.tenantSummary = self.tenantService.getTenantSummary();

    var lang: string = '';
    var langFromCookie = self.cookieService.get(self.langCookieName);
    if (!!langFromCookie) {
      let tag = langFromCookie.match(/c=(\w+)\|uic=(\w+)/);
      lang = tag.length > 1
        ? tag[1]
        : self.translate.getBrowserLang();
    } else {
      lang = self.translate.getBrowserLang();
    }

    return self.setLang(lang);
  }

  setLang(lang: string): LanguageDefinition {
    var definition: LanguageDefinition;

    definition = _.find(this.supportedLanguages, <LanguageDefinition>{ languageAlias: lang });

    if (!definition) {
      definition = _.find(this.supportedLanguages, <LanguageDefinition>{
        languageAlias: this.translate.getDefaultLang()
      });
    }

    return this.setLangDefinition(definition);
  }

  setLangDefinition(lang: LanguageDefinition): LanguageDefinition {
    let self = this;

    self.translate.use(lang.languageAlias);
    self.tenantSummary.subscribe(ts => {
      let cookieValue = 'c=' + lang.languageAlias + '|uic=' + lang.languageAlias;
      self.cookieService.put(this.langCookieName, cookieValue, {
        expires: moment().add(999, 'year').toDate(),
        domain: ts.tenantInfo.domain, // || '.webooker.eu';
        storeUnencoded: true
      });

      // DevExtreme
      locale(lang.languageAlias);
      moment.locale(lang.languageAlias);

      if (lang.languageAlias === 'cs') {
        let messagesCs = require("assets/i18n/dx_cs.json");
        loadMessages(messagesCs);

        registerLocaleData(localeCs, 'cs');
      }
    });

    return _.find(this.supportedLanguages, <LanguageDefinition>{ languageAlias: lang.languageAlias });
  }

  getSupportedLanguages(): LanguageDefinition[] {
    return this.supportedLanguages;
  }

  getCurrentLanguage(): LanguageDefinition {
    return _.find(this.supportedLanguages, <LanguageDefinition>{
      languageAlias: this.translate.getDefaultLang()
    });
  }
}


