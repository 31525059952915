import { Type, Expose, Transform, Exclude } from "class-transformer";
import { TransformDate } from '../../decorators';


import { UserScheduleCompensationWaitQueue } from './userScheduleCompensationWaitQueue';
import { UserScheduleDetailAttendance } from './userScheduleDetailAttendance';
import { UserScheduleDetailRef } from './userScheduleDetailRef';

import { SubjectCategory, CourseAttendanceType } from '../enums';

import * as _ from 'lodash';

export class UserScheduleDetail implements UserScheduleDetailRef {

    attendanceChangesAllowed: boolean;
    attendanceChangesInTime: boolean;
    compensationGroup: string;
    freeSlots: number;
    notes: string;
    subject: string;

    @TransformDate()
    startDate: Date;

    @TransformDate()
    endDate: Date;

    @Expose({ name: "subjectCategoryID" })
    subjectCategory: SubjectCategory;

    @Type(() => UserScheduleCompensationWaitQueue)
    waitQueue: UserScheduleCompensationWaitQueue[];

    @Type(() => UserScheduleDetailAttendance)
    studentsAttendance: UserScheduleDetailAttendance[];

    @Exclude()
    showCompensationGroupHelp: boolean;

    @Exclude()
    showAttendanceTypeColumn: boolean;

    @Exclude()
    showAvailableAmendsColumn: boolean;

    @Exclude()
    oneOffDisabledHelp: boolean;

    @Exclude()
    showPriceColum: boolean;

    @Exclude()
    showExcuseHelp: boolean;

    @Exclude()
    showUnregisterHelp: boolean;

    @Exclude()
    showActionUnRegHelp: boolean;

    @Exclude()
    showRegisterHelp: boolean;

    @Exclude()
    showActionRegHelp: boolean;

    @Exclude()
    showAmendHelp: boolean;

    @Exclude()
    showOneOffRegHelp: boolean;

    @Exclude()
    showNoStudentsHelp: boolean;

    @Exclude()
    get presentStudentsIDs(): number[] {
        return _.map(_.filter(this.studentsAttendance, { present: true }), 'studentID');
    }

    @Exclude()
    get showQueueColumn(): boolean {
        return !!_.find(this.studentsAttendance, (n) => { return n.waitQueueIndex !== null });
    }

    @Exclude()
    get showCompensationWaitQueueHelp(): boolean {
        return this.showQueueColumn && this.subjectCategory === SubjectCategory.RegularCourses;
    }

    @Exclude()
    get showCompensationWaitQueueForActionHelp(): boolean {
        return this.showQueueColumn && this.subjectCategory === SubjectCategory.OneOffActions;
    }

    @Exclude()
    get isDirty(): boolean {
        return _.reduce(this.studentsAttendance, (sum, n) => { return sum || n.isDirty; }, false);
    }

    getVirtualWaitQueueIndex(attendance: UserScheduleDetailAttendance, newPresent: boolean, oldPresent: boolean): number {

        let self = this;
        let wq = self.waitQueue || [];

        let newExcuses = _.filter(self.studentsAttendance, { presentOrig: true, present: false });
        let newAttendances = _.filter(self.studentsAttendance, { presentOrig: false, present: true });
        let wqWithoutExcuses = _.filter(wq, function (o) { return !_.find(newExcuses, { studentID: o.studentID }) });

        let effectiveFreeSlots = self.freeSlots - wq.length + newExcuses.length;
        _.forEach(newAttendances, function (o) {
            effectiveFreeSlots = effectiveFreeSlots - 1;
            if (effectiveFreeSlots < 0) {
                let wqStudent = new UserScheduleCompensationWaitQueue();
                wqStudent.studentID = o.studentID;
                wqWithoutExcuses.push(wqStudent);
            }
        });

        let wqIndex = _.findIndex(wqWithoutExcuses, { studentID: attendance.studentID });
        return wqIndex !== -1
            ? wqIndex + 1
            : null;
    }

    initializeModel(): void {
        let self = this;
        _.forEach(this.studentsAttendance, (o) => {
            o.initializeModel(self);
        });

        this.showExcuseHelp = _.reduce(self.studentsAttendance, (sum, n) => {
            return sum || (n.effectivePresent && self.attendanceChangesAllowed && self.subjectCategory === SubjectCategory.RegularCourses);
        }, false);

        this.showUnregisterHelp = _.reduce(self.studentsAttendance, (sum, n) => {
            return sum || (n.effectivePresent && self.attendanceChangesAllowed && self.subjectCategory === SubjectCategory.RegularCourses);
        }, false);

        this.showActionUnRegHelp = _.reduce(self.studentsAttendance, (sum, n) => {
            return sum || (n.effectivePresent && self.attendanceChangesAllowed && self.subjectCategory === SubjectCategory.OneOffActions);
        }, false);

        this.showRegisterHelp = _.reduce(self.studentsAttendance, (sum, n) => {
            return sum || (!n.effectivePresent && self.attendanceChangesAllowed && self.subjectCategory === SubjectCategory.RegularCourses);
        }, false);

        this.showActionRegHelp = _.reduce(self.studentsAttendance, (sum, n) => {
            return sum || (!n.effectivePresent && self.attendanceChangesAllowed && self.subjectCategory === SubjectCategory.OneOffActions);
        }, false);

        this.showAmendHelp = _.reduce(self.studentsAttendance, (sum, n) => {
            return sum || (!n.effectivePresent && n.effectiveCourseAttendanceType === CourseAttendanceType.Amend &&
                self.attendanceChangesAllowed && self.subjectCategory === SubjectCategory.OneOffActions);
        }, false);

        this.showOneOffRegHelp = _.reduce(self.studentsAttendance, (sum, n) => {
            return sum || (!n.effectivePresent && n.effectiveCourseAttendanceType === CourseAttendanceType.OneOff &&
                self.attendanceChangesAllowed && !n.presentReadOnly && self.subjectCategory === SubjectCategory.RegularCourses);
        }, false);

        this.showPriceColum = _.reduce(self.studentsAttendance, (sum, n) => {
            return sum ||
                (n.courseAttendanceType === CourseAttendanceType.OneOff &&
                    !!n.causalPrice &&
                    (self.attendanceChangesAllowed || n.present));
        }, false);

        this.showNoStudentsHelp = !this.studentsAttendance || this.studentsAttendance.length === 0;
        this.oneOffDisabledHelp = _.reduce(this.studentsAttendance, (sum, n) => { return sum || n.oneOffDisabled; }, false) &&
            this.attendanceChangesAllowed && this.subjectCategory === SubjectCategory.RegularCourses;
        this.showCompensationGroupHelp = !!this.compensationGroup && this.attendanceChangesAllowed && this.subjectCategory === SubjectCategory.RegularCourses;
        this.showAttendanceTypeColumn = this.subjectCategory === SubjectCategory.RegularCourses;
        this.showAvailableAmendsColumn = this.subjectCategory === SubjectCategory.RegularCourses;
    }

}