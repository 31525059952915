<div class="dx-fieldset">

  <div class="dx-fieldset-header"
      *ngIf="showFieldsetHeaders">{{'TXT_IDENTITY' | translate}}</div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_NAME' | translate }}</div>
    <dx-text-box [(value)]="user.name">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
    </dx-text-box>
  </div>


  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_SURNAME' | translate }}
      <span class="dx-field-item-required-mark"> *</span>
    </div>
    <dx-text-box [(value)]="user.surname">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_SURNAME' | translate) }, { type: 'stringLength', max: 30 }]"></dx-validator>
    </dx-text-box>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'TXT_MIDDLE_NAME' | translate }}</div>
    <dx-text-box [(value)]="user.middlename">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
    </dx-text-box>
  </div>

  <div *ngIf="showFieldsetHeaders"
       class="dx-fieldset-header">{{'TXT_CONTACT' | translate}}</div>

  <div class="dx-field">
      <div class="dx-field-label">{{ 'TXT_PHONE' | translate }}
        <span class="dx-field-item-required-mark"> *</span>
      </div>
    <dx-text-box [(value)]="user.phone"
                 placeholder="+420 999 999 999"
                 mask="+999 999 999 999"
                 showMaskMode="onFocus">
      <dx-validator [validationRules]="[{ type: 'pattern', pattern: phoneValidationRegEx, message: ('TXT_PHONE_FORMAT_INVALID' | translate)},{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PHONE' | translate) }]"></dx-validator>
    </dx-text-box>
  </div>
  <div>user.phone: {{user.phone}}</div>

  <div class="dx-field">
      <div class="dx-field-label">{{ 'TXT_EMAIL' | translate }}
        <span class="dx-field-item-required-mark"> *</span>
      </div>
      <dx-text-box [(value)]="user.email"
                   [readOnly]="disableEmailEdit"
                   mode="email">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_EMAIL' | translate) }, { type: 'email', message: 'TXT_LOGIN_ERROR_INVALID_EMAIL' | translate }]"></dx-validator>
      </dx-text-box>
  </div>

</div>
