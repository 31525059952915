import { Type, Transform } from "class-transformer";

import { RecurrenceType } from '../enums/recurrenceType';
import { WeekOfMonth } from '../enums/weekOfMonth';
import { RecurrenceRange } from '../enums/recurrenceRange';
import { WeekDays } from '../enums/weekDays';

import { TransformDate } from '../../decorators';

export class RecurrenceInfo {
    type: RecurrenceType;
    periodicity: number;
    dayNumber: number;
    weekDays: WeekDays;
    weekOfMonth: WeekOfMonth;
    month: number;
    range: RecurrenceRange;

    @TransformDate()
    start: Date;

    @TransformDate()
    end: Date;

    occurrenceCount: number;
}