import { CourseRegistrationPayment } from './courseRegistrationPayment';
import { CourseRegistrationAttendance } from './courseRegistrationAttendance'
import { CourseRegistrationInvoice } from './courseRegistrationInvoice'

import { CourseRegistrationRef } from './courseRegistrationRef';

import { Type, Transform, Exclude } from "class-transformer";
import { TransformDate } from '../../decorators';

import * as _ from 'lodash';
import {CourseAttendanceStatus} from "../enums";

export class CourseRegistration implements CourseRegistrationRef {
    courseRegistrationID: number;
    courseID: number;
    studentID: number;
    studentName: string;
    studentSurname: string;
    studentMiddleName: string;

    @TransformDate()
    studentBirthday: Date;

    price: number;

    @Type(() => CourseRegistrationPayment)
    payments: CourseRegistrationPayment[];

    @Type(() => CourseRegistrationAttendance)
    registeredAttendance: CourseRegistrationAttendance[];

    contactPhone: string;
    contactEmail: string;

    @TransformDate()
    registrationDate: Date;

    notes: string;
    notificationSent: boolean;
    preAmendsCount: number;
    timeBoundAmendCount: number;

    @TransformDate()
    timeBoundAmendExpiration: Date;

    attendantHealth: string;
    parentFullName: string;
    parentID: number;
    tenantID: number;

    @Type(() => CourseRegistrationInvoice)
    invoice: CourseRegistrationInvoice;

    invoiceID: number;
    semesterID: number;
    semesterName: string;
    courseFullName: string;

    @TransformDate()
    firstDate: Date;

    allowMultiAttendance: boolean;
    reservedPlaces: number;
    health: string; // student health
    selfAssessedProficiencyLevelID: number;
    paymentsSum: number;
    arrear: number;
    studentFullName: string;
    studentAge: string;
    smsCode: string;


    // Non DTO properties
    @Exclude()
    isNew: boolean;
    //get isNew(): boolean { return !this.courseRegistrationID; }

    @Exclude()
    initialized: boolean;

    @Exclude()
    allowClientLessonSelection: boolean;

    @Exclude()
    private _attend: boolean = false;

    @Exclude()
    private _attendOrigValue: boolean = null;

    @Exclude()
    get isModified(): boolean {
        if (this.allowClientLessonSelection) {
            return _.reduce(this.registeredAttendance, function (res, a) {
                return res || (a.hasChanged && a.isUserEditable);
            }, false)
        }

        return this._attendOrigValue !== this._attend
    }

    @Exclude()
    get isAnySelected(): boolean {
        return _.reduce(this.registeredAttendance, function (res, a) {
            return res || (a.isUserEditable && a.isRegistered);
        }, false) && this.isNew;
    }

    @Exclude()
    get isAnySelectable(): boolean {
        return !!_.find(this.registeredAttendance, { isUserEditable: true });
    }

    @Exclude()
    get isAllNewSelected(): boolean {
        var res = _.reduce(this.registeredAttendance, function (res, a) {
            return res && (a.isUserEditable ? a.isRegistered : true);
        }, true);

        return res && !!_.find(this.registeredAttendance, { isUserEditable: true, isRegistered: true }) && this.isNew;
    }
    set isAllNewSelected(value: boolean) {
        if (value !== this.isAllNewSelected) {
            _.forEach(this.registeredAttendance, function (a) {
                if (a.isUserEditable) {
                    a.isRegistered = value;
                }
            });
        }
    }

    @Exclude()
    get attend(): boolean { return this._attend || (this.isAnySelected && !!this.allowClientLessonSelection); }
    set attend(value: boolean) {
        if (this._attendOrigValue == null) {
            this._attendOrigValue = value;
        }

        this._attend = value;
    }

    @Exclude()
    get isInThePast(): boolean {
        return _.reduce(this.registeredAttendance, function (res, a) {
            return res && a.isInThePast;
        }, true);
    }

    @Exclude()
    arrearByAmortizationSchedule: number;

    @Exclude()
    get actionAttendanceStatus(): CourseAttendanceStatus {
      if (!!this.registeredAttendance && this.registeredAttendance.length == 1) {
        return this.registeredAttendance[0].courseAttendanceStatus;
      }

      return CourseAttendanceStatus.Present;
    }
}
