import { Component, OnInit } from '@angular/core';

import { ConsentsService, UserService } from '../../shared/services';
import { UserConsetsInfo, Consent } from '../../shared/models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import * as _ from 'lodash';

import * as moment from "moment";

@Component({
  selector: 'app-my-account-consents',
  templateUrl: './my-account-consents.component.html',
  styleUrls: ['./my-account-consents.component.css']
})
export class MyAccountConsentsComponent implements OnInit {

  userConsetsInfo: UserConsetsInfo = null;

  userConsents: DataSource;

  constructor(
    private consentsService: ConsentsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    let self = this;

    self.userConsents = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.userService.getCurrent(false).subscribe(user => {
              let uc = self.consentsService.getConsentValidityGroupsForUser(user.id);
              uc.subscribe(data => resolve(data), error => reject(error))
            });
          })
        }
      })
    });
  }

  onPullRefresh() {
    this.userConsents.load();
  }

  approveConsent(consent: Consent) {
    let self = this;
    self.consentsService.approveConsent(consent.consentID)
      .subscribe(d => self.userConsents.load());
  }

  rejectConsent(consent: Consent) {
    let self = this;
    self.consentsService.rejectConsent(consent.consentID)
      .subscribe(d => self.userConsents.load());
  }

}
