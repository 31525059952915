import { Type, Transform, Exclude, Expose } from "class-transformer";
import { InvoiceLineType } from '../enums/invoiceLineType';
import { InvoiceLineItem } from '../invoice/invoiceLineItem';

import { ICourseRegistrationInvoiceLineData } from './icourseRegistrationInvoiceLineData';

import { CourseRegistrationInvoiceRef } from './courseRegistrationInvoiceRef';

import * as _ from 'lodash';
import * as moment from 'moment';

export class CourseRegistrationInvoiceLine {

    @Transform(({value}) => InvoiceLineType[value]) // Comes as string from server
    lineType: InvoiceLineType;

    membershipID: number;
    studentMembershipID: number;

    // Non DTO properties
    @Exclude()
    private _buy: boolean;

    @Expose()
    get buy(): boolean {
        switch (this.lineType) {
            case InvoiceLineType.CourseFee:
                return true;
            case InvoiceLineType.Membership:
                return (!!this._buy && !!this.isValid) ||
                    (!!this.isMandatory && !!this.isValid && !(!!this.owns && !this.isInInvoice));
            case InvoiceLineType.ManualAdjustment:
                return this._buy;
            case InvoiceLineType.Discount:
                return !!this.$relatedMembership.buy ||
                    (!this.$relatedMembership.buy && !!this.$relatedMembership.owns && (
                        (!this.isInInvoice && !this.$relatedMembership.isInInvoice) || (!!this.isInInvoice && !this.$relatedMembership.isInInvoice))
                    );
            default:
                return false;
        }
    }
    set buy(value: boolean) {
        switch (this.lineType) {
            case InvoiceLineType.Membership:
                if (!this.isReadOnly) {
                    this._buy = value;
                }
                break;
            case InvoiceLineType.ManualAdjustment:
                this._buy = value;
                break;
            default:
                // Cannot be changed by default
                break;
        }
    }

    @Exclude()
    private _price: number;

    @Expose()
    get price(): number {
        switch (this.lineType) {
            case InvoiceLineType.CourseFee:
                return this.$parent.$coursePriceService.getCourseBasePrice(
                    this.$parent.$course,
                    this.$parent.noOfRegisteredAttendances,
                    this.$parent.$courseRegistration.reservedPlaces || 1,
                    this.$parent.useAmortizationSchedule
                );
            case InvoiceLineType.Membership:
                return this.buy
                    ? this._price
                    : 0;
            case InvoiceLineType.Discount:
                return this.buy
                    ? this.$relatedMembership ? -(this.$parent.basePrice * this.$relatedMembership.discountPct) / 100 : this._price
                    : 0;
            case InvoiceLineType.ManualAdjustment:
                return this.buy
                    ? this._price
                    : 0;
            default:
                return 0;
        }
    }
    set price(value: number) { this._price = value; }


    @Exclude()
    private _name: string;

    @Expose()
    get name(): string {
        switch (this.lineType) {
            case InvoiceLineType.CourseFee:
                return this.$parent.$coursePriceService.getCourseFeeLineName(
                    this.$parent.$course,
                    this.$parent.noOfRegisteredAttendances,
                    this.$parent.useAmortizationSchedule);
            case InvoiceLineType.Membership:
                return this._name;
            case InvoiceLineType.Discount:
                if (this.$relatedMembership) {
                    return this.$parent.$coursePriceService.getDiscountLineStr(this.$relatedMembership._name, this.$relatedMembership.discountPct);
                }
                return this._name;
            case InvoiceLineType.ManualAdjustment:
                return this.buy
                    ? this._name
                    : null;
            default:
                return this._name;
        }
    }
    set name(value: string) { this._name = value; }

    @Exclude()
    get isReadOnly(): boolean {
        switch (this.lineType) {
            case InvoiceLineType.CourseFee:
                return true;
            case InvoiceLineType.Membership:
                return (this.owns && !this.isInInvoice) ||
                    this.isMandatory ||
                    !this.isValid;
            case InvoiceLineType.ManualAdjustment:
                return false;
            default:
                return true;
        }
    }

    @Exclude()
    get isInInvoice(): boolean { return !!this.$invoiceLineItem; }

    @Exclude()
    owns: boolean;

    @Exclude()
    discountPct: number;

    @Exclude()
    isMandatory: boolean;

    @Exclude()
    private _description: string;

    @Exclude()
    get description(): string {
        switch (this.lineType) {
            case InvoiceLineType.Membership:
                return this._description;
            default:
                return '';
        }
    }
    set description(value: string) { this._description = value; }

    @Exclude()
    private _isValid: boolean;

    @Exclude()
    get isValid(): boolean {
        switch (this.lineType) {
            case InvoiceLineType.Membership:
                if (this._validFrom) {
                    var parent = this.$parent;
                    var membershipNow = moment(parent.membershipNow);

                    return moment(this._validFrom).isSameOrBefore(membershipNow) &&
                        moment(this._validTo).isSameOrAfter(membershipNow);
                }
                else {
                    return this._isValid;
                }
            case InvoiceLineType.Discount:
                return (this.$relatedMembership.isValid && !!this.price);
            default:
                return true;
        }
    }
    set isValid(value: boolean) { this._isValid = value; }

    @Exclude()
    get isVisible(): boolean {
        switch (this.lineType) {
            case InvoiceLineType.Membership:
                return (!this.owns && this.isValid) || this.isInInvoice;
            case InvoiceLineType.Discount:
                return (!!this.price || (!this.price && !!this.isInInvoice));
            default:
                return true;
        }
    }

    @Exclude()
    get isClientVisible(): boolean {
        return this.isVisible && this.lineType !== InvoiceLineType.ManualAdjustment;
    }

    @Exclude()
    get isModified(): boolean {
        switch (this.lineType) {
            case InvoiceLineType.CourseFee:
                return (!this.isInInvoice && this.buy && !!this.price) ||
                    (this.isInInvoice && !this.isValid) ||
                    (!!this.isInInvoice && !!this.buy && this.price !== this.$invoiceLineItem.price);
            case InvoiceLineType.Membership:
                return (!this.isInInvoice && this.buy) ||
                    (!!this.isInInvoice && !this.buy) ||
                    (!!this.isInInvoice && !!this.buy && this.price !== this.$invoiceLineItem.price) ||
                    (!!this.isInInvoice && !!this.buy && this.name !== this.$invoiceLineItem.name);
            case InvoiceLineType.ManualAdjustment:
                return (!!this.isInInvoice && !!this.buy && this.price !== this.$invoiceLineItem.price) ||
                    (!!this.isInInvoice && !!this.buy && this.name !== this.$invoiceLineItem.name) ||
                    (!this.isInInvoice && !!this.buy && !!this.price) ||
                    (!!this.isInInvoice && (!this.buy || !this.price));
            default:
                return (!this.isInInvoice && this.buy && !!this.price) ||
                    (this.isInInvoice && !this.isValid) ||
                    (!!this.isInInvoice && !!this.buy && this.price !== this.$invoiceLineItem.price) ||
                    (!!this.isInInvoice && !!this.buy && this.name !== this.$invoiceLineItem.name);
        }
    }

    @Exclude()
    get isNameEditable(): boolean { return this.lineType === InvoiceLineType.ManualAdjustment }

    @Exclude()
    get isValueEditable(): boolean { return this.lineType === InvoiceLineType.ManualAdjustment }

    @Exclude()
    $parent: CourseRegistrationInvoiceRef;

    @Exclude()
    $invoiceLineItem: InvoiceLineItem;

    @Exclude()
    private get $relatedMembership(): CourseRegistrationInvoiceLine {
        if (this.$parent) {
            return _.find(this.$parent.lines, { membershipID: this.membershipID, lineType: InvoiceLineType.Membership });
        }

        return null;
    }

    @Exclude()
    private get $relatedDiscount(): CourseRegistrationInvoiceLine {
        if (this.$parent) {
            return _.find(this.$parent.lines, { membershipID: this.membershipID, lineType: InvoiceLineType.Discount });
        }

        return null;
    }

    @Exclude()
    private _validFrom: Date;

    @Exclude()
    private _validTo: Date;

    constructor(data: ICourseRegistrationInvoiceLineData) {
        this.$parent = data.$parent;
        this.$invoiceLineItem = data.$invoiceLineItem;
        this.lineType = data.lineType;
        this.owns = data.owns;
        this._buy = data.buy;
        this._name = data.name;
        this._price = data.price;
        this.discountPct = data.discountPct;
        this.isMandatory = data.isMandatory;
        this.membershipID = data.membershipID;
        this._description = data.description;
        this._isValid = data.isValid;
        this._validFrom = data.validFrom;
        this._validTo = data.validTo;
    }

}
