<div class="header dx-card dx-theme-border-color-as-background-color">
    <div class="max-length-text" *ngIf="!!semester">
        <small>{{semester.name}} ({{semester.startDate | date:'shortDate'}} - {{semester.endDate | date:'shortDate'}})</small>
    </div>
    <div class="max-length-text" *ngIf="!!course">
        <small>{{course.courseFullName}}</small>
    </div>
    <div class="max-length-text" *ngIf="!!courseSchedule">
        <small>{{courseSchedule.startDate | date:'dd.MM.yyyy EE' | uppercase }} ({{courseSchedule.startDate | date:'HH:mm' }} - {{courseSchedule.endDate | date:'HH:mm' }})</small>
    </div>
</div>
<dx-load-panel
  [(visible)]="saving"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  message="{{'TXT_SAVING' | translate}}"
  closeOnOutsideClick="false">
</dx-load-panel>
<div class="dx-fieldset">

    <div class="dx-field">
        <div class="dx-field-label">{{ 'TXT_STUDENT' | translate }}</div>
        <div class="dx-field-value">
            <dx-lookup  
                [(value)]="selectedStudent.studentID"
                class="student-lookup"
                [placeholder]="'TXT_SELECT_STUDENT' | translate"
                [dataSource]="students"
                [disabled]="hasStudentIdParam"
                valueExpr="id"
                fullScreen="true"
                displayExpr="fullName"                
                [searchEnabled]="true"
                [searchExpr]="['fullName']"
                (onValueChanged)="onStudentValueChanged($event)"
                (onPullRefresh)="onPullStudentsRefresh()">    
            </dx-lookup>
        </div>
    </div>

    <div class="dx-field">
        <div class="dx-field-label">{{ 'TXT_STATUS_PRESENT' | translate }}</div>
        <div class="dx-field-value">
            <dx-lookup 
                [(value)]="selectedStudent.attendanceStatus"
                [dataSource]="attendanceStatuses"
                valueExpr="id"
                fullScreen="true"
                [searchEnabled]="false"
                [disabled]="!selectedStudent.studentID"
                displayExpr="name">                
            </dx-lookup>
        </div>
    </div>   

    <div class="dx-field">
        <div class="dx-field-label">{{ 'TXT_ATTENDANCE_TYPE' | translate }}</div>
        <div class="dx-field-value">
            <dx-lookup 
                [(value)]="selectedStudent.attendanceType"
                [dataSource]="attendanceTypes"
                valueExpr="id"
                fullScreen="true"
                [searchEnabled]="false"
                [disabled]="!selectedStudent.studentID || (hasStudentIdParam && selectedStudent.attendanceType == 0)"
                displayExpr="name"
                (onValueChanged)="onAttendanceTypeValueChanged($event)">                
            </dx-lookup>
        </div>
    </div>  

    <div class="dx-field" *ngIf="selectedStudent.attendanceType == 2">
        <div class="dx-field-label">{{ 'TXT_COMPENSATION_FOR' | translate }}</div>
        <div class="dx-field-value">
            <app-compensation-course-lookup 
                [(value)]="selectedStudent.compensationCourseID"
                [studentId]="selectedStudent.studentID"
                [semesterId]="semesterID"
                [compGroupId]="course.compensationGroupID"
                [disabled]="!selectedStudent.studentID"
                [compOverride]="compensationGroupOverride"
                [acceptingGroups]="course.acceptingCompensationGroups"
                [lessonEndDate]="courseSchedule.startDate">                
            </app-compensation-course-lookup>
        </div>
    </div>     
    
    <div class="dx-field" *ngIf="(selectedStudent.attendanceType == 2 || selectedStudent.attendanceType == 0) && allowMultiAmends">
        <div class="dx-field-label">{{ 'TXT_RESERVED_PLACES' | translate }}</div>
        <div class="dx-field-value">
            <dx-number-box 
                [(value)]="selectedStudent.attendanceCount"
                [min]="1"
                [max]="100"
                [disabled]="!selectedStudent.studentID">   
                <dx-validator [validationRules]="[{ type: 'required', message: 'TXT_ERROR_REQUIRED' | translate }, { type: 'range', max: 100, min: 1 }]"></dx-validator>             
            </dx-number-box>
        </div>
    </div>    

    <div class="dx-field" *ngIf="clientAttendanceShowStudentNote">
        <div class="dx-field-label">{{ 'TXT_NOTES' | translate }}</div>
        <div class="dx-field-value">
            <dx-text-area 
                [(value)]="selectedStudent.note"
                [disabled]="!selectedStudent.studentID">                   
            </dx-text-area>
        </div>
    </div>      
</div>
<div class="row" 
    style="margin-top: 50px; margin-bottom: 10px;">
    <div class="col-xs-6 text-right">
        <dx-button text="{{'TXT_PREV' | translate}}"
            class="wb-button"
            type="default"            
            (onClick)="onPrevBtnClick()">
        </dx-button>        
    </div>
    <div class="col-xs-6 text-left">
        <dx-button text="{{'TXT_SAVE' | translate}}"
            class="wb-button"
            type="success"
            [disabled]="!selectedStudent.studentID"
            (onClick)="onSaveBtnClick()">
        </dx-button>            
    </div>
</div> 