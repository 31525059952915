<h4 class="text-center">{{'TXT_SELECT_SEMESTER' | translate}}</h4>
<dx-list class="wb-under-header-list"
  [dataSource]="semesters"
  [grouped]="showCompensationGroupSelect"
  [groupTemplate]="'semester'"
  [itemTemplate]="showCompensationGroupSelect ? 'compGroup' : 'semester'"
  [keyExpr]="'id'"    
  pullRefreshEnabled="true"
  (onPullRefresh)="onPullRefresh()"
  (onItemClick)="onItemClick($event)">
  <div *dxTemplate="let groupData of 'semester'"
    [ngClass]="{ 'semester-wrapper' : !showCompensationGroupSelect }">
    <div>
      <h4 class="wordwrap" style="margin-bottom: 0">
        {{groupData.name | uppercase}}
      </h4>
      <h4 *ngIf="showPreferredBookingInfo && groupData.preferredBookingEffectiveIsActive" class="wordwrap dx-button-mode-text dx-button-danger" style="margin-bottom: 0">
        {{'TXT_PREFERRED_BOOKING' | translate}}
      </h4>
      <div class="semester-details">
        <div class="wordwrap" *ngIf="!(showPreferredBookingInfo && groupData.preferredBookingEffectiveIsActive)">
          ({{groupData.startDate | date:'shortDate'}} - {{groupData.endDate | date:'shortDate'}})
        </div>
        <div class="wordwrap dx-button-mode-text dx-button-danger" *ngIf="showPreferredBookingInfo && groupData.preferredBookingEffectiveIsActive" >
          {{'TXT_PREFERRED_BOOKING_DESC1' | translate}} {{groupData.preferredBookingStartDate | date:'shortDate'}} - {{groupData.preferredBookingEndDate | date:'shortDate'}} {{'TXT_PREFERRED_BOOKING_DESC2' | translate}}.
        </div>        
        <table *ngIf="!!groupData.description && !showCompensationGroupSelect"
          style="width:100%">
          <tr>
            <td>
              <div class="wordwrap description">{{groupData.description}}</div>
            </td>
            <td style="width: 32px;">
              <dx-button type="normal"
                icon="comment"
                (onClick)="showDescriptionDetail($event, groupData)">
              </dx-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *dxTemplate="let itemData of 'compGroup'">
    <h5 style="margin-bottom: 0">{{itemData.studentFullName}}</h5>
    <div>{{itemData.compensationGroupName}}</div>
  </div>
</dx-list>

<dx-popup showTitle="false"
  showCloseButton="true"
  [(visible)]="detailDescriptionVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      {{clickedSemester.description}}
    </dx-scroll-view>
  </div>
</dx-popup>