import { Type, Transform, Exclude } from "class-transformer";
import * as moment from "moment";

import { CourseUserRegistrationSchedule } from './courseUserRegistrationSchedule';

import { CourseAttendanceType } from '../enums/courseAttendanceType';
import { CourseAttendanceStatus } from '../enums/courseAttendanceStatus';

import { TransformDate } from '../../decorators';

export class CourseRegistrationAttendance {
    courseScheduleID: number;

    @TransformDate()
    startDate: Date;

    @TransformDate()
    endDate: Date;

    courseAttendanceType: CourseAttendanceType;
    courseAttendanceStatus: CourseAttendanceStatus;
    attendanceNotes: string;
    attendanceCount: number;

    // Non DTO properties
    @Exclude()
    origCourseAttendanceType: CourseAttendanceType;

    @Exclude()
    origCourseAttendanceStatus: CourseAttendanceStatus;

    @Exclude()
    get isRegistered(): boolean {
        return this.courseAttendanceType === CourseAttendanceType.Registered;
    }
    set isRegistered(value: boolean) {
        if (value) {
            if (this.origCourseAttendanceType === CourseAttendanceType.Registered) {
                this.courseAttendanceType = this.origCourseAttendanceType;
                this.courseAttendanceStatus = this.origCourseAttendanceStatus;

            } else {
                this.courseAttendanceType = CourseAttendanceType.Registered;
                this.courseAttendanceStatus = CourseAttendanceStatus.Present;
            }
        } else {
            if (this.origCourseAttendanceType !== CourseAttendanceType.Registered) {
                this.courseAttendanceType = this.origCourseAttendanceType;
                this.courseAttendanceStatus = this.origCourseAttendanceStatus;
            } else {
                this.courseAttendanceType = null;
                this.courseAttendanceStatus = null;
            }
        }
    }

    @Exclude()
    get isDisabled(): boolean {
        var disabled =
            this.courseAttendanceType === CourseAttendanceType.OneOff ||
            this.courseAttendanceType === CourseAttendanceType.Amend ||
            this.courseAttendanceType === CourseAttendanceType.Preview;

        if (this.$courseUserRegistrationSchedule) {
            disabled = disabled || (this.$courseUserRegistrationSchedule.freePlaces === 0 && !this.isRegistered);
        }

        return disabled;
    }

    @Exclude()
    get hasChanged(): boolean {
        return (this.origCourseAttendanceType !== this.courseAttendanceType) ||
            (this.origCourseAttendanceStatus !== this.courseAttendanceStatus);
    }

    @Exclude()
    get freePlaces(): number {
        if (this.$courseUserRegistrationSchedule) {
            return this.$courseUserRegistrationSchedule.freePlaces;
        }

        return 0;
    }

    @Exclude()
    get isInThePast(): boolean {
        return moment(this.startDate).isBefore(moment());
    }

    @Exclude()
    get isUserEditable(): boolean {
        return !this.isInThePast && !this.isDisabled;
    }

    @Exclude()
    $courseUserRegistrationSchedule: CourseUserRegistrationSchedule;
}