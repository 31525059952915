
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import {
  UserConsetsInfo,
  ConsentValidityGroup
} from '../models';
import { BaseService } from './base.service';

import { TranslateService } from '@ngx-translate/core';

import { notifySuccess } from './notifications';

import * as _ from 'lodash';
import * as moment from "moment";

@Injectable()
export class ConsentsService extends BaseService {

  private baseServiceUrl: string;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService) {
    super();
    this.baseServiceUrl = '/Api/Consents/';
  }

  getConsentsInfoForUser(userID: number): Observable<UserConsetsInfo> {

    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("userID", userID.toString());

    let url: string = this.baseServiceUrl + 'GetConsentsInfoForUser';

    return this.http
      .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object) => plainToClass<UserConsetsInfo, Object>(UserConsetsInfo, res)))
  }

  getConsentValidityGroupsForUser(userID: number): Observable<ConsentValidityGroup[]> {
    let self = this;

    return new Observable<ConsentValidityGroup[]>(observer => {

      let uc = self.getConsentsInfoForUser(userID);
      uc.subscribe(data => {
        let result: ConsentValidityGroup[] = [];
        let validConsents = [];
        let invalidConsents = [];

        var now = moment();

        _.forEach(data.consents, function (c) {
          if (moment(c.validFrom).isSameOrBefore(now) &&
            moment(c.validTo).isSameOrAfter(now)) {
            c.isValid = true;
            validConsents.push(c);
          } else {
            c.isValid = false;
            invalidConsents.push(c);
          }
        });

        result.push({
          validity: 1,
          validityText: self.translateService.instant('TXT_VALID'),
          items: _.orderBy(validConsents, ['validFrom'], ['desc'])
        });

        result.push({
          validity: 0,
          validityText: self.translateService.instant('TXT_INVALID'),
          items: _.orderBy(invalidConsents, ['validFrom'], ['desc'])
        });

        observer.next(result);
        observer.complete();

      }, err => observer.error(self.handleError(err)))
    });
  }

  approveConsent(consentID: number): Observable<Object> {
    let self = this;

    let headers = self.getDefaultHttpHeaders();
    let url: string = self.baseServiceUrl + 'ApproveConsent';

    return new Observable<Object>(observer => {

      self.http.post(url, { consentID: consentID }, { headers: headers }).subscribe(
        value => { /* success */
          self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
            notifySuccess(res);
          });

          observer.next(value);
          observer.complete();
        },
        err => observer.error(self.handleError(err)))
    });
  }

  rejectConsent(consentID: number): Observable<Object> {
    let self = this;

    let headers = self.getDefaultHttpHeaders();
    let url: string = self.baseServiceUrl + 'RejectConsent';

    return new Observable<Object>(observer => {

      self.http.post(url, { consentID: consentID }, { headers: headers }).subscribe(
        value => { /* success */
          self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
            notifySuccess(res);
          });

          observer.next(value);
          observer.complete();
        },
        err => observer.error(self.handleError(err)))
    });
  }
}