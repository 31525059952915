import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TranslateService } from '@ngx-translate/core'
import { forkJoin } from 'rxjs';

import { LoginService, TenantService } from '../../services';
import {Router} from "@angular/router";

@Component({
  selector: 'app-request-login-action-sheet',
  templateUrl: './request-login-action-sheet.component.html',
  styleUrls: ['./request-login-action-sheet.component.css']
})
export class RequestLoginActionSheetComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
    private tenantService: TenantService,
    private router: Router,
    private loginService: LoginService) { }

  loginOptionItems: any[] = [];

  private _signInVisible: boolean;
  @Output() signInVisibleChange = new EventEmitter<boolean>();

  @Input()
  get signInVisible() {
    return this._signInVisible;
  }
  set signInVisible(value: boolean) {
    this._signInVisible = value;
    this.signInVisibleChange.emit(value);
  }

  @Output() onCancelClick = new EventEmitter<any>();

  private _visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Input()
  get visible(): boolean {
    return this._visible;
  }
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(value);
  }

  ngOnInit() {
    forkJoin([
      this.tenantService.getTenantSummary(),
      this.translateService.get('TXT_LOGIN'),
      this.translateService.get('TXT_NEW_ACCOUNT')]
    ).subscribe(([ts, res0, res1]) => {
      this.loginOptionItems.push({ text: this.translateService.instant('TXT_LOGIN'), action: "login" });
      if (!!ts.tenantSetting && ts.tenantSetting.enableUserRegistration) {
        this.loginOptionItems.push({ text: this.translateService.instant('TXT_NEW_ACCOUNT'), action: "new-account" });
      }
    });
  }

  onCancel(params) {
    this.onCancelClick.emit(params);
  }

  loginOptionClick(params) {
    switch (params.itemData.action) {
      case "login":
        this.signInVisible = true;
        break;
      case "new-account":
        this.loginService.newUserRegistration(this.router.url);
        break;
    }
  }

}
