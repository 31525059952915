import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AccountingBook} from "../models";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {plainToInstance} from "class-transformer";

@Injectable()
export class AccountingBookService extends BaseService {

  private readonly baseServiceUrl: string;
  private currentAccountingBooks: Observable<AccountingBook[]>;
  constructor(private http: HttpClient) {
    super();
    this.baseServiceUrl = '/Api/AccountingBooks/';
  }

  getAll(refresh: boolean = false): Observable<AccountingBook[]> {
    if (!this.currentAccountingBooks || refresh) {
      let headers = this.getDefaultHttpHeaders();
      let url: string = this.baseServiceUrl + 'GetAll';
      this.currentAccountingBooks = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToInstance(AccountingBook, res)),
          publishReplay(1),
          refCount(),);
    }

    return this.currentAccountingBooks;
  }

  getDefault(): Observable<AccountingBook> {
    return this.getAll(false).pipe(
      map(accountingBooks =>
        accountingBooks.find(accountingBook => accountingBook.isDefault === true))
    );
  }
}
