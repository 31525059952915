
import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import {Semester, TenantSetting} from '../models';
import { BaseService } from './base.service';

import * as _ from 'lodash';

@Injectable()
export class SemesterService extends BaseService {

  private baseServiceUrl: string;
  private currentSemesters: Observable<Semester[]>;

  private lastOpenForRegistrationOnly: boolean = null;
  private lastShowInAttendanceOnly: boolean = null;
  private lastShowInCourses: boolean = null;
  private lastShowInKindergarten: boolean = null;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/Api/Semesters/';

  }

  isSemesterOpenForCourseRegistration(semesterId, ts: TenantSetting): Observable<boolean> {

    let preferredBookingSupported =  !ts.courseInterestsSupport &&
      !(ts.coursePreviewOnlyRegistration && !ts.allowClientCourseRegistration);

    return this.getAll(true, null, true)
      .pipe(map(arr => {
          return arr.some((semester) => (semester.id == semesterId) &&
                ((semester.openForRegistration ||
                   (semester.preferredBookingEffectiveIsActive && preferredBookingSupported)
                 ) && semester.showInCourses)
          )
        })
      );
  }

  getAll(openForRegistrationOnly: boolean = null,
         showInAttendanceOnly: boolean = null,
         showInCourses: boolean = null,
         showInKindergarten: boolean = null,
         refresh: boolean = false): Observable<Semester[]> {
    if (!this.currentSemesters ||
      this.lastOpenForRegistrationOnly !== openForRegistrationOnly ||
      this.lastShowInAttendanceOnly !== showInAttendanceOnly ||
      this.lastShowInCourses !== showInCourses ||
      this.lastShowInKindergarten !== showInKindergarten ||
      refresh) {

      this.lastOpenForRegistrationOnly = openForRegistrationOnly;
      this.lastShowInAttendanceOnly = showInAttendanceOnly;
      this.lastShowInCourses = showInCourses;
      this.lastShowInKindergarten = showInKindergarten;

      let headers = this.getDefaultHttpHeaders();
      let params = new HttpParams()
        .set("openForRegistrationOnly", "false")

      let url: string = this.baseServiceUrl + 'Get';

      this.currentSemesters = this.http
        .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) =>
          _.filter(_.reverse(_.sortBy(plainToClass(Semester, res), function (o: Semester) {
            return (!!o.active ? "2_" : "1_") + o.startDate.toISOString()
          })), (s: Semester) => {

            let result = true;
            if (openForRegistrationOnly !== null) {
              result = result && (s.openForRegistration === openForRegistrationOnly || s.preferredBookingEffectiveIsActive);
            }

            if (showInCourses !== null ) {
              result = result && (s.showInCourses == showInCourses);
            }

            if (showInAttendanceOnly !== null) {
              result = result && (s.showInAttendance === showInAttendanceOnly);
            }

            if (showInKindergarten !== null) {
              result = result && (s.showInKindergarten === showInKindergarten);
            }

            return result;
          })),
        publishReplay(1),
        refCount(),);
    }

    return this.currentSemesters;
  }



}
