import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ClassroomSelection } from '../shared/models';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onPlaceSelected(data: ClassroomSelection) {
    this.router.navigate(["/reservations/place", data.classroom.id], { replaceUrl: data.isAutoRedirect });
  }

}
