import { Injectable } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';
import { plainToClass } from "class-transformer";

import { StudentPromotion } from '../models/promotion/studentPromotion';
import { SubjectCategory } from '../models/enums/subjectCategory';

import { BaseService } from './base.service';

// services
import { StudentService } from './student.service';
import { MembershipService } from './membership.service';
import { MembershipStudentService } from './membership-student.service';

import { Student } from '../models/users/student';
import { Membership } from '../models/membership';
import { MembershipStudent } from '../models/membershipStudent';





import * as _ from 'lodash';

@Injectable()
export class PromotionService extends BaseService {

  constructor(
    private studentService: StudentService,
    private membershipService: MembershipService,
    private membershipStudentService: MembershipStudentService,
  ) {
    super();
  }

  getPromotionsForUser(
    userID: number,
    subjectCategory: SubjectCategory,
    promotionNow: Date,
    studentPromotionNow: Date,
    isAdmin: boolean = false,
    refresh: boolean = false): Observable<StudentPromotion[]> {

    var self = this;

    return new Observable<StudentPromotion[]>(observer => {
      forkJoin(
        this.studentService.getByUserID(userID),
        this.membershipService.getValid(promotionNow, refresh),
        this.membershipStudentService.getValidByUser(userID, studentPromotionNow, refresh)
      ).subscribe((res: [Student[], Membership[], MembershipStudent[]]) => {
        var students = res[0];
        var membership = res[1];
        var studentsMembership = res[2];

        observer.next(self.buildStudentPromotionModel(
          students,
          membership,
          studentsMembership,
          subjectCategory,
          isAdmin
        ));
        observer.complete();

      }, (error: any) => {
        observer.error(self.handleError(error));
      });
    });
  }

  buildStudentPromotionModel(
    students: Student[],
    memberships: Membership[],
    studentsMembership: MembershipStudent[],
    subjectCategory: SubjectCategory,
    isAdmin: boolean = false
  ): StudentPromotion[] {

    let res: StudentPromotion[] = [];

    let isAction = subjectCategory === SubjectCategory.OneOffActions;
    let isCourse = subjectCategory === SubjectCategory.RegularCourses;

    _.forEach(students, function (student) {
      _.forEach(memberships, function (membership) {
        let studentMembership = _.find(studentsMembership, { studentID: student.id, membershipID: membership.id });

        if ((studentMembership && studentMembership.applyToActions && isAction && studentMembership.isValid) ||
          (studentMembership && studentMembership.applyToCourses && isCourse && studentMembership.isValid) ||
          (!studentMembership && membership.applyToActions && isAction) ||
          (!studentMembership && membership.applyToCourses && isCourse)) {

          if (membership.isClientAssignable || isAdmin || studentMembership) {
            let studentPromotion = new StudentPromotion();
            studentPromotion.studentID = student.id;
            studentPromotion.buy = membership.isMandatory && !studentMembership;
            studentPromotion.owns = !!studentMembership;
            studentPromotion.isMandatory = membership.isMandatory;
            studentPromotion.membershipID = membership.id;
            studentPromotion.name = membership.name;
            studentPromotion.description = membership.description;
            studentPromotion.price = studentMembership != null ? studentMembership.price : membership.price;
            studentPromotion.discountPct = studentMembership != null ? studentMembership.discountPct : membership.discountPct;
            studentPromotion.validFrom = studentMembership != null ? studentMembership.validFrom : membership.validFrom;
            studentPromotion.validTo = studentMembership != null ? studentMembership.validTo : membership.validTo;

            res.push(studentPromotion);
          }
        }
      });

      _.forEach(_.filter(studentsMembership, { studentID: student.id }), function (studentMembership) {
        let membership = _.find(memberships, { 'id': studentMembership.membershipID });
        if (!membership && studentMembership.isValid) {
          // student membership with no coresponding membership
          if ((studentMembership.applyToActions && isAction) || (studentMembership.applyToCourses && isCourse)) {
            let studentPromotion = new StudentPromotion();
            studentPromotion.studentID = student.id;
            studentPromotion.buy = false;
            studentPromotion.owns = true;
            studentPromotion.isMandatory = false;
            studentPromotion.membershipID = studentMembership.membershipID;
            studentPromotion.name = studentMembership.name;
            studentPromotion.description = studentMembership.description;
            studentPromotion.price = studentMembership.price;
            studentPromotion.discountPct = studentMembership.discountPct;
            studentPromotion.validFrom = studentMembership.validFrom;
            studentPromotion.validTo = studentMembership.validTo;

            res.push(studentPromotion);
          }
        }
      });
    });
    return res;
  }

}
