import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SemesterWithCompGroup } from '../shared/models';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  constructor(
    private router: Router) { }

  ngOnInit() {
  }

  public showPreferredBookingInfo: boolean = true;

  onSemesterSelect(data: SemesterWithCompGroup) {
    this.router.navigate(["/courses/semester", data.semesterID], { replaceUrl: data.isAutoRedirect });
  }

}
