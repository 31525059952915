import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { TenantService } from '../services/tenant.service';

@Pipe({
  name: 'wbcurrency'
})
export class WbcurrencyPipe extends CurrencyPipe implements PipeTransform {

  constructor(
    private tenantService: TenantService,
    @Inject(LOCALE_ID) _locale: string) {
    super(_locale);
  }

  transform(value: any, args?: any): any {
    let currencyCode = this.tenantService.getCurrency();
    return super.transform(value, currencyCode, 'symbol');
  }
}
