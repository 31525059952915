import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import {
  FormDefinitionService,
  TenantService,
  StudentService,
  FormResponseService,
  LoginService, UserService
} from '../../shared/services';

import { FormsAccessPermissionsType } from '../../shared/models/enums';

import { map } from 'rxjs/operators';
import {
  FormDefinition,
  FormResponse,
  FormQuestionWithAnswer,
  Student,
  User,
  NotRegisteredUserInfo,
  NotRegisteredStudentInfo
} from 'app/shared/models';

import * as _ from 'lodash';
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-form-response',
  templateUrl: './form-response.component.html',
  styleUrls: ['./form-response.component.css']
})
export class FormResponseComponent implements OnInit {

  pictureUrls: string[] = [];
  isLoggedIn: boolean = false;
  students: Student[] = [];
  selectedStudent: Student = null;
  user: User = null;
  formResponse: FormResponse = new FormResponse();
  formId: string = '';
  formDefinition: FormDefinition;
  formQuestionsWithAnswers: FormQuestionWithAnswer[] = [];
  loading: boolean = true;
  saving: boolean = false;
  confirmResponseCreatedVisible: boolean = false;
  emailSendingEnabled: boolean = false;
  loginAdvertDisplayed: boolean = false;
  signInVisible: boolean = false;
  returnUrl: string = '';

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private tenantService: TenantService,
    private formDefinitionService: FormDefinitionService,
    private studentService: StudentService,
    private formResponseService: FormResponseService,
    private location: Location,
    private loginService: LoginService,
    private userService: UserService) { }

  ngOnInit() {
    let self = this;

    self.route.params.pipe(map(params => params['id']))
      .subscribe(id => {
        self.formId = id;
        self.returnUrl = `/forms/${id}/form-response`;
      });

    self.tenantService.getTenantSummary()
      .subscribe(ts => {
        self.isLoggedIn = ts.loggedInUser.isAuthenticated;
        self.emailSendingEnabled = ts.tenantSetting.enableFormResponseCreatedNotification &&
          !ts.tenantSetting.disableAllEmails &&
          !ts.tenantSetting.disableAllEmailsExceptUserRegistration;

        if (self.isLoggedIn) {
          self.formResponse.userId = ts.loggedInUser.userID + "";
          self.studentService.getCurrent().subscribe(s => self.students = s);
          self.userService.getCurrent().subscribe(u => self.user = u);
        }
        let formDefinitionObserver = self.formDefinitionService.getFormDefinitionById(self.formId);
        formDefinitionObserver.subscribe(fd => {
          if (fd.pictureUrls && fd.pictureUrls.length > 0) {
            self.pictureUrls.push.apply(self.pictureUrls, fd.pictureUrls);
          } else {
            self.pictureUrls.push('https://storage.googleapis.com/gaxb7o2ddx-images/Generic/0/default_form.jpg');
          }
          self.formDefinition = fd;
          self.formResponse.formDefinitionId = fd.id;
          self.formResponse.formDefinitionRevision = fd.auditEvent.revision;
          _.forEach(fd.questions, function (q) {
            self.formQuestionsWithAnswers.push({
              question: q,
              answer: {
                id: q.id,
                optionsSelection: null,
                textAnswer: null
              }
            });
          });
          self.loading = false;

          if ((fd.accessPermissions == FormsAccessPermissionsType.LoggedInUsersOnlyNoStudent ||
            fd.accessPermissions == FormsAccessPermissionsType.LoggedInUsersOnlyStudentRequired) &&
            !self.isLoggedIn) {
            self.loginAdvertDisplayed = true;
          } else if (!self.isLoggedIn) {
            self.formResponse.notRegisteredUserInfo = new NotRegisteredUserInfo();
            if (fd.accessPermissions == FormsAccessPermissionsType.AllowAnonymousStudentRequired) {
              self.formResponse.notRegisteredUserInfo.students = [new NotRegisteredStudentInfo()];
            }
          }
        }, err => {
          self.loading = false;
        });
      });
  }

  onSelectedStudentChanged(params: any) {
    if (!!this.students && this.students.length > 0 && !!this.formResponse) {
      this.selectedStudent = this.students.find(s => s.id === +this.formResponse.studentId);
    }
    else {
      this.selectedStudent = null;
    }
  }

  onSubmitResponse(params: any) {
    let self = this;
    let isValid = true;
    if (params.validationGroup) {
      isValid = params.validationGroup.validate().isValid;
    }

    if (isValid) {
      let model = _.cloneDeep(self.formResponse);
      model.answers = [];
      _.forEach(self.formQuestionsWithAnswers, qa => {
        model.answers.push(qa.answer);
      });
      model.isNotRegisteredUser = !self.isLoggedIn;

      self.saving = true;


      let observables: any[] = [];

      if (self.formDefinition.showFullEditableUserDetail && self.user != null) {
        observables.push(self.userService.update(self.user, false));
      }

      if (self.formDefinition.showFullEditableStudentDetail && self.selectedStudent != null) {
        observables.push(self.studentService.update(self.selectedStudent, false))
      }
      observables.push(self.formResponseService.create(model));

      forkJoin(observables).subscribe(s => {
        self.saving = false;
        self.confirmResponseCreatedVisible = true;
      }, err => {
        self.saving = false;
      });
    }
  }

  confirmResponseCreatedOkClick(e) {
    this.confirmResponseCreatedVisible = false;
    this.location.back();
  }

  onLoginAdvertCancel(e) {
    this.router.navigate(["/forms"]);
  }

  onLoginButtonClick(e) {
    this.signInVisible = true;
  }

  navigateToStudents() {
    this.router.navigate(["/my-account/students"], { queryParams: { returnUrl: this.router.url } } );
  }
}
