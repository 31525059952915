import { Transform } from "class-transformer";

import { TransformDate } from '../decorators';

export class Semester {
    id: number;
    name: string;
    description: string;

    @TransformDate()
    startDate: Date;

    @TransformDate()
    endDate: Date;

    active: boolean;
    noOfStudyWeeks: number;
    openForRegistration: boolean;
    showInAttendance: boolean;
    showInCourses: boolean;
    showInKindergarten: boolean;

    @TransformDate()
    preferredBookingStartDate: Date;
    @TransformDate()
    preferredBookingEndDate: Date;
    preferredBookingIsActive: boolean;
    preferredBookingEffectiveIsActive: boolean;
}