<dx-action-sheet #onlinePaymentConfirmSheet
  title="{{'TXT_ONLINE_PAYMENT_QUESTION' | translate}}"
  cancelText="{{'TXT_CANCEL' | translate}}"
  [items]="onlinePaymentConfirmItems"
  [(visible)]="onlinePaymentConfirmVisible"
  [showTitle]="true"
  [showCancelButton]="true"
  (onItemClick)="onlinePaymentConfirmClick()">
</dx-action-sheet>
<dx-list [dataSource]="duePaymentsByStudent"
  [grouped]="true"
  [keyExpr]="'studentFullName'"
  [groupTemplate]="'student'"
  [itemTemplate]="'duePayment'"
  pullRefreshEnabled="true"
  [collapsibleGroups]="true"
  (onPullRefresh)="onPullRefresh()"
  (onItemClick)="onDuePaymentClick($event)">
  <div *dxTemplate="let groupData of 'student'"
    class="student-wrapper">
    <h4>
      {{groupData.studentFullName}}
    </h4>
  </div>
  <div *dxTemplate="let itemData of 'duePayment'"
    class="activity-wrapper">
    <div class="row">
      <h5 class="wordwrap"
        style="margin:0 0 10px 0">{{itemData.subject}}</h5>
    </div>
    <div class="row">
      <div class="col-xs-3">{{'TXT_INVOICE_NO'| translate}}:</div>
      <div class="col-xs-9 text-right">{{itemData.invoiceNo}}</div>
    </div>
    <div class="row">
      <div *ngIf="itemData.arrear >= 0 && !itemData.hasAmortizationSchedule" 
        class="col-xs-3">{{'TXT_ARREAR'| translate}}:</div>
      <div *ngIf="itemData.arrear >= 0 && !!itemData.hasAmortizationSchedule" 
        class="col-xs-3">{{'TXT_ARREAR_ALL'| translate}}:</div>        
      <div *ngIf="itemData.arrear &lt; 0" 
        class="col-xs-3">{{'TXT_OVER_PAYMENT'| translate}}:</div>        
      <div [ngClass]="{'dx-button-danger': itemData.arrear > 0, 'dx-button-success': itemData.arrear &lt; 0}"
        class="col-xs-9 text-right dx-button-mode-text">
        <strong *ngIf="!itemData.hasAmortizationSchedule">{{itemData.arrear | wbcurrency}}</strong>
        <span *ngIf="!!itemData.hasAmortizationSchedule">{{itemData.arrear | wbcurrency}}</span>
      </div>
    </div>
    <div class="row" *ngIf="itemData.arrearByAmortizationSchedule != itemData.arrear" >
      <div class="col-xs-3">{{'TXT_ARREAR_BY_AMORTIZATION_SCHEDULE'| translate}}:</div>
        <div
          class="col-xs-9 dx-button-mode-text dx-button-danger text-right">
          <strong>{{itemData.arrearByAmortizationSchedule | wbcurrency}}</strong>
        </div>        
    </div>
    <div class="row">
      <div class="col-xs-3">{{'TXT_DUE_DATE'| translate}}:</div>
      <div class="col-xs-9 text-right">{{itemData.dueDate | date:'shortDate'}}</div>
    </div>
    <div *ngIf="itemData.supportsOnlinePayment && itemData.arrear > 0"
      class="row">
      <div class="col-xs-12 text-center dx-button-mode-text dx-button-success">
        <span class="glyphicon glyphicon-credit-card"
          aria-hidden="true"></span>
        {{'TXT_CLICK_FOR_ONLINE_PAYMENT' | translate}}
      </div>
    </div>
  </div>
</dx-list>
<dx-popup showTitle="true"
  title="{{'TXT_INFORMATION' | translate}}"
  [maxHeight]="230"
  [(visible)]="confirmVisible">
  <div *dxTemplate="let data of 'content'">
    <p>{{confirmPaymentMessage}}</p>
    <div class="text-center">
      <dx-button text="{{'TXT_OK' | translate}}"
        class="wb-button"
        type="success"
        (onClick)="onConfimgPaymentResultClick($event)">
      </dx-button>
    </div>
  </div>
</dx-popup>