import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import {SemesterService, TenantService} from '../services';

import {Observable} from 'rxjs';

@Injectable()
export class CanActivateForCourseRegistrationGuard implements CanActivate {

    constructor(
        private tenantService: TenantService,
        private semesterService: SemesterService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let self = this;

        return new Observable<boolean>(observer => {
            self.tenantService.getTenantSummary().subscribe((t) => {
                if (t.tenantSetting.allowClientCourseRegistration ||
                    t.tenantSetting.coursePreviewOnlyRegistration ||
                    t.tenantSetting.courseInterestsSupport) {

                  let semesterId = route.params.semesterId;
                  if (semesterId != null) {
                    this.semesterService.isSemesterOpenForCourseRegistration(semesterId, t.tenantSetting).subscribe(r => {
                      if (r) {
                        observer.next(true);
                      } else {
                        observer.next(false);
                        self.router.navigate(["/courses"]);
                      }
                      observer.complete();
                    });
                  } else {
                    observer.next(true);
                    observer.complete();
                  }
                } else {
                    observer.next(false);
                    self.router.navigate(["/"]);
                    observer.complete();
                }
            },
                err => {
                    observer.error(err);
                    self.router.navigate(["/"]);
                });
        });
    }
}
