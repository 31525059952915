
import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Membership } from '../models/membership';
import { BaseService } from './base.service';

import * as moment from "moment";

@Injectable()
export class MembershipService extends BaseService {

  private baseServiceUrl: string;
  private memberships: Observable<Membership[]>;
  private now: Date;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/Api/Membership/';
  }

  getValid(now: Date, refresh: boolean = false): Observable<Membership[]> {

    var normalizedNow: Date = moment(now).startOf('day').toDate();

    if (
      !this.now ||
      this.now.getTime() !== normalizedNow.getTime() ||
      !this.memberships ||
      refresh) {

      this.now = normalizedNow;

      let headers = this.getDefaultHttpHeaders();
      let params = new HttpParams()
        .set("now", normalizedNow.toISOString());
      let url: string = this.baseServiceUrl + 'GetValid';


      this.memberships = this.http
        .get(url, {
          headers: headers,
          params: params
        }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(Membership, res)),
        publishReplay(1),
        refCount(),);
    }

    return this.memberships;
  }
}
