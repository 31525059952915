import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CompensationGroup } from '../models';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import * as _ from 'lodash';

@Injectable()
export class CompensationGroupService extends BaseService {

  private baseServiceUrl: string;
  private currentCompensationGroups: Observable<CompensationGroup[]>;

  constructor(private http: HttpClient) {
    super();
    this.baseServiceUrl = '/Api/CompensationGroups/';
  }

  getAll(): Observable<CompensationGroup[]> {
    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams();

    let url: string = this.baseServiceUrl + 'GetAll';

    this.currentCompensationGroups = this.http
      .get(url, { headers: headers, params: params })
      .pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(CompensationGroup, res)),
        publishReplay(1),
        refCount());

      return this.currentCompensationGroups;
    }
}
