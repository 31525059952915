import { Type } from "class-transformer";

import { TransformDate } from '../decorators';

export class MembershipStudent {
    membershipStudentID: number;
    name: string;
    description: string;
    studentID: number;
    membershipID: number;
    userID: number;

    @TransformDate()
    validFrom: Date;

    @TransformDate()
    validTo: Date;

    isValid: boolean;
    price: number;
    discountPct: number;
    applyToActions: boolean;
    applyToCourses: boolean;

    student: string;
    parent: string;
    email: string;
    phone: string;

    @TransformDate()
    birthday: Date;

    @TransformDate()
    createdAt: Date;

    isDeleted: boolean;
}
