
import { filter, take } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';

import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import * as _ from 'lodash';

import {
  TenantService,
  UserService,
  StudentService,
  ConsentsService, LoginService
} from '../shared/services';
import {User, Student, TenantSetting, TenantSummary, SignInUser} from '../shared/models';
import { WindowWrapper } from '../shared/windowWrapper';
import {DxTabPanelComponent} from "devextreme-angular";

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  user: User = new User();
  signInUser: SignInUser = new SignInUser();
  signInOtpWasSend: boolean = false;
  students: Student[] = [];
  hasConsents: boolean = false;
  private dataLoaded: boolean = false;
  private returnUrl: string;

  @ViewChild("targetTabPanel", { static: false }) tabPanel: DxTabPanelComponent


  private _selectedTabIndex: number = 0;
  get selectedTabIndex(): number { return this._selectedTabIndex; }
  set selectedTabIndex(value: number) {
    if (this._selectedTabIndex !== value) {
      this._selectedTabIndex = value;

      if (!!sessionStorage.getItem("registrationDone")) {
        this.router.navigate([this.tabPanelItems[value].url], { queryParams: {
            registration: null
          },
          queryParamsHandling: "merge"});
      } else {
        this.router.navigate([this.tabPanelItems[value].url], {queryParamsHandling: "merge"});
      }
    }
  }

  registrationMode: boolean = false;

  isLoadPanelVisible: boolean = false;
  isSavePanelVisible: boolean = false;
  isAuthenticated: boolean = false;
  errorMessage: string = null;

  public tabPanelItems = [
    { id: 1, title: "TXT_USER", url: "/my-account/user-account", visible: true, template: "template1" },
    { id: 2, title: "TXT_STUDENTS", url: "/my-account/students", visible: false, template: "template2" },
    { id: 3, title: "TXT_CONTACT_DETAILS", url: "/my-account/contact-details", visible: false, template: "template3" },
    { id: 4, title: "TXT_DUE_PAYMENTS", url: "/my-account/due-payments", visible: false, template: "template4" },
    { id: 5, title: "TXT_CONSENTS", url: "/my-account/my-consents", visible: false, template: "template5" }];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tenantService: TenantService,
    private userService: UserService,
    private studentService: StudentService,
    private loginService: LoginService,
    private location: Location,
    private _window: WindowWrapper,
    private consentsService: ConsentsService) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let idx = _.findIndex(this.tabPanelItems, o => event.url.startsWith(o.url));
        if (idx !== -1) {
          this.selectedTabIndex = idx;
        }
      });
  }

  ngOnInit() {
    let self = this;

    self.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    if (!!self.returnUrl && self.returnUrl.startsWith('/m/')) {
      self.returnUrl = self.returnUrl.substring(2);
    }

    if (!!sessionStorage.getItem("registrationDone")) {
      self.registrationMode = false;
    } else {
      self.registrationMode = this.activatedRoute.snapshot.queryParamMap.get('registration') === 'true';
    }

    self.tenantService.getTenantSummary().subscribe(ts => {

      this.isAuthenticated = ts.loggedInUser.isAuthenticated;

      if (ts.loggedInUser.isAuthenticated) {
        _.find(self.tabPanelItems, { 'id': 2 }).visible = true;
        _.find(self.tabPanelItems, { 'id': 3 }).visible = true;
      }

      this.loadData(false, ts);

      if (ts.tenantSetting.enableDuePaymentsInClient && !self.registrationMode) {
        _.find(self.tabPanelItems, { 'id': 4 }).visible = true;
      } else {
        if (self.selectedTabIndex === 3) {
          this.router.navigate([self.tabPanelItems[0].url], { queryParamsHandling: "merge" });
        }
      }
      let consentPane = _.find(self.tabPanelItems, { 'id': 5 });
      if (!self.registrationMode) {
        consentPane.visible = true;

        self.userService.getCurrent(false).subscribe(user => {
          let uc = self.consentsService.getConsentValidityGroupsForUser(user.id);
          uc.subscribe(data => {
            if (!!data && data.length > 0 && !!data[0].items && data[0].items.length > 0) {
              consentPane.visible = true;
              self.hasConsents = true;
            }
          });
        });
      }

      // if (self.registrationMode && this.selectedTabIndex !== 1) {
      //   this.selectedTabIndex = 1;
      // }
    });
  }

  private loadData(refresh: boolean = false, ts: TenantSummary) {
    let self = this;

    if (ts.loggedInUser.isAuthenticated) {

      if (!self.dataLoaded || refresh) {
        self.isLoadPanelVisible = true;
        forkJoin(
          self.userService.getCurrent(refresh),
          self.studentService.getCurrent(refresh)).subscribe((res: [User, Student[]]) => {
          self.user = res[0];
          self.students = res[1];
          self.isLoadPanelVisible = false;
          self.dataLoaded = true;
        }, err => {
          self.isLoadPanelVisible = false
        })
      }
    } else {
      self.user = new User();
      self.students = [];
      self.dataLoaded = true;
    }
  }

  onUserSave(params: any) {
    let self = this;

    let valid = true;
    if (params.validationGroup) {
      valid = params.validationGroup.validate().isValid;
    }

    if (valid) {
      self.isSavePanelVisible = true;
      self.userService.update(self.user)
        .subscribe(u => {
          self.user = u;
          self.isSavePanelVisible = false;

          if (!!self.returnUrl) {
            this.router.navigate([self.returnUrl]);
          }
        }, err => { self.isSavePanelVisible = false })
    }
  }

  onStudentsSave(params: any) {
    let self = this;

    let valid = true;
    if (params.validationGroup) {
      valid = params.validationGroup.validate().isValid;
    }

    if (valid) {
      self.isSavePanelVisible = true;
      self.studentService.saveCurrent(self.students)
        .subscribe(s => {
          self.students = s;
          self.isSavePanelVisible = false;

          if (!!self.returnUrl) {
            this.router.navigate([self.returnUrl]);
          }
        }, err => {
          self.isSavePanelVisible = false
        })
    }
  }

  onFinishRegistration(params: any) {
    let self = this;

    let valid = true;
    if (params.validationGroup) {
      valid = params.validationGroup.validate().isValid;
    }

    if (valid) {
      self.isSavePanelVisible = true;
      forkJoin(
        self.userService.update(self.user),
        self.studentService.saveCurrent(self.students),
        self.activatedRoute.queryParamMap.pipe(take(1))
      ).subscribe((res: [User, Student[], ParamMap]) => {
        self.user = res[0];
        self.students = res[1];

        sessionStorage.setItem("registrationDone", "true");

        if (!!self.returnUrl) {
          if (self.returnUrl.startsWith("/m/")) {
            this._window.location.href = self.returnUrl;
          } else {
            this._window.location.href = "/m/" + self.returnUrl;
          }
        } else {
          self.isSavePanelVisible = false;
          this._window.location.reload();
        }
      }, err => { self.isSavePanelVisible = false });
    }
  }

  onSignUpNext(params: any) {

    if (this.isAuthenticated) {
      this.onNext(params);
      return;
    }

    let valid = true;
    if (params.validationGroup) {
      valid = params.validationGroup.validate().isValid;
    }

    if (valid) {
      if (!this.signInOtpWasSend) {
        this.errorMessage = null;
        this.isSavePanelVisible = true;
        this.loginService.createUserWithEmailAndPassword(this.signInUser.email, this.signInUser.password).subscribe(u => {
          this.loginService.sendEmailVerificationAuthorizationCode(this.signInUser.email).subscribe(() => {
            this.signInOtpWasSend = true;
            this.isSavePanelVisible = false;
          }, err => {
            this.isSavePanelVisible = false;
            this.errorMessage = this.loginService.translateError(err);
          });
        }, err => {
          this.isSavePanelVisible = false;
          this.errorMessage = this.loginService.translateError(err);
        });
      } else {
        this.errorMessage = null;
        this.isSavePanelVisible = true;
        this.loginService.confirmEmailVerification(this.signInUser.email, this.signInUser.otp).subscribe(() => {
          this.loginService.login(this.signInUser.email, this.signInUser.password).subscribe(() => {
            this._window.location.reload();
          },err => {
            this.isSavePanelVisible = false;
            this.errorMessage = this.loginService.translateError(err);
          })
        },err => {
          this.isSavePanelVisible = false;
          this.errorMessage = this.loginService.translateError(err);
        });
      }
    }
  }

  onNext(params: any) {
    let nextIndex = _.findIndex(this.tabPanelItems, { visible: true }, this.selectedTabIndex + 1);

    if (nextIndex !== -1) {
      this.selectedTabIndex = nextIndex;
    }
  }

  onValidationSummaryItemClick(e: any) {
    let validator = e.itemData.validator;
    let $element = validator.element();
   // let tabPanel = $element.closest(".dx-tabpanel").dxTabPanel("instance");
    let tabPanelItem = $element.closest("div[data-tab-panel-index]").attributes["data-tab-panel-index"].nodeValue;
    if (tabPanelItem) {
      this.tabPanel.instance.option("selectedIndex", +tabPanelItem - 1);
      validator.focus();
    }
  }
}
