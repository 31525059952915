import { Type, Transform } from "class-transformer";
import { TransformDate } from '../../decorators';

export class DuePayment {
    invoiceID: number;
    typeReferenceID: number;
    typeReference2ID: number;
    accountingBookID: number;
    typeID: number;
    isReccuring: boolean;
    semesterID: number;
    studentFullName: string;
    parentFullName: string;
    parentEmail: string;
    parentPhone: string;
    parentID: number;
    subject: string;
    notes: string;
    price: number;
    priceByAmortizationSchedule: number;
    payments: number;
    arrear: number;
    arrearByAmortizationSchedule: number;

    @TransformDate()
    invoiceDate: Date;

    @TransformDate()
    dueDate: Date;

    invoiceNo: number;
    supportsOnlinePayment: boolean;
    expectedPrice: number;
    invoiceNotInSync: boolean;
    coursePricePerHour: number;
    coursePrice: number;
    studentPayedAttendace: number;

    @TransformDate()
    courseStartDate: Date;

    hasAmortizationSchedule: boolean;

}