import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { TranslateService } from '@ngx-translate/core'

import { Observable } from 'rxjs';

import { TenantService, UserScheduleService } from '../../shared/services';
import { TenantSummary, UserScheduleDetail } from '../../shared/models';

@Component({
  selector: 'app-semester-schedule-detail',
  templateUrl: './semester-schedule-detail.component.html',
  styleUrls: ['./semester-schedule-detail.component.css']
})
export class SemesterScheduleDetailComponent implements OnInit {

  private courseScheduleID: number;
  userScheduleDetail: UserScheduleDetail;
  isLoadPanelVisible: boolean = false;
  compensationGrpHelp: string;
  isSavePanelVisible: boolean = false;
  disableClientCalendar: boolean = true;
  showCourseWaitQueueIndexInClient: boolean = false;

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private location: Location,
    private userScheduleService: UserScheduleService,
    private tenantService: TenantService) { }

  ngOnInit() {
    let self = this;

    self.isLoadPanelVisible = true;

    self.courseScheduleID = +self.route.snapshot.params['courseScheduleID'];

    let userID: number = 0;
    self.tenantService.getTenantSummary().subscribe((tenant: TenantSummary) => {

      self.disableClientCalendar = tenant.tenantSetting.disableClientCalendar;
      userID = tenant.loggedInUser.isAuthenticated
        ? tenant.loggedInUser.userID
        : 0;

      this.showCourseWaitQueueIndexInClient =
        tenant.tenantSetting.showCourseWaitQueueIndexInClient;

      self.userScheduleService.getUserScheduleDetail(
        self.courseScheduleID,
        userID).subscribe((res: UserScheduleDetail) => {
          self.userScheduleDetail = res;

          self.translateService.get('TXT_COMPENSATION_GROUP_HELP').subscribe((r: string) => {
            self.compensationGrpHelp = r.f(self.userScheduleDetail.compensationGroup);
          });

          self.isLoadPanelVisible = false;
        }, (err) => {
          self.isLoadPanelVisible = false;
        });
    });
  }

  onSave(): void {
    let self = this;

    self.tenantService.getTenantSummary().subscribe((tenant: TenantSummary) => {
      if (tenant.loggedInUser.isAuthenticated) {
        self.isSavePanelVisible = true;

        if (!tenant.tenantSetting.disableClientCalendar) {
          self.userScheduleService.setNewAttendance(
            tenant.loggedInUser.userID,
            self.courseScheduleID,
            self.userScheduleDetail.presentStudentsIDs)
            .subscribe(() => {
              self.isSavePanelVisible = false;
              self.location.back();
            }, err => {
              self.isSavePanelVisible = false;
            });
        }
      }
    });
  }
}
