
import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { CourseSchedule } from '../models/courseSchedule/courseSchedule';
import { BaseService } from './base.service';
import { TranslateService } from '@ngx-translate/core';

import { notifySuccess } from './notifications';


@Injectable()
export class CourseScheduleService extends BaseService {

  private baseServiceUrl: string;
  private schedules: Observable<CourseSchedule[]>;
  private courseID: number;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService) {

    super();
    this.baseServiceUrl = '/Api/CourseSchedule';

  }

  getAllForCourse(
    courseID: number,
    refresh: boolean = false
  ): Observable<CourseSchedule[]> {

    if (this.courseID !== courseID ||
      !this.schedules ||
      refresh) {

      this.courseID = courseID;

      let headers = this.getDefaultHttpHeaders();
      let params = new HttpParams()
        .set("courseID", courseID.toString());

      let url: string = `${this.baseServiceUrl}/Get`;

      this.schedules = this.http
        .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(CourseSchedule, res)),
        publishReplay(1),
        refCount(),);
    }

    return this.schedules;
  }

  updateLessonNote(courseScheduleID: number, lessonNote: string, sendNotification: boolean): Observable<Object>  {
    let headers = this.getDefaultHttpHeaders();
    let url: string = '/Attendance/UpdateScheduleNote';

    return new Observable<Object>(observer => {

      let self = this;

      self.http.post(url, {
        courseScheduleID: courseScheduleID,
        lessonNote: lessonNote,
        sendNotification: sendNotification
      }, { headers: headers })
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            observer.next(value);
            observer.complete();
          },
          err => observer.error(self.handleError(err)))
    });
  }

}
