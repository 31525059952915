import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ClassroomService } from '../../services';
import { Classroom } from '../../models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import Query from 'devextreme/data/query';

import * as _ from 'lodash';

@Component({
  selector: 'app-classroom-calendar',
  templateUrl: './classroom-calendar.component.html',
  styleUrls: ['./classroom-calendar.component.css']
})
export class ClassroomCalendarComponent implements OnInit {

  public classrooms: DataSource;
  private classroomsForceRefresh: boolean = false;

  private classroomIDValue: number;
  @Output() classroomIDChange = new EventEmitter<Number>();
  @Input()
  get classroomID() {
    return this.classroomIDValue;
  }
  set classroomID(value: number) {
    if (this.classroomIDValue !== value) {
      this.classroomIDValue = value;
      this.classroomIDChange.emit(value);
    }
  }

  private selectedDateValue: Date;
  @Output() selectedDateChange = new EventEmitter<Date>();
  @Input()
  get selectedDate(): Date {
    return this.selectedDateValue;
  }
  set selectedDate(value: Date) {
    this.selectedDateValue = value;
    this.selectedDateChange.emit(value);
  }

  @Input() highlightedDays: _.Dictionary<Date>;

  @Input()
  filterForReservations: boolean;

  @Input()
  filterForCourses: boolean;

  constructor(private classroomService: ClassroomService) { }

  ngOnInit() {
    let self = this;

    self.classrooms = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.classroomService.getAllActive(self.classroomsForceRefresh).subscribe((data) => {
              let result = data;
              if (loadOptions.searchValue) {
                let filter = loadOptions.filter;
                result = Query(result).filter([loadOptions.searchExpr[0], loadOptions.searchOperation, loadOptions.searchValue]).toArray();
              }

              if (self.filterForReservations) {
                result = _.filter(result, { showInReservations: true });
              }

              if (self.filterForCourses) {
                result = _.filter(result, { showInCourses: true });
              }

              resolve(result);
              self.classroomsForceRefresh = false;
            }, (error) => {
              reject(error);
            });
          });
        },
        byKey: (key: any) => {
          return new Promise((resolve, reject) => {
            self.classroomService.getByID(key).subscribe((data) => {
              resolve(data);
            }, (error) => {
              reject(error);
            })
          })
        },
      })
    });
  }

  onPullClassroomsRefresh() {
    this.classroomsForceRefresh = true;
    this.classrooms.load();
  }

  getDayStyleWeight(date: Date, text: string, view: string) {
    if (this.highlightedDays) {
      let day = this.highlightedDays[date.toString()];
      if (day) {
        return "bold";
      }
    }
    return "inherit";
  }
}
