import { Type, Transform } from "class-transformer";
import { TransformDate } from '../../decorators';

import { WaitQueue } from './waitQueue';
import { RecurrenceInfo } from './recurrenceInfo';
import { RecurrenceEventType } from '../enums/recurrenceEventType';

export class CourseSchedule {
    courseScheduleID: number;
    courseID: number;

    @TransformDate()
    startDate: Date;

    @TransformDate()
    endDate: Date;

    capacity: number;
    filled: number;
    teacher: string;
    employeeID: number;
    classroomID: number;
    classroomName: string;
    notes: string;
    subjectCategoryID: number;

    @TransformDate()
    notificationSentAt: Date;

    @Type(() => WaitQueue)
    waitQueue: WaitQueue[];

    @Type(() => RecurrenceInfo)
    recurrenceInfo: RecurrenceInfo;

    recurrenceId: string;

    recurrenceType: RecurrenceEventType;

    semesterID: number;

}