import { Component, OnInit, Input } from '@angular/core';

import { FormQuestion, FormAnswer } from '../../models/form';
import { FormsQuestionType } from '../../models/enums/formsQuestionType';

import * as _ from 'lodash';

@Component({
  selector: 'app-form-question-edit',
  templateUrl: './form-question-edit.component.html',
  styleUrls: ['./form-question-edit.component.css']
})
export class FormQuestionEditComponent implements OnInit {

  isValid: boolean = true;
  callbacks = [];

  constructor() { }

  @Input()
  question: FormQuestion;

  @Input()
  answer: FormAnswer;

  ngOnInit() {
  }

  atLeastOneValueAdapterConfig = {
    getValue: () => {
      this.isValid = this.answer.optionsSelection && this.answer.optionsSelection.length > 0;
      return this.isValid;
    },
    validationRequestsCallbacks: this.callbacks
  };

  revalidate() {
    this.callbacks.forEach(func => {
      func();
    });
  };

  onValueChanged(e, option) {
    if (!this.answer.optionsSelection) {
      this.answer.optionsSelection = [];
    }

    switch (this.question.questionType) {
      case FormsQuestionType.SingleSelectOption:
      case FormsQuestionType.SingleSelectOptionPlusFreeFormText:
        if (e.value) {
          var existingIdx = _.indexOf(this.answer.optionsSelection, e.value.id);
          if (existingIdx == -1) {
            this.answer.optionsSelection = [e.value.id];
          }
        }
        break;
      case FormsQuestionType.MultiSelectOption:
      case FormsQuestionType.MultiSelectOptionPlusFreeFormText:
        if (e.value) {
          var existingIdx = _.indexOf(this.answer.optionsSelection, option.id);
          if (existingIdx == -1) {
            this.answer.optionsSelection.push(option.id);
          }
        } else {
          _.pull(this.answer.optionsSelection, option.id);
        }
        break;
    }

    this.revalidate();
  }

}
