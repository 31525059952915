import { TransformDate } from '../decorators';
import { Type } from "class-transformer";

import { Consent } from './consent';

export class UserConsetsInfo {

    userID: number;
    fullName: string;
    email: string;
    phone: string;
    idmEmailConfirmed: boolean;
    isDisabled: boolean;

    @TransformDate()
    idmConsentIssued: Date;

    @Type(() => Consent)
    consents: Consent[];

}