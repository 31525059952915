import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { ClassroomService } from '../../services';
import { Classroom, ClassroomSelection } from '../../models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import * as _ from 'lodash';

class Tooltip {
  isShown: boolean;
  text: string;
}

class Marker {
  location: any;
  tooltip: Tooltip;
}

@Component({
  selector: 'app-classroom-select',
  templateUrl: './classroom-select.component.html',
  styleUrls: ['./classroom-select.component.css']
})
export class ClassroomSelectComponent implements OnInit {

  constructor(
    private classroomService: ClassroomService
  ) { }

  classrooms: DataSource;
  forceRefresh: boolean = false;
  dataLoaded: boolean = false;
  mapVisible: boolean = false;
  clickedClassroom: Classroom;
  selectedAddress: string = null;
  markers: Marker[] = null;
  selectedClassroomName: string = null;

  @Output() onClick = new EventEmitter<ClassroomSelection>();

  @Input()
  filterForReservations: boolean;

  @Input()
  filterForCourses: boolean;


  onPullRefresh() {
    this.forceRefresh = true;
    this.classrooms.load();
  }

  onItemClick(params) {
    this.onClick.emit({
      classroom: params.itemData,
      isAutoRedirect: false
    });
  }

  showMapDetail(params, classroom: Classroom) {

    params.event.stopPropagation();
    this.clickedClassroom = classroom;

    if (classroom.address && classroom.address.formattedAddress) {
      this.selectedAddress = classroom.address.formattedAddress;
      this.markers = [{
        location: [classroom.address.location.latitude, classroom.address.location.longitude],
        tooltip: {
          isShown: false,
          text: classroom.address.formattedAddress
        }
      }];
      this.selectedClassroomName = classroom.name;
      this.mapVisible = true;


    } else {
      this.markers = [];
    }
  }

  ngOnInit() {
    let self = this;

    self.classrooms = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.classroomService.getAllActive(this.forceRefresh)
              .subscribe((data: Classroom[]) => {

                let filtered = data;

                if (self.filterForReservations) {
                  filtered = _.filter(filtered, { showInReservations: true });
                }

                if (self.filterForCourses) {
                  filtered = _.filter(filtered, { showInCourses: true });
                }

                self.dataLoaded = true;
                self.forceRefresh = false;

                // No need to alter data in any way
                if (filtered.length === 1) {
                  self.onClick.emit({
                    classroom: filtered[0],
                    isAutoRedirect: true
                  });
                }
                resolve(filtered);
              }, (error) => {
                reject(error);
              });
          })
        }
      })
    });

  }

}
