import { TransformDate } from '../../decorators';
import { Exclude } from "class-transformer";
import * as moment from "moment";

export class GcpStudent {
  id: string;
  userID: string;
  name: string;
  middlename: string;
  surname: string;
  gender: string;
  @TransformDate()
  birthday: Date;
  health: string;
  externalId: string;
  nationality: string;
  adminNotes: string;
  anonymized: boolean;
  groupsIDs: string[];
  rfidCardIDs: string[];
  personalIDNumber: string;

  @Exclude()
  get fullName(): string {
    let result = "";
    if (this.name)
      result = result + this.name;
    if (this.middlename)
      result = result + ' ' + this.middlename;
    if (this.surname)
      result = result + ' ' + this.surname;
    return result;
  }

  @Exclude()
  get reducedAge(): string {
    var mbirthday = !!this.birthday
      ? moment(this.birthday)
      : null;
    var age = null;
    if (!!mbirthday && mbirthday.isValid()) {
      if ((moment().diff(mbirthday, 'years') < 16) &&
        (moment().diff(mbirthday, 'months') >= 1)) {
        age = mbirthday.toNow(true);
      }
    }

    return age;
  }
}