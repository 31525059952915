import { Type } from "class-transformer";
import { NotRegisteredStudentInfo } from './notRegisteredStudentInfo';

export class NotRegisteredUserInfo {
  id: string;
  name: string;
  surname: string;
  middlename: string;
  address: string;
  email: string;
  phone: string;

  @Type(() => NotRegisteredStudentInfo)
  students: NotRegisteredStudentInfo[];
}