import { Type, Transform } from "class-transformer";

import { InvoiceLineItem } from './invoiceLineItem';
import { InvoiceAmortizationSchedule } from './invoiceAmortizationSchedule';

import { TransformDate } from '../../decorators';

export class Invoice {
    invoiceID: number;
    customerOperatorID: number;
    customerStudentID: number;
    accountingBookID: number;

    @TransformDate()
    dueDate: Date;

    @Type(() => InvoiceLineItem)
    lineItems: InvoiceLineItem[];

    total: number;
    currency: string;
    VAT: number;
    paymentSum: number;
    studentFullName: string;

    @Type(() => InvoiceAmortizationSchedule)
    amortizationSchedules: InvoiceAmortizationSchedule[];
}