<h4 class="text-center">{{'TXT_SELECT_STUDENT' | translate}}</h4>
<div class="wb-under-header-list-wrapper">
<dx-list class="wb-under-header-list" 
  [dataSource]="studentSummary"
  [grouped]="true"
  [keyExpr]="'studentID'"
  [groupTemplate]="'student'"
  [itemTemplate]="'activity'"
  [collapsibleGroups]="true"
  pullRefreshEnabled="true"
  height="100%"
  (onPullRefresh)="onPullRefresh()"
  (onItemClick)="onItemClick($event)">
  <div *dxTemplate="let groupData of 'student'"  class="student-wrapper">
    <h4>
      {{groupData.studentFullName}}
    </h4>
  </div>
  <div *dxTemplate="let itemData of 'activity'" class="activity-wrapper">
    <div class="row">
      <div class="col-xs-10 activity-name">
        <span class="dx-theme-accent-as-text-color">{{itemData.activityName}}</span><span> - ({{itemData.activityStartDate | date:'shortDate'}} - {{itemData.activityEndDate | date:'shortDate'}})</span>
      </div>
      <div class="coll-xs-2 picture-gallery-button">
        <dx-button
            icon="image"
            type="default"
            (onClick)="onPictureGalleryClick()">
        </dx-button>
      </div>
    </div>    
  </div>
</dx-list>
</div>
