import { TransformDate } from '../../decorators';

export class NotRegisteredStudentInfo {
  id: string;
  name: string;
  surname: string;
  middlename: string;

  @TransformDate()
  birthday: Date;

  health: string;
  nationality: string;
}