
import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { MembershipStudent } from '../models/membershipStudent';
import { BaseService } from './base.service';

import * as moment from "moment";





import * as _ from 'lodash';

@Injectable()
export class MembershipStudentService extends BaseService {

  private baseServiceUrl: string;

  private membershipStudents: Observable<MembershipStudent[]>;
  private now: Date;
  private userID: number;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/Api/MembershipStudent/';
  }

  getValidByUser(userID: number, now: Date, refresh: boolean = false): Observable<MembershipStudent[]> {

    var normalizedNow: Date = moment(now).startOf('day').toDate();

    if (
      !this.now ||
      this.now.getTime() !== normalizedNow.getTime() ||
      !this.membershipStudents ||
      this.userID !== userID ||
      refresh) {

      this.now = normalizedNow;
      this.userID = userID;

      let headers = this.getDefaultHttpHeaders();
      let params = new HttpParams()
        .set("userID", userID.toString())
        .append("now", normalizedNow.toISOString());
      let url: string = this.baseServiceUrl + 'GetValidByUser';

      this.membershipStudents = this.http
        .get(url, {
          headers: headers,
          params: params
        }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(MembershipStudent, res)),
        publishReplay(1),
        refCount(),);
    }

    return this.membershipStudents;
  }

}
