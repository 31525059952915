import { Type, Transform, Expose, Exclude } from "class-transformer";

import { Activity } from './activity';

export class ActivitiesBySemester {
    semesterID: number;
    semesterName: string;

    @Type(() => Activity)
    activities: Activity[];
}