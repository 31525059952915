import { ActivitySchedule } from './activitySchedule';
import { ActivityReservation } from './activityReservation';
import { ActivityAttendance } from './activityAttendance';
import { ActivityAttendancePeriodFlags } from './activityAttendancePeriodFlags';
import { ActivityStudentSetting } from './activityStudentSetting';
import { Activity } from './activity';
import { TenantSetting } from '../tenantSetting';

import {
    ActivityAttendanceStatus,
    ExcuseThresholdType,
    ActivityCompensationType,
    CourseAttendanceType
} from '../enums';

import * as moment from "moment";
import * as _ from 'lodash';

export class ActivityAttendanceModel {

  constructor(
    private schedule: ActivitySchedule,
    private reservation: ActivityReservation,
    private attendance: ActivityAttendance,
    private activityStudentSetting: ActivityStudentSetting,
    private activity: Activity,
    private tenantSetting: TenantSetting,
    numberOfExcuses: number,
    studentID: number,
    private momentNow: () => moment.Moment = () => moment()
  ) {
    this.$calculateProperties(studentID, numberOfExcuses);

    this.origAttendance = this.getActivityAttendance(null);
  }

  firstPeriodStartDate: Date;
  firstPeriodEndDate: Date;
  secondPeriodStartDate: Date;
  secondPeriodEndDate: Date;

  firstPeriodAttendanceType: CourseAttendanceType;

  secondPeriodAttendanceType: CourseAttendanceType;

  activityAttendanceID: number;
  studentID: number;
  activityScheduleID: number;
  lunch: boolean;
  firstSnack: boolean;
  secondSnack: boolean;
  notes: string;
  scheduleNotes: string;
  activityID: number;
  studentFullName: string;
  confirmed: boolean;
  firstPeriodFilled: number;
  secondPeriodFilled: number;
  firstPeriodCapacity: number;
  secondPeriodCapacity: number;
  numberOfExcuses: number;

  firstPeriodWaitQueuePosition: number;
  secondPeriodWaitQueuePosition: number;

  $calculateProperties(studentID: number, numberOfExcuses: number, attendance: ActivityAttendance = null, schedule: ActivitySchedule = null) {
    if (attendance) {
      this.attendance = attendance;
    }

    if (schedule) {
      this.schedule = schedule;
    }

    let rdata = this.attendance ||
      this.reservation;

    let data = rdata || this.schedule;

    this.numberOfExcuses = numberOfExcuses;

    this.activityAttendanceID = this.attendance
      ? this.attendance.activityAttendanceID
      : 0;
    this.studentFullName = rdata ? rdata.studentFullName : null;
    this.studentID = rdata ? rdata.studentID : studentID;
    this.activityScheduleID = data.activityScheduleID;
    this.activityID = data.activityID;

    this.firstPeriodStartDate = this.normalizeDate(data.firstPeriodStartDate);
    this.firstPeriodEndDate = this.normalizeDate(data.firstPeriodEndDate);
    this.secondPeriodStartDate = this.normalizeDate(data.secondPeriodStartDate);
    this.secondPeriodEndDate = this.normalizeDate(data.secondPeriodEndDate);
    this.lunch = data.lunch;
    this.firstSnack = data.firstSnack;
    this.secondSnack = data.secondSnack;
    this.notes = rdata ? rdata.notes : null;

    this.scheduleNotes = this.schedule.notes;
    this.confirmed = this.activityAttendanceID !== 0
      ? this.attendance.confirmed
      : false;
    this.firstPeriodFilled = this.schedule.firstPeriodFilled;
    this.secondPeriodFilled = this.schedule.secondPeriodFilled;
    this.firstPeriodCapacity = this.schedule.firstPeriodCapacity;
    this.secondPeriodCapacity = this.schedule.secondPeriodCapacity;

    this.firstPeriodWaitQueuePosition = !!this.attendance
      ? this.attendance.firstPeriodWaitQueuePosition
      : _.findIndex(this.schedule.firstPeriodWaitQueue, ['studentID', this.studentID]) + 1;

    this.secondPeriodWaitQueuePosition = !!this.attendance
      ? this.attendance.secondPeriodWaitQueuePosition
      : _.findIndex(this.schedule.secondPeriodWaitQueue, ['studentID', this.studentID]) + 1;

    if (this.attendance) {
      this._firstPeriodAttendanceStatus = this.attendance.firstPeriodAttendanceStatus;
      this.firstPeriodAttendanceType = this.attendance.firstPeriodAttendanceType;
      this._secondPeriodAttendanceStatus = this.attendance.secondPeriodAttendanceStatus;
      this.secondPeriodAttendanceType = this.attendance.secondPeriodAttendanceType;
    } else if (this.reservation) {
      this._firstPeriodAttendanceStatus = !!this.reservation.firstPeriodStartDate
        ? ActivityAttendanceStatus.Present
        : ActivityAttendanceStatus.NotPresentNotExcused;
      this.firstPeriodAttendanceType = CourseAttendanceType.Registered;
      this._secondPeriodAttendanceStatus = !!this.reservation.secondPeriodStartDate
        ? ActivityAttendanceStatus.Present
        : ActivityAttendanceStatus.NotPresentNotExcused;
      this.secondPeriodAttendanceType = CourseAttendanceType.Registered;
    } else {
      this._firstPeriodAttendanceStatus = ActivityAttendanceStatus.NotPresentNotExcused;
      this.firstPeriodAttendanceType = CourseAttendanceType.Registered;
      this._secondPeriodAttendanceStatus = ActivityAttendanceStatus.NotPresentNotExcused;
      this.secondPeriodAttendanceType = CourseAttendanceType.Registered;
    }
  }

  clone(): ActivityAttendanceModel {
    let newObject = new ActivityAttendanceModel(
      this.schedule,
      this.reservation,
      _.cloneDeep(this.attendance),
      this.activityStudentSetting,
      this.activity,
      this.tenantSetting,
      this.numberOfExcuses,
      this.studentID,
      this.momentNow
    );

    return newObject;
  }

  getActivityAttendance(activitySchedule: ActivitySchedule = null): ActivityAttendance {
    let schedule = activitySchedule || this.schedule;

    let activityAttendance = new ActivityAttendance();

    activityAttendance.studentID = this.studentID;
    activityAttendance.activityAttendanceID = this.activityAttendanceID;
    activityAttendance.activityScheduleID = this.activityScheduleID;
    activityAttendance.activityID = this.activityID;

    if (!this.noReservationFirstPeriod) {
      activityAttendance.firstPeriodStartDate =
        this.deNormalizeDate(this.firstPeriodStartDate, schedule.firstPeriodStartDate);
      activityAttendance.firstPeriodEndDate =
        this.deNormalizeDate(this.firstPeriodEndDate, schedule.firstPeriodEndDate);

      activityAttendance.lunch = this.lunch;
      activityAttendance.firstSnack = this.firstSnack;
    } else {
      activityAttendance.lunch = false;
      activityAttendance.firstSnack = false;
    }

    if (!this.noReservationSecondPeriod) {
      activityAttendance.secondPeriodStartDate =
        this.deNormalizeDate(this.secondPeriodStartDate, schedule.secondPeriodStartDate);
      activityAttendance.secondPeriodEndDate =
        this.deNormalizeDate(this.secondPeriodEndDate, schedule.secondPeriodEndDate);
      activityAttendance.secondSnack = this.secondSnack;
    } else {
      activityAttendance.secondSnack = false;
    }

    activityAttendance.notes = this.notes;
    activityAttendance.confirmed = this.confirmed;

    activityAttendance.firstPeriodAttendanceType = this.firstPeriodAttendanceType;
    activityAttendance.firstPeriodAttendanceStatus = this.firstPeriodAttendanceStatus;
    activityAttendance.secondPeriodAttendanceType = this.secondPeriodAttendanceType;
    activityAttendance.secondPeriodAttendanceStatus = this.secondPeriodAttendanceStatus;

    activityAttendance.firstPeriodWaitQueuePosition = this.firstPeriodWaitQueuePosition;
    activityAttendance.secondPeriodWaitQueuePosition = this.secondPeriodWaitQueuePosition;

    return activityAttendance;
  }

  bookAttendanceByPeriod(activityAttendancePeriod: ActivityAttendancePeriodFlags) {

    this.bookFirstPeriod(activityAttendancePeriod.am, false);

    this.bookSecondPeriod(activityAttendancePeriod.pm, false);
  }

  private _firstPeriodAttendanceStatus: ActivityAttendanceStatus;
  get firstPeriodAttendanceStatus() {
    return this._firstPeriodAttendanceStatus;
  }

  set firstPeriodAttendanceStatus(value: ActivityAttendanceStatus) {
    if (!this.schedule.firstPeriodStartDate) {
      value = ActivityAttendanceStatus.NotPresentNotExcused;
    }

    var valid = true;
    var reserved = !!this.reservation && !!this.reservation.firstPeriodStartDate;

    switch (value) {
      case ActivityAttendanceStatus.Present:
        break;
      case ActivityAttendanceStatus.NotPresentNotExcused:
        break;
      case ActivityAttendanceStatus.NotPresentExcused:
        valid = reserved || this.firstPeriodAttendanceType === CourseAttendanceType.Amend;
        break;
    }

    if (!valid) {
      this.firstPeriodAttendanceType = CourseAttendanceType.Registered;
      value = ActivityAttendanceStatus.NotPresentNotExcused;
    }

    if (value !== ActivityAttendanceStatus.Present) {
      this.firstPeriodStartDate = null;
      this.firstPeriodEndDate = null;
      if (value === ActivityAttendanceStatus.NotPresentExcused) {
        this.lunch = false;
        this.firstSnack = false;

        if (this.firstPeriodAttendanceType === CourseAttendanceType.Amend ||
          (!this.reservation || !this.reservation.firstPeriodStartDate && this.firstPeriodAttendanceType === CourseAttendanceType.Registered)) {
          value = ActivityAttendanceStatus.NotPresentNotExcused;
          this.firstPeriodAttendanceType = CourseAttendanceType.Registered;
        }
      } else {
        if (!reserved) {
          this.lunch = !!(this.attendance && this.attendance.lunch);
          this.firstSnack = !!(this.attendance && this.attendance.firstSnack);
        } else {
          this.lunch = !!this.reservation.lunch;
          this.firstSnack = !!this.reservation.firstSnack;
        }
      }
    } else {
      if ((!this.reservation || !this.reservation.firstPeriodStartDate) && this.firstPeriodAttendanceType === CourseAttendanceType.Registered) {
        this.firstPeriodAttendanceType = CourseAttendanceType.Amend;
      }

      if (!this.firstPeriodStartDate || !this.firstPeriodEndDate) {
        if (this.reservation && !!this.reservation.firstPeriodStartDate) {
          this.firstPeriodStartDate = this.normalizeDate(this.reservation.firstPeriodStartDate);
          this.firstPeriodEndDate = this.normalizeDate(this.reservation.firstPeriodEndDate);
          this.lunch = this.reservation.lunch;
          this.firstSnack = this.reservation.firstSnack;
        }
        if (this.schedule) {
          this.firstPeriodStartDate = this.normalizeDate(this.schedule.firstPeriodStartDate);
          this.firstPeriodEndDate = this.normalizeDate(this.schedule.firstPeriodEndDate);
          this.lunch = this.schedule.lunch;
          this.firstSnack = this.schedule.firstSnack;
        }
      }
    }

    this._firstPeriodAttendanceStatus = value;
  }

  private _secondPeriodAttendanceStatus: ActivityAttendanceStatus;
  get secondPeriodAttendanceStatus(): ActivityAttendanceStatus {
    return this._secondPeriodAttendanceStatus;
  }

  set secondPeriodAttendanceStatus(value: ActivityAttendanceStatus) {
    if (!this.schedule.secondPeriodStartDate) {
      value = ActivityAttendanceStatus.NotPresentNotExcused;
    }

    var valid = true;
    var reserved = !!this.reservation && !!this.reservation.secondPeriodStartDate;

    switch (value) {
      case ActivityAttendanceStatus.Present:
        break;
      case ActivityAttendanceStatus.NotPresentNotExcused:
        break;
      case ActivityAttendanceStatus.NotPresentExcused:
        valid = reserved || this.secondPeriodAttendanceType === CourseAttendanceType.Amend;
        break;
    }

    if (!valid) {
      this.secondPeriodAttendanceType = CourseAttendanceType.Registered;
      value = ActivityAttendanceStatus.NotPresentNotExcused;
    }

    if (value !== ActivityAttendanceStatus.Present) {
      this.secondPeriodStartDate = null;
      this.secondPeriodEndDate = null;
      if (value === ActivityAttendanceStatus.NotPresentExcused) {
        this.secondSnack = false;

        if (this.secondPeriodAttendanceType === CourseAttendanceType.Amend) {
          value = ActivityAttendanceStatus.NotPresentNotExcused;
          this.secondPeriodAttendanceType = CourseAttendanceType.Registered;
        }
      } else {
        if (!reserved) {
          this.secondSnack = !!(this.attendance && this.attendance.secondSnack);
        } else {
          this.secondSnack = !!this.reservation.secondSnack;
        }
      }
    } else {
      if ((!this.reservation || !this.reservation.secondPeriodStartDate) && this.secondPeriodAttendanceType === CourseAttendanceType.Registered) {
        this.secondPeriodAttendanceType = CourseAttendanceType.Amend;
      }

      if (!this.secondPeriodStartDate || !this.secondPeriodEndDate) {
        if (this.reservation && !!this.reservation.secondPeriodStartDate) {
          this.secondPeriodStartDate = this.normalizeDate(this.reservation.secondPeriodStartDate);
          this.secondPeriodEndDate = this.normalizeDate(this.reservation.secondPeriodEndDate);
          this.secondSnack = this.reservation.secondSnack;
        }
        if (this.schedule) {
          this.secondPeriodStartDate = this.normalizeDate(this.schedule.secondPeriodStartDate);
          this.secondPeriodEndDate = this.normalizeDate(this.schedule.secondPeriodEndDate);
          this.secondSnack = this.schedule.secondSnack;
        }
      }
    }

    this._secondPeriodAttendanceStatus = value;
  }

  get firstPeriodActive(): boolean {
    return !!this.schedule.firstPeriodStartDate;
  }

  get secondPeriodActive(): boolean {
    return !!this.schedule.secondPeriodStartDate;
  }

  get startDate(): Date {
    return moment(this.schedule.firstPeriodStartDate || this.schedule.secondPeriodStartDate).startOf('day').toDate();
  }

  get weekNo(): number {
    return moment(this.schedule.firstPeriodStartDate || this.schedule.secondPeriodStartDate).isoWeek();
  }

  get attending(): boolean {
    return ((!!this.reservation || !!this.attendance) &&
        !(!this.reservation && (
          this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
          this.firstPeriodAttendanceType === CourseAttendanceType.Registered &&
          this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
          this.secondPeriodAttendanceType === CourseAttendanceType.Registered))) ||
      (!!this.firstPeriodWaitQueuePosition || !!this.secondPeriodWaitQueuePosition);
  }

  get shouldShowFirstPeriodTypeIDEdit(): boolean {
    return ((this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present &&
          (!this.reservation || !this.reservation.firstPeriodStartDate)) ||
        (this.firstPeriodAttendanceType !== CourseAttendanceType.Registered)) &&
      !this.firstPeriodWaitQueuePosition;
  }

  get shouldShowSecondPeriodTypeIDEdit(): boolean {
    return ((this.secondPeriodAttendanceStatus == ActivityAttendanceStatus.Present &&
          (!this.reservation || !this.reservation.secondPeriodStartDate)) ||
        (this.secondPeriodAttendanceType !== CourseAttendanceType.Registered)) &&
      !this.secondPeriodWaitQueuePosition;
  }

  get canBeDeleted(): boolean {
    return !this.reservation;
  }

  get canBeDeletedWithoutConfirmation(): boolean {
    return !this.reservation && !this.activityAttendanceID;
  }

  get clientEditable(): boolean {
    var now = this.momentNow().subtract(1, 'days').startOf('day');
    var startDate = moment(this.schedule.startDate).startOf('day');

    return !this.confirmed && now.isBefore(startDate);
  }

  get clientExcusable(): boolean {
    return this.clientEditable &&
      ((this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present || this.firstPeriodWaitQueuePosition > 0) ||
        (this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.Present || this.secondPeriodWaitQueuePosition > 0));
  }

  get clientBookable(): boolean {
    if (!this.clientEditable) {
      return false;
    }

    var excused =
      (this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcused ||
        this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcusedNoCompensation ||
        this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcusedWithoutFood) ||
      (this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcused ||
        this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcusedNoCompensation ||
        this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcusedWithoutFood);

    if (excused) {
      return true;
    }

    return this.noReservationFirstPeriod || this.noReservationSecondPeriod;
  }

  get noReservationFirstPeriod(): boolean {
    return (!this.reservation || (!!this.reservation && !this.reservation.firstPeriodStartDate)) &&
      !!this.schedule.firstPeriodStartDate &&
      this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
      this.firstPeriodAttendanceType === CourseAttendanceType.Registered;
  }

  get noReservationSecondPeriod(): boolean {
    return (!this.reservation || (!!this.reservation && !this.reservation.secondPeriodStartDate)) &&
      !!this.schedule.secondPeriodStartDate &&
      this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
      this.secondPeriodAttendanceType === CourseAttendanceType.Registered;
  }

  get firstPeriodFreeSpots(): number {
    return Math.max(this.firstPeriodCapacity - this.firstPeriodFilled, 0);
  }

  get showFirstPeriodFreeSpots(): boolean {
    return this.clientBookable && !this.shouldDisableFirstPeriodBooking;
  }

  get secondPeriodFreeSpots(): number {
    return Math.max(this.secondPeriodCapacity - this.secondPeriodFilled, 0);
  }

  get showSecondPeriodFreeSpots(): boolean {
    return this.clientBookable && !this.shouldDisableSecondPeriodBooking
  }

  get shouldDisableFirstPeriodBooking(): boolean {
    return !this.schedule.firstPeriodStartDate || this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present;
  }

  get shouldDisableSecondPeriodBooking(): boolean {
    return !this.schedule.secondPeriodStartDate || this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.Present;
  }

  get clientBookableCapacityCheck(): boolean {
    return this.clientBookable &&
      ((this.firstPeriodFreeSpots > 0 && !this.shouldDisableFirstPeriodBooking) ||
        (this.secondPeriodFreeSpots > 0 && !this.shouldDisableSecondPeriodBooking));
  }

  get shouldShowFirstPeriodStatus(): boolean {
    return !!this.schedule.firstPeriodStartDate &&
      (
        (!!this.reservation && !!this.reservation.firstPeriodStartDate) ||
        (!!this.attendance &&
          !(this.attendance.firstPeriodAttendanceType === CourseAttendanceType.Registered &&
            this.attendance.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
            (!this.reservation || !this.reservation.firstPeriodStartDate)))
      );
  }

  get shouldShowSecondPeriodStatus(): boolean {
    return !!this.schedule.secondPeriodStartDate &&
      (
        (!!this.reservation && !!this.reservation.secondPeriodStartDate) ||
        (!!this.attendance &&
          !(this.attendance.secondPeriodAttendanceType === CourseAttendanceType.Registered &&
            this.attendance.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
            (!this.reservation || !this.reservation.secondPeriodStartDate)))
      );
  }

  get shouldDisableFirstPeriodExcuse(): boolean {
    return !this.schedule.firstPeriodStartDate ||
      (this.firstPeriodAttendanceStatus !== ActivityAttendanceStatus.Present && !this.firstPeriodWaitQueuePosition);
  }

  get shouldDisableSecondPeriodExcuse(): boolean {
    return !this.schedule.secondPeriodStartDate ||
      (this.secondPeriodAttendanceStatus !== ActivityAttendanceStatus.Present && !this.secondPeriodWaitQueuePosition);
  }

  get isNotRegularAttendance(): boolean {
    return this.attending &&
      (((this.firstPeriodAttendanceStatus !== ActivityAttendanceStatus.Present ||
            this.firstPeriodAttendanceType !== CourseAttendanceType.Registered) &&
          !(this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
            this.firstPeriodAttendanceType === CourseAttendanceType.Registered && this.reservation &&
            !this.reservation.firstPeriodStartDate)) ||
        ((this.secondPeriodAttendanceStatus !== ActivityAttendanceStatus.Present ||
            this.secondPeriodAttendanceType !== CourseAttendanceType.Registered) &&
          !(this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
            this.secondPeriodAttendanceType === CourseAttendanceType.Registered && this.reservation &&
            !this.reservation.secondPeriodStartDate)));
  }

  // This looks suspicious !!!
  // set isDirty(value: boolean) {
  //     this.actualAttendance = this.getActivityAttendance(null);
  // }
  get isDirty(): boolean {
    if (this.confirmed) {
      return false;
    }

    var attendance = this.getActivityAttendance(null);
    return !_.isEqual(attendance, this.origAttendance);
  }

  get hasNewWaitQueue(): boolean {
    let firstPeriodScheduleWaitQueue = this.schedule.firstPeriodWaitQueue || [];
    let secondPeriodScheduleWaitQueue = this.schedule.secondPeriodWaitQueue || [];

    return this.isDirty && (
      (!!this.firstPeriodWaitQueuePosition && !(_.findIndex(firstPeriodScheduleWaitQueue, ['studentID', this.studentID]) + 1)) ||
      (!!this.secondPeriodWaitQueuePosition && !(_.findIndex(secondPeriodScheduleWaitQueue, ['studentID', this.studentID]) + 1))
    );
  }

  get firstPeriodAttending(): boolean {
    return this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present ||
      this.firstPeriodWaitQueuePosition > 0;
  }

  set firstPeriodAttending(value: boolean) {
    if (value === this.firstPeriodAttending) {
      return;
    }

    if (!value) {

      let waitQueueOperation = this.firstPeriodWaitQueuePosition > 0;
      if (waitQueueOperation) {
        this.firstPeriodWaitQueuePosition = 0;
      }

      // Excuse
      if (this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present) {

        if (this.clientExcusable) {
          let resetToOrig = this.origAttendance.firstPeriodAttendanceStatus !== this.firstPeriodAttendanceStatus ||
            this.origAttendance.firstPeriodAttendanceType !== this.firstPeriodAttendanceType;

          let excuseType = resetToOrig
            ? this.origAttendance.firstPeriodAttendanceStatus
            : this.getExcuseType(
              this.deNormalizeDate(this.firstPeriodStartDate, this.schedule.firstPeriodStartDate), this.momentNow().toDate());

          if (!waitQueueOperation) {
            if ((excuseType === ActivityAttendanceStatus.NotPresentExcused ||
                excuseType === ActivityAttendanceStatus.NotPresentExcusedWithoutFood) &&
              (this.firstPeriodAttendanceType === CourseAttendanceType.Amend ||
                this.firstPeriodAttendanceType === CourseAttendanceType.Registered)) {

              switch (this.activityStudentSetting.activityCompensationType) {
                case ActivityCompensationType.OneExcuseForOneCompensation:
                  this.numberOfExcuses = this.numberOfExcuses + 1;
                  break;
                case ActivityCompensationType.TwoExcusesForOneCompensation:
                  this.numberOfExcuses = this.numberOfExcuses + 2;
                  break;
                case ActivityCompensationType.CompensationsDisabled:
                  break;
              }
            }
          }
          this.firstPeriodAttendanceStatus = excuseType;
          this.firstPeriodFilled = waitQueueOperation
            ? this.firstPeriodFilled
            : this.firstPeriodFilled - 1;
          if (resetToOrig) {
            this.firstPeriodAttendanceType = this.origAttendance.firstPeriodAttendanceType;
          }
        }
      }
    } else if (value) {
      if (this.clientBookable) {
        let waitQueueOperation = this.schedule.firstPeriodCapacity <= this.firstPeriodFilled;
        let resetToWqOrigState = false;

        if (waitQueueOperation) {
          let scheduleWaitQueue = this.schedule.firstPeriodWaitQueue || [];

          resetToWqOrigState = _.findIndex(scheduleWaitQueue, ['studentID', this.studentID]) > -1;

          this.firstPeriodWaitQueuePosition =
            (_.findIndex(scheduleWaitQueue, ['studentID', this.studentID]) + 1) || scheduleWaitQueue.length + 1;
        } else {
          if ((this.firstPeriodAttendanceType === CourseAttendanceType.Amend ||
              this.firstPeriodAttendanceType === CourseAttendanceType.Registered) &&
            (this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcused ||
              this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcusedWithoutFood)) {

            switch (this.activityStudentSetting.activityCompensationType) {
              case ActivityCompensationType.OneExcuseForOneCompensation:
                if (this.numberOfExcuses > 0) {
                  this.numberOfExcuses = this.numberOfExcuses - 1;
                } else {
                  return; // We do not have any excuses to reuse, so do not allow to change the attendance
                }
                break;
              case ActivityCompensationType.TwoExcusesForOneCompensation:
                if (this.numberOfExcuses > 1) {
                  this.numberOfExcuses = this.numberOfExcuses - 2;
                } else {
                  return; // We do not have any excuses to reuse, so do not allow to change the attendance
                }
                break;
              case ActivityCompensationType.CompensationsDisabled:
                return; // Compensations are disabled;
            }
          }
        }

        if (!resetToWqOrigState) {
          this.bookFirstPeriod(true, waitQueueOperation);
        } else {
          this.firstPeriodAttendanceType = this.origAttendance.firstPeriodAttendanceType;
          this.firstPeriodAttendanceStatus = this.origAttendance.firstPeriodAttendanceStatus;
          this.firstPeriodStartDate = this.origAttendance.firstPeriodStartDate;
          this.firstPeriodEndDate = this.origAttendance.firstPeriodEndDate;
          this.lunch = this.origAttendance.lunch;
          this.firstSnack = this.origAttendance.firstSnack;
        }
        if (!waitQueueOperation) {
          this.firstPeriodFilled = this.firstPeriodFilled + 1;
        }
      }
    }
  }

  get secondPeriodAttending(): boolean {
    return this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.Present ||
      this.secondPeriodWaitQueuePosition > 0;
  }

  set secondPeriodAttending(value: boolean) {
    if (value === this.secondPeriodAttending) {
      return;
    }

    if (!value) {

      let waitQueueOperation = this.secondPeriodWaitQueuePosition > 0;
      if (waitQueueOperation) {
        this.secondPeriodWaitQueuePosition = 0;
      }

      // Excuse
      if (this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.Present) {

        if (this.clientExcusable) {
          let resetToOrig = this.origAttendance.secondPeriodAttendanceStatus !== this.secondPeriodAttendanceStatus ||
            this.origAttendance.secondPeriodAttendanceType !== this.secondPeriodAttendanceType;

          let excuseType = resetToOrig
            ? this.origAttendance.secondPeriodAttendanceStatus
            : this.getExcuseType(
              this.deNormalizeDate(this.secondPeriodStartDate, this.schedule.secondPeriodStartDate), this.momentNow().toDate());

          if (!waitQueueOperation) {
            if ((excuseType === ActivityAttendanceStatus.NotPresentExcused ||
                excuseType === ActivityAttendanceStatus.NotPresentExcusedWithoutFood) &&
              (this.secondPeriodAttendanceType === CourseAttendanceType.Amend ||
                this.secondPeriodAttendanceType === CourseAttendanceType.Registered)) {

              switch (this.activityStudentSetting.activityCompensationType) {
                case ActivityCompensationType.OneExcuseForOneCompensation:
                  this.numberOfExcuses = this.numberOfExcuses + 1;
                  break;
                case ActivityCompensationType.TwoExcusesForOneCompensation:
                  this.numberOfExcuses = this.numberOfExcuses + 2;
                  break;
                case ActivityCompensationType.CompensationsDisabled:
                  break;
              }
            }
          }
          this.secondPeriodAttendanceStatus = excuseType;
          this.secondPeriodFilled = waitQueueOperation
            ? this.secondPeriodFilled
            : this.secondPeriodFilled - 1;
          if (resetToOrig) {
            this.secondPeriodAttendanceType = this.origAttendance.secondPeriodAttendanceType;
          }
        }
      }
    } else if (value) {
      if (this.clientBookable) {
        let waitQueueOperation = this.schedule.secondPeriodCapacity <= this.secondPeriodFilled;
        let resetToWqOrigState = false;
        if (waitQueueOperation) {
          let scheduleWaitQueue = this.schedule.secondPeriodWaitQueue || [];

          resetToWqOrigState = _.findIndex(scheduleWaitQueue, ['studentID', this.studentID]) > -1;

          this.secondPeriodWaitQueuePosition =
            (_.findIndex(scheduleWaitQueue, ['studentID', this.studentID]) + 1) || scheduleWaitQueue.length + 1;
        } else {
          if (this.schedule.secondPeriodCapacity > this.schedule.secondPeriodFilled) {
            if ((this.secondPeriodAttendanceType === CourseAttendanceType.Amend ||
                this.secondPeriodAttendanceType === CourseAttendanceType.Registered) &&
              (this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcused ||
                this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcusedWithoutFood)) {

              switch (this.activityStudentSetting.activityCompensationType) {
                case ActivityCompensationType.OneExcuseForOneCompensation:
                  if (this.numberOfExcuses > 0) {
                    this.numberOfExcuses = this.numberOfExcuses - 1;
                  } else {
                    return; // We do not have any excuses to reuse, so do not allow to change the attendance
                  }
                  break;
                case ActivityCompensationType.TwoExcusesForOneCompensation:
                  if (this.numberOfExcuses > 1) {
                    this.numberOfExcuses = this.numberOfExcuses - 2;
                  } else {
                    return; // We do not have any excuses to reuse, so do not allow to change the attendance
                  }
                  break;
                case ActivityCompensationType.CompensationsDisabled:
                  return; // Compensations are disabled;
              }
            }
          }
        }
        if (!resetToWqOrigState) {
          this.bookSecondPeriod(true, waitQueueOperation);
        } else {
          this.secondPeriodAttendanceType = this.origAttendance.secondPeriodAttendanceType;
          this.secondPeriodAttendanceStatus = this.origAttendance.secondPeriodAttendanceStatus;
          this.secondPeriodStartDate = this.origAttendance.secondPeriodStartDate;
          this.secondPeriodEndDate = this.origAttendance.secondPeriodEndDate;
          this.secondSnack = this.origAttendance.secondSnack;
        }
        if (!waitQueueOperation) {
          this.secondPeriodFilled = this.secondPeriodFilled + 1;
        }
      }
    }
  }

  get firstPeriodBookable(): boolean {
    return this.firstPeriodClientEditable && this.origAttendance.firstPeriodAttendanceStatus !== ActivityAttendanceStatus.Present;
  }

  get secondPeriodBookable(): boolean {
    return this.secondPeriodClientEditable && this.origAttendance.secondPeriodAttendanceStatus !== ActivityAttendanceStatus.Present;
  }

  get firstPeriodClientEditable(): boolean {

    let reservationNotExcused = !!this.reservation &&
      !!this.reservation.firstPeriodStartDate &&
      !!this.attendance &&
      this.attendance.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused;

    let attendanceNotExcused = !!this.attendance &&
      this.attendance.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
      this.attendance.firstPeriodAttendanceType !== CourseAttendanceType.Registered;

    let ce = this.clientEditable && !reservationNotExcused && !attendanceNotExcused;

    let amendReturnPosibble = true;
    if ((this.firstPeriodAttendanceType === CourseAttendanceType.Amend || this.firstPeriodAttendanceType === CourseAttendanceType.Registered) &&
      (this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcused || this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcusedWithoutFood)) {
      switch (this.activityStudentSetting.activityCompensationType) {
        case ActivityCompensationType.OneExcuseForOneCompensation:
          amendReturnPosibble = this.numberOfExcuses > 0;
          break;
        case ActivityCompensationType.TwoExcusesForOneCompensation:
          amendReturnPosibble = this.numberOfExcuses > 1;
          break;
        case ActivityCompensationType.CompensationsDisabled:
          amendReturnPosibble = false;
          break;
      }
    }

    let enableOneOffs = true;
    if (!this.activity.enableOneOffAttendance &&
      ((this.origAttendance.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
        this.origAttendance.firstPeriodAttendanceType === CourseAttendanceType.Registered &&
        (!this.reservation || !this.reservation.firstPeriodStartDate)))) {

      if (!this.firstPeriodWaitQueuePosition) {
        switch (this.activityStudentSetting.activityCompensationType) {
          case ActivityCompensationType.OneExcuseForOneCompensation:
            enableOneOffs = this.numberOfExcuses > 0 || this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present;
            break;
          case ActivityCompensationType.TwoExcusesForOneCompensation:
            enableOneOffs = this.numberOfExcuses > 1 || this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present;
            break;
          case ActivityCompensationType.CompensationsDisabled:
            enableOneOffs = this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present;
            break;
        }
      }
    }

    let ret = ce && enableOneOffs && amendReturnPosibble;

    return ret;
  }

  get isOneOffAttendanceEnabled(): boolean {
    return this.activity.enableOneOffAttendance;
  }

  get secondPeriodClientEditable(): boolean {

    let reservationNotExcused = !!this.reservation &&
      !!this.reservation.secondPeriodStartDate &&
      !!this.attendance &&
      this.attendance.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused;

    let attendanceNotExcused = !!this.attendance &&
      this.attendance.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
      this.attendance.secondPeriodAttendanceType !== CourseAttendanceType.Registered;

    let ce = this.clientEditable && !reservationNotExcused && !attendanceNotExcused;

    let amendReturnPosibble = true;
    if ((this.secondPeriodAttendanceType === CourseAttendanceType.Amend || this.secondPeriodAttendanceType === CourseAttendanceType.Registered) &&
      (this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcused || this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentExcusedWithoutFood)) {
      switch (this.activityStudentSetting.activityCompensationType) {
        case ActivityCompensationType.OneExcuseForOneCompensation:
          amendReturnPosibble = this.numberOfExcuses > 0;
          break;
        case ActivityCompensationType.TwoExcusesForOneCompensation:
          amendReturnPosibble = this.numberOfExcuses > 1;
          break;
        case ActivityCompensationType.CompensationsDisabled:
          amendReturnPosibble = false;
          break;
      }
    }

    let enableOneOffs = true;
    if (!this.activity.enableOneOffAttendance &&
      ((this.origAttendance.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
        this.origAttendance.secondPeriodAttendanceType === CourseAttendanceType.Registered &&
        (!this.reservation || !this.reservation.secondPeriodStartDate)))) {

      if (!this.secondPeriodWaitQueuePosition) {
        switch (this.activityStudentSetting.activityCompensationType) {
          case ActivityCompensationType.OneExcuseForOneCompensation:
            enableOneOffs = this.numberOfExcuses > 0 || this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.Present;
            break;
          case ActivityCompensationType.TwoExcusesForOneCompensation:
            enableOneOffs = this.numberOfExcuses > 1 || this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.Present;
            break;
          case ActivityCompensationType.CompensationsDisabled:
            enableOneOffs = this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.Present;
            break;
        }
      }
    }

    return ce && enableOneOffs && amendReturnPosibble;
  }

  get hasNewOneOff(): boolean {
    return ((this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.Present && this.firstPeriodAttendanceType === CourseAttendanceType.OneOff) &&
        (this.origAttendance.firstPeriodAttendanceStatus !== ActivityAttendanceStatus.Present || this.origAttendance.firstPeriodAttendanceType !== CourseAttendanceType.OneOff)) ||
      ((this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.Present && this.secondPeriodAttendanceType === CourseAttendanceType.OneOff) &&
        (this.origAttendance.secondPeriodAttendanceStatus !== ActivityAttendanceStatus.Present || this.origAttendance.secondPeriodAttendanceType !== CourseAttendanceType.OneOff));
  }

  private bookFirstPeriod(book: boolean, waitQueueOperation: boolean) {
    let shouldDisableFirstPeriodBooking = !this.schedule.firstPeriodStartDate;
    if (!shouldDisableFirstPeriodBooking) {
      if (!book) {
        // Return to orig state
        this._firstPeriodAttendanceStatus = this.origAttendance.firstPeriodAttendanceStatus;
        this.firstPeriodAttendanceType = this.origAttendance.firstPeriodAttendanceType;
      } else {
        if (this.firstPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
          this.firstPeriodAttendanceType === CourseAttendanceType.Registered &&
          (!this.reservation || !this.reservation.firstPeriodStartDate)) {
          switch (this.activityStudentSetting.activityCompensationType) {
            case ActivityCompensationType.OneExcuseForOneCompensation:
              if (this.numberOfExcuses > 0) {
                this.numberOfExcuses = waitQueueOperation
                  ? this.numberOfExcuses
                  : this.numberOfExcuses - 1;
                this.firstPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
                this.firstPeriodAttendanceType = CourseAttendanceType.Amend;
              } else {
                this.firstPeriodAttendanceType = CourseAttendanceType.OneOff;
                this.firstPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
              }
              break;
            case ActivityCompensationType.TwoExcusesForOneCompensation:
              if (this.numberOfExcuses > 1) {
                this.numberOfExcuses = waitQueueOperation
                  ? this.numberOfExcuses
                  : this.numberOfExcuses - 2;
                this.firstPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
                this.firstPeriodAttendanceType = CourseAttendanceType.Amend;
              } else {
                this.firstPeriodAttendanceType = CourseAttendanceType.OneOff;
                this.firstPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
              }
              break;
            case ActivityCompensationType.CompensationsDisabled:
              this.firstPeriodAttendanceType = CourseAttendanceType.OneOff;
              this.firstPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
              break;
          }
        } else {
          this.firstPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
        }
      }
    }
  }

  private bookSecondPeriod(book: boolean, waitQueueOperation: boolean) {
    let shouldDisableSecondPeriodBooking = !this.schedule.secondPeriodStartDate;
    if (!shouldDisableSecondPeriodBooking) {
      if (!book) {
        // Return to orig state
        this._secondPeriodAttendanceStatus = this.origAttendance.secondPeriodAttendanceStatus;
        this.secondPeriodAttendanceType = this.origAttendance.secondPeriodAttendanceType;
      } else {
        if (this.secondPeriodAttendanceStatus === ActivityAttendanceStatus.NotPresentNotExcused &&
          this.secondPeriodAttendanceType === CourseAttendanceType.Registered &&
          (!this.reservation || !this.reservation.secondPeriodStartDate)) {

          switch (this.activityStudentSetting.activityCompensationType) {
            case ActivityCompensationType.OneExcuseForOneCompensation:
              if (this.numberOfExcuses > 0) {
                this.numberOfExcuses = waitQueueOperation
                  ? this.numberOfExcuses
                  : this.numberOfExcuses - 1;
                this.secondPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
                this.secondPeriodAttendanceType = CourseAttendanceType.Amend;
              } else {
                this.secondPeriodAttendanceType = CourseAttendanceType.OneOff;
                this.secondPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
              }
              break;
            case ActivityCompensationType.TwoExcusesForOneCompensation:
              if (this.numberOfExcuses > 1) {
                this.numberOfExcuses = waitQueueOperation
                  ? this.numberOfExcuses
                  : this.numberOfExcuses - 2;
                this.secondPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
                this.secondPeriodAttendanceType = CourseAttendanceType.Amend;
              } else {
                this.secondPeriodAttendanceType = CourseAttendanceType.OneOff;
                this.secondPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
              }
              break;
            case ActivityCompensationType.CompensationsDisabled:
              this.secondPeriodAttendanceType = CourseAttendanceType.OneOff;
              this.secondPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
              break;
          }
        } else {
          this.secondPeriodAttendanceStatus = ActivityAttendanceStatus.Present;
        }
      }
    }
  }


  private origAttendance: ActivityAttendance;

  private normalizeDate(date: Date): Date {
    if (!date) {
      return date;
    }

    var mvalue = moment(date);
    return new Date(1970, 0, 1, mvalue.hours(), mvalue.minutes());
  }

  private deNormalizeDate(date: Date, scheduleDate: Date): Date {
    if (!date) {
      return date;
    }

    var mvalue = moment(date);
    var mscheduleDate = moment(scheduleDate);
    return new Date(mscheduleDate.year(), mscheduleDate.month(), mscheduleDate.date(), mvalue.hours(), mvalue.minutes());
  }


  private getExcuseType(periodStartDate: Date, now: Date): ActivityAttendanceStatus {

    if (this.isWithinActivityExcuseWithoutFoodTimeLimit(periodStartDate, now)) {
      return ActivityAttendanceStatus.NotPresentExcused;
    }

    if (this.isWithinActivityExcuseTimeLimit(periodStartDate, now)) {
      return ActivityAttendanceStatus.NotPresentExcusedWithoutFood;
    }

    return ActivityAttendanceStatus.NotPresentExcusedNoCompensation;
  }

  private isWithinActivityExcuseWithoutFoodTimeLimit(lessonStartDate: Date, now: Date): boolean {
    return this.isWithinThresholdTimeLimit(
      this.tenantSetting.activityExcuseWithoutFoodThresholdType,
      this.tenantSetting.activityExcuseWithoutFoodThreshold,
      lessonStartDate,
      now);
  }

  private isWithinActivityExcuseTimeLimit(lessonStartDate: Date, now: Date): boolean {
    return this.isWithinThresholdTimeLimit(
      this.tenantSetting.activityExcuseThresholdType,
      this.tenantSetting.activityExcuseThreshold,
      lessonStartDate,
      now);
  }

  private isWithinThresholdTimeLimit(
    thresholdType: ExcuseThresholdType,
    threshold: number,
    lessonStartDate: Date,
    now: Date): boolean {

    let mLessonStartDate = moment(lessonStartDate);
    let mNow = moment(now);

    switch (thresholdType) {
      case ExcuseThresholdType.NoOfHoursBeforeStart:
        return moment.duration(mLessonStartDate.diff(mNow)).asHours() > threshold;
      case ExcuseThresholdType.AtSpecificTimeOnStartDate:
        return mNow.isBefore(mLessonStartDate.startOf('day').add(threshold, 'hours'));
      case ExcuseThresholdType.AtSpecificTimeOnDateBeforeStartDate:
        return mNow.isBefore(mLessonStartDate.startOf('day').add(threshold, 'hours').subtract(1, 'day'));
      case ExcuseThresholdType.AtSpecificTimeOnPrevBusinessDay:
        return mNow.isBefore(mLessonStartDate.startOf('day').add(threshold, 'hours').subtract(
          this.getNoOfDaysToPrevBusinessDay(mLessonStartDate), 'day'));
      case ExcuseThresholdType.AtSpecificTimeOnPrevPrevBusinessDay:
        return mNow.isBefore(mLessonStartDate.startOf('day').add(threshold, 'hours').subtract(
          this.getNoOfDaysToPrevPrevBusinessDay(mLessonStartDate), 'day'));
    }

    return false;
  }

  private getNoOfDaysToPrevBusinessDay(pivot: moment.Moment): number {
    switch (pivot.isoWeekday()) {
      case 1: // Monday
        return 3;
      case 7: // Sunday
        return 2;
      default:
        return 1;
    }
  }

  private getNoOfDaysToPrevPrevBusinessDay(pivot: moment.Moment): number {
    switch (pivot.isoWeekday()) {
      case 1: // Monday
        return 4;
      case 2: // Tuesday
        return 4;
      case 7: // Sunday
        return 3;
      default:
        return 2;
    }
  }

}
