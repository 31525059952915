import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { StudentService, TenantService } from '../../services';
import { Student, User, TenantSetting, TenantSummary } from '../../models';

import { TranslateService } from '@ngx-translate/core'
import { Observable, forkJoin } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-students-edit-detail',
  templateUrl: './students-edit-detail.component.html',
  styleUrls: ['./students-edit-detail.component.css']
})
export class StudentsEditDetailComponent implements OnInit {

  public tenantSetting: TenantSetting;
  public genders = [{
    id: 'M',
    name: 'Muž'
  }, {
    id: 'F',
    name: 'Žena'
  }]

  personalIdPattern: any = /^(\d\d)(\d\d)(\d\d)(\d\d\d\d?)$/;

  constructor(
    private studentService: StudentService,
    private tenantService: TenantService,
    private translateService: TranslateService
  ) { }

  @Input()
  students: Student[];

  @Input()
  user: User;

  ngOnInit() {
    let self = this;

    self.tenantService.getTenantSummary().subscribe(ts => {
      self.tenantSetting = ts.tenantSetting;
    });

    forkJoin(
      this.translateService.get('TXT_MALE'),
      this.translateService.get('TXT_FEMALE')
    ).subscribe((res: string[]) => {
      this.genders[0].name = res[0];
      this.genders[1].name = res[1];

    });
  }

  onAddStudent() {
    let self = this;
    self.studentService.createNew(self.user).subscribe(s => self.students.push(s));
  }

  onDeleteStudent(student: Student) {
    _.pull(this.students, student);
  }

}
