import { Component, OnInit, Input } from '@angular/core';

import {
  CourseUserRegistration,
  CourseRegistration,
  InvoiceAmortizationSchedule
} from '../../models'


import {
  CoursePriceService
} from '../../services';

import * as _ from 'lodash';
import * as moment from "moment";

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.css']
})
export class PaymentSummaryComponent implements OnInit {

  constructor(private coursePriceService: CoursePriceService) { }

  @Input() courseUserRegistration: CourseUserRegistration;
  @Input() courseInterestsSupport: boolean;
  @Input() isAction: boolean;

  showAmortizationSchedule = new Map<number, boolean>();
  amortizationSchedule = new Map<number, InvoiceAmortizationSchedule[]>();

  get courseRegistrations(): CourseRegistration[] {
    if (this.isAction) {
      return _.filter(this.courseUserRegistration.courseRegistrations, {'attend': true, 'isModified': true, 'isNew': true});
    } else {
      return _.filter(this.courseUserRegistration.courseRegistrations, {'attend': true, 'isModified': true});
    }
  }

  ngOnInit() {
  }

  onShowAmortizationSchedule(registration: CourseRegistration, e: any) {
    this.showAmortizationSchedule[registration.studentID] = true;

    // Calculate schedule
    let now = moment().startOf('day').toDate();

    this.amortizationSchedule[registration.studentID] = this.coursePriceService.calculateAmortizationSchedules(
      registration.invoice.total,
      now,
      registration.invoice.$course.amortizationSchedules);
  }

  onHideAmortizationSchedule(registration: CourseRegistration, e: any) {
    this.showAmortizationSchedule[registration.studentID] = false;
  }

}
