<dx-text-box class="wb-searchbox"
  mode="search"
  placeholder="{{'TXT_SEARCH_PLACEHOLDER' | translate}}"
  [showClearButton]="true"
  (onValueChanged)="searchValueChanged($event)"
  value=""></dx-text-box>
<dx-list class="wb-under-search-list"
  [dataSource]="actions"
  pullRefreshEnabled="true"
  (onPullRefresh)="onPullRefresh()"
  (onItemClick)="onItemClick($event)">
  <div *dxTemplate="let itemData of 'item'" >
    <h4 class="wordwrap">
      <span class="course-type-img">
        <img src="{{itemData.courseTypePicture}}" />
      </span>
      {{itemData.subjectName}}
    </h4>
    <app-full-course-stamp
      *ngIf="itemData.capacity <= itemData.filled"
      stampSize="medium"
      [allowBooking]="false"></app-full-course-stamp
      >
    <div class="row dx-theme-accent-as-text-color">
      <div class="col-xs-5">{{itemData.firstLesson | date:'EE dd.MM. HH:mm' | uppercase }}</div>
      <div class="col-xs-4">{{itemData.price | wbcurrency}}</div>
      <div class="col-xs-3 text-right">{{ Math.max(itemData.capacity - itemData.filled, 0) }} ({{itemData.capacity}})</div>
    </div>
    <div>{{itemData.name}}</div>
    <p class="text-justify wordwrap">{{itemData.shortDescription}}</p>
  </div>
</dx-list>