
import {filter,  publishReplay, catchError, map, refCount, flatMap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { plainToClass } from "class-transformer";

import { ActivitySummary, ActivityStudentSummary, ActivitiesBySemester, Activity } from '../models';
import { BaseService } from './base.service';

import { TranslateService } from '@ngx-translate/core'
import { notifySuccess } from './notifications';

import * as _ from 'lodash';
import {PhotoAlbumInfo} from "../models/activities";
import UploadInfo from "devextreme/file_management/upload_info";
import {throwError as observableThrowError} from "rxjs/internal/observable/throwError";
import * as moment from "moment";


@Injectable()
export class ActivityService extends BaseService {

  private baseServiceUrl: string;
  private currentSummary: Observable<ActivityStudentSummary[]>;

  private currentActivity: Observable<Activity>;
  private currentActivityID: number;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService) {

    super();
    this.baseServiceUrl = '/Api/Activities/';
  }

  get(activityID: number, refresh: boolean = false): Observable<Activity> {

    if (!this.currentActivity || this.currentActivityID !== activityID || refresh) {

      this.currentActivityID = activityID;

      let headers = this.getDefaultHttpHeaders();
      let url: string = this.baseServiceUrl + 'Get';
      let params = new HttpParams()
        .set("activityID", activityID.toString());

      this.currentActivity = this.http
        .get(url, {headers: headers, params: params}).pipe(
          catchError((err: HttpErrorResponse, c) => this.handleErrorAndThrow(err)),
          map((res: Object) => plainToClass(Activity, res)),
          publishReplay(1),
          refCount());
    }

    return this.currentActivity;
  }

  getSummary(refresh: boolean = false): Observable<ActivityStudentSummary[]> {
    if (!this.currentSummary || refresh) {
      let headers = this.getDefaultHttpHeaders();
      let url: string = this.baseServiceUrl + 'GetSummary';

      this.currentSummary = this.http
        .get(url, {headers: headers}).pipe(
          catchError((err: HttpErrorResponse, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(ActivityStudentSummary, res)),
          publishReplay(1),
          refCount());
    }

    return this.currentSummary;
  }

  getSummaryForStudent(studentID: number, refresh: boolean = false)
    : Observable<ActivitySummary[]> {

    return this.getSummary(refresh)
      .pipe(flatMap(r => r)).pipe(
        filter(r => r.studentID === studentID))
      .pipe(map(r => r.items))
  }

  getSummaryForStudentAndActivity(studentID: number, activityID: number, refresh: boolean = false)
    : Observable<ActivitySummary> {

    return this.getSummaryForStudent(studentID, refresh)
      .pipe(flatMap(r => r)).pipe(
        filter(r => r.activityID === activityID));
  }

  getAllOpenForRegistration(): Observable<ActivitiesBySemester[]> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'GetAllOpenForRegistration';

    return this.http
      .get(url, {headers: headers}).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(ActivitiesBySemester, res)));
  }

  registerActivityInterest(semesterID: number): Observable<Object> {
    let url: string = this.baseServiceUrl + 'registerActivityInterest';

    let headers = this.getDefaultHttpHeaders();

    return new Observable<Object>(observer => {
      this.http
        .post(url, {
          semesterId: semesterID.toString()
        }, {headers: headers})
        .subscribe(r => {
          this.translateService.get('TXT_ACTIVITY_INTEREST_REQUESTED').subscribe((res: string) => {
            notifySuccess(res);
          });

          observer.next(r);
          observer.complete();

        }, err => observer.error(this.handleError(err)))

    })
  }

  getPhotoAlbum(studentID: number = null, activityID: number): Observable<PhotoAlbumInfo[]> {

    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'GetPhotoAlbum';
    let params = new HttpParams()
      .set("activityID", activityID.toString());

    if (studentID != null) {
      params = params.append("studentID", studentID.toString());
    }

    return this.http
      .get(url, {headers: headers, params: params}).pipe(
        catchError((err: HttpErrorResponse, c) => this.handleErrorAndThrow(err)),
        map((res: Object) => <PhotoAlbumInfo[]>res));
  }

  createPhotoAlbumUploadUrl(activityID: number, objectUri: string, contentType: string): Observable<Object>
  {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'CreateUploadUrl';

    return new Observable<Object>(observer => {
      this.http
        .post(url, {
          activityId: activityID,
          objectUri: objectUri,
          contentType: contentType
        }, {headers: headers})
        .subscribe(r => {
          observer.next(r);
          observer.complete();
        }, err => observer.error(this.handleError(err)))
    });
  }

  uploadPhotoChunkIntoPhotoAlbumImpl(file:File, uploadInfo:UploadInfo): Observable<Object> {
      let headers = new HttpHeaders()
        .set('Content-Type', file.type)
        //.append('Content-Length', uploadInfo.chunkBlob.size + "")
        .append('Content-Range', "bytes " + uploadInfo.bytesUploaded + "-" + (uploadInfo.bytesUploaded + uploadInfo.chunkBlob.size - 1) + "/" + file.size);

      let url: string = uploadInfo.customData.accessUrl;

      return this.http
        .put(url, uploadInfo.chunkBlob, {headers: headers})
        .pipe(
          catchError((err: HttpErrorResponse, c) => {
            if (err.status === 308) {
              return c;
            } else {
              return observableThrowError(this.handleError(err));
            }
          }));
  }

  uploadPhotoChunkIntoPhotoAlbum(activityID: number, file:File, uploadInfo:UploadInfo, directoryPath: string): Promise<any> {
    return new Promise(function (resolve, reject) {
      if (uploadInfo.chunkIndex === 0) {
        let objectUri = `${directoryPath}/${file.name}`;
        this.createPhotoAlbumUploadUrl(activityID, objectUri, file.type)
          .subscribe(o => {
            uploadInfo.customData.accessUrl = o.url;
            this.uploadPhotoChunkIntoPhotoAlbumImpl(file, uploadInfo)
              .subscribe(o => resolve(o), e => reject(e));
          }, err => reject(err));
      } else {
        this.uploadPhotoChunkIntoPhotoAlbumImpl(file, uploadInfo)
          .subscribe(o => resolve(o), e => reject(e));
      }
    }.bind(this));
  }

  createPhotoAlbumGalleryWithInfo(activityID: number, galleryDirectoryPath: string, galleryDescription: string, galleryCreatedAtUtc: Date)
  {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'CreateGalleryWithInfo';

    return new Observable<Object>(observer => {
      this.http
        .put(url, {
          activityId: activityID,
          galleryPath: galleryDirectoryPath,
          galleryDescription: galleryDescription,
          galleryCreatedAtUtc: moment(galleryCreatedAtUtc).utc().format()
        }, {headers: headers})
        .subscribe(r => {
          observer.next(r);
          observer.complete();
        }, err => observer.error(this.handleError(err)))
    });
  }
}

