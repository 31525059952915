import { Component, OnInit, Input } from '@angular/core';
import { forkJoin } from 'rxjs';

import { ReferenceSourceService, TenantService } from '../../../shared/services';

import { TenantSetting, NotRegisteredUserInfo, ReferenceSource, TenantSummary } from '../../models';

import { TranslateService } from '@ngx-translate/core'

import * as _ from 'lodash';

@Component({
  selector: 'app-not-registered-user-edit',
  templateUrl: './not-registered-user-edit.component.html',
  styleUrls: ['./not-registered-user-edit.component.css']
})
export class NotRegisteredUserEditComponent implements OnInit {

  public isLoggedIn: boolean = false;
  public tenantSetting: TenantSetting;
  public referenceSourcesPlaceholder: string;
  public isReferenceSourceMandatory: boolean = false;
  public referenceSources: ReferenceSource[] = [];

  constructor(
    private tenantService: TenantService,
    private translateService: TranslateService,
    private referenceSourceService: ReferenceSourceService,
  ) { }

  @Input()
  userInfo: NotRegisteredUserInfo;

  @Input()
  displayStudentEdit: boolean;

  phoneValidationRegEx: any = /\d{3}\d{3}\d{3}\d{3}/;

  ngOnInit() {
    let self = this;

    self.translateService.get('TXT_PLACEHOLDER_REFERENCE_SOURCE').subscribe(res =>
      self.referenceSourcesPlaceholder = res);

    forkJoin([
      self.referenceSourceService.getAll(),
      self.tenantService.getTenantSummary()]
    ).subscribe((res: [ReferenceSource[], TenantSummary]) => {
      self.referenceSources = res[0];

      self.isLoggedIn = res[1].loggedInUser.isAuthenticated;
      self.tenantSetting = res[1].tenantSetting;

      self.isReferenceSourceMandatory =
        self.tenantSetting.allowReferenceSource == 1 &&
        self.referenceSources.length > 0;
    });
  }

}
