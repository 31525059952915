<div class="component-base">
  <dx-lookup 
    class="activity-lookup"
    [dataSource]="activities"
    valueExpr="activityID"
    displayExpr="activityName"
    fullScreen="true"
    [(value)]="activityID"
    itemTemplate="template"
    [searchEnabled]="true"
    [searchExpr]="['activityName']"
    (onPullRefresh)="onPullActivityRefresh()">    
    <div *dxTemplate="let item of 'template'">
      <h5>{{item.activityName}}</h5>
      <div>{{item.activityStartDate | date:'shortDate' }} - {{item.activityEndDate | date:'shortDate' }}</div>
    </div>    
  </dx-lookup>
  <dx-calendar
    class="calendar"
    width="100%"  
    height="250px" 
    cellTemplate="template"       
    [(value)]="selectedDate">
    <div *dxTemplate="let item of 'template'">
      <span [style.font-weight]="getDayStyleWeight(item.date, item.text, item.view)">{{item.text}}</span>
    </div>    
  </dx-calendar>
</div>
<dx-load-panel [visible]="isLoadPanelVisible">
</dx-load-panel>