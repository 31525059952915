import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { DomainService } from '../../services';
import { Domain, DomainSelection } from '../../models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import * as _ from 'lodash';

@Component({
  selector: 'app-domain-select',
  templateUrl: './domain-select.component.html',
  styleUrls: ['./domain-select.component.css']
})
export class DomainSelectComponent implements OnInit {

  constructor(
    private domainsService: DomainService) { }

  domains: DataSource;
  dataLoaded: boolean = false;

  @Output() onClick = new EventEmitter<DomainSelection>();

  ngOnInit() {
    let self = this;

    self.domains = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.domainsService.getAll(false)
              .subscribe((data: Domain[]) => {
                self.dataLoaded = true;

                // No need to alter data in any way
                if (data.length === 1 && data[0].specializations.length === 1) {
                  self.onClick.emit({
                    domain: data[0],
                    isAutoRedirect: true
                  });
                }
                resolve(data);
              }, (error) => {
                reject(error);
              });
          })
        }
      })
    });
  }

  onRowPrepared(e) {
    if (e.rowType == "data" && e.node.level == 0 && e.data.disabledForClients) {
      e.rowElement.style.color = "silver";
    }
  }

  onSelectionChanged(params) {
    let self = this;
    let emitValue: DomainSelection;

    if (params.selectedRowsData[0].parentId === 0) { // Domain level

      if (params.selectedRowsData[0].disabledForClients)
      {
        return;
      }

      emitValue = {
        domain: {
          id: params.selectedRowsData[0].id,
          name: params.selectedRowsData[0].name,
          specializations: [],
          disabledForClients: false
        },
        isAutoRedirect: false
      };
      setTimeout(() => {
        this.onClick.emit(emitValue);
      }, 200);
    } else {

      if (params.selectedRowsData[0].disabledForClients)
      {
        return;
      }

      self.domainsService.getAll(false)
        .subscribe((data: Domain[]) => {
          let domain = _.find(data, { 'id': params.selectedRowsData[0].parentId });

          emitValue = {
            domain: {
              id: domain.id,
              name: domain.name,
              specializations: [{
                id: params.selectedRowsData[0].id,
                name: params.selectedRowsData[0].name,
                disabledForClients: false
              }],
              disabledForClients: false
            },
            isAutoRedirect: false
          }

          setTimeout(() => {
            this.onClick.emit(emitValue);
          }, 200);
        });
    }
  }

}
