import { CourseAttendanceStatus } from './enums/courseAttendanceStatus';
import { CourseAttendanceType } from './enums/courseAttendanceType';

export class Attendance {
    studentID: number;
    courseID: number;
    courseScheduleID: number;
    fullName: string;
    phone1: string;
    birthday: string;
    arrear: string;
    attendanceStatus: CourseAttendanceStatus;
    attendanceStatusStr: string;
    attendanceType: CourseAttendanceType;
    attendanceTypeStr: string;
    modified: boolean;
    note: string;
    compensationCourseID: number;
    noOfRegisteredAttendances: number;
    currentAttendanceNo: number;
    attendanceCount: number;
    enableOneOffAttendance: boolean;
}