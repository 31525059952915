<dx-text-box class="wb-searchbox"
  mode="search"
  placeholder="{{'TXT_SEARCH_PLACEHOLDER' | translate}}"
  [showClearButton]="true"
  (onValueChanged)="searchValueChanged($event)"
  value=""></dx-text-box>
<dx-list class="wb-under-search-list"
  [dataSource]="courses"
  pullRefreshEnabled="true"
  (onPullRefresh)="onPullRefresh()"
  (onItemClick)="onItemClick($event)">
  <div *dxTemplate="let itemData of 'item'; let i = index" class="course-item" [ngClass]="{ 'course-item-first': i == 0 }" >
    <h4 class="wordwrap">
      <span class="course-type-img">
        <img src="{{itemData.courseTypePicture}}" />
      </span>
      {{itemData.subjectName}}
    </h4>
    <h5 class="wordwrap"><span *ngIf="itemData.abbreviation">{{itemData.abbreviation}}&nbsp;</span>{{itemData.name}}</h5>
    <app-full-course-stamp 
      *ngIf="itemData.capacity !== 0 && itemData.isFull && !tenantSettings.courseInterestsSupport && !tenantSettings.coursePreviewOnlyRegistration" 
      stampSize="medium"
      [allowBooking]="tenantSettings.enableFullCourseInterest"> 
    </app-full-course-stamp>    
    <div class="row dx-theme-accent-as-text-color">
      <div class="col-xs-5">
        <span *ngIf="tenantSettings.showScheduleInCourses && tenantSettings.showDateInClientCourses">{{itemData.firstLesson | date:'dd.MM.yy EE HH:mm' | uppercase }}</span>
        <span *ngIf="tenantSettings.showScheduleInCourses && !tenantSettings.showDateInClientCourses">{{itemData.firstLesson | date:'EE HH:mm' | uppercase }}</span>
      </div>
      <div class="col-xs-4" *ngIf="tenantSettings.useTotalPriceForCourses && tenantSettings.showPriceInCourseRegistration" >{{itemData.remainingPrice | wbcurrency}}</div>
      <div class="col-xs-4" *ngIf="!tenantSettings.useTotalPriceForCourses && tenantSettings.showPriceInCourseRegistration" >{{itemData.price | wbcurrency}} / {{'TXT_LESSON' | translate}}</div>
      <div class="col-xs-4" *ngIf="!tenantSettings.showPriceInCourseRegistration" ></div>
      <div class="col-xs-3 text-right" *ngIf="!tenantSettings.showLessonsRemaining">{{ itemData.noOfFreePlaces }} ({{itemData.capacity}})</div>
      <div class="col-xs-3 text-right" *ngIf="tenantSettings.showLessonsRemaining">{{ itemData.noOfRemainingLessons }}</div>
    </div>
    <div class="row">
      <div class="col-xs-3">{{'TXT_TEACHER'| translate}}:</div>
      <div class="col-xs-9">{{itemData.teacher}}</div>      
    </div>
    <div class="row" *ngIf="itemData.classroomID">
      <div class="col-xs-3">{{'TXT_PLACE'| translate}}:</div>
      <div class="col-xs-9">{{itemData.classroomID | classroom }}</div>
    </div>
    <div class="row" *ngIf="tenantSettings.showAgeRangesInCourses">
      <div class="col-xs-3">{{'TXT_AGE'| translate}}:</div>
      <div class="col-xs-9">{{itemData.courseAgeRangeID | ageRange }}</div>
    </div>
  </div>
</dx-list>