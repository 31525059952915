export enum InvoiceLineType {
    CourseFee = 1,
    Discount = 2,
    Membership = 3,
    ManualAdjustment = 4,
    ActivityFee = 5,
    ActivityOneOffFee = 6,
    ActivityLunch = 7,
    ActivitySnack = 8,
    ActivityLatePickup = 9,
    ActivityFeeReccuring = 10
}