import { Type, Transform } from "class-transformer";
import { TransformDate } from '../decorators';

export class OnlinePayment {
    onlinePaymentID: number;
    transactionID: number;
    invoiceID: number;
    paymentID: number;
    price: number;
    currency: string;
    description: string;
    productName: string;

    state: number;
    userID: number;
    userEmail: string;
    redirectUrl: string;
    returnToUrl: string;
    resultCode: string;
    resultMessage: string;
    language: string;

    providerType: number;
    canRequestStatus: boolean;

    createdAt: Date;
    updatedAt: Date;
    isTesting: boolean;

    parentFullName: string;
    studentFullName: string;
    parentPhone: string;
    invoiceNo: number;
    accountingBookID: number;
}

