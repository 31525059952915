
import {publishReplay, catchError, map, refCount} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { UserSchedule, UserScheduleDetail } from '../models';
import { BaseService } from './base.service';

import { Constants } from '../constants';

import { TranslateService } from '@ngx-translate/core';
import { notifySuccess } from './notifications';





@Injectable()
export class UserScheduleService extends BaseService {

  private baseServiceUrl: string;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService) {

    super();
    this.baseServiceUrl = '/Api/UserSchedule/';
  }

  getAllForSemester(semesterID: number, userID: number, compensationGroupID: number, startDate: Date, endDate: Date)
    : Observable<UserSchedule[]> {

    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("semesterID", semesterID.toString())
      .append("userID", userID.toString())
      .append("startDate", startDate.toISOString())
      .append("endDate", endDate.toISOString())
    if (compensationGroupID) {
      params = params.append("compensationGroupID", compensationGroupID.toString());
    }
    let url: string = this.baseServiceUrl + 'GetAllForSemester';

    return this.http
      .get(url, { headers: headers, params: params }).pipe(
      catchError((err, c) => this.handleErrorAndThrow(err)),
      map((res: Object[]) => plainToClass(UserSchedule, res)),
      publishReplay(1),
      refCount(),);
  }

  getUserScheduleDetail(courseScheduleID: number, userID: number)
    : Observable<UserScheduleDetail> {

    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("courseScheduleID", courseScheduleID.toString())
      .append("userID", userID.toString());
    let url: string = this.baseServiceUrl + 'GetUserScheduleDetail';

    return this.http
      .get(url, { headers: headers, params: params }).pipe(
      catchError((err, c) => this.handleErrorAndThrow(err)),
      map((res: Object) => {
        let result = plainToClass<UserScheduleDetail, Object>(UserScheduleDetail, res);
        result.initializeModel();
        return result;
      }),)
  }

  setNewAttendance(
    userID: number,
    courseScheduleID: number,
    presentStudentsIDs: number[])
    : Observable<Object> {

    let self = this;

    let url: string = this.baseServiceUrl + 'SetNewAttendance';
    let headers = this.getDefaultHttpHeaders();

    return new Observable<Object>(observer => {
      self.http.post(url, {
        userID: userID.toString(),
        courseScheduleID: courseScheduleID.toString(),
        studentIDs: presentStudentsIDs
      }, {
          headers: headers
        })
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            observer.next(value);
            observer.complete();
          },
          err => observer.error(self.handleError(err)))
    })

  }

}
