import { TransformDate } from '../../decorators';

export class PlaceEmployeesAvailabilityPeriod {

  @TransformDate()
  start: Date;

  @TransformDate()
  end: Date;

  employeeIds: string[];
}