<dx-load-panel #loadPanel
  [(visible)]="loading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  closeOnOutsideClick="false">
</dx-load-panel>
<dx-load-panel [(visible)]="saving"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  message="{{'TXT_SAVING' | translate}}"
  closeOnOutsideClick="false">
</dx-load-panel>
<app-request-login-action-sheet [(visible)]="loginAdvertDisplayed"
                                [(signInVisible)]="signInVisible"
  (onCancelClick)="onLoginAdvertCancel($event)"></app-request-login-action-sheet>
<dx-scroll-view>
  <form class="form">
    <dx-gallery #gallery
      id="gallery"
      [dataSource]="pictureUrls"
      [width]="'100%'"
      [height]="'auto'"
      [loop]="true"
      [showNavButtons]="true"
      [animationEnabled]="true"
      [showIndicator]="true"></dx-gallery>
    <dx-validation-group>
      <div class="dx-fieldset wb-fieldset-vertical">
        <div class="dx-field-label wb-filed-label-large dx-theme-accent-as-text-color">{{formDefinition?.name}}</div>
        <div class="wb-field-label-description"
          *ngIf="formDefinition?.descriptionHtml"
          [innerHtml]="formDefinition?.descriptionHtml"></div>

        <div *ngIf="isLoggedIn && formDefinition?.showFullEditableUserDetail"
        class="user-full-detail-edit">
          <div class="dx-fieldset-header">{{'TXT_CONTACT' | translate}}</div>
          <app-user-account-detail
            [user]="user"
            [showFieldsetHeaders]="false"
            [disableEmailEdit]="true">
          </app-user-account-detail>
          <div style="margin-top: 20px" ></div>
          <app-user-contact-edit-detail
            [user]="user"
            [showNonUserInfo]="false"
            [showContactFieldsetHeader]="false">
          </app-user-contact-edit-detail>
        </div>

        <div *ngIf="isLoggedIn && (formDefinition?.accessPermissions == 0 || formDefinition?.accessPermissions == 2)"
          class="wb-select-student">
          <div *ngIf="!students || students.length == 0" class="bg-danger text-message wb-button-margin padded-content">
            {{'TXT_NO_STUDENTS_ERROR' | translate}}
            <div class="text-center padded-content">
              <dx-button text="{{'TXT_ADD_STUDENTS' | translate}}"
                         class="wb-button"
                         type="danger"
                         (onClick)="navigateToStudents()">
              </dx-button>
            </div>
          </div>
          <ng-container *ngIf="!!students && students.length > 0">
            <div class="dx-field-label wb-filed-label-large">{{'TXT_SELECT_STUDENT' | translate}}</div>
            <dx-radio-group
              [items]="students"
              [(value)]="formResponse.studentId"
              [displayExpr]="'fullName'"
              [valueExpr]="'id'"
              (onValueChanged)="onSelectedStudentChanged($event)">
              <dx-validator>
                <dxi-validation-rule type="required"
                  message="{{'TXT_SELECT_EXACTLY_ONE_VALUE' | translate}}"></dxi-validation-rule>
              </dx-validator>
            </dx-radio-group>
          </ng-container>
          <div *ngIf="isLoggedIn && formDefinition?.showFullEditableStudentDetail && selectedStudent"
            style="margin-top: 20px" ></div>
          <app-student-edit-detail
            *ngIf="isLoggedIn && formDefinition?.showFullEditableStudentDetail && selectedStudent"
            [student]="selectedStudent">

          </app-student-edit-detail>
        </div>

        <div *ngIf="!isLoggedIn">
          <div class="text-center wb-button-vertical">
            <dx-button text="{{'TXT_ALREADY_HAVE_AN_ACCOUNT_LOGIN' | translate}}"
              type="success"
              width="300"
              (onClick)="onLoginButtonClick($event)">
            </dx-button>
            <dx-button text="{{'TXT_FILL_WITHOUT_REGISTRATION' | translate}}"
              type="default"
              width="300">
            </dx-button>
          </div>
        </div>
        <app-not-registered-user-edit *ngIf="!isLoggedIn && formDefinition?.accessPermissions != 0 && formDefinition?.accessPermissions != 3"
          [userInfo]="formResponse.notRegisteredUserInfo"
          [displayStudentEdit]="formDefinition?.accessPermissions == 2"></app-not-registered-user-edit>


        <div *ngFor="let item of formQuestionsWithAnswers"
          class="dx-field wb-form-question">
          <div class="dx-field-label wb-filed-label-large">{{item.question.name}}</div>
          <div class="wb-field-label-description"
            *ngIf="item.question.descriptionHtml"
            [innerHtml]="item.question.descriptionHtml"></div>

          <app-form-question-edit class="dx-field-value"
            [question]="item.question"
            [answer]="item.answer"></app-form-question-edit>

        </div>
      </div>
      <dx-validation-summary></dx-validation-summary>
      <div class="wb-button-margin text-center">
        <dx-button text="{{'TXT_SUBMIT' | translate}}"
          icon="check"
          type="success"
          (onClick)="onSubmitResponse($event)">
        </dx-button>
      </div>
    </dx-validation-group>

  </form>
</dx-scroll-view>

<dx-popup showTitle="true"
  title="{{'TXT_INFORMATION' | translate}}"
  fullScreen="true"
  showCloseButton="false"
  [(visible)]="confirmResponseCreatedVisible">
  <div *dxTemplate="let data of 'content'">
    <p class="message-box-message">{{'TXT_FORM_RESPONSE_SAVED' | translate}}</p>
    <p *ngIf="emailSendingEnabled"
      class="message-box-message">{{'TXT_FORM_EMAIL_CONFIRM_SENT' | translate}}</p>
    <div class="text-center">
      <dx-button text="{{'TXT_OK' | translate}}"
        class="wb-button"
        type="success"
        (onClick)="confirmResponseCreatedOkClick($event)">
      </dx-button>
    </div>
  </div>
</dx-popup>
