<dx-scroll-view>
  <div *ngIf="registerDescription && registerDescription[1]"
    style="line-height: 1.2em; margin-top: 10px;">{{registerDescription[1]}}</div>
  <div *ngIf="registerDescription && registerDescription[0]"
    style="line-height: 1.2em; margin-top: 10px;">{{registerDescription[0]}}</div>
  <app-payment-summary
    [courseUserRegistration]="courseUserRegistration"
    [courseInterestsSupport]="false"
    [isAction]="true">
  </app-payment-summary>

  <table *ngIf="showAgreementSection"
    class="agreement-table">
    <tr *ngIf="!!requireTermsAndConditionsAgreement" >
      <td class="agreement-switch">
        <dx-switch [(value)]="requireTermsAndConditionsAgreementConfirmed"></dx-switch>
      </td>
      <td class="agreement-text dx-button-mode-text"
        [ngClass]="{'dx-button-danger' : !requireTermsAndConditionsAgreementConfirmed, 'dx-button-success': requireTermsAndConditionsAgreementConfirmed}">
        {{'TXT_I_AGREE_WITH' | translate}}<a href="html-content/0" target="_blank">{{'TXT_TERMS_AND_CONDITIONS_2' | translate}}</a>.
      </td>
    </tr>

    <tr *ngIf="!!requireTradeTermsAgreement" >
      <td class="agreement-switch">
        <dx-switch [(value)]="requireTradeTermsAgreementConfirmed"></dx-switch>
      </td>
      <td class="agreement-text dx-button-mode-text"
        [ngClass]="{'dx-button-danger' : !requireTradeTermsAgreementConfirmed, 'dx-button-success': requireTradeTermsAgreementConfirmed}">
        {{'TXT_I_AGREE_WITH' | translate}}<a href="html-content/2" target="_blank">{{'TXT_TRADE_TERMS_2' | translate}}</a>.
      </td>
    </tr>
  </table>

  <div class="row"
    style="margin-top: 30px; margin-bottom: 10px;">
    <div class="col-xs-6 text-right">
      <dx-button text="{{'TXT_NO' | translate}}"
        class="wb-button"
        type="normal"
        (onClick)="onNoClick($event)">
      </dx-button>
    </div>
    <div class="col-xs-6 text-left">
      <dx-button text="{{'TXT_YES' | translate}}"
        class="wb-button"
        type="success"
        (onClick)="onYesClick($event)">
      </dx-button>
    </div>
  </div>
</dx-scroll-view>

<dx-popup showTitle="true"
  title="{{'TXT_INFORMATION' | translate}}"
  [maxHeight]="160"
  [(visible)]="confirmVisible">
  <div *dxTemplate="let data of 'content'">
    <p>{{'TXT_CONFIRM_AGREEMENT' | translate}}</p>
    <div class="text-center">
      <dx-button text="{{'TXT_OK' | translate}}"
        class="wb-button"
        type="success"
        (onClick)="onInformationConfirm($event)">
      </dx-button>
    </div>
  </div>
</dx-popup>
