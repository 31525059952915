import { Type, Transform, Exclude } from "class-transformer";
import { TransformDate } from '../decorators';

export class Consent {
    consentID: number;
    operatorID: number;
    consentDefinitionID: number;

    @TransformDate()
    consentRequestSentAt: Date;

    @TransformDate()
    consentRatifiedAt: Date;
    consentRatifiedBy: number;

    @TransformDate()
    consentRevokedAt: Date;
    consentRevokedBy: number;

    @TransformDate()
    validFrom: Date;

    @TransformDate()
    validTo: Date;

    notes: string;
    consentName: string;
    consentText: string;
    consentRatifiedByFullName: string;
    consentRevokedByFullName: string;

    @Exclude()
    isValid: boolean;
}