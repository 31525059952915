import { Type, Expose, Transform, Exclude } from "class-transformer";

import { TransformDate } from '../../decorators';

import { SubjectCategory, AmortizationScheduleMode } from '../enums';
import { CoursePicture } from '../coursePicture';
import { CourseAmortizationScheduleTemplate } from '../courseAmortizationScheduleTemplate';

export class Course {
    courseID: number;
    subjectID: number;
    courseTypeID: number;
    semesterID: number;
    abbreviation: string;
    name: string;
    description: string;
    lessonLengthInMinutes: number;
    employeeID: number;
    capacity: number;
    price: number;
    causalPrice: number;
    fixedPrice: number;
    picture: string;
    allowRegistration: boolean;
    showInSchedule: boolean;
    showInEmployeeTimeSheet: boolean;
    shortDescription: string;
    accountingBookID: number;
    compensationGroupID : number;
    acceptingCompensationGroups: number[];
    emailCategory: number;
    preAmendsCount: number;
    timeBoundAmendCount: number;

    @TransformDate()
    timeBoundAmendExpiration: Date;
    allowMultiAttendance: boolean;

    @Expose({ name: "subjectCategoryID" })
    subjectCategory: SubjectCategory;
    isOnlineMeeting: boolean;

    @Type(() => CoursePicture)
    pictures: CoursePicture[]
    courseAgeRangeID: number;
    courseCategoryID: number;
    subjectName: string;
    subjectDescription: string;
    teacher: string;
    courseTypePicture: string;
    filled: number;
    classroomID: number;

    @TransformDate()
    firstLesson: Date;

    @TransformDate()
    nearestLesson: Date;

    @TransformDate()
    lastLesson: Date;

    pictureUrl: string;
    smallPictureUrl: string;
    surveyID: number;
    noOfRemainingLessons: number;
    remainingPrice: number;
    changeEmployeeFromNowOn: boolean;
    supportsOnlinePayment: boolean;

    preferOnlinePayments: boolean;
    autoUnregisterLimitInMinutes: number;

    allowClientLessonSelection: boolean;
    maxFreePlacesForLessonSelection: number;

    amortizationScheduleSurchargePct: number;
    amortizationScheduleMode: AmortizationScheduleMode;

    @Type(() => CourseAmortizationScheduleTemplate)
    amortizationSchedules: CourseAmortizationScheduleTemplate[];

    @Exclude()
    get courseFullName(): string {
        let result = "";
        if (this.subjectName)
            result = result + this.subjectName;
        if (this.abbreviation)
            result = result + '(' + this.abbreviation + ')';
        if (this.name)
            result = result + ' - ' + this.name;
        return result;
    }

    @Exclude()
    get isFull(): boolean {
        return this.noOfFreePlaces <= 0;
    }

    @Exclude()
    get noOfFreePlaces(): number {
        if (this.allowClientLessonSelection) {
            return Math.max(this.maxFreePlacesForLessonSelection || 0, 0);
        } else {
            return Math.max(this.capacity - this.filled, 0);
        }
    }

    // Needs to be filled by client code
    @Exclude()
    compensationGroupName : string;
}