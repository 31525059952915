
import { map, catchError, publishReplay, filter, refCount, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { Employee } from '../models/users/employee';
import { BaseService } from './base.service';


@Injectable()
export class EmployeeService extends BaseService {

  private baseServiceUrl: string;

  private cacheEvictionTime: number = 30000; // 30s
  private byUserIdCache: { [key: string]: Observable<Employee> } = {};

  private cacheAll: Observable<Employee[]>;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/restapi/employees/';
  }

  getAll(): Observable<Employee[]> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl;
    let params = new HttpParams()
      .set("includeActive", "true")
      .append("includeDeleted", "false");

    this.cacheAll = this.cacheAll || this.http
      .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(Employee, res)),
        publishReplay(1, this.cacheEvictionTime),
        refCount(),
        take(1));

    return this.cacheAll;
  }

  getByEmployeeId(employeeId: string): Observable<Employee> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + employeeId;

    return this.http
      .get(url, { headers: headers }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object) => plainToClass(Employee, res)));
  }

  getByUserId(userId: string): Observable<Employee> {

    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'find-by-user-id';
    let params = new HttpParams()
      .set("userId", userId);

    this.byUserIdCache[userId] = this.byUserIdCache[userId] || this.http
      .post(url, null, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object) => plainToClass(Employee, res)),
        publishReplay(1, this.cacheEvictionTime),
        refCount(),
        take(1)
      );

    return this.byUserIdCache[userId];
  }

  getByEmployeeIds(employeeIds: string[]): Observable<Employee[]> {
    return this.getAll()
      .pipe(map(employees => employees.filter(employee => employeeIds.includes(employee.id))));
  }
}
