import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { ActivityAttendanceService, notifyWarning, ActivityService } from '../../shared/services';
import { ActivityAttendanceModel } from '../../shared/models';

import { Constants } from '../../shared/constants';

import { TranslateService } from '@ngx-translate/core'

import * as moment from "moment";
import * as _ from 'lodash';

@Component({
  selector: 'app-my-activities-attendance',
  templateUrl: './my-activities-attendance.component.html',
  styleUrls: ['./my-activities-attendance.component.css']
})
export class MyActivitiesAttendanceComponent implements OnInit {

  private _activityID: number;
  get activityID(): number { return this._activityID; }
  set activityID(value: number) {
    if (this._activityID !== value) {
      this._activityID = value;
      this.changeUrl(value, this.selectedDate, true, true);
    }
  }

  studentID: number;

  activityAttendanceModel: _.Dictionary<ActivityAttendanceModel>;
  selectedAttendanceModel: ActivityAttendanceModel;
  selectingDate: boolean = false;
  newOneOffConfirmVisible: boolean = false;
  newWaitQueueConfirmVisible: boolean = false;
  notesVisible: boolean = false;
  scheduleNotes: string = "";
  shouldReloadCalendar: boolean = false;
  singlePeriodProgramOnly: boolean = false;


  loading: boolean = false;
  saving: boolean = false;

  private selectedDateValue: Date = new Date();
  get selectedDate(): Date {
    return this.selectedDateValue;
  }
  set selectedDate(value: Date) {
    if (this.selectedDateValue !== value) {
      this.selectedDateValue = value;
      this.changeUrl(this.activityID, value, true, false);
    }
  }

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private activityAttendanceService: ActivityAttendanceService,
    private translateService: TranslateService,
    private activityService: ActivityService
  ) { }

  ngOnInit() {
    let self = this;

    self.activityID = Number(self.route.snapshot.queryParams["activityID"]);
    self.studentID = Number(self.route.snapshot.queryParams["studentID"]);

    self.activityService.get(self.activityID).subscribe(a => {
      self.singlePeriodProgramOnly = a.singlePeriodProgramOnly;
    });

    let sd = moment(self.route.snapshot.queryParams['selectedDate']);
    if (sd.isValid) {
      self.selectedDate = sd.toDate();
    }

    if (!self.selectedDate) {
      self.selectedDate = new Date();
    }

    self.loadData(false);
  }

  changeUrl(activityID: number, date: Date, showWarning: boolean, loadData: boolean) {
    let momentDate = moment(date);
    if (momentDate.isValid && !!activityID && !!this.studentID) {
      let url = _.join(_.map(this.route.snapshot.url, 'path'), '/')
      this.location.replaceState(url, `activityID=${activityID.toString()}&studentID=${this.studentID.toString()}&selectedDate=${momentDate.format("YYYY-MM-DD")}`);

      if (loadData) {
        this.loadData(false);
      } else {
        this.selectAttendanceModel(activityID, date, showWarning);
      }
    }
  }

  selectAttendanceModel(activityID: number, selectedDate: Date, showWarning: boolean = false) {
    this.selectingDate = true;
    let mSelectedDate = moment(selectedDate);

    if (this.selectedAttendanceModel && this.selectedAttendanceModel.isDirty && showWarning) {
      this.translateService.get('TXT_ATTENDANCE_CHANGE_WAS_NOT_SAVED').subscribe(t => {
        notifyWarning(t);
      });
    }

    if (activityID && this.studentID && mSelectedDate.isValid && this.activityAttendanceModel) {
      let model = this.activityAttendanceModel[+mSelectedDate.startOf('day')];
      if (model) {
        this.selectedAttendanceModel = model.clone();
      } else {
        this.selectedAttendanceModel = null;
      }
    } else {
      this.selectedAttendanceModel = null;
    }

    this.selectingDate = false;
  }

  onNoClickNewOneOffConfirmation(params: any) {
    this.newOneOffConfirmVisible = false;
  }

  onNoNewWaitQueueConfirmation(params: any) {
    this.newWaitQueueConfirmVisible = false;
  }

  onNoteDetail(params: any, notes: string) {
    this.notesVisible = true;
    this.scheduleNotes = notes;
  }

  onAttendanceSave(params: any, notificationConfirmed: boolean = false) {
    let self = this;

    if (notificationConfirmed) {
      self.newOneOffConfirmVisible = false;
      self.newWaitQueueConfirmVisible = false;
    }

    if (!notificationConfirmed && self.selectedAttendanceModel.hasNewWaitQueue) {
      self.newWaitQueueConfirmVisible = true;
      return;
    }

    if (!notificationConfirmed && self.selectedAttendanceModel.hasNewOneOff) {
      self.newOneOffConfirmVisible = true;
      return;
    }

    self.saving = true;
    self.activityAttendanceService.saveAttendance(self.selectedAttendanceModel, true)
      .subscribe(r => {
        self.saving = false;
        self.loading = true;
        self.shouldReloadCalendar = true;
        self.applyActivityAttendanceModel(r, () => {
          setTimeout(() => {
            self.shouldReloadCalendar = false;
          }, 1000)
        });
      },
        err => {
          self.saving = false;
          self.loading = true;
          self.shouldReloadCalendar = true;
          self.loadData(true, () => {
            setTimeout(() => {
              self.shouldReloadCalendar = false;
            }, 1000)
          });
        });
  }

  onScrollViewPullDown(options: any) {
    this.loadData(true, () => options.component.release());
  }

  private loadData(refresh: boolean = false, finished: () => any = () => { }) {
    this.activityAttendanceService.getStudentAttendanceDetail(this.activityID, this.studentID, refresh)
      .subscribe(r => this.applyActivityAttendanceModel(r, finished));
  }

  private applyActivityAttendanceModel(model: ActivityAttendanceModel[], finished: () => any = () => { }) {
    this.activityAttendanceModel = _.keyBy(model, (o) => +moment(o.startDate).startOf('day'));
    this.selectAttendanceModel(this.activityID, this.selectedDate, false);
    this.loading = false;
    finished();
  }

}
