import { Injectable } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { WbcurrencyPipe } from '../pipes/wbcurrency.pipe';

import { SubjectCategory } from '../models/enums/subjectCategory';

import { ICoursePriceService } from './icourse-price.service';

import {
  Course,
  InvoiceAmortizationSchedule,
  CourseAmortizationScheduleTemplate
} from '../models';

import * as _ from 'lodash';
import * as moment from "moment";

@Injectable()
export class CoursePriceService implements ICoursePriceService {

  constructor(
    private translate: TranslateService,
    private percentPipe: PercentPipe,
    private wbcurrencyPipe: WbcurrencyPipe) { }


  getCourseFeeLineName(
    course: Course,
    noOfRegisteredAttendances: number,
    useAmortizationSchedule: boolean): string {
    if (course.subjectCategory === SubjectCategory.OneOffActions) {
      return this.translate.instant('TXT_EVENT_FEE');
    }

    if (course.fixedPrice !== null && course.fixedPrice !== undefined) {
      return `${this.translate.instant('TXT_COURSE_FEE')} (${this.translate.instant('TXT_FIXED_PRICE')})`;
    }

    let factor = !!useAmortizationSchedule
      ? (course.amortizationScheduleSurchargePct / 100) + 1
      : 1;

    return `${this.translate.instant('TXT_COURSE_FEE')} (${noOfRegisteredAttendances} x ${this.wbcurrencyPipe.transform(course.price * factor)})`
  }

  getDiscountLineStr(name: string, discountPct: number): string {
    return this.translate.instant('TXT_DISCOUNT_PCT') + ` - ${name} (${this.percentPipe.transform(discountPct / 100, '1.2-2')})`;
  }

  getCourseBasePrice(
    course: Course,
    noOfRegisteredAttendances: number,
    attendanceCount: number,
    useAmortizationSchedule: boolean): number {
    let basePrice = 0;

    if (course.fixedPrice !== null && course.fixedPrice !== undefined) { // 0 is valid price
      basePrice = course.fixedPrice;
    } else {
      basePrice = noOfRegisteredAttendances * course.price;
    }

    basePrice = basePrice * (attendanceCount || 1);

    if (!!useAmortizationSchedule) {
      let factor = (course.amortizationScheduleSurchargePct / 100) + 1;
      basePrice = basePrice * factor;
    }

    return _.round(basePrice, 2);
  }

  calculateAmortizationSchedules(
    price: number,
    invoiceDate: Date,
    amortizationScheduleTemplate: CourseAmortizationScheduleTemplate[]
  ): InvoiceAmortizationSchedule[] {
    let amortizationSchedules: InvoiceAmortizationSchedule[] = [];
    let remainingAmount = price;
    let rollUpAmount = 0;

    let byIssueDateOrder = _.map(_.sortBy(_.map(amortizationScheduleTemplate, (x) => { return {
      actualIssueDate: x.issueAt != null
        ? moment(x.issueAt).startOf('day').toDate()
        : moment(invoiceDate).add(x.issueDaysAfterRegistration, 'days').startOf('day').toDate(),
      item: x
    }}), ['actualIssueDate']), (x) => x.item);

    for (let idx = 0; idx < byIssueDateOrder.length; idx++) {
      let isLast = (idx + 1) == byIssueDateOrder.length;
      let amortizationTemplateItem = byIssueDateOrder[idx];

      let amortizationPartAmount = amortizationTemplateItem.amount != null
        ? Math.min(amortizationTemplateItem.amount, remainingAmount)
        : Math.min(price * (amortizationTemplateItem.percentageAmount / 100), remainingAmount);

      if (isLast) {
        amortizationPartAmount = remainingAmount;
      }

      if (amortizationPartAmount <= 0) {
        break;
      }

      let issueAt: Date = amortizationTemplateItem.issueAt != null
        ? moment(amortizationTemplateItem.issueAt).startOf('day').toDate()
        : moment(invoiceDate).add(amortizationTemplateItem.issueDaysAfterRegistration, 'days').startOf('day').toDate();

      if (moment(issueAt).isBefore(moment(invoiceDate).startOf('day'))) {
        rollUpAmount += amortizationPartAmount;
        continue;
      }

      let existingAmortizationScheduleIndex =_.findIndex(amortizationSchedules,
        (n: InvoiceAmortizationSchedule) => moment(n.issueAt).isSame(issueAt));

      if (existingAmortizationScheduleIndex !== -1) {
        let existingAmortizationScheduleAmount =
          amortizationSchedules[existingAmortizationScheduleIndex].amount;
        let amortizationSchedule = new InvoiceAmortizationSchedule
        amortizationSchedule.issueAt = issueAt;
        amortizationSchedule.dueDate = moment(issueAt).add(15, 'days').toDate();
        amortizationSchedule.amount = Math.min(amortizationPartAmount + rollUpAmount, remainingAmount) + existingAmortizationScheduleAmount

        amortizationSchedules.splice(existingAmortizationScheduleIndex, 1);
        amortizationSchedules.push(amortizationSchedule);
        rollUpAmount = 0;
        remainingAmount -= amortizationSchedule.amount - existingAmortizationScheduleAmount;
      }
      else
      {
        let amortizationSchedule = new InvoiceAmortizationSchedule();
        amortizationSchedule.issueAt = issueAt;
        amortizationSchedule.dueDate = moment(issueAt).add(15, 'days').toDate();
        amortizationSchedule.amount = Math.min(amortizationPartAmount + rollUpAmount, remainingAmount);
        amortizationSchedules.push(amortizationSchedule);
        rollUpAmount = 0;
        remainingAmount -= amortizationSchedule.amount;
      }
    }

    if (amortizationSchedules.length > 0) {
      // Check the sum
      // This can happen if there are some schedules with conflicting issue dates
      let sum = _.reduce(amortizationSchedules, (sum, n) => sum += n.amount, 0);
      let diff = price - sum;
      if (diff > 0) {
        _.last(amortizationSchedules).amount += diff;
      }
    }

    return amortizationSchedules;
  }
}
