import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SemesterWithCompGroup } from '../../shared/models';

@Component({
  selector: 'app-courses-attendance',
  templateUrl: './courses-attendance.component.html',
  styleUrls: ['./courses-attendance.component.css']
})
export class CoursesAttendanceComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onSemesterSelect(data: SemesterWithCompGroup) {
    this.router.navigate(["/attendance/courses/semester", data.semesterID], { replaceUrl: data.isAutoRedirect });
  }

}
