import { Type, Exclude } from "class-transformer";

import { UserInfo } from './users/userInfo';
import { TenantInfo } from './tenantInfo';
import { TenantSetting } from './tenantSetting';

export class TenantSummary {

  preferredLanguage: string;
  tenantPictureUrl: string;
  hasStudentOnActivity: boolean;
  noOfFeaturedActions: number;
  securityFeatures: string[];

  @Type(() => UserInfo)
  loggedInUser: UserInfo;

  @Type(() => TenantInfo)
  tenantInfo: TenantInfo;

  @Type(() => TenantSetting)
  tenantSetting: TenantSetting;

  @Exclude()
  isInRole(role: string): boolean {
    if (!this.loggedInUser || !this.loggedInUser.isAuthenticated || !this.securityFeatures) {
      return false;
    }

    return this.securityFeatures.indexOf(role) != -1;
  }
};