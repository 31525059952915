import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { BaseGuard } from './baseGuard';

import { LoginService } from '../services';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class CanActivateIfNotAuthenticated extends BaseGuard implements CanActivate {

    constructor(
        loginService: LoginService,
        router: Router) {
        super(router, loginService);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let self = this;

        return new Observable<boolean>(observer => {
            self.loginService.isAuthenticated().subscribe((a) => {
                if (a) {
                    observer.next(false);
                    self.navigateToHome();
                } else {
                    observer.next(true);
                }
                observer.complete();
            },
                err => {
                    observer.error(err);
                    self.navigateToHome();
                });
        });
    }
}