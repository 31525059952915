
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { plainToClass, classToPlain } from "class-transformer";
import { Location } from '@angular/common';

import { User, Student, LoginDetails, UserRegistration, UserName } from '../models';
import { BaseService } from './base.service';
import { WindowWrapper } from '../windowWrapper';
import { Constants } from '../constants';
import { TenantService } from './tenant.service';

import { TranslateService } from '@ngx-translate/core';

import { notifySuccess } from './notifications';

@Injectable()
export class UserService extends BaseService {

  private baseServiceUrl: string;
  private currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private _window: WindowWrapper,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private tenantService: TenantService,
    private location: Location
  ) {

    super();
    this.baseServiceUrl = '/Api/Users/';
  }

  createNew(): Observable<User> {
    let self = this;

    return Observable.create(observer => {
      self.tenantService.getTenantSummary().subscribe(ts => {
        let newEntity = new User();
        newEntity.securityRoleID = 3;
        newEntity.allowEmailContact = true;
        newEntity.nationality = ts.tenantSetting.defaultNationality;

        observer.next(newEntity);
        observer.complete();
      }, err => observer.error(self.handleError(err)))
    });
  }

  getCurrent(refresh: boolean = false): Observable<User> {
    if (!this.currentUser || refresh) {

      let headers = this.getDefaultHttpHeaders();
      let url: string = this.baseServiceUrl + 'GetCurrent';

      this.currentUser = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object) => plainToClass<User, Object>(User, res)),
          publishReplay(1),
          refCount(), );
    }

    return this.currentUser;
  }

  update(user: User, showSuccessNotification: boolean = true): Observable<User> {
    let self = this;
    let url: string = this.baseServiceUrl + 'Update';
    let headers = this.getDefaultHttpHeaders();

    return new Observable<User>(observer => {
      self.http
        .post(url, user, { headers: headers }).pipe(
          map((res: Object) => plainToClass<User, Object>(User, res)))
        .subscribe(
          value => { /* success */
            if (showSuccessNotification) {
              self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
                notifySuccess(res);
              });
            }

            self.currentUser = null;

            observer.next(value);
            observer.complete();
          }, err => observer.error(self.handleError(err)))
    })
  }

  registerNewUser(userRegistration: UserRegistration): Observable<Object> {
    let self = this;

    let headers = self.getDefaultHttpHeaders();
    let url: string = self.baseServiceUrl + 'RegisterNewUser';

    return new Observable<Object>(observer => {
      let plain = classToPlain(userRegistration);

      self.http.post(url, plain, { headers: headers }).subscribe(
        value => { /* success */
          self.translateService.get('TXT_ALERT_TITLE_USER_REGISTERED').subscribe((res: string) => {
            notifySuccess(res);
          });

          observer.next(value);
          observer.complete();

          self.route.queryParams
            .subscribe(params => {
              let toUrl = self.location.prepareExternalUrl(params[Constants.returnUrl] || '/');
              self._window.location.href = toUrl;
            });
        },
        err => observer.error(self.handleError(err)))
    });
  }

  updateLoginDetails(loginDetails: LoginDetails): Observable<Object> {
    let self = this;

    let headers = self.getDefaultHttpHeaders();
    let url: string = self.baseServiceUrl + 'UpdateLoginDetails';
    return new Observable<Object>(observer => {
      let plain = classToPlain(loginDetails);

      self.http
        .post(url, plain, { headers: headers })
        .subscribe(
          value => { /* success */
            self.translateService.get('TXT_DATA_SAVED').subscribe((res: string) => {
              notifySuccess(res);
            });

            self.currentUser = null;

            observer.next(value);
            observer.complete();
          },
          err => observer.error(self.handleError(err)))
    })
  }

  generateUserName(name: string, middleName: string, surname: string): Observable<string> {

    if (String.prototype.isNullOrWhitespace(surname)) {
      return of('');
    }

    let headers = this.getDefaultHttpHeaders();
    let params = new HttpParams()
      .set("surname", surname);

    if (!String.prototype.isNullOrWhitespace(name)) {
      params = params.append("name", name);
    }

    if (!String.prototype.isNullOrWhitespace(middleName)) {
      params = params.append("middleName", middleName);
    }

    let url: string = this.baseServiceUrl + 'GenerateUserName';

    return this.http
      .post(url, {}, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object) => plainToClass<UserName, Object>(UserName, res).userName), );
  }

  checkPasswordStrength(password: string, userName: string): number {
    let score = 0

    //password < 4
    if (password.length < 4) { return 0; }

    //password == username
    if (userName && password.toLowerCase() === userName.toLowerCase()) return 0;

    //password length
    score += password.length * 4;
    score += (this.checkRepetition(1, password).length - password.length) * 1;
    score += (this.checkRepetition(2, password).length - password.length) * 1;
    score += (this.checkRepetition(3, password).length - password.length) * 1;
    score += (this.checkRepetition(4, password).length - password.length) * 1;

    //password has 3 numbers
    if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) { score += 5; }

    //password has 2 sybols
    if (password.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/)) { score += 5; }

    //password has Upper and Lower chars
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) { score += 10; }

    //password has number and chars
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) { score += 15; }
    //
    //password has number and symbol
    if (password.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && password.match(/([0-9])/)) { score += 15; }

    //password has char and symbol
    if (password.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && password.match(/([a-zA-Z])/)) { score += 15; }

    //password is just a nubers or chars
    if (password.match(/^\w+$/) || password.match(/^\d+$/)) { score -= 10; }

    //verifing 0 < score < 100
    if (score < 0) { score = 0; }
    if (score > 100) { score = 100; }

    return score;

    //if (score < 34) return badPass
    //if (score < 68) return goodPass
    //return strongPass
  }

  private checkRepetition(pLen: number, str: string): string {
    let res = ""
    for (let i = 0; i < str.length; i++) {
      let repeated = true;
      let j = 0;
      for (j = 0; j < pLen && (j + i + pLen) < str.length; j++)
        repeated = repeated && (str.charAt(j + i) == str.charAt(j + i + pLen))
      if (j < pLen) repeated = false
      if (repeated) {
        i += pLen - 1
        repeated = false
      }
      else {
        res += str.charAt(i)
      }
    }
    return res
  }

}
