import { Type, Transform, Expose } from "class-transformer";

import { ActivityCompensationType } from '../enums/activityCompensationType';

export class ActivityStudentSetting {
    activityStudentSettingID: number;
    activityTariffID: number;
    studentID: number;
    activityID: number;

    @Expose({ name: "activityCompensationTypeID" })
    activityCompensationType: ActivityCompensationType;
}
