import { Observable, forkJoin } from 'rxjs';

import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { WindowWrapper } from '../../../shared/windowWrapper';
import { TranslateService } from '@ngx-translate/core';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service'

const STORAGE_KEY = 'action-payment-history-index';

import {
  TenantService,
  CourseRegistrationService,
  CourseService,
  OnlinePaymentService
} from '../../../shared/services';
import {
  TenantSummary,
  CourseUserRegistration,
  Course
} from '../../../shared/models';

import { combineLatest } from 'rxjs';
import {map, take} from 'rxjs/operators';

import * as _ from 'lodash';

@Component({
  selector: 'app-action-detail-register',
  templateUrl: './action-detail-register.component.html',
  styleUrls: ['./action-detail-register.component.css']
})
export class ActionDetailRegisterComponent implements OnInit {

  private courseID: number;

  onlinePaymentConfirmItems: any[] = [];
  onlinePaymentConfirmVisible: boolean = false;

  userRegistration: CourseUserRegistration;
  course: Course;
  loading: boolean = true;
  saving: boolean = false;
  hasAnyStudents: boolean = false;
  confirmVisible: boolean = false;

  private onlinePaymentID: number;
  confirmPaymentMessage: string = '';
  confirmPaymentVisible: boolean = false;

  onlinePaymentConfirmMessage: string = '';
  preferOnlinePayments: boolean = false;
  private savedStudentIDs = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _window: WindowWrapper,
    private translate: TranslateService,
    private courseRegistrationService: CourseRegistrationService,
    private onlinePaymentService: OnlinePaymentService,
    private courseService: CourseService,
    private tenantService: TenantService,
    @Inject(SESSION_STORAGE) private storage: StorageService) { }

  ngOnInit() {
    this.translate.get('TXT_PAY_ONLINE').subscribe((res: string) => {
      this.onlinePaymentConfirmItems.push({ text: this.translate.instant('TXT_PAY_ONLINE') });
      this.onlinePaymentConfirmMessage = this.translate.instant('TXT_ONLINE_PAYMENT_QUESTION');
    });

    this.loadData().subscribe(res => {});
  }

  private loadData(): Observable<Object> {
    return new Observable<Object>(observer => {
      let self = this;

      self.loading = true;

      let urlParams = combineLatest([
        this.route.parent.params,
        this.route.queryParams
      ]).pipe(map((params) => ({ ...params[0], ...params[1] })));

      combineLatest([
        urlParams,
        self.tenantService.getTenantSummary()
      ]).subscribe((res: [[string], TenantSummary]) => {
        let urlParams = res[0];
        let tenantSummary = res[1];

        self.courseID = +urlParams['id'];
        self.onlinePaymentID = +urlParams['onlinePaymentID'];

        self.courseService.getAction(self.courseID).subscribe(c => {
          self.course = c;

          this.preferOnlinePayments = c.supportsOnlinePayment && c.preferOnlinePayments;

          if (this.preferOnlinePayments) {
            this.translate.get('TXT_PAY_ONLINE').subscribe((res: string) => {
              this.onlinePaymentConfirmMessage = this.translate.instant('TXT_PAY_ONLINE');
            });
          }

          self.courseRegistrationService.getCourseUserRegistration({
            courseID: self.courseID,
            userID: tenantSummary.loggedInUser.userID,
            includeInterestsAsVirtual: true,
            allowClientLessonSelection: false,
            preselectSingleStudentAttendance: true,
            refresh: true
          }).subscribe(ur => {
            self.userRegistration = ur;
            self.loading = false;
            self.hasAnyStudents = !!ur && !!ur.courseRegistrations && ur.courseRegistrations.length > 0;

            observer.next(self.userRegistration);
            observer.complete();
          }, err => {
            self.loading = false;
            observer.error(err);
          });
        }, err => {
          self.loading = false;
          observer.error(err);
        });

        if (self.onlinePaymentID) {
          self.onlinePaymentService.getTransactionState(self.onlinePaymentID).subscribe((op) => {
            self.confirmPaymentMessage = self.onlinePaymentService.getOnlinePaymentResultMessage(op);
            self.confirmPaymentVisible = true;
          });
        }

      }, err => {
        self.loading = false;
        observer.error(err);
      });
    });
  }

  showConfirmRegistration(params: any) {
    let valid = true;
    if (params.validationGroup) {
      valid = params.validationGroup.validate().isValid;
    }

    if (valid) {
      this.confirmVisible = true;
    }
  }

  navigateToStudents() {
    this.router.navigate(["/my-account/students"], { queryParams: { returnUrl: this.router.url } } );
  }

  registractionCanceled(params) {
    this.confirmVisible = false;
  }

  registrationConfirmed(params) {
    let self = this;
    self.confirmVisible = false;
    self.saving = true;

    self.savedStudentIDs = _.map(_.filter(self.userRegistration.courseRegistrations, { attend: true, isNew: true }), 'studentID');

    let newAttends = _.filter(self.userRegistration.courseRegistrations, {
      attend: true, isModified: true
    });

    self.courseRegistrationService.saveActionRegistration(self.userRegistration)
      .subscribe(() => {
        self.saving = false;
        self.loadData().subscribe(res => {
          if (self.userRegistration) {
            let newRegistrations = _.intersectionWith(
              self.userRegistration.courseRegistrations, self.savedStudentIDs, (r, s) => {
                return r.studentID === s;
              });
            let price = _.sumBy(newRegistrations, 'price');
            if (self.course.supportsOnlinePayment && newAttends.length > 0 && price > 0) {
              self.onlinePaymentConfirmVisible = true;
            }
          }
        });
    }, err => {
      self.saving = false;
      self.loadData().subscribe(res => {});
      self.savedStudentIDs = [];
    });
  }

  onlinePaymentConfirmClick() {
    if (this.userRegistration) {

      let newRegistrations = _.intersectionWith(
        this.userRegistration.courseRegistrations, this.savedStudentIDs, (r, s) => {
          return r.studentID === s;
        });
      let invoiceIDs = _.map(newRegistrations, 'invoiceID');
      let price = _.sumBy(newRegistrations, 'price');

      this.storage.set(STORAGE_KEY, this._window.history.length);

      this.onlinePaymentService.startOnlinePayment(
        invoiceIDs,
        price);
    }
  }

  onConfirmPaymentResultClick(param: any) {
    let self = this;
    self.confirmPaymentVisible = false;
    self.confirmPaymentMessage = '';

    let queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['onlinePaymentID'] = null;

    self.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      replaceUrl: true
    });

    self.onlinePaymentID = null;

    // Navigate back in history prior to online payment
    let storedIndex = this.storage.get(STORAGE_KEY) || this._window.history.length;

    let noOfPagesToRemove = storedIndex - this._window.history.length;
    this._window.history.go(noOfPagesToRemove);
  }
}
