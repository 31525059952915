<div class="dx-fieldset">
  <div *ngIf="showContactFieldsetHeader"
    class="dx-fieldset-header">{{'TXT_CONTACT' | translate}}</div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserAddress < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_ADDRESS' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserAddress == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-area *ngIf="tenantSetting?.mandatoryUserAddress == 1"
                    [(value)]="user.address"
                    [height]="60">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_ADDRESS' | translate)  }, { type: 'stringLength', max: 255 }]"></dx-validator>
      </dx-text-area>
      <dx-text-area *ngIf="tenantSetting?.mandatoryUserAddress == 0"
                    [(value)]="user.address"
                    [height]="60">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 255 }]"></dx-validator>
      </dx-text-area>
    </div>
  </div>

  <div class="dx-field"
       *ngIf="tenantSetting?.mandatoryUserNationality < 2">
    <div class="dx-field-label">{{ 'TXT_NATIONALITY' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserNationality == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserNationality == 1" [(value)]="user.nationality">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_NATIONALITY' | translate) }, { type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserNationality == 0" [(value)]="user.nationality">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserIdentityCardNumber < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_IDENTITY_CARD_NUMBER' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserIdentityCardNumber == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserIdentityCardNumber == 1"
                   [(value)]="user.identityCardNumber">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_IDENTITY_CARD_NUMBER' | translate)  }, { type: 'stringLength', max: 40 }]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserIdentityCardNumber == 0"
                   [(value)]="user.identityCardNumber">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 40 }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserPersonalIDNumber < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_PERSONAL_ID_NUMBER' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserPersonalIDNumber == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserPersonalIDNumber == 1"
                   [(value)]="user.personalIDNumber"
                   [placeholder]="'TXT_NO_SLASH' | translate">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PERSONAL_ID_NUMBER' | translate)  }, { type: 'stringLength', max: 40 }, { type: 'pattern', pattern: personalIdPattern}]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserPersonalIDNumber == 0"
                   [(value)]="user.personalIDNumber"
                   [placeholder]="'TXT_NO_SLASH' | translate">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 40 }, { type: 'pattern', pattern: personalIdPattern}]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserBirthday < 2">
    <div class="dx-field-label">{{ 'TXT_BIRTHDAY' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserBirthday == 1" class="dx-field-item-required-mark"> *</span></div>
    <div class="dx-field-value">
      <dx-date-box *ngIf="tenantSetting?.mandatoryUserBirthday == 1"
                   [(value)]="user.birthday"
                   pickerType="native"
                   type="date">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_BIRTHDAY' | translate) }]"></dx-validator>
      </dx-date-box>
      <dx-date-box *ngIf="tenantSetting?.mandatoryUserBirthday == 0"
                   [(value)]="user.birthday"
                   type="date"
                   pickerType="native"
                   [showClearButton]="true">
      </dx-date-box>
    </div>
  </div>

  <div *ngIf="(tenantSetting?.allowReferenceSource < 2) && showNonUserInfo"
       class="dx-fieldset-header">{{'TXT_OTHER' | translate}}</div>

  <div class="dx-field"
    *ngIf="(tenantSetting?.allowReferenceSource < 2 && isReferenceSourceMandatory) && showNonUserInfo">
    <div class="dx-field-label">{{ 'TXT_REFERENCE_SOURCE' | translate }}<span class="dx-field-item-required-mark"> *</span></div>
    <div class="dx-field-value">
      <dx-lookup [(value)]="user.referenceSourceID"
        [dataSource]="referenceSources"
        valueExpr="id"
        displayExpr="name"
        fullScreen="true"
        [placeholder]="referenceSourcesPlaceholder">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_REFERENCE_SOURCE' | translate) }]"></dx-validator>
      </dx-lookup>
    </div>
  </div>

  <div class="dx-field"
    *ngIf="(tenantSetting?.allowReferenceSource < 2 && !isReferenceSourceMandatory) && showNonUserInfo">
    <div class="dx-field-label">{{ 'TXT_REFERENCE_SOURCE' | translate }}</div>
    <div class="dx-field-value">
      <dx-lookup [(value)]="user.referenceSourceID"
        [dataSource]="referenceSources"
        valueExpr="id"
        displayExpr="name"
        fullScreen="true"
        [placeholder]="referenceSourcesPlaceholder">
      </dx-lookup>
    </div>
  </div>

  <div *ngIf="hasSecondContact"
       class="dx-fieldset-header">{{'TXT_SECONDARY_CONTACT' | translate}}</div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserName2 < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_NAME' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserName2 == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserName2 == 1"
                   [(value)]="user.name2">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_NAME' | translate)  }, { type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserName2 == 0"
                   [(value)]="user.name2">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserSurname2 < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_SURNAME' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserSurname2 == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserSurname2 == 1"
                   [(value)]="user.surname2">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_SURNAME' | translate)  }, { type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserSurname2 == 0"
                   [(value)]="user.surname2">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserMiddleName2 < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_MIDDLE_NAME' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserMiddleName2 == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserMiddleName2 == 1"
                   [(value)]="user.middleName2">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_MIDDLE_NAME' | translate)  }, { type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserMiddleName2 == 0"
                   [(value)]="user.middleName2">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field"  *ngIf="tenantSetting?.mandatoryEmail2 < 2">
    <div class="dx-field-label">{{ 'TXT_EMAIL' | translate }}
      <span *ngIf="tenantSetting?.mandatoryEmail2 == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryEmail2 == 1"
                   [(value)]="user.email2"
                   mode="email">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_EMAIL' | translate) }, { type: 'email', message: 'TXT_LOGIN_ERROR_INVALID_EMAIL' | translate }]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryEmail2 == 0"
                   [(value)]="user.email2"
                   mode="email">
        <dx-validator [validationRules]="[{ type: 'email', message: 'TXT_LOGIN_ERROR_INVALID_EMAIL' | translate }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryPhone2 < 2">
    <div class="dx-field-label">{{ 'TXT_PHONE' | translate }}
      <span *ngIf="tenantSetting?.mandatoryPhone2 == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryPhone2 == 1"
                   [(value)]="user.phone2"
                   placeholder="+420 999 999 999"
                   mask="+999 999 999 999"
                   showMaskMode="onFocus">
        <dx-validator [validationRules]="[{ type: 'pattern', pattern: phoneValidationRegEx, message: ('TXT_PHONE_FORMAT_INVALID' | translate)}, { type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PHONE' | translate) }]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryPhone2 == 0"
                   [(value)]="user.phone2"
                   placeholder="+420 999 999 999"
                   mask="+999 999 999 999"
                   showMaskMode="onFocus"
                   [showClearButton]="true">
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserAddress2 < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_ADDRESS' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserAddress2 == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-area *ngIf="tenantSetting?.mandatoryUserAddress2 == 1"
                    [(value)]="user.address2"
                    [height]="60">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_ADDRESS' | translate)  }, { type: 'stringLength', max: 255 }]"></dx-validator>
      </dx-text-area>
      <dx-text-area *ngIf="tenantSetting?.mandatoryUserAddress2 == 0"
                    [(value)]="user.address2"
                    [height]="60">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 255 }]"></dx-validator>
      </dx-text-area>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserIdentityCardNumber2 < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_IDENTITY_CARD_NUMBER' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserIdentityCardNumber2 == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserIdentityCardNumber2 == 1"
                   [(value)]="user.identityCardNumber2">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_IDENTITY_CARD_NUMBER' | translate)  }, { type: 'stringLength', max: 40 }]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserIdentityCardNumber2 == 0"
                   [(value)]="user.identityCardNumber2">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 40 }]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserPersonalIDNumber2 < 2">
    <div class="dx-field-label dx-field-label-wrap">{{ 'TXT_PERSONAL_ID_NUMBER' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserPersonalIDNumber2 == 1" class="dx-field-item-required-mark"> *</span>
    </div>
    <div class="dx-field-value">
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserPersonalIDNumber2 == 1"
                   [(value)]="user.personalIDNumber2"
                   [placeholder]="'TXT_NO_SLASH' | translate">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PERSONAL_ID_NUMBER' | translate)  }, { type: 'stringLength', max: 40 }, { type: 'pattern', pattern: personalIdPattern}]"></dx-validator>
      </dx-text-box>
      <dx-text-box *ngIf="tenantSetting?.mandatoryUserPersonalIDNumber2 == 0"
                   [(value)]="user.personalIDNumber2"
                   [placeholder]="'TXT_NO_SLASH' | translate">
        <dx-validator [validationRules]="[{ type: 'stringLength', max: 40 }, { type: 'pattern', pattern: personalIdPattern}]"></dx-validator>
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field" *ngIf="tenantSetting?.mandatoryUserBirthday2 < 2">
    <div class="dx-field-label">{{ 'TXT_BIRTHDAY' | translate }}
      <span *ngIf="tenantSetting?.mandatoryUserBirthday2 == 1" class="dx-field-item-required-mark"> *</span></div>
    <div class="dx-field-value">
      <dx-date-box *ngIf="tenantSetting?.mandatoryUserBirthday2 == 1"
                   [(value)]="user.birthday2"
                   pickerType="native"
                   type="date">
        <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_BIRTHDAY' | translate) }]"></dx-validator>
      </dx-date-box>
      <dx-date-box *ngIf="tenantSetting?.mandatoryUserBirthday2 == 0"
                   [(value)]="user.birthday2"
                   type="date"
                   pickerType="native"
                   [showClearButton]="true">
      </dx-date-box>
    </div>
  </div>


</div>
