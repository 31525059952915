import { Component, OnInit } from '@angular/core';
import { DomainSelection } from 'app/shared/models';
import { Router, ActivatedRoute } from '@angular/router';

import { TenantService } from '../../shared/services';

@Component({
  selector: 'app-reservations-place',
  templateUrl: './reservations-place.component.html',
  styleUrls: ['./reservations-place.component.css']
})
export class ReservationsPlaceComponent implements OnInit {

  placeId: string;
  isLoggedIn: boolean = false;
  loginAdvertDisplayed: boolean = false;
  returnUrl: string;

  constructor(
    private tenantService: TenantService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.placeId = params['placeId'];
    });

    this.tenantService.getTenantSummary().subscribe(res => {
      this.isLoggedIn = res.loggedInUser.isAuthenticated;
    });
  }

  onDomainSelected(data: DomainSelection) {
    if (!this.isLoggedIn) {
      if (data.domain.specializations.length === 0) {
        this.returnUrl = `/reservations/place/${this.placeId}/domain/${data.domain.id}`;
      } else {
        this.returnUrl = `/reservations/place/${this.placeId}/domain/${data.domain.id}/specialization/${data.domain.specializations[0].id}`;
      }
      this.loginAdvertDisplayed = true;
    } else {
      if (data.domain.specializations.length === 0) {
        this.router.navigate(["/reservations/place", this.placeId, "domain", data.domain.id], { replaceUrl: data.isAutoRedirect });
      } else {
        this.router.navigate(["/reservations/place", this.placeId, "domain", data.domain.id, "specialization", data.domain.specializations[0].id], { replaceUrl: data.isAutoRedirect });
      }
    }
  }

}
