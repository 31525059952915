import {Exclude, Type} from "class-transformer";
import { Specialization } from "./specialization";

export class Domain {
  id: string;
  name: string;

  @Type(() => Specialization)
  specializations: Specialization[];

  disabledForClients: boolean;
}
