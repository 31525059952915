import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  CourseScheduleService,
  TenantService,
  EmployeeService,
  SemesterService,
  CourseService} from 'app/shared/services';

  import {
    TenantSummary,
    CourseSchedule,
    Employee,
    Semester,
    Course} from 'app/shared/models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment from "moment";
import * as _ from 'lodash';

@Component({
  selector: 'app-course-course-attendance',
  templateUrl: './course-course-attendance.component.html',
  styleUrls: ['./course-course-attendance.component.css']
})
export class CourseCourseAttendanceComponent implements OnInit {

  lessons: DataSource;
  courseID: number;
  semesterID: number;
  semester: Semester;
  course: Course;

  editNotesVisible: boolean = false;
  lastClickWasOnNotesEditButton: boolean = false;
  clickedCourseSchedule: CourseSchedule = null;
  editedNote: string = null;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeService: EmployeeService,
    private courseScheduleService: CourseScheduleService,
    private tenantService: TenantService,
    private semesterService: SemesterService,
    private courseService: CourseService,) { }

  ngOnInit() {
    let self = this;

    this.route.params.subscribe(params => {
      self.courseID = Number(params['courseId']);
      self.semesterID = Number(params['semesterId']);

      combineLatest([
        self.semesterService.getAll(null, true, true),
        self.courseService.getCourse(self.courseID),
        self.courseScheduleService.getAllForCourse(self.courseID)])
          .subscribe((data: [Semester[], Course, CourseSchedule[]]) => {
            self.semester = _.find(data[0], { id: self.semesterID });
            self.course = data[1];
          });
    });

    let courseID = self.route
      .params
      .pipe(map(params => Number(params['courseId'])));

    let tenantSummaryObservable = self.tenantService.getTenantSummary();

    self.lessons = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            combineLatest([courseID, tenantSummaryObservable])
              .subscribe((res: [number, TenantSummary]) => {
                self.courseID = res[0];
                let tenantSummary = res[1];
                combineLatest([
                  self.courseScheduleService.getAllForCourse(self.courseID, true),
                  self.employeeService.getByUserId(tenantSummary.loggedInUser.userID + '')
                ]).subscribe((data: [CourseSchedule[], Employee]) => {
                    let filteredLessons = _.orderBy(data[0], ['startDate']);
                    let employee = data[1];
                    if (!tenantSummary.isInRole("ClientAttendanceAdmin"))
                    {
                      if (!employee) {
                        filteredLessons = [];
                      } else {
                        let threshold: moment.Moment;
                        if (tenantSummary.tenantSetting.noOfHoursToUpdateAttendance >= 0 &&
                          !tenantSummary.isInRole("UpdateAttendanceInThePast")) {
                            threshold = moment().subtract(tenantSummary.tenantSetting.noOfHoursToUpdateAttendance, "hour");
                        } else {
                            threshold = moment().subtract(365 * 2, "days");
                        }
                        filteredLessons = _.filter(filteredLessons, (l: CourseSchedule) => moment(l.startDate).isAfter(threshold));
                      }
                    }

                    resolve(filteredLessons);
                  }, (error) => {
                    reject(error);
                  })
              }, (error) => {
                reject(error);
              })
          });
        }
      })
    });
  }

  onPullRefresh() {
    this.lessons.load();
  }

  onItemClick(params) {

    if (this.lastClickWasOnNotesEditButton) {
      this.lastClickWasOnNotesEditButton = false;
      return;
    }

    this.lastClickWasOnNotesEditButton = false;

    this.router.navigate([
      "/attendance/courses/semester/",
      (<CourseSchedule>params.itemData).semesterID,
      "course",
      (<CourseSchedule>params.itemData).courseID,
      "lesson",
      (<CourseSchedule>params.itemData).courseScheduleID
    ]);
  }

  onNotesClick(lesson: CourseSchedule) {
    this.editNotesVisible = true;
    this.clickedCourseSchedule = lesson;
    this.editedNote = this.clickedCourseSchedule.notes;
  }

  onNotesMouseDown(e: any) {
    e.stopPropagation();
    this.lastClickWasOnNotesEditButton = true;
  }

  onNotesEditCancelClick() {
    this.editNotesVisible = false;
  }

  onNotesEditSaveClick() {

console.warn("this.clickedCourseSchedule:" + this.clickedCourseSchedule);

    if (!!this.clickedCourseSchedule) {
      this.courseScheduleService.updateLessonNote(this.clickedCourseSchedule.courseScheduleID, this.editedNote, false)
        .subscribe(s => {
          this.clickedCourseSchedule.notes = this.editedNote;
        });
    }
    this.editNotesVisible = false;

  }

  onNotesEditSaveAndSendClick() {
    this.editNotesVisible = false;
    if (!!this.clickedCourseSchedule) {
      this.courseScheduleService.updateLessonNote(this.clickedCourseSchedule.courseScheduleID, this.editedNote, true)
        .subscribe(s => {
          this.clickedCourseSchedule.notes = this.editedNote;
        });
    }
  }

}
