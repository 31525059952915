import { NgModule, OnInit, Component, enableProdMode, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common'

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Reservation } from '../../shared/models/reservations';
import { TranslateService } from '@ngx-translate/core';

import { TenantService, EmployeeService, ReservationService, ClassroomService } from '../../shared/services';

import { DxSchedulerComponent } from 'devextreme-angular';

import * as _ from 'lodash';
import * as moment from "moment";

@Component({
  selector: 'app-reservations-attendance',
  templateUrl: './reservations-attendance.component.html',
  styleUrls: ['./reservations-attendance.component.css']
})
export class ReservationsAttendanceComponent implements OnInit {

  @ViewChild(DxSchedulerComponent) scheduler: DxSchedulerComponent;

  currentDate: Date = new Date();
  reservations: DataSource;
  classrooms: DataSource;
  usedPlaceIds: string[];

  editingOptions: {
    allowAdding: boolean,
    allowDeleting: boolean,
    allowDragging: boolean,
    allowResizing: boolean,
    allowUpdating: boolean
  } = {
      allowAdding: false,
      allowDeleting: false,
      allowDragging: false,
      allowResizing: false,
      allowUpdating: false
    };

  displayRange: {
    startDayHour: number,
    endDayHour: number
  } = {
      startDayHour: 8,
      endDayHour: 23
    };

  constructor(
    private tenantService: TenantService,
    private employeeService: EmployeeService,
    private reservationService: ReservationService,
    private classroomService: ClassroomService,
    private translate: TranslateService,
    private cp: CurrencyPipe,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    let self = this;

    self.classrooms = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.classroomService.getAll().subscribe(c => {
              var classroomMapping = _.map(c, m => {
                return {
                  id: m.id + "",
                  name: m.name
                };
              });

              if (!self.usedPlaceIds && classroomMapping.length > 0) {
                classroomMapping = [classroomMapping[0]];
              }

              if (!!self.usedPlaceIds) {
                classroomMapping = _.intersectionWith(classroomMapping, self.usedPlaceIds, (c, p) => {
                  return c.id == p;
                });
              }

              resolve(classroomMapping);
            }, (error) => {
              reject(error);
            });
          });
        }
      })
    });

    self.reservations = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.tenantService.getTenantSummary().subscribe((t) => {

              self.employeeService.getByUserId(t.loggedInUser.userID + "").subscribe(e => {
                if (!!e) {
                  self.reservationService.getAllByRangeAndEmployeeId(
                    moment(self.currentDate).startOf('day').toDate(),
                    moment(self.currentDate).endOf('day').toDate(),
                    e.id
                  ).subscribe(r => {
                    let maxEndDate = _.maxBy(r, 'reservationEndDate');
                    let minStartDate = _.minBy(r, 'reservationStartDate');

                    if (!!maxEndDate) {
                      let maxHour = Math.min(24, moment(maxEndDate.reservationEndDate).hour() + 1);
                      let minHour = Math.max(0, moment(minStartDate.reservationStartDate).hour() - 0.5);

                      if (minHour > t.tenantSetting.scheduleVisibleTimeStart &&
                        minHour - t.tenantSetting.scheduleVisibleTimeStart < 2) {
                        minHour = t.tenantSetting.scheduleVisibleTimeStart;
                      };

                      self.displayRange.startDayHour = minHour;
                      self.displayRange.endDayHour = maxHour;
                      self.ref.detectChanges();
                    }

                    let newUsedPlaces = _.uniqBy(_.map(r, 'placeId'));
                    if (!_.isEqual(self.usedPlaceIds, newUsedPlaces) && newUsedPlaces.length > 0) {
                      self.usedPlaceIds = newUsedPlaces;

                      // repaint - this is how to repaint the scheduler with reloading resources
                      // https://www.devexpress.com/Support/Center/Question/Details/T825611/scheduler-filtering-a-data-source-assigned-to-the-resources-property-doesn-t-hide-this
                      self.scheduler._setOption("resources[0].dataSource", self.classrooms);
                    }

                    resolve(r);
                  }, (error) => {
                    reject(error);
                  });
                } else {
                  reject("No employee!");
                }
              }, (error) => {
                reject(error);
              });
            }, (error) => {
              reject(error);
            });
          });
        }
      })
    })
  }

  onAppointmentFormOpening(data) {
    var self = this;
    var form = data.form;
    var formData = form.option('formData');

    //const popup = data.element.find(".dx-scheduler-appointment-popup").dxPopup("instance");
    //form.option('title', self.translate.instant('TXT_RESERVATION_DETAIL'));

    var items: any = [{
      label: {
        text: self.translate.instant('TXT_PLACE')
      },
      name: "place",
      dataField: "placeId",
      editorType: "dxSelectBox",
      editorOptions: {
        dataSource: self.classrooms,
        displayExpr: 'name',
        valueExpr: 'id'
      }
    }, {
      label: {
        text: this.translate.instant('TXT_LESSON_START')
      },
      dataField: "reservationStartDate",
      editorType: "dxDateBox",
      editorOptions: {
        width: "100%",
        type: "datetime",
      },
    }, {
      label: {
        text: this.translate.instant('TXT_LESSON_END')
      },
      dataField: "reservationEndDate",
      editorType: "dxDateBox",
      editorOptions: {
        width: "100%",
        type: "datetime",
      },
    }, {
      label: {
        text: this.translate.instant('TXT_REGISTRATION_DATE')
      },
      dataField: "registrationDate",
      editorType: "dxDateBox",
      editorOptions: {
        width: "100%",
        type: "datetime",
      }
    }, {
      label: {
        text: this.translate.instant('TXT_DOMAIN'),
      },
      editorType: "dxTextBox",
      editorOptions: {
        value: !!formData.domainSpecialization
          ? formData.domainSpecialization.name
          : formData.domain.name,
        width: "100%"
      }
    }, {
      label: {
        text: this.translate.instant('TXT_ARREAR')
      },
      dataField: "arrear",
      editorType: "dxTextBox",
      editorOptions: {
        value: this.cp.transform(formData.invoice.arrear, formData.invoice.currency, 'symbol', '1.2-2', 'cs-CZ'),
        width: "100%"
      }
    }];

    _.forEach(formData.students, (s, i) => {
      items.push({
        label: {
          text: this.translate.instant('TXT_STUDENTS'),
          visible: i == 0
        },
        editorType: "dxTextBox",
        editorOptions: {
          value: !!s.reducedAge
            ? s.fullName + ' (' + s.reducedAge + ')'
            : s.fullName,
          width: "100%"
        },
      });
    });

    items.push({
      label: {
        text: this.translate.instant('TXT_NOTES')
      },
      dataField: "notes",
      editorType: "dxTextArea",
      editorOptions: {
        height: '84px',
      }
    });

    form.option("items", items);
  }
}
