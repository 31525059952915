<dx-action-sheet #onlinePaymentConfirmSheet
  title="{{'TXT_ONLINE_PAYMENT_QUESTION' | translate}}"
  cancelText="{{'TXT_CANCEL' | translate}}"
  [items]="onlinePaymentConfirmItems"
  [(visible)]="onlinePaymentConfirmVisible"
  [showTitle]="true"
  [showCancelButton]="true"
  (onItemClick)="onlinePaymentConfirmClick()">
</dx-action-sheet>
<dx-load-panel #loadPanel
  [(visible)]="loading"
  [showIndicator]="true"
  [showPane]="false"
  [shading]="false"
  closeOnOutsideClick="false">
</dx-load-panel>
<dx-load-panel
  [(visible)]="saving"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  message="{{'TXT_SAVING' | translate}}"
  closeOnOutsideClick="false">
</dx-load-panel>


<div *ngIf="!loading">
  <form>
    <dx-validation-group>
      <div *ngFor="let student of userRegistration.courseRegistrations"
        class="studentRegistration">
        <app-student-course-registration
          [student]="student"
          [isAction]="false"
          [allowClientLessonSelection]="allowClientLessonSelection"
          [maxFreePlacesForLessonSelection]="maxFreePlacesForLessonSelection"
          [courseInterestsSupport]="courseInterestsSupport">
        </app-student-course-registration>
      </div>

      <div *ngIf="showGoToScheduleHelp" class="bg-danger text-message wb-button-margin padded-content">
        {{'TXT_GO_TO_SCHEDULE_HELP' | translate}} <a (click)="navigateToSchedule()"> {{'TXT_SCHEDULE' | translate}}.</a>
      </div>
      <div *ngIf="courseInterestsSupport" class="text-message wb-button-margin">
        {{'TXT_REGISTER_INTEREST_HELP' | translate}}
      </div>
      <div *ngIf="!hasAnyStudents" class="bg-danger text-message wb-button-margin padded-content">
        {{'TXT_NO_STUDENTS_ERROR' | translate}}
        <div class="text-center padded-content">
          <dx-button text="{{'TXT_ADD_STUDENTS' | translate}}"
                     class="wb-button"
                     type="danger"
                     (onClick)="navigateToStudents()">
          </dx-button>
        </div>
      </div>
      <div class="text-center wb-button-margin"
        *ngIf="!loading">
        <app-button-with-login [text]="registerButtonText"
          type="success"
          [disabled]="!userRegistration.isModified || disableRegisterButton"
          (onClick)="showConfirmRegistration($event)"></app-button-with-login>
      </div>
    </dx-validation-group>
  </form>

</div>

<dx-popup showTitle="true"
  title="{{'TXT_REGISTER_ON_COURSE' | translate}}"
  fullScreen="true"
  showCloseButton="true"
  [(visible)]="confirmVisible">
  <div *dxTemplate="let data of 'content'">
    <app-course-detail-register-confirm
      [courseUserRegistration]="userRegistration"
      [courseInterestsSupport]="courseInterestsSupport"
      (onCancel)="registrationCanceled($event)"
      (onConfirm)="registrationConfirmed($event)">
    </app-course-detail-register-confirm>
  </div>
</dx-popup>

<dx-popup showTitle="true"
  title="{{'TXT_INFORMATION' | translate}}"
  [maxHeight]="230"
  [(visible)]="confirmPaymentVisible">
  <div *dxTemplate="let data of 'content'">
    <p>{{confirmPaymentMessage}}</p>
    <div class="text-center">
      <dx-button text="{{'TXT_OK' | translate}}"
        class="wb-button"
        type="success"
        (onClick)="onConfirmPaymentResultClick($event)">
      </dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup showTitle="true"
  title="{{'TXT_INFORMATION' | translate}}"
  fullScreen="true"
  showCloseButton="false"
  [(visible)]="confirmFullCourseInterestVisible">
  <div *dxTemplate="let data of 'content'">
    <p class="message-box-message">{{'TXT_FULL_COURSE_INTEREST_SAVED' | translate}}</p>
    <div class="text-center">
      <dx-button text="{{'TXT_OK' | translate}}"
        class="wb-button"
        type="success"
        (onClick)="confirmFullCourseInterestResultClick($event)">
      </dx-button>
    </div>
  </div>
</dx-popup>
