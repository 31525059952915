
import { refCount, publishReplay, map, catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { TenantSummary } from '../models/tenantSummary';

import { BaseService } from './base.service';





@Injectable()
export class TenantService extends BaseService {

  private baseServiceUrl: string;
  private preferredLanguage: string;
  private currency: string;

  private tenantSummary: Observable<TenantSummary>;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/Api/TenantSummary/';
  }

  getTenantSummary(): Observable<TenantSummary> {

    if (!this.tenantSummary) {

      let headers = this.getDefaultHttpHeaders();

      let url: string = this.baseServiceUrl + 'Get';

      this.tenantSummary = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object) => plainToClass<TenantSummary, Object>(TenantSummary, res)),
          publishReplay(1),
          refCount());
    }

    return this.tenantSummary;
  }

  getLanguage(): string {
    if (!this.preferredLanguage) {
      this.getTenantSummary()
        .subscribe(x =>
          this.preferredLanguage = x.preferredLanguage);
    }

    return this.preferredLanguage || 'cs';
  }

  getCurrency(): string {
    if (!this.currency) {
      this.getTenantSummary()
        .subscribe(x =>
          this.currency = x.tenantInfo.currency);
    }
    return this.currency || 'CZK';
  }
}
