import { Type, Transform } from "class-transformer";

import { TransformDate } from '../../decorators';

export class ActivityStudentExcuseByDate {

    @TransformDate()
    date: Date;

    noOfExcuses: number;
}