<dx-scroll-view>
  <app-request-login-action-sheet [(visible)]="loginAdvertDisplayed"
    ></app-request-login-action-sheet>
  <div>
    <dx-load-panel #loadPanel
      [(visible)]="loading"
      [showIndicator]="true"
      [showPane]="false"
      [shading]="false"
      closeOnOutsideClick="false">
    </dx-load-panel>

    <div class="wb-content">

      <div class="row button-grid">
        <dx-button *ngIf="eventsButtonVisible"
          class="col-xs-6 col-sm-4"
          icon="fa fa-briefcase"
          [height]="45"
          [text]="eventsButtonText"
          [stylingMode]="'text'"
          (onClick)="eventsButtonClick($event)"></dx-button>

        <dx-button *ngIf="coursesButtonVisible"
          class="col-xs-6 col-sm-4"
          icon="fa fa-graduation-cap"
          [height]="45"
          [text]="courseButtonText"
          [stylingMode]="'text'"
          (onClick)="coursesButtonClick($event)"></dx-button>

        <dx-button *ngIf='excusesButtonVisible'
          class="col-xs-6 col-sm-4"
          icon="fa fa-calendar"
          [height]="45"
          [text]="excusesButtonText"
          [stylingMode]="'text'"
          (onClick)="excusesButtonClick($event)"></dx-button>

        <dx-button *ngIf="kindergartenButtonVisible"
          class="col-xs-6 col-sm-4"
          icon="fa fa-users"
          [height]="45"
          [text]="kindergartenButtonText"
          [stylingMode]="'text'"
          (onClick)="kindergartenButtonClick($event)"></dx-button>

        <dx-button *ngIf="formsButtonVisible"
          class="col-xs-6 col-sm-4"
          icon="fa fa-file-text-o"
          [height]="45"
          [text]="formsButtonText"
          [stylingMode]="'text'"
          (onClick)="formsButtonClick($event)"></dx-button>

        <dx-button *ngIf="reservationsButtonVisible"
          class="col-xs-6 col-sm-4"
          icon="fa fa-hourglass-half"
          [height]="45"
          [text]="reservationsButtonText"
          [stylingMode]="'text'"
          (onClick)="reservationsButtonClick($event)"></dx-button>
      </div>


      <img class="tenantPicture"
        [src]="tenantSummary?.tenantPictureUrl || ''" />

      <div class="preText padded-content">
        {{ tenantSummary?.tenantInfo.description }}
      </div>
    </div>
  </div>
</dx-scroll-view>
