import { map, catchError, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import { BaseService } from './base.service';
import { DomainId, DayPlaceAvailability } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PlaceAvailabilityService extends BaseService {

  private baseServiceUrl: string;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/restapi/places/';
  }

  getAllByRangeAndDomains(placeId: string, start: Date, end: Date, domains: DomainId[]): Observable<DayPlaceAvailability[]> {

    let headers = this.getDefaultHttpHeaders();

    let url: string = this.baseServiceUrl + `${placeId}/availability/find-by-domains-and-range`;

    let domainsPlain = classToPlain(domains);

    return this.http
      .post(url, {
        start: start.toISOString(),
        end: end.toISOString(),
        domains: domainsPlain
      },
        { headers: headers }).pipe(
          catchError((err) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(DayPlaceAvailability, res)),
          take(1));
  }
}
