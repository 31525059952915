<dx-action-sheet #onlinePaymentConfirmSheet
                 title="{{onlinePaymentConfirmMessage}}"
                 cancelText="{{'TXT_CANCEL' | translate}}"
                 [items]="onlinePaymentConfirmItems"
                 [(visible)]="onlinePaymentConfirmVisible"
                 [showTitle]="true"
                 [showCancelButton]="true"
                 (onItemClick)="onlinePaymentConfirmClick()">
</dx-action-sheet>
<dx-scheduler id="reservations-scheduler"
              [dataSource]="reservations"
              [views]='["day"]'
              [groups]="['placeId']"
              currentView="day"
              startDateExpr="reservationStartDate"
              endDateExpr="reservationEndDate"
              [adaptivityEnabled]="true"
              [(currentDate)]="currentDate"
              cellDuration="15"
              [showAllDayPanel]="false"
              [editing]="editingOptions"
              [height]="'100%'"
              [useDropDownViewSwitcher]="false"
              [crossScrollingEnabled]="false"
              (onCellClick)="onCellClick($event)"
              [(selectedCellData)]="selectedCellData"
              (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
              >
    <dxi-view
        type="day"
        [startDayHour]="displayRange.startDayHour"
        [endDayHour]="displayRange.endDayHour"
        appointmentTemplate="appointment-template"
        appointmentTooltipTemplate="tooltip-template"
        dataCellTemplate="data-cell-template">
    </dxi-view>
    <dxi-resource
        fieldExpr="placeId"
        valueExpr="id"
        displayExpr="name"
        label="Place"
        [allowMultiple]="false"
        [dataSource]="classrooms">
    </dxi-resource>

    <div *dxTemplate="let model of 'appointment-template'"
         class="dx-item-content dx-scheduler-appointment-content"
         [ngClass]="{'paid-reservation': (!!model.appointmentData.invoice && model.appointmentData.invoice.arrear === 0)}">
        <div class='dx-scheduler-appointment-content-details'>
            <div class="dx-scheduler-appointment-content-date">{{model.appointmentData.reservationStartDate| date:'HH:mm'}}-{{model.appointmentData.reservationEndDate| date:'HH:mm'}}
                <span *ngIf="!!model.appointmentData.domainSpecialization"> - {{model.appointmentData.domainSpecialization.name}}</span>
                <span *ngIf="!model.appointmentData.domainSpecialization"> - {{model.appointmentData.domain.name}}</span>
            </div>
            <div *ngFor="let student of model.appointmentData.students">
                {{student.fullName}} <span *ngIf="!!student.reducedAge">({{student.reducedAge}})</span>
            </div>
        </div>
    </div>

    <div *dxTemplate="let model of 'data-cell-template'"
        [ngClass]="{'data-cell-available': getDataCellAvailability(model.startDate, model.endDate) == 1}">
    </div>

    <div *dxTemplate="let model of 'tooltip-template'">
        <div class='reservation-tooltip'>
            <div class="reservation-payment-indicator"
                [ngClass]="{'paid-reservation': (!!model.appointmentData.invoice && model.appointmentData.invoice.arrear === 0), 'dx-theme-accent-as-background-color': (!model.appointmentData.invoice || model.appointmentData.invoice.arrear !== 0)}"></div>
            <div class="reservation-tooltip-header">
                <div><b>{{model.appointmentData.reservationStartDate| date:'HH:mm'}}-{{model.reservationEndDate| date:'HH:mm'}}</b></div>
                <div class="domain-name">
                    <div *ngIf="!!model.appointmentData.domainSpecialization">{{model.appointmentData.domainSpecialization.name}}</div>
                    <div *ngIf="!model.appointmentData.domainSpecialization">{{model.appointmentData.domain.name}}</div>
                </div>
            </div>
            <ul *ngFor="let student of model.appointmentData.students" class="students-list">
                <li>{{student.fullName}} <span *ngIf="!!student.reducedAge">({{student.reducedAge}})</span></li>
            </ul>
            <div *ngIf="!!model.appointmentData.notes" class="reservation-notes">
                <span><b>{{'TXT_NOTES' | translate}}:</b></span>
                {{model.appointmentData.notes}}
            </div>
        </div>
    </div>
</dx-scheduler>
<dx-popup
    [width]="'100%'"
    [height]="200"
    [showTitle]="false"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [(visible)]="availableInfoVisible"
    [position]="{ my: 'top', at: 'bottom', offset: '0 -200' }">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view
            [direction]="'horizontal'"
            width="'100%'"
            height="170">
            <div class="employee-scroll-view-content">
                <div *ngFor='let employee of selectedEmployees | async' class="employee-info-ticket">
                    <dx-button height="155"
                               (onClick)="onEmployeeClick($event, employee)">
                        <div class="employee-photo">
                            <img *ngIf="!!employee.smallImage"
                                [src]="employee.smallImage" />
                            <img *ngIf="!employee.smallImage"
                                style="left: 10px;"
                                src="/Data/teampictures/cf2830ffb3df4d72a4cbbc8f88fa3c8a.png" />
                        </div>
                        <div class="employee-name">
                            {{employee.fullName}}
                        </div>
                </dx-button>
                </div>
            </div>
        </dx-scroll-view>
    </div>
</dx-popup>
<dx-popup showTitle="true"
          title="{{'TXT_INFORMATION' | translate}}"
          [maxHeight]="230"
          [(visible)]="confirmPaymentVisible">
  <div *dxTemplate="let data of 'content'">
    <p>{{confirmPaymentMessage}}</p>
    <div class="text-center">
      <dx-button text="{{'TXT_OK' | translate}}"
                 class="wb-button"
                 type="success"
                 (onClick)="onConfirmPaymentResultClick($event)">
      </dx-button>
    </div>
  </div>
</dx-popup>
