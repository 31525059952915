import { Component, OnInit, Input } from '@angular/core';

import { CourseRegistration, TenantSummary } from '../../models';
import { TenantService } from '../../services';

@Component({
  selector: 'app-student-course-registration',
  templateUrl: './student-course-registration.component.html',
  styleUrls: ['./student-course-registration.component.css']
})
export class StudentCourseRegistrationComponent implements OnInit {

  showNote: boolean = false;
  noteDescriptionInCourseRegistration: string = "";
  showHealthInCourseRegistration: boolean = false;

  constructor(private tenantService: TenantService) { }

  @Input() student: CourseRegistration;

  @Input() isAction: boolean = true;

  @Input() allowClientLessonSelection: boolean = false;
  @Input() maxFreePlacesForLessonSelection: number = 0;
  @Input() courseInterestsSupport: boolean = false;

  ngOnInit() {
    this.tenantService.getTenantSummary()
      .subscribe(ts => {
        this.showNote = this.isAction
          ? ts.tenantSetting.showNoteInEventRegistration
          : ts.tenantSetting.showNoteInCourseRegistration;
        this.noteDescriptionInCourseRegistration = ts.tenantSetting.noteDescriptionInCourseRegistration;
        this.showHealthInCourseRegistration = ts.tenantSetting.showHealthInCourseRegistration;

      });
  }

}
