import { Type, Transform } from "class-transformer";

import { TransformDate } from '../../decorators';

export class ActivityReservation {
    activityReservationID: number;
    activityID: number;
    activityScheduleID: number;
    studentID: number;

    @TransformDate()
    firstPeriodStartDate: Date;

    @TransformDate()
    firstPeriodEndDate: Date;

    @TransformDate()
    secondPeriodStartDate: Date;

    @TransformDate()
    secondPeriodEndDate: Date;

    lunch: boolean;
    firstSnack: boolean;
    secondSnack: boolean;
    notes: string;
    studentFullName: string;
}