<dx-lookup 
    [(value)]="value"
    [dataSource]="compensationCourses"
    [disabled]="disabled"
    valueExpr="id"
    displayExpr="fullName"
    fullScreen="true"
    showCancelButton="true"    
    itemTemplate="listItem"
    fieldTemplate="field"
    >
    <div *dxTemplate="let item of 'listItem'" >
        <div class="dx-button-mode-text" [ngClass]="{'dx-button-danger': item. requiresCompOverride}">
            {{item.fullName}}
        </div>
        <div class="dx-button-mode-text" [ngClass]="{'dx-button-danger': item. requiresCompOverride}">
            <small>{{'TXT_AVAILABLE_AMENDS' | translate}}: {{item.amends}}</small>
        </div>
    </div>  
    <div *dxTemplate="let item of 'field'">
        <div *ngIf="!!item" class="dx-button-mode-text" [ngClass]="{'dx-button-danger': item. requiresCompOverride}">
            {{item.fullName}}
        </div>
        <div *ngIf="!item" class="placeholder-color">{{'TXT_SELECT_COURSE' | translate}}</div>
    </div>      
</dx-lookup>

<!-- fieldTemplate="field" -->
