
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { plainToClass, classToPlain } from "class-transformer";

import {
  UniqueEmailValidationRequest,
  UniqueUserNameValidationRequest,
  ValidationResponse
} from '../models';

import { BaseService } from './base.service';

@Injectable()
export class ValidationService extends BaseService {

  private baseServiceUrl: string;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/Api/Validation/';
  }

  isEmailUnique(email: string, userID: number): Observable<boolean> {

    let self = this;

    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'UniqueEmail';

    let data = new UniqueEmailValidationRequest();
    data.email = email;
    data.userID = userID;

    return self.http
      .post(url, data, { headers: headers }).pipe(
        catchError(e => {
          self.handleError(e);
          return of(false);
        }),
        map((res: Object) => plainToClass<ValidationResponse, Object>(ValidationResponse, res).isValid));
  }

  isUserNameUnique(userName: string, userID: number): Observable<boolean> {

    let self = this;

    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'UniqueUserName';

    let data = new UniqueUserNameValidationRequest();
    data.userName = userName;
    data.userID = userID;

    return self.http
      .post(url, data, { headers: headers }).pipe(
        catchError(e => {
          self.handleError(e);
          return of(false);
        }),
        map((res: Object) => plainToClass<ValidationResponse, Object>(ValidationResponse, res).isValid));
  }

}
