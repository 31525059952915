import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SignInUser} from "../../models";
import {LoginService} from "../../services";
import {WindowWrapper} from "../../windowWrapper";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private loginService: LoginService,
    private _window: WindowWrapper,) { }


  passwordValidationRegEx: RegExp;

  user: SignInUser = new SignInUser();
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input()
  get visible(): boolean {
    return this.signInVisible || this.passwordResetVisible;
  }
  set visible(value: boolean) {
    if (value) {
      this.signInVisible = true;
    }
    this.visibleChange.emit(value);
  }

  _signInVisible: boolean = false;
  get signInVisible(): boolean {
    return this._signInVisible;
  }
  set signInVisible(value: boolean) {
    if (value) {
      this._signInVisible = true;
      this._passwordResetVisible = false;
    } else {
      this._signInVisible = false;
      this.resetState();
    }
    this.visibleChange.emit(value);
  }

  _passwordResetVisible: boolean = false;
  get passwordResetVisible(): boolean {
    return this._passwordResetVisible;
  }
  set passwordResetVisible(value: boolean) {
    if (value) {
      this._passwordResetVisible = true;
      this._signInVisible = false;
    } else {
      this._passwordResetVisible = false;
      this.resetState();
    }
    this.visibleChange.emit(value);
  }

  errorMessage: string = null;
  authCodeSend: boolean = false;
  isSigningIn: boolean = false;
  isNextDisabled: boolean = false;
  emailVerificationMode: boolean = false;
  signInReturnUrl: string = null;

  ngOnInit(): void {
    this.passwordValidationRegEx = this.loginService.passwordValidationRegEx();
    this.route.queryParams.subscribe(params => {
      this.signInReturnUrl = params['signIn'];
    });
  }

  resetState() {
    this.isNextDisabled = false;
    this.isSigningIn = false;
    this.errorMessage = null;
    this.authCodeSend = false;
    this.emailVerificationMode = false;
  }

  onSignIn(event: any) {
    let isValid = true;
    if (event.validationGroup) {
      isValid = event.validationGroup.validate().isValid;
    }
    if (isValid) {
      this.errorMessage = null;
      if (this.emailVerificationMode) {
        this.emailVerificationFlow();
      } else {
        this.signInFlow();
      }
    }
  }

  emailVerificationFlow() {
    this.isSigningIn = true;
    this.loginService.confirmEmailVerification(this.user.email, this.user.otp).subscribe(() => {
      this.signInFlow();
      this.emailVerificationMode = false;
    }, err => {
      this.isSigningIn = false;
      this.errorMessage = this.loginService.translateError(err);
    })
  }

  signInFlow() {
    this.isSigningIn = true;
    this.loginService.login(this.user.email, this.user.password).subscribe(l => {
      this.isSigningIn = false;
      this.visible = false;
      if (!!this.signInReturnUrl) {
        this._window.location.href = "/m" + this.signInReturnUrl;
      } else {
        this._window.location.reload();
      }
    }, err => {
      if (err.code == "wb/user-email-not-verified") {
        this.emailVerificationMode = true;
        this.loginService.sendEmailVerificationAuthorizationCode(this.user.email).subscribe(() => {
          this.isSigningIn = false;
        }, err => {
          this.isSigningIn = false;
          this.errorMessage = this.loginService.translateError(err);
        })
      } else {
        this.isSigningIn = false;
        this.errorMessage = this.loginService.translateError(err);
      }
    });

  }

  onPasswordReset() {
    this.authCodeSend = false;
    this.errorMessage = null;
    this.user.password = null;
    this.passwordResetVisible = true;
  }

  onCancel() {
    this.passwordResetVisible = false;
  }

  onNext(event: any) {
    this.errorMessage = null;
    let isValid = true;
    if (event.validationGroup) {
      isValid = event.validationGroup.validate().isValid;
    }
    if (isValid) {
      this.isNextDisabled = true;
      if (!this.authCodeSend) {
        this.loginService.sendPasswordResetAuthorizationCode(this.user.email).subscribe(() => {
          this.authCodeSend = true;
          this.isNextDisabled = false;
        }, err => {
          this.authCodeSend = false;
          this.isNextDisabled = false;
          this.errorMessage = this.loginService.translateError(err);
        });
      } else {
        this.loginService.confirmPasswordReset(this.user.email, this.user.password, this.user.otp)
          .subscribe(() => {
            this.loginService.login(this.user.email, this.user.password).subscribe(() => {
              this.isNextDisabled = false;
              this.errorMessage = null;
              this.visible = false;
              if (!!this.signInReturnUrl) {
                this._window.location.href = "/m" + this.signInReturnUrl;
              } else {
                this._window.location.reload();
              }
            }, err => {
              this.isNextDisabled = false;
              this.errorMessage = this.loginService.translateError(err, true);
            })
          }, err => {
            this.isNextDisabled = false;
            this.errorMessage = this.loginService.translateError(err, true);
          })
        this.isNextDisabled = false;
      }
    }
  }

}
