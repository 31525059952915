
import {refCount, publishReplay, map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { ReferenceSource } from '../models';
import { BaseService } from './base.service';

import * as _ from 'lodash';

@Injectable()
export class ReferenceSourceService extends BaseService {

  private baseServiceUrl: string;
  private currentRefferenceSources: Observable<ReferenceSource[]>;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/Api/ReferenceSource/';
  }

  getAll(refresh: boolean = false): Observable<ReferenceSource[]> {
    if (!this.currentRefferenceSources || refresh) {

      let headers = this.getDefaultHttpHeaders();

      let url: string = this.baseServiceUrl + 'Get';

      this.currentRefferenceSources = this.http
        .get(url, {
          headers: headers
        }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(ReferenceSource, res)),
        publishReplay(1),
        refCount(),);
    }

    return this.currentRefferenceSources;
  }

  getByID(referenceSourceID): Observable<ReferenceSource> {
    let self = this;

    return Observable.create(observer => {
      this.getAll().subscribe((res: ReferenceSource[]) => {
        var referenceSource = _.find(res, { id: referenceSourceID });
        if (!!referenceSource)
          observer.next(referenceSource);
        observer.complete();
      }, err => observer.error(self.handleError(err)))
    })
  }

}
