
import { map, catchError, publishReplay, refCount, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { HtmlContentInfo, HtmlContent, TenantSummary } from '../models';
import { BaseService } from './base.service';
import { TenantService } from '../services/tenant.service';
import { HtmlContentType } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class HtmlContentService extends BaseService {

  private baseServiceUrl: string;

  private cacheEvictionTime: number = 60000; // 60s
  private contentInfo: Observable<HtmlContentInfo>;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private tenantService: TenantService
  ) {
    super();
    this.baseServiceUrl = '/restapi/html-content/';
  }

  getInfo(refresh: boolean = false): Observable<HtmlContentInfo> {
    if (!this.contentInfo || refresh) {
      let headers = this.getDefaultHttpHeaders();
      let url: string = this.baseServiceUrl + 'info';
      this.contentInfo = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object) => plainToClass(HtmlContentInfo, res)),
          publishReplay(1, this.cacheEvictionTime),
          refCount(),
          take(1));
    }

    return this.contentInfo;
  }

  getById(id: HtmlContentType): Observable<HtmlContent> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + id;

    return this.http
      .get(url, { headers: headers }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object) => plainToClass(HtmlContent, res)));
  }

  getCaptionById(id: HtmlContentType): Observable<string> {

    return new Observable<string>((observer) => {

      let tenantSummary = this.tenantService.getTenantSummary();

      switch(id)
      {
        case HtmlContentType.Instructions:
          observer.next(this.translate.instant('TXT_INSTRUCTIONS'));
          observer.complete();
          break;
        case HtmlContentType.ProtectionOfPersonalData:
          observer.next(this.translate.instant('TXT_PROTECTION_OF_PERSONAL_DATA'));
          observer.complete();
          break;
        case HtmlContentType.TermsAndConditions:
          observer.next(this.translate.instant('TXT_TERMS_AND_CONDITIONS'));
          observer.complete();
          break;
        case HtmlContentType.TradeTerms:
          observer.next(this.translate.instant('TXT_TRADE_TERMS'));
          observer.complete();
          break;
        case HtmlContentType.Custom:
          tenantSummary.subscribe(ts => {
            observer.next(ts.tenantSetting.customContentTypeText);
            observer.complete();
          });         
          break;
        default:
          observer.next("");
          observer.complete();
          break;
      }
    });
  }
}
