import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TenantSummary } from '../shared/models/tenantSummary';
import { TenantService } from '../shared/services/tenant.service';
import { LoginService } from '../shared/services/login.service';
import { FormDefinitionService } from '../shared/services/formDefinition.service';
import { TranslateService } from '@ngx-translate/core'

import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  returnUrl: string = '';
  loginAdvertDisplayed: boolean = false;

  tenantSummary: TenantSummary;
  loading: boolean = false;

  eventsButtonText: string = '';
  eventsButtonVisible: boolean = false;

  courseButtonText: string = '';
  coursesButtonVisible: boolean = false;

  excusesButtonText: string = '';
  excusesButtonVisible: boolean = false;

  kindergartenButtonText: string = '';
  kindergartenButtonVisible: boolean = false;

  formsButtonText: string = '';
  formsButtonVisible: boolean = false;

  reservationsButtonText: string = '';
  reservationsButtonVisible: boolean = false;

  constructor(
    private router: Router,
    private tenantService: TenantService,
    private translate: TranslateService,
    private loginService: LoginService,
    private formDefinitionService: FormDefinitionService) { }

  ngOnInit() {
    let self = this;

    self.loading = true;

    let isAuthenticatedObservable = self.loginService.isAuthenticated();
    let tenantSummaryObservable = self.tenantService.getTenantSummary();

    forkJoin([
      isAuthenticatedObservable,
      tenantSummaryObservable,
      self.translate.get('TXT_EVENTS')]
    ).subscribe((res: [boolean, TenantSummary, any]) => {

      self.tenantSummary = res[1];
      let tenantSetting = res[1].tenantSetting;

      self.eventsButtonText = !!tenantSetting.eventMainPageButton
        ? tenantSetting.eventMainPageButton
        : self.translate.instant('TXT_EVENTS');
      self.eventsButtonVisible = tenantSetting.allowClientActionRegistration;

      self.courseButtonText = !!tenantSetting.coursesMainPageButton
        ? tenantSetting.coursesMainPageButton
        : self.translate.instant('TXT_COURSES');
      self.coursesButtonVisible = tenantSetting.allowClientCourseRegistration ||
        tenantSetting.coursePreviewOnlyRegistration ||
        tenantSetting.courseInterestsSupport;

      self.excusesButtonText = !!tenantSetting.compensationsMainPageButton
        ? tenantSetting.compensationsMainPageButton
        : self.translate.instant('TXT_EXCUSES');
      self.excusesButtonVisible = tenantSetting.enableClientUserSchedules && (!tenantSetting.disableClientCalendar &&
        (tenantSetting.allowClientCourseRegistration || res[0]));

      self.formsButtonText = !!tenantSetting.formsMainPageButton
        ? tenantSetting.formsMainPageButton
        : self.translate.instant('TXT_FORMS');
      self.formDefinitionService.getFormDefinitionAllActive().subscribe(f => {
        if (!!f && !!f.length) {
          self.formsButtonVisible = true;
        }
      });

      self.kindergartenButtonText = !!tenantSetting.kidergartenMainPageButton
        ? tenantSetting.kidergartenMainPageButton
        : self.translate.instant('TXT_KINDERGARTEN');
      self.kindergartenButtonVisible = tenantSetting.enableKindergartenSupport;

      self.reservationsButtonText = !!tenantSetting.reservationsMainPageButton
        ? tenantSetting.reservationsMainPageButton
        : self.translate.instant('TXT_RESERVATIONS');
      self.reservationsButtonVisible = tenantSetting.enableReservationSupport;

      self.loading = false;
    }, err => {
      self.loading = false;
    });
  }

  eventsButtonClick(e: any) {
    this.router.navigate(['/actions']);
  }

  coursesButtonClick(e: any) {
    this.router.navigate(['/courses']);
  }

  excusesButtonClick(e: any) {
    let isLoggedIn = this.tenantSummary.loggedInUser.isAuthenticated;
    if (isLoggedIn || this.tenantSummary.tenantSetting.allowClientCourseRegistration) {
      this.router.navigate(['/schedules']);
    } else {
      this.returnUrl = e.itemData.link;
      this.loginAdvertDisplayed = true;
    }

  }

  formsButtonClick(e: any) {
    this.router.navigate(['/forms']);
  }

  kindergartenButtonClick(e: any) {
    if (this.tenantSummary.hasStudentOnActivity) {
      this.router.navigate(['/my-activities']);
    } else {
      this.router.navigate(['/activities']);
    }
  }

  reservationsButtonClick(e: any) {
    this.router.navigate(['/reservations']);
  }

}
