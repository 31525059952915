import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { plainToClass, classToPlain } from "class-transformer";

import { Reservation } from '../models/reservations/reservation';
import { BaseService } from './base.service';

@Injectable()
export class ReservationService extends BaseService {

  private baseServiceUrl: string;

  constructor(
    private http: HttpClient) {

    super();
    this.baseServiceUrl = '/restapi/reservations/';
  }

  getAllByRangeAndEmployeeId(fromDay: Date, toDay: Date, employeeId: string): Observable<Reservation[]> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'range/find-by-employee';
    let params = new HttpParams()
      .set("employeeId", employeeId)
      .append("expanded", 'Students,Invoices')
      .append("from", fromDay.toISOString())
      .append("to", toDay.toISOString());

    return this.http
      .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(Reservation, res)));
  }

  getAllByRangeAndUser(fromDay: Date, toDay: Date, userId: string): Observable<Reservation[]> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + 'range/find-by-user';
    let params = new HttpParams()
      .set("userId", userId)
      .append("expanded", 'Students,Invoices')
      .append("from", fromDay.toISOString())
      .append("to", toDay.toISOString());

    return this.http
      .get(url, { headers: headers, params: params }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object[]) => plainToClass(Reservation, res)));
  }

  insert(model: Reservation): Observable<Reservation> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl;

    let dto = classToPlain(model);
    return this.http
      .post(url, dto,
        { headers: headers }).pipe(
          catchError((err) => this.handleErrorAndThrow(err)),
          map((res: Object) => plainToClass(Reservation, res)),
          take(1));
  }
}
