<div class="header dx-card dx-theme-border-color-as-background-color">
    <div class="max-length-text" *ngIf="!!semester">
        <small>{{semester.name}} ({{semester.startDate | date:'shortDate'}} - {{semester.endDate | date:'shortDate'}})</small>
    </div>
    <div class="max-length-text" *ngIf="!!course">
        <small>{{course.courseFullName}}</small>
    </div>
    <div class="max-length-text" *ngIf="!!courseSchedule">
        <small>{{courseSchedule.startDate | date:'dd.MM.yyyy EE' | uppercase }} ({{courseSchedule.startDate | date:'HH:mm' }} - {{courseSchedule.endDate | date:'HH:mm' }})</small>
    </div>
    <dx-button class="right-button" icon="add" stylingMode="contained" type="default"
      (onClick)="onAddBtnClick($event)"></dx-button>
</div>
<div class="under-header-list">
    <dx-list
    [dataSource]="students"
    pullRefreshEnabled="true"
    (onPullRefresh)="onPullRefresh()"
    (onItemClick)="onItemClick($event)">
    <div *dxTemplate="let itemData of 'item'; let i = index" class="student-item" [ngClass]="{ 'student-item-first': i == 0 }" >
        <h5 class="wordwrap">
            {{itemData.fullName}}
        </h5>
        <div class="row" *ngIf="tenantSettings.showContatInClientAttendance">
            <div class="col-xs-4">{{'TXT_PHONE'| translate}}:</div>
            <div class="col-xs-8">
                <a href="tel:{{itemData.phone1}}">{{itemData.phone1}}</a>
            </div>
        </div>
        <div class="row" *ngIf="tenantSettings.showAgeInClientAttenandace">
            <div class="col-xs-4">{{'TXT_BIRTHDAY'| translate}}:</div>
            <div class="col-xs-8">{{itemData.birthday}}</div>
        </div>
        <div class="row" *ngIf="tenantSettings.showArrearInClientAttendance && !!itemData.arrear">
            <div class="col-xs-4">{{'TXT_ARREAR'| translate}}:</div>
            <div class="col-xs-8">{{itemData.arrear}}</div>
        </div>
        <div class="row">
            <div class="col-xs-4">{{'TXT_STATUS_PRESENT'| translate}}:</div>
            <div class="col-xs-8 dx-button-mode-text"
                [ngClass]="{'dx-button-success': itemData.attendanceStatus == 0, 'dx-button-danger': itemData.attendanceStatus != 0 }">
                {{itemData.attendanceStatusStr}}</div>
        </div>
        <div class="row" >
            <div class="col-xs-4">{{'TXT_REGISTERED'| translate}}:</div>
            <div class="col-xs-8">{{itemData.attendanceTypeStr}}</div>
        </div>
        <div class="row confirm-button-row">
            <div class="col-xs-4">{{'TXT_CONFIRMED'| translate}}:</div>
            <div class="col-xs-8"><dx-switch [(value)]="itemData.modified" (onValueChanged)="onConfirmedChanged(itemData)" (mousedown)="onSwitchMouseDown($event)"></dx-switch></div>
        </div>
        <div *ngIf="!!itemData.note" class="row">
            <div class="col-xs-4">{{'TXT_NOTES'| translate}}:</div>
        </div>
        <pre *ngIf="!!itemData.note" class="student-note">
            {{itemData.note}}
        </pre>
    </div>
    </dx-list>
</div>
