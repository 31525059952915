import { Type, Transform } from "class-transformer";

import { InvoiceLineType } from '../enums/invoiceLineType';

export class InvoiceLineItem {
    name: string;
    price: number;
    quantity: number;
    unitPrice: number;

    @Transform(({value}) => InvoiceLineType[value]) // Comes as string from server
    invoiceLineType: InvoiceLineType;

    typeReferenceID: number;
    typeReference2ID: number;
}
