import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, NgZone} from '@angular/core';
import { Location } from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { TenantService } from './shared/services/tenant.service';
import { WindowWrapper } from './shared/windowWrapper';
import { LanguageService, LanguageDefinition } from './shared/services/language.service';
import { LoginService } from './shared/services/login.service';
import { FormDefinitionService } from './shared/services/formDefinition.service';
import { TranslateService } from '@ngx-translate/core';
import { HtmlContentService } from './shared/services/html-content.service';
import {
  TenantSummary,
  HtmlContentInfo,
} from './shared/models';

import { HtmlContentType } from './shared/models/enums';

import { ListItemData } from './shared/models/listItemData';
import { DxDrawerComponent } from 'devextreme-angular/ui/drawer';

import { Observable, forkJoin } from 'rxjs';

import * as _ from 'lodash';
import {Angulartics2GoogleAnalytics} from "angulartics2/ga";
import {LightboxService} from "./shared/services/lightbox.service";
import {notifyError} from "./shared/services";
import {DxToolbarComponent} from "devextreme-angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  //@ViewChild(DxDrawerComponent, { static: true }) drawer: DxDrawerComponent;

  tenantSummary: Observable<TenantSummary>;
  languageItems: any[];
  selectedLang: LanguageDefinition;
  logoutConfirmItems: any[] = [];
  loginAdvertDisplayed: boolean = false;
  returnUrl: string = '';
  navigation: ListItemData[];
  logoutConfirmSheetVisible: boolean = false;
  languageSheetVisible: boolean = false;
  htmlContentInfo: Observable<HtmlContentInfo>;
  signInVisible: boolean = false;
  reload: boolean = false;
  reloadMenu: boolean = false;
  menuOpened: boolean = false;
  signInReturnUrl: string = null;

  private switchUrl: string;

  menuBtnClick(e: any) {
    this.menuOpened = !this.menuOpened;
    //return this.drawer.instance.toggle();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    let self = this;

    let isAuthenticatedObservable = self.loginService.isAuthenticated();
    self.tenantSummary = self.tenantService.getTenantSummary();
    self.htmlContentInfo = self.htmlContentService.getInfo();

    self.selectedLang = self.languageService.init();

    forkJoin([
      isAuthenticatedObservable,
      self.tenantSummary,
      self.translate.get('TXT_HOME'),
      self.htmlContentInfo
    ]).subscribe((res: [boolean, TenantSummary, any, HtmlContentInfo]) => {

      self.languageItems = _.map(
        self.languageService.getSupportedLanguages(),
        l => {
          return {
            icon: l.pictureUri,
            text: self.translate.instant(l.localizationToken),
            onClick: e => self.languageSelectionChanged(l.languageAlias)
          }
        });

      let isAuthenticated = res[0];
      let user = res[1].loggedInUser;
      let tenantSetting = res[1].tenantSetting;
      let contentInfo = res[3];

      window['ga']('create', 'UA-35179689-4', 'auto');
      if (!!tenantSetting.googleAnalyticsId) {
        window['ga']('create', tenantSetting.googleAnalyticsId, 'auto', 'tenant');
        this.angulartics2GoogleAnalytics.settings.additionalAccountNames.push('tenant');
      }
      this.angulartics2GoogleAnalytics.startTracking();

      self.navigation = [{
        id: 1,
        text: user != null ? user.fullName : '',
        icon: 'fa fa-user',
        link: "/my-account",
        visible: isAuthenticated,
      }, {
        id: 2,
        text: self.translate.instant('TXT_LOGIN'),
        icon: 'fa fa-sign-in',
        onClick: e => self.login(e),
        visible: !isAuthenticated,
      }, {
        id: 3,
        text: self.translate.instant('TXT_NEW_ACCOUNT'),
        icon: 'fa fa-user-plus',
        onClick: e => self.newUserRegistration(e),
        visible: !isAuthenticated && tenantSetting.enableUserRegistration,
      }, {
        id: 10,
        text: self.translate.instant('TXT_HOME'),
        icon: 'fa fa-home',
        link: "/"
      }, {
        id: 15,
        text: !!tenantSetting.myKidergartenMenuName
          ? tenantSetting.myKidergartenMenuName
          : self.translate.instant('TXT_MY_KINDERGARTEN'),
        icon: 'fa fa-users',
        onClick: e => self.onMyActivitiesClick(e),
        visible: isAuthenticated && tenantSetting.enableKindergartenSupport,
      }, {
        id: 20,
        text: !!tenantSetting.eventsMenuName
          ? tenantSetting.eventsMenuName
          : self.translate.instant('TXT_EVENTS'),
        icon: 'fa fa-briefcase',
        link: "/actions",
        visible: tenantSetting.allowClientActionRegistration,
        badge: res[1].noOfFeaturedActions > 0
          ? res[1].noOfFeaturedActions
          : null
      }, {
        id: 30,
        text: !!tenantSetting.coursesMenuName
          ? tenantSetting.coursesMenuName
          : self.translate.instant('TXT_COURSES'),
        icon: 'fa fa-graduation-cap',
        link: "/courses",
        visible: tenantSetting.allowClientCourseRegistration ||
          tenantSetting.coursePreviewOnlyRegistration ||
          tenantSetting.courseInterestsSupport
      }, {
        id: 40,
        text: !!tenantSetting.compensationsMenuName
          ? tenantSetting.compensationsMenuName
          : self.translate.instant('TXT_EXCUSES'),
        icon: 'fa fa-calendar',
        onClick: e => self.onSchedulesClick(e),
        visible: tenantSetting.enableClientUserSchedules && (tenantSetting.allowClientCourseRegistration || user.isAuthenticated)
      }, {
        id: 50,
        text: self.translate.instant('TXT_FORMS'),
        icon: 'fa fa-file-text-o',
        link: "/forms",
        visible: false
      }, {
        id: 60,
        text: !!tenantSetting.kidergartenMenuName
          ? tenantSetting.kidergartenMenuName
          : self.translate.instant('TXT_KINDERGARTEN'),
        icon: 'fa fa-female',
        onClick: e => self.onActivitiesClick(e),
        visible: tenantSetting.enableKindergartenSupport,
      }, {
        id: 65,
        text: !!tenantSetting.reservationsMainPageButton
          ? tenantSetting.reservationsMainPageButton
          : self.translate.instant('TXT_RESERVATIONS'),
        icon: 'fa fa-hourglass-half',
        onClick: e => self.onReservationsClick(e),
        visible: tenantSetting.enableReservationSupport,
      }, {
        id: 70,
        text: self.translate.instant('TXT_ATTENDANCE'),
        icon: 'fa fa-calendar-times-o',
        onClick: e => self.onAttendanceClick(e),
        visible: user.isAuthenticated && (
            (tenantSetting.enableReservationSupport && res[1].isInRole('Reservations')) ||
            (res[1].isInRole('ClientAttendance'))),
      }, {
        id: 75,
        text: self.translate.instant('TXT_TERMS_AND_CONDITIONS'),
        icon: 'fa fa-file-text-o',
        onClick: e => self.onHtmlContentInfoClick(e, HtmlContentType.TermsAndConditions),
        visible: !!contentInfo.info.TermsAndConditions,
      }, {
        id: 76,
        text: self.translate.instant('TXT_INSTRUCTIONS'),
        icon: 'fa fa-file-text-o',
        onClick: e => self.onHtmlContentInfoClick(e, HtmlContentType.Instructions),
        visible: !!contentInfo.info.Instructions,
      }, {
        id: 77,
        text: self.translate.instant('TXT_TRADE_TERMS'),
        icon: 'fa fa-file-text-o',
        onClick: e => self.onHtmlContentInfoClick(e, HtmlContentType.TradeTerms),
        visible: !!contentInfo.info.TradeTerms,
      }, {
        id: 78,
        text: self.translate.instant('TXT_PROTECTION_OF_PERSONAL_DATA'),
        icon: 'fa fa-file-text-o',
        onClick: e => self.onHtmlContentInfoClick(e, HtmlContentType.ProtectionOfPersonalData),
        visible: !!contentInfo.info.ProtectionOfPersonalData,
      }, {
        id: 79,
        text: tenantSetting.customContentTypeText,
        icon: 'fa fa-file-text-o',
        onClick: e => self.onHtmlContentInfoClick(e, HtmlContentType.Custom),
        visible: !!contentInfo.info.Custom,
      }, {
        id: 100,
        text: self.translate.instant('TXT_LOGOUT'),
        icon: 'fa fa-times-circle',
        onClick: e => self.logoutConfirmSheetVisible = true,
        visible: isAuthenticated,
      }, {
        id: 150,
        text: self.translate.instant('TXT_CHANGE_LANGUAGE'),
        icon: self.selectedLang.pictureUri,
        onClick: e => self.languageSheetVisible = true
      }, {
        id: 200,
        text: self.translate.instant('TXT_SWITCH_TO_DESKTOP'),
        icon: 'fa fa-desktop',
        onClick: e => self.switchToDesktop(e)
      }];

      self.formDefinitionService.getFormDefinitionAllActive().subscribe(f => {
        if (!!f && !!f.length) {
          var formsMenu = _.find(self.navigation, { 'id': 50 });
          formsMenu.visible = true;
          formsMenu.badge = f.length;
        }
      });

      this.loginService.onSingInRequested.subscribe(() => {
        this.loginAdvertDisplayed = true;
      });

      if (isAuthenticated && String.prototype.isNullOrWhitespace(user.fullName)) {
        this.router.navigate(["/my-account/user-account"], { queryParams: { registration: true } } );
      }

      this.route.queryParams.subscribe(params => {
        this.signInReturnUrl = params['signIn'];
        if (!!this.signInReturnUrl) {
          if (!isAuthenticated) {
            this.signInVisible = true;
          } else {
            self.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                'signIn': null
              },
              queryParamsHandling: 'merge',
              replaceUrl: true
            });
          }
        }
      });

    });

    this.translate.get('TXT_HOME').subscribe((res: string) => {
      this.logoutConfirmItems.push({ text: this.translate.instant('TXT_LOGOUT'), action: "logout" });
    });

    setTimeout(() => {
      this.reload = true;
      setTimeout(() => {
        this.reloadMenu = true;
      }, 50);
    }, 100);
  }

  loadView(e: any) {
    if (e.addedItems[0].link) {
      this.router.navigate([e.addedItems[0].link]);
    }
    this.menuOpened = false;
    //this.drawer.instance.hide();
  }

  onHtmlContentInfoClick(e: any, htmlContentType: HtmlContentType)
  {
    this.router.navigate(["/html-content", htmlContentType]);
  }

  onSchedulesClick(e: any) {
    this.tenantSummary.subscribe(ts => {
      let isLoggedIn = ts.loggedInUser.isAuthenticated;
      if (isLoggedIn || ts.tenantSetting.allowClientCourseRegistration) {
        this.router.navigate(['/schedules']);
      } else {
        this.returnUrl = e.itemData.link;
        this.loginAdvertDisplayed = true;
      }
    });
  }

  onActivitiesResolveClick(e: any) {
    this.tenantSummary.subscribe(ts => {
      if (ts.hasStudentOnActivity) {
        this.router.navigate([e.itemData.link]);
      } else {
        this.router.navigate(['/activities']);
      }
    });
  }

  onActivitiesClick(e: any) {
    this.router.navigate(['/activities']);
  }

  onReservationsClick(e: any) {
    this.router.navigate(['/reservations']);
  }

  onAttendanceClick(e: any) {
    this.router.navigate(['/attendance']);
  }

  onMyActivitiesClick(e: any) {
    this.router.navigate(['/my-activities']);
  }

  selectionChanged(e: any) {
    if (e.itemData.onClick) {
      e.itemData.onClick(e);
    } else {
      this.router.navigate([e.itemData.link]);
    }
  }

  switchToDesktop(e: any) {
    this._window.location.href = this.switchUrl;
  }

  newUserRegistration(e: any) {
    this.loginService.newUserRegistration(this.router.url);
  }

  login(e: any) {
    this.signInVisible = true;
  }

  myAccount() {
    this.router.navigate(["/my-account"]);
  }

  logoutConfirmClick() {
    this.loginService.logout().subscribe(() => {
      this._window.location.href = '/m/';
    }, err => {
      notifyError(this.loginService.translateError(err));
    });
  }

  languageSelectionChanged(languageAlias: string) {
    this.languageService.setLang(languageAlias);
    this._window.location.reload();
  }

  backBtnClick(e) {
    this.location.back();
  }

  lightBoxAlbum: Array<object> = [];
  showLightBox: boolean = false;
  lightBoxImageIndex: number = -1;

  closeLightBox() {
    this.showLightBox = false;
    this.lightBoxImageIndex = -1;
  }

  constructor(
    private route: ActivatedRoute,
    private zone:NgZone,
    private router: Router,
    private tenantService: TenantService,
    private _window: WindowWrapper,
    private translate: TranslateService,
    private languageService: LanguageService,
    private loginService: LoginService,
    private location: Location,
    private formDefinitionService: FormDefinitionService,
    private htmlContentService: HtmlContentService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private lightboxService: LightboxService) {

    this.lightboxService.onShowLightbox.subscribe(m => {
      this.lightBoxAlbum = m.lightBoxAlbum;
      this.lightBoxImageIndex = m.lightBoxImageIndex;
      this.showLightBox = true;
    });

    this.switchUrl = `${this._window.location.protocol}//${this._window.location.host}/ViewSwitcher/SwitchView?mobile=false&amp;returnUrl=/x`;
  }
}
