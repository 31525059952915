<dx-scroll-view>
  <app-classroom-calendar
    [(classroomID)]="classroomID"
    [(selectedDate)]="selectedDate"
    [highlightedDays]="highlightedDays"
    [filterForCourses]="true"
  ></app-classroom-calendar>
  <dx-list
    id="day-schedules"
    [items]="daySchedules"
    [pullRefreshEnabled]="false"
    height="auto"
    indicateLoading="false"
    [scrollingEnabled]="false"
    (onItemClick)="onScheduleClick($event)">
    <div *dxTemplate="let itemData of 'item'" >
      <div class="lesson-wrapper">
          <table class="lesson-content">
            <tr>
              <td class="time-cell"
                  [ngClass]="{'time-cell-action': itemData.type === SubjectCategory.OneOffActions, 'time-cell-course':  itemData.type === SubjectCategory.RegularCourses}">
                <div>{{itemData.startDate | date:'shortTime'}}</div>
                <div class="end-date">{{itemData.endDate | date:'shortTime'}}</div>
              </td>
              <td class="data-cell"
                  [ngClass]="{'data-cell-filled': itemData.status === AttendanceStatusIndication.CapacityFull, 'data-cell-booked': itemData.status === AttendanceStatusIndication.BookedOnLesson }"> 
                <div class="subject">{{itemData.subject}}</div>
                <div class="teacher dx-theme-accent-as-text-color">{{itemData.teacherName}}</div>
                <div class="filled">{{itemData.filledCapacity}}</div>
              </td>
            </tr>
          </table>
          
      </div>
    </div>
  </dx-list>
</dx-scroll-view>
<dx-load-panel
    [visible]="isLoadPanelVisible">
</dx-load-panel>
 