import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CourseService } from '../../../shared/services';
import { Course } from '../../../shared/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DxGalleryComponent } from "devextreme-angular";


@Component({
  selector: 'app-action-detail-home',
  templateUrl: './action-detail-home.component.html',
  styleUrls: ['./action-detail-home.component.css']
})
export class ActionDetailHomeComponent implements OnInit {

  courseID: Observable<number>;
  action: Observable<Course>;
  pictures: string[] = [];
  hasPictures: boolean = false;
  loading: boolean = true;
  returnUrl: string = '';

  @ViewChild("gallery") gallery: DxGalleryComponent

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseService: CourseService) { }

  ngOnInit() {
    this.courseID = this.route
      .params
      .pipe(map(params => +params['id']));

    this.courseID.subscribe(id => {
      this.action = this.courseService.getAction(id);
      this.returnUrl = `/actions/${id}/register`;

      this.courseService.getEffectivePictures(this.action).subscribe(
        res => {
          this.pictures.push.apply(this.pictures, res);
          this.hasPictures = this.pictures.length > 0;
          this.loading = false;
          let self = this;
          setTimeout(() => {
            self.gallery.instance.repaint();
          }, 5000);
        });
    })
  }

  register(params) {
    this.courseID.subscribe(id => {
      this.router.navigate(["/actions", id, "register"]);
    });
  }
}
