import {EventEmitter, Injectable} from '@angular/core';
import {LightboxModel} from "../models/lightboxModel";

@Injectable({
  providedIn: 'root'
})
export class LightboxService {

  public onShowLightbox: EventEmitter<LightboxModel> = new EventEmitter<LightboxModel>();

  constructor() { }

  public showLightbox(model: LightboxModel) {
    this.onShowLightbox.emit(model);
  }
}
