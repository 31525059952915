import { Expose, Exclude } from "class-transformer";

import { TransformDate } from '../../decorators';

import { ActivityAttendanceStatus } from '../enums/activityAttendanceStatus';
import { CourseAttendanceType } from '../enums/courseAttendanceType';

import * as moment from "moment";

export class ActivityAttendance {

    activityAttendanceID: number;
    studentID: number;
    activityScheduleID: number;

    @TransformDate()
    firstPeriodStartDate: Date;

    @TransformDate()
    firstPeriodEndDate: Date;

    @TransformDate()
    secondPeriodStartDate: Date;

    @TransformDate()
    secondPeriodEndDate: Date;

    lunch: boolean;
    firstSnack: boolean;
    secondSnack: boolean;
    notes: string;

    @Expose({ name: "firstPeriodAttendanceStatusID" })
    firstPeriodAttendanceStatus: ActivityAttendanceStatus;

    @Expose({ name: "firstPeriodAttendanceTypeID" })
    firstPeriodAttendanceType: CourseAttendanceType;

    @Expose({ name: "secondPeriodAttendanceStatusID" })
    secondPeriodAttendanceStatus: ActivityAttendanceStatus;

    @Expose({ name: "secondPeriodAttendanceTypeID" })
    secondPeriodAttendanceType: CourseAttendanceType;

    confirmed: boolean;
    activityReconciliationID: number;
    activityID: number;
    studentFullName: string;

    firstPeriodWaitQueuePosition: number;
    secondPeriodWaitQueuePosition: number;

    @Exclude()
    get scheduleDay(): Date {
        let date = this.firstPeriodStartDate || this.secondPeriodStartDate;
        return moment(date).startOf('day').toDate();
    }
}
