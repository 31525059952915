import { Type, Transform, Exclude } from "class-transformer";

import { TransformDate } from '../../decorators';

import { ActivityCompensationWaitQueue } from './activityCompensationWaitQueue';

import * as moment from "moment";

export class ActivitySchedule {
    activityScheduleID: number;
    activityID: number;
    semesterID: number;

    @TransformDate()
    firstPeriodStartDate: Date;

    @TransformDate()
    firstPeriodEndDate: Date;

    @TransformDate()
    secondPeriodStartDate: Date;

    @TransformDate()
    secondPeriodEndDate: Date;

    lunch: boolean;
    firstSnack: boolean;
    secondSnack: boolean;
    notes: string;
    firstPeriodCapacity: number;
    secondPeriodCapacity: number;

    @Exclude()
    get startDate(): Date {
        let date = this.firstPeriodStartDate || this.secondPeriodStartDate;
        return moment(date).startOf('day').toDate();
    }

    firstPeriodFilled: number;
    secondPeriodFilled: number;

    @Type(() => ActivityCompensationWaitQueue)
    firstPeriodWaitQueue: ActivityCompensationWaitQueue[];

    @Type(() => ActivityCompensationWaitQueue)
    secondPeriodWaitQueue: ActivityCompensationWaitQueue[];
}