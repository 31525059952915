import { Type, Transform } from "class-transformer";

import { ActivitySchedule } from './activitySchedule';
import { ActivityReservation } from './activityReservation';
import { ActivityAttendance } from './activityAttendance';
import { ActivityStudentExcuseByDate } from './activityStudentExcuseByDate';
import { ActivityStudentSetting } from './activityStudentSetting';

export class StudentActivityDetails {

    @Type(() => ActivitySchedule)
    schedules: ActivitySchedule[];

    @Type(() => ActivityReservation)
    reservations: ActivityReservation[];

    @Type(() => ActivityAttendance)
    attendances: ActivityAttendance[];

    @Type(() => ActivityStudentExcuseByDate)
    excusesByDate: ActivityStudentExcuseByDate[];

    @Type(() => ActivityStudentSetting)
    setting: ActivityStudentSetting;
}

