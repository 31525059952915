import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { TenantService } from '../services';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class CanActivateForActivitiesGuard implements CanActivate {

    constructor(
        private tenantService: TenantService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let self = this;

        return new Observable<boolean>(observer => {
            self.tenantService.getTenantSummary().subscribe((t) => {
                if (t.tenantSetting.enableKindergartenSupport) {
                    observer.next(true);
                } else {
                    observer.next(false);
                    self.router.navigate(["/"]);
                }
                observer.complete();
            },
                err => {
                    observer.error(err);
                    self.router.navigate(["/"]);
                });
        });
    }
}