import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ActivityAttendanceService, ActivityService } from '../../services';
import { ActivityStudentSummary, ActivityAttendanceModel } from '../../models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import Query from 'devextreme/data/query';

import * as _ from 'lodash';

@Component({
  selector: 'app-activity-calendar',
  templateUrl: './activity-calendar.component.html',
  styleUrls: ['./activity-calendar.component.css']
})
export class ActivityCalendarComponent implements OnInit {

  public activities: DataSource;
  private _activityID: number;
  private activitiesForceRefresh: boolean = false;
  private activityAttendanceModel: _.Dictionary<ActivityAttendanceModel>;

  isLoadPanelVisible: boolean = false;

  @Output() activityIDChange = new EventEmitter<Number>();
  @Input()
  get activityID(): number { return this._activityID; }
  set activityID(value: number) {
    if (this._activityID !== value) {
      this._activityID = value;
      this.activityIDChange.emit(value);
      this.loadAttendanceDetail();
    }
  }

  @Input()
  studentID: number;

  private _selectedDate: Date;
  @Output() selectedDateChange = new EventEmitter<Date>();
  @Input()
  get selectedDate(): Date {
    return this._selectedDate;
  }
  set selectedDate(value: Date) {
    this._selectedDate = value;
    this.selectedDateChange.emit(value);
  }

  private _shouldReload: boolean = false;
  @Input()
  get shouldReload(): boolean { return this._shouldReload; }
  set shouldReload(value: boolean) {
    if (value) {
      this.loadAttendanceDetail();
    }
  }


  constructor(
    private activityService: ActivityService,
    private activityAttendanceService: ActivityAttendanceService
  ) { }

  ngOnInit() {
    let self = this;

    self.loadAttendanceDetail();

    self.activities = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.activityService.getSummaryForStudent(self.studentID, self.activitiesForceRefresh).subscribe((data) => {
              let result = data;
              if (loadOptions.searchValue) {
                let filter = loadOptions.filter;
                result = Query(result).filter([loadOptions.searchExpr[0], loadOptions.searchOperation, loadOptions.searchValue]).toArray();
              }
              resolve(result);
              self.activitiesForceRefresh = false;
            }, (error) => {
              reject(error);
            });
          });
        },
        byKey: (key: any) => {
          return new Promise((resolve, reject) => {
            self.activityService.getSummaryForStudentAndActivity(self.studentID, key).subscribe((data) => {
              resolve(data);
            }, (error) => {
              reject(error);
            })
          })
        },
      })
    });
  }

  loadAttendanceDetail(refresh: boolean = false) {
    let self = this;

    if (self.activityID && self.studentID) {
      self.isLoadPanelVisible = true;
      self.activityAttendanceService.getStudentAttendanceDetail(self.activityID, self.studentID, refresh)
        .subscribe(r => {
          self.activityAttendanceModel = _.keyBy(r, 'startDate');
          self.isLoadPanelVisible = false;
        }, err => {
          self.isLoadPanelVisible = false;
        });
    }
  }

  onPullActivityRefresh() {
    this.activitiesForceRefresh = true;
    this.activities.load();
  }

  getDayStyleWeight(date: Date, text: string, view: string) {
    if (this.activityAttendanceModel) {
      let day = this.activityAttendanceModel[date.toString()];
      if (day && day.attending) {
        return "bold";
      }
    }
    return "inherit";
  }

}
