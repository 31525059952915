import {NgModule, OnInit, Component, enableProdMode, ViewChild, ChangeDetectorRef, Inject} from '@angular/core';
import { CurrencyPipe } from '@angular/common'
import { Router, ActivatedRoute, Params } from '@angular/router';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Reservation, Specialization } from '../../shared/models/reservations';
import {
  TenantSummary,
  DayPlaceAvailability,
  Employee,
  Domain,
  Student,
  Classroom
} from '../../shared/models';
import { ReservationStartTimeType } from '../../shared/models/enums/reservationStartTimeType';
import { TranslateService } from '@ngx-translate/core';

import {
  TenantService,
  EmployeeService,
  ReservationService,
  ClassroomService,
  PlaceAvailabilityService,
  DomainService,
  StudentService,
  ReservationPriceService, OnlinePaymentService, AccountingBookService
} from '../../shared/services';

import { DxSchedulerComponent } from 'devextreme-angular';

import {Observable, forkJoin, combineLatest} from 'rxjs';

import * as _ from 'lodash';
import * as moment from "moment";
import {SESSION_STORAGE, StorageService} from "ngx-webstorage-service";
import {WindowWrapper} from "../../shared/windowWrapper";
import {map} from "rxjs/operators";

const STORAGE_KEY = 'reservation-payment-history-index';

@Component({
  selector: 'app-reservations-place-domain',
  templateUrl: './reservations-place-domain.component.html',
  styleUrls: ['./reservations-place-domain.component.css']
})
export class ReservationsPlaceDomainComponent implements OnInit {

  @ViewChild(DxSchedulerComponent) scheduler: DxSchedulerComponent;

  currentDate: Date = new Date();
  reservations: DataSource;
  classrooms: DataSource;
  placeId: string;
  domainId: string;
  specializationId: string;
  selectedCellData = [];
  userId: string;
  currency: string;

  selectedEmployees: Observable<Employee[]>;
  selectedEmployeesResolved: Employee[];

  selectedClassroom: Classroom;

  onlinePaymentConfirmItems: any[] = [];
  onlinePaymentConfirmMessage: string = '';
  onlinePaymentConfirmVisible: boolean = false;
  onlinePaymentReservation: Reservation = null;

  private onlinePaymentID: number;
  confirmPaymentMessage: string = '';
  confirmPaymentVisible: boolean = false;

  editingOptions: {
    allowAdding: boolean,
    allowDeleting: boolean,
    allowDragging: boolean,
    allowResizing: boolean,
    allowUpdating: boolean
  } = {
      allowAdding: true,
      allowDeleting: false,
      allowDragging: false,
      allowResizing: false,
      allowUpdating: false
    };

  displayRange: {
    startDayHour: number,
    endDayHour: number
  } = {
      startDayHour: 8,
      endDayHour: 23
    };

  dayPlaceAvailability: DayPlaceAvailability[];
  availableInfoVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tenantService: TenantService,
    private employeeService: EmployeeService,
    private reservationService: ReservationService,
    private placeAvailabilityService: PlaceAvailabilityService,
    private classroomService: ClassroomService,
    private translate: TranslateService,
    private domainService: DomainService,
    private studentService: StudentService,
    private reservationPriceService: ReservationPriceService,
    private cp: CurrencyPipe,
    private ref: ChangeDetectorRef,
    private onlinePaymentService: OnlinePaymentService,
    private _window: WindowWrapper,

    private accountingBookService: AccountingBookService,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) { }

  ngOnInit() {

    let self = this;

    let urlParams = combineLatest([
      self.route.parent.params,
      self.route.queryParams
    ]).pipe(map((params) => ({ ...params[0], ...params[1] })));

    combineLatest([
      urlParams,
      self.translate.get('TXT_PAY_ONLINE')])
      .subscribe((res: [[string], string]) => {
        let urlParams = res[0];

        self.onlinePaymentID = +urlParams['onlinePaymentID'];

        self.onlinePaymentConfirmItems.push({ text: this.translate.instant('TXT_PAY_ONLINE') });
        self.onlinePaymentConfirmMessage = this.translate.instant('TXT_ONLINE_PAYMENT_QUESTION');

        if (self.onlinePaymentID) {
          self.onlinePaymentService.getTransactionState(self.onlinePaymentID).subscribe((op) => {
            self.confirmPaymentMessage = self.onlinePaymentService.getOnlinePaymentResultMessage(op);
            self.confirmPaymentVisible = true;
          });
        }
    });

    self.placeId = self.route.snapshot.params['placeId'];
    self.domainId = self.route.snapshot.params['domainId'];
    self.specializationId = self.route.snapshot.params['specializationId'];

    self.classrooms = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.tenantService.getTenantSummary()
              .subscribe(ts => {
                self.classroomService.getByID(self.placeId).subscribe(c => {

                  self.selectedClassroom = c;

                  var classroom = {
                    id: c.id + "",
                    name: c.name
                  };

                  resolve(classroom);
                }, (error) => {
                  reject(error);
                });
              });
          });
        }
      })
    });

    self.reservations = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.tenantService.getTenantSummary()
              .subscribe(ts => {
                self.userId = ts.loggedInUser.userID + "";
                self.currency = ts.tenantInfo.currency;
                forkJoin([
                  self.reservationService.getAllByRangeAndUser(
                    self.getStartDate(),
                    self.getEndDate(),
                    self.userId),
                  self.placeAvailabilityService.getAllByRangeAndDomains(
                    self.placeId,
                    self.getStartDate(),
                    self.getEndDate(),
                    [{
                      id: self.domainId,
                      specializations: !!self.specializationId
                        ? [{ id: self.specializationId }]
                        : []
                    }]
                  )
                ]).subscribe((res: [Reservation[], DayPlaceAvailability[]]) => {

                  self.dayPlaceAvailability = res[1];
                  if (!!self.dayPlaceAvailability && self.dayPlaceAvailability.length == 1 &&
                    !!self.dayPlaceAvailability[0].periods.length) {

                    let maxAvailEndDate = _.maxBy(self.dayPlaceAvailability[0].periods, 'end');
                    let minAvailStartDate = _.minBy(self.dayPlaceAvailability[0].periods, 'start');

                    let maxResEndDate = _.maxBy(res[0], 'reservationEndDate');
                    let minResStartDate = _.minBy(res[0], 'reservationStartDate');

                    let maxEndDate = Math.max(
                      moment(maxAvailEndDate.end).hour(),
                      moment(maxResEndDate.reservationEndDate).hour());
                    let minStartDate = Math.min(
                      moment(minAvailStartDate.start).hour(),
                      moment(minResStartDate.reservationStartDate).hour());

                    if (!!maxEndDate) {
                      let maxHour = Math.min(24, maxEndDate + 1);
                      let minHour = Math.max(0, minStartDate - 0.5);

                      if (minHour > ts.tenantSetting.scheduleVisibleTimeStart &&
                        minHour - ts.tenantSetting.scheduleVisibleTimeStart < 2) {
                        minHour = ts.tenantSetting.scheduleVisibleTimeStart;
                      }

                      self.displayRange.startDayHour = minHour;
                      self.displayRange.endDayHour = maxHour;
                      self.ref.detectChanges();
                    }
                  } else {
                    self.displayRange.startDayHour = ts.tenantSetting.scheduleVisibleTimeStart;
                    self.displayRange.endDayHour = ts.tenantSetting.scheduleVisibleTimeEnd;
                  }

                  resolve(res[0]);
                }, (error) => {
                  reject(error);
                });
              }, (error) => {
                reject(error);
              });
          });
        },
        insert: data => {
          return new Promise((resolve, reject) => {
            self.reservationService.insert(data)
              .subscribe(r => {
                self.accountingBookService.getDefault().subscribe(ab => {
                  if (ab.onlinePaymentConfigurationID) {
                    self.onlinePaymentReservation = r;
                    self.onlinePaymentConfirmVisible = true;
                  }
                });
                resolve(r);
              }, (error) => {
                reject(error);
              })
          });
        }
      })
    });
  }

  // -1 - unavailable (taken) - not implemented yet
  //  0 - unavailable (not scheduled)
  //  1 - available
  getDataCellAvailability(startDate: Date, endDate: Date) {

    if (!this.dayPlaceAvailability || this.dayPlaceAvailability.length != 1) {
      return 0;
    }

    return !!_.find(this.dayPlaceAvailability[0].periods, p =>
      moment(p.start).isSameOrBefore(startDate) && moment(p.end).isSameOrAfter(endDate)
    ) ? 1 : 0;

  }

  getStartDate(): Date {
    return moment(this.currentDate).startOf('day').toDate();
  }

  getEndDate(): Date {
    return moment(this.currentDate).endOf('day').toDate();
  }

  onCellClick(e) {

    let self = this;

    e.cancel = true;
    if (!self.dayPlaceAvailability || self.dayPlaceAvailability.length != 1) {
      return;
    }

    if (this.selectedCellData.length > 0) {

      let availability = _.find(self.dayPlaceAvailability[0].periods, p =>
        moment(p.start).isSameOrBefore(self.selectedCellData[0].startDate) &&
        moment(p.end).isSameOrAfter(self.selectedCellData[0].endDate));

      if (!!availability) {
        let employeeIds = availability.employeeIds;
        self.selectedEmployees = self.employeeService.getByEmployeeIds(employeeIds);
        self.availableInfoVisible = true;
      }
    }
  }

  calculatePrice(r: Reservation) {
    if (!!r.studentIds && r.studentIds.length > 0) {
      this.reservationPriceService.calculateReservationPrice(r)
        .subscribe(p => {
          r.price = p.price;
        });
    }
  }

  onEmployeeClick(event, employee) {
    let self = this;

    forkJoin([
      self.selectedEmployees,
      self.domainService.getAll(),
      self.studentService.getCurrent()])
      .subscribe((res: [Employee[], Domain[], Student[]]) => {

      self.availableInfoVisible = false;
      self.selectedEmployeesResolved = res[0];

      let domain: Domain = _.find(res[1], { 'id': self.domainId });
      let specialization: Specialization;
      if (!!self.specializationId) {
        specialization = _.find(domain.specializations, { 'id': self.specializationId });
      }

      let appointmentData = {
        userId: self.userId,
        reservationStartDate: self.selectedCellData[0].startDate,
        reservationEndDate: moment(self.selectedCellData[0].startDate).add(1, 'hour').toDate(),
        placeId: self.placeId,
        domain: domain,
        domainSpecialization: specialization,
        employeeId: employee.id,
        price: 0,
        students: res[2]
      };

      self.scheduler.instance.showAppointmentPopup(
        appointmentData,
        true,
        null
      );
    });
  }

  onAppointmentFormOpening(data) {
    let self = this;
    let form = data.form;
    let formData = form.option('formData');

    var tbStartDate, tbEndDate;

    let employeesDS: DataSource;
    let isNew: boolean = !formData.id;
    if (!isNew) {
      employeesDS = new DataSource({
        paginate: false,
        store: new CustomStore({
          load: loadOptions => {
            return new Promise((resolve, reject) => {
              self.employeeService.getByEmployeeIds(formData.employeeId)
                .subscribe(e => {
                  resolve(e);
                }, (error) => {
                  reject(error);
                });
            });
          },
          byKey: (key: any) => {
            return new Promise((resolve, reject) => {
              self.employeeService.getByEmployeeIds(key)
                .subscribe((data) => {
                  resolve(data);
                }, (error) => {
                  reject(error);
                })
            })
          },
        })
      });
    }

    if (isNew) {
      data.popup.option('title', self.translate.instant('TXT_RESERVATION_CREATE'));
    } else {
      data.popup.option('title', self.translate.instant('TXT_RESERVATION_DETAIL'));
    }

    let items: any = [{
      label: {
        text: self.translate.instant('TXT_PLACE')
      },
      name: "place",
      dataField: "placeId",
      editorType: "dxSelectBox",
      editorOptions: {
        dataSource: self.classrooms,
        displayExpr: 'name',
        valueExpr: 'id'
      }
    }, {
      label: {
        text: self.translate.instant('TXT_LECTURER')
      },
      name: "lecturer",
      dataField: "employeeId",
      editorType: "dxSelectBox",
      editorOptions: {
        dataSource: !isNew
          ? employeesDS
          : self.selectedEmployeesResolved,
        displayExpr: 'fullName',
        valueExpr: 'id',
        onValueChanged: function (v) {
          self.calculatePrice(formData);
        }
      }
    }, {
      label: {
        text: this.translate.instant('TXT_LESSON_START')
      },
      dataField: "reservationStartDate",
      editorType: "dxDateBox",
      editorOptions: {
        width: "100%",
        type: "datetime",
        onInitialized: function (e) {
          tbStartDate = e.component;
        },
        onValueChanged: function (e) {
          self.calculatePrice(formData);
          if (!tbEndDate.option('isValid')) {
            form.validate();
          }
        }
      },
      validationRules: [{
        type: "required",
        message: self.translate.instant('TXT_ERROR_REQUIRED_TMPL').f(self.translate.instant('TXT_LESSON_START'))
      }, {
        type: "custom",
        validationCallback: function (e) {
          return moment(e.value).isBefore(formData.reservationEndDate);
        },
        reevaluate: true,
        message: self.translate.instant('TXT_ERROR_MUST_BE_EARLIER_THEN_PARAM').f(
          self.translate.instant('TXT_LESSON_START'),
          self.translate.instant('TXT_LESSON_END')
        )
      }, {
        type: "custom",
        validationCallback: function (e) {
          var diffHours = Math.abs(moment(formData.reservationEndDate).diff(e.value, 'hours', true));
          return diffHours === 1.0 || diffHours === 2.0;
        },
        reevaluate: true,
        message: self.translate.instant('TXT_LESSON_LENGTH_CAN_BE_1_OR_2_HOURS')
      }, {
        type: "custom",
        validationCallback: function (e) {
          if (self.selectedClassroom.reservationStartTimeType == ReservationStartTimeType.CanStartAtHalfHourOnly) {
            return moment(e.value).minute() == 30;
          }
          return true;
        },
        reevaluate: true,
        message: self.translate.instant('TXT_LESSON_CAN_START_AT_HALF_HOUR_ONLY')
      }, {
        type: "custom",
        validationCallback: function (e) {
          if (self.selectedClassroom.reservationStartTimeType == ReservationStartTimeType.CanStartAtWholeHourOnly) {
            return moment(e.value).minute() == 0;
          }
          return true;
        },
        reevaluate: true,
        message: self.translate.instant('TXT_LESSON_CAN_START_AT_WHOLE_HOUR_ONLY')
      }, {
        type: "custom",
        validationCallback: function (e) {
          if (self.selectedClassroom.reservationStartTimeType == ReservationStartTimeType.CanStartAtWholeOrHalfHour) {
            return moment(e.value).minute() == 0;
          }
          return true;
        },
        reevaluate: true,
        message: self.translate.instant('TXT_LESSON_CAN_START_AT_WHOLE_OR_HALF_HOUR_ONLY')
      }]
    }, {
      label: {
        text: this.translate.instant('TXT_LESSON_END')
      },
      dataField: "reservationEndDate",
      editorType: "dxDateBox",
      editorOptions: {
        width: "100%",
        type: "datetime",
        onInitialized: function (e) {
          tbEndDate = e.component;
        },
        onValueChanged: function (e) {
          self.calculatePrice(formData);
          if (!tbStartDate.option('isValid')) {
            form.validate();
          }
        }
      },
      validationRules: [{
        type: "required",
        message: self.translate.instant('TXT_ERROR_REQUIRED_TMPL').f(self.translate.instant('TXT_LESSON_END'))
      }, {
        type: "custom",
        validationCallback: function (e) {
          return moment(e.value).isAfter(formData.reservationStartDate);
        },
        reevaluate: true,
        message: self.translate.instant('TXT_ERROR_MUST_BE_LATER_THEN_PARAM').f(
          self.translate.instant('TXT_LESSON_END'),
          self.translate.instant('TXT_LESSON_START')
        )
      }, {
        type: "custom",
        validationCallback: function (e) {
          var diffHours = Math.abs(moment(e.value).diff(formData.reservationStartDate, 'hours', true));
          return diffHours === 1.0 || diffHours === 2.0;
        },
        reevaluate: true,
        message: self.translate.instant('TXT_LESSON_LENGTH_CAN_BE_1_OR_2_HOURS')
      }]
    }, {
      label: {
        text: this.translate.instant('TXT_DOMAIN'),
      },
      editorType: "dxTextBox",
      editorOptions: {
        value: !!formData.domainSpecialization
          ? formData.domainSpecialization.name
          : formData.domain.name,
        width: "100%"
      }
    }, {
      label: {
        text: self.translate.instant('TXT_STUDENTS')
      },
      dataField: "studentIds",
      editorType: "dxTagBox",
      editorOptions: {
        dataSource: formData.students,
        displayExpr: 'fullName',
        valueExpr: 'id',
        showSelectionControls: true,
        applyValueMode: "useButtons",
        multiline: true,
        width: "100%",
        onValueChanged: function (v) {
          self.calculatePrice(formData);
        },
      },
      validationRules: [{
        type: "required",
        message: self.translate.instant('TXT_ERROR_REQUIRED_TMPL').f(self.translate.instant('TXT_STUDENTS'))
      }]
    }, {
      label: {
        text: self.translate.instant('TXT_PRICE'),
      },
      dataField: "price",
      editorType: "dxNumberBox",
      editorOptions: {
        format: "#,##0.##" + this.cp.transform(0, self.currency, 'symbol', '1.0-2', 'cs-CZ').replace(/[0-9]/g, ''),
      }
    }, {
      label: {
        text: self.translate.instant('TXT_NOTES')
      },
      dataField: "notes",
      editorType: "dxTextArea",
      editorOptions: {
        height: '84px',
      }
    }];

    form.option("items", items);
  }

  onlinePaymentConfirmClick() {
    if (this.onlinePaymentReservation) {
      let invoiceId = this.onlinePaymentReservation.invoiceId;
      let price = this.onlinePaymentReservation.price;

      this.storage.set(STORAGE_KEY, this._window.history.length);

      this.onlinePaymentService.startOnlinePayment(
        [invoiceId],
        price);
    }
  }

  onConfirmPaymentResultClick(param: any) {
    let self = this;
    self.confirmPaymentVisible = false;
    self.confirmPaymentMessage = '';

    let queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['onlinePaymentID'] = null;

    self.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      replaceUrl: true
    });

    self.onlinePaymentID = null;

    // Navigate back in history prior to online payment
    let storedIndex = this.storage.get(STORAGE_KEY) || this._window.history.length;

    let noOfPagesToRemove = storedIndex - this._window.history.length;
    this._window.history.go(noOfPagesToRemove);
  }

}
