
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { plainToClass } from "class-transformer";

import { FormDefinition } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class FormDefinitionService extends BaseService {

  private baseServiceUrl: string;
  private activeFormsDefinitions: Observable<FormDefinition[]>;

  constructor(
    private http: HttpClient
  ) {
    super();
    this.baseServiceUrl = '/restapi/forms-definitions/';
  }

  getFormDefinitionById(id: string): Observable<FormDefinition> {
    let headers = this.getDefaultHttpHeaders();
    let url: string = this.baseServiceUrl + id;

    return this.http
      .get(url, { headers: headers }).pipe(
        catchError((err, c) => this.handleErrorAndThrow(err)),
        map((res: Object) => {
          return plainToClass(FormDefinition, res);
        }));
  }

  getFormDefinitionAllActive(refresh: boolean = false): Observable<FormDefinition[]> {
    if (!this.activeFormsDefinitions || refresh) {
      let headers = this.getDefaultHttpHeaders();
      let url: string = this.baseServiceUrl + 'find-by-active';
      this.activeFormsDefinitions = this.http
        .get(url, { headers: headers }).pipe(
          catchError((err, c) => this.handleErrorAndThrow(err)),
          map((res: Object[]) => plainToClass(FormDefinition, res)),
          publishReplay(1),
          refCount());
    }

    return this.activeFormsDefinitions;
  }
}
