import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-full-course-stamp',
  templateUrl: './full-course-stamp.component.html',
  styleUrls: ['./full-course-stamp.component.css']
})
export class FullCourseStampComponent implements OnInit {

  constructor() { }

  @Input()
  stampSize: string;

  @Input()
  allowBooking: boolean;

  ngOnInit() {
  }

}
