<dx-action-sheet #onlinePaymentConfirmSheet
  title="{{onlinePaymentConfirmMessage}}"
  cancelText="{{'TXT_CANCEL' | translate}}"
  [items]="onlinePaymentConfirmItems"
  [(visible)]="onlinePaymentConfirmVisible"
  [showTitle]="true"
  [showCancelButton]="!preferOnlinePayments"
  (onItemClick)="onlinePaymentConfirmClick()">
</dx-action-sheet>
<dx-load-panel #loadPanel
  [(visible)]="loading"
  [showIndicator]="true"
  [showPane]="false"
  [shading]="false"
  closeOnOutsideClick="false">
</dx-load-panel>
<dx-load-panel
  [(visible)]="saving"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  message="{{'TXT_SAVING' | translate}}"
  closeOnOutsideClick="false">
</dx-load-panel>


<div *ngIf="!loading">
  <form>
    <dx-validation-group>
      <div *ngFor="let student of userRegistration.courseRegistrations"
        class="studentRegistration">
        <app-student-course-registration
          [student]="student"
          [isAction]="true">
        </app-student-course-registration>
      </div>

      <div *ngIf="!hasAnyStudents" class="bg-danger text-message wb-button-margin padded-content">
        {{'TXT_NO_STUDENTS_ERROR' | translate}}
        <div class="text-center padded-content">
          <dx-button text="{{'TXT_ADD_STUDENTS' | translate}}"
                     class="wb-button"
                     type="danger"
                     (onClick)="navigateToStudents()">
          </dx-button>
        </div>
      </div>

      <div class="text-center wb-button-margin"
        *ngIf="!loading">
        <app-button-with-login text="{{'TXT_SAVE' | translate}}"
          type="success"
          [disabled]="!userRegistration.isModified"
          (onClick)="showConfirmRegistration($event)"></app-button-with-login>
      </div>
    </dx-validation-group>
  </form>
</div>

<dx-popup showTitle="true"
  title="{{'TXT_REGISTER_ON_EVENT' | translate}}"
  fullScreen="true"
  showCloseButton="true"
  [(visible)]="confirmVisible">
  <div *dxTemplate="let data of 'content'">
    <app-action-detail-register-confirm
      [courseUserRegistration]="userRegistration"
      [shown]="confirmVisible"
      (onCancel)="registractionCanceled($event)"
      (onConfirm)="registrationConfirmed($event)">
    </app-action-detail-register-confirm>
  </div>
</dx-popup>

<dx-popup showTitle="true"
  title="{{'TXT_INFORMATION' | translate}}"
  [maxHeight]="230"
  [(visible)]="confirmPaymentVisible">
  <div *dxTemplate="let data of 'content'">
    <p>{{confirmPaymentMessage}}</p>
    <div class="text-center">
      <dx-button text="{{'TXT_OK' | translate}}"
        class="wb-button"
        type="success"
        (onClick)="onConfirmPaymentResultClick($event)">
      </dx-button>
    </div>
  </div>
</dx-popup>
