import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormDefinitionService, TenantService } from '../shared/services';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { FormDefinition } from 'app/shared/models';

import { FormsAccessPermissionsType } from 'app/shared/models/enums/formsAccessPermissionsType';


import * as _ from 'lodash';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {

  formDefinitions: DataSource;
  loginAdvertDisplayed: boolean = false;
  isLoggedIn: boolean = false;
  returnUrl: string = '/forms';

  constructor(
    private router: Router,
    private tenantService: TenantService,
    private formService: FormDefinitionService
  ) { }

  ngOnInit() {
    let self = this;

    self.tenantService.getTenantSummary().subscribe(res => {
      self.isLoggedIn = res.loggedInUser.isAuthenticated;
    });

    self.formDefinitions = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.formService.getFormDefinitionAllActive().subscribe((data) => {
              let sortedData = _.sortBy(data, 'name');
              if (!!loadOptions.searchValue) {
                resolve(self.applyFilter(sortedData, <string>loadOptions.searchValue));
              } else {
                resolve(sortedData);
              }
            }, (error) => {
              reject(error);
            });
          });

        }
      })
    });
  }

  onPullRefresh() {
    this.formDefinitions.load();
  }

  applyFilter(formDefinitions: FormDefinition[], filter: string) {
    return _.filter(formDefinitions, function (formDefinition) {
      return (!String.prototype.isNullOrWhitespace(formDefinition.name) && formDefinition.name.indexOf(filter) != -1);
    });
  }

  searchValueChanged(e: any) {
    this.formDefinitions.searchValue(e.value);
    this.formDefinitions.load();
  }

  onItemClick(params) {
    let formDefinition: FormDefinition = params.itemData;
    if (formDefinition.accessPermissions == FormsAccessPermissionsType.LoggedInUsersOnlyStudentRequired ||
      formDefinition.accessPermissions == FormsAccessPermissionsType.LoggedInUsersOnlyNoStudent) {
      if (!this.isLoggedIn) {
        this.loginAdvertDisplayed = true;
        return;
      }
    }

    this.router.navigate(["/forms", formDefinition.id, "form-response"]);
  }
}
