import { Type, Transform, Expose } from "class-transformer";
import { ActivitySummary } from "./activitySummary";

export class ActivityStudentSummary {
    studentID: number;
    studentFullName: string;

    @Type(() => ActivitySummary)
    @Expose({ name: "activities" })
    items: ActivitySummary[];
}