import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import { CourseUserRegistration } from '../../../shared/models';
import { CourseRegistrationService, TenantService } from '../../../shared/services';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-course-detail-register-confirm',
  templateUrl: './course-detail-register-confirm.component.html',
  styleUrls: ['./course-detail-register-confirm.component.css']
})
export class CourseDetailRegisterConfirmComponent implements OnInit {

  constructor(
    private courseRegistrationService: CourseRegistrationService,
    private tenantService: TenantService
  ) { }

  @Input() courseInterestsSupport: boolean;
  @Input() courseUserRegistration: CourseUserRegistration;
  @Output() onCancel = new EventEmitter<boolean>();
  @Output() onConfirm = new EventEmitter<boolean>();

  courseRegistrationAgreement: string;
  requireTermsAndConditionsAgreement: boolean;
  requireTradeTermsAgreement: boolean;

  showAgreementSection: boolean = false;

  courseRegistrationAgreementConfirmed: boolean = false;
  requireTermsAndConditionsAgreementConfirmed: boolean = false;
  requireTradeTermsAgreementConfirmed: boolean = false;

  confirmVisible: boolean = false;

  get registerDescription(): Observable<[string, string]> {
    return this.courseRegistrationService.getConfirmRegistrationDescription(
      this.courseUserRegistration,
      false
    );
  }

  ngOnInit() {
    this.tenantService.getTenantSummary().subscribe(t => {
      this.courseRegistrationAgreement = t.tenantSetting.courseRegistrationAgreement;
      if (!this.courseRegistrationAgreement) {
        this.courseRegistrationAgreementConfirmed = true;
      }

      this.requireTermsAndConditionsAgreement = t.tenantSetting.requireTermsAndConditionsAgreement;
      if (!this.requireTermsAndConditionsAgreement) {
        this.requireTermsAndConditionsAgreementConfirmed = true;
      }

      this.requireTradeTermsAgreement = t.tenantSetting.requireTradeTermsAgreement;
      if (!this.requireTradeTermsAgreement) {
        this.requireTradeTermsAgreementConfirmed = true;
      }

      this.showAgreementSection = !!t.tenantSetting.courseRegistrationAgreement ||
        !!t.tenantSetting.requireTermsAndConditionsAgreement ||
        !!t.tenantSetting.requireTradeTermsAgreement;
    });
  }

  onNoClick(params) {
    this.onCancel.emit(true);
  }

  onYesClick(params) {
    if (!this.courseRegistrationAgreementConfirmed ||
        !this.requireTradeTermsAgreementConfirmed ||
        !this.requireTermsAndConditionsAgreementConfirmed) {
      this.confirmVisible = true;
    } else {
      this.onConfirm.emit(true);
    }
  }

  onInformationConfirm(params) {
    this.confirmVisible = false;
  }

}
