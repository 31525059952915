


<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentName < 2">
  <div class="dx-field-label">{{ 'TXT_NAME' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentName == 1" class="dx-field-item-required-mark"> *</span></div>
  <div class="dx-field-value">
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentName == 1"
                 [(value)]="student.name">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_NAME' | translate) }, { type: 'stringLength', max: 30 }]"></dx-validator>
    </dx-text-box>
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentName == 0"
                 [(value)]="student.name">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
    </dx-text-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'TXT_SURNAME' | translate }}
    <span class="dx-field-item-required-mark"> *</span></div>
  <div class="dx-field-value">
    <dx-text-box [(value)]="student.surname">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_SURNAME' | translate) }, { type: 'stringLength', max: 30 }]"></dx-validator>
    </dx-text-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'TXT_MIDDLE_NAME' | translate }}</div>
  <div class="dx-field-value">
    <dx-text-box [(value)]="student.middleName">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 30 }]"></dx-validator>
    </dx-text-box>
  </div>
</div>

<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentBirthday < 2">
  <div class="dx-field-label">{{ 'TXT_BIRTHDAY' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentBirthday == 1" class="dx-field-item-required-mark"> *</span></div>
  <div class="dx-field-value">
    <dx-date-box *ngIf="tenantSetting?.mandatoryStudentBirthday == 1"
                 [(value)]="student.birthday"
                 pickerType="native"
                 type="date">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_BIRTHDAY' | translate) }]"></dx-validator>
    </dx-date-box>
    <dx-date-box *ngIf="tenantSetting?.mandatoryStudentBirthday == 0"
                 [(value)]="student.birthday"
                 type="date"
                 pickerType="native"
                 [showClearButton]="true">
    </dx-date-box>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'TXT_GENDER' | translate }}
    <span class="dx-field-item-required-mark"> *</span>
  </div>
  <div class="dx-field-value">
    <dx-lookup [(value)]="student.gender"
               [dataSource]="genders"
               valueExpr="id"
               fullScreen="true"
               [searchEnabled]="false"
               displayExpr="name">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_GENDER' | translate)  }]"></dx-validator>
    </dx-lookup>
  </div>
</div>

<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentPersonalIDNumber < 2">
  <div class="dx-field-label">{{ 'TXT_PERSONAL_ID_NUMBER' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentPersonalIDNumber == 1" class="dx-field-item-required-mark"> *</span>
  </div>
  <div class="dx-field-value">
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentPersonalIDNumber == 1"
                 [(value)]="student.personalIDNumber"
                 [placeholder]="'TXT_NO_SLASH' | translate">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PERSONAL_ID_NUMBER' | translate)  }, { type: 'stringLength', max: 80 }, { type: 'pattern', pattern: personalIdPattern}]"></dx-validator>
    </dx-text-box>
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentPersonalIDNumber == 0"
                 [(value)]="student.personalIDNumber"
                 [placeholder]="'TXT_NO_SLASH' | translate">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 80 }, { type: 'pattern', pattern: personalIdPattern}]"></dx-validator>
    </dx-text-box>
  </div>
</div>


<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentNationality < 2">
  <div class="dx-field-label">{{ 'TXT_NATIONALITY' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentNationality == 1" class="dx-field-item-required-mark"> *</span>
  </div>
  <div class="dx-field-value">
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentNationality == 1"
                 [(value)]="student.nationality">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_NATIONALITY' | translate)  }, { type: 'stringLength', max: 60 }]"></dx-validator>
    </dx-text-box>
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentNationality == 0"
                 [(value)]="student.nationality">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 60 }]"></dx-validator>
    </dx-text-box>
  </div>
</div>

<div class="dx-field"
     *ngIf="tenantSetting?.showHealthInCourseRegistration">
  <div class="dx-field-label">{{ 'TXT_HEALTH' | translate }}</div>
  <div class="dx-field-value">
    <dx-text-box [(value)]="student.health"
                 placeholder="{{'TXT_HEALTH_OK' | translate}}">
    </dx-text-box>
  </div>
</div>

<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentAllergies < 2">
  <div class="dx-field-label">{{ 'TXT_LBL_ALLERGIES' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentAllergies == 1" class="dx-field-item-required-mark"> *</span>
  </div>
  <div class="dx-field-value">
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentAllergies == 1"
                 [(value)]="student.allergies">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_LBL_ALLERGIES' | translate)  }, { type: 'stringLength', max: 120 }]"></dx-validator>
    </dx-text-box>
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentAllergies == 0"
                 [(value)]="student.allergies">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 120 }]"></dx-validator>
    </dx-text-box>
  </div>
</div>

<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentHealthInsurance < 2">
  <div class="dx-field-label">{{ 'TXT_LBL_HEALTH_INSURANCE' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentHealthInsurance == 1" class="dx-field-item-required-mark"> *</span>
  </div>
  <div class="dx-field-value">
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentHealthInsurance == 1"
                 [(value)]="student.healthInsurance">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_LBL_HEALTH_INSURANCE' | translate)  }, { type: 'stringLength', max: 60 }]"></dx-validator>
    </dx-text-box>
    <dx-text-box *ngIf="tenantSetting?.mandatoryStudentHealthInsurance == 0"
                 [(value)]="student.healthInsurance">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 60 }]"></dx-validator>
    </dx-text-box>
  </div>
</div>

<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentAddress < 2">
  <div class="dx-field-label">{{ 'TXT_ADDRESS' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentAddress == 1" class="dx-field-item-required-mark"> *</span>
  </div>
  <div class="dx-field-value">
    <dx-text-area *ngIf="tenantSetting?.mandatoryStudentAddress == 1"
                  [(value)]="student.studentAddress"
                  [height]="60"
                  [placeholder]="student.address">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_ADDRESS' | translate)  }, { type: 'stringLength', max: 255 }]"></dx-validator>
    </dx-text-area>
    <dx-text-area *ngIf="tenantSetting?.mandatoryStudentAddress == 0"
                  [(value)]="student.studentAddress"
                  [height]="60"
                  [placeholder]="student.address">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 255 }]"></dx-validator>
    </dx-text-area>
  </div>
</div>

<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentPlaceOfBirth < 2">
  <div class="dx-field-label">{{ 'TXT_PLACE_OF_BIRTH' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentPlaceOfBirth == 1" class="dx-field-item-required-mark"> *</span>
  </div>
  <div class="dx-field-value">
    <dx-text-area *ngIf="tenantSetting?.mandatoryStudentPlaceOfBirth == 1"
                  [(value)]="student.placeOfBirth"
                  [height]="60">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_PLACE_OF_BIRTH' | translate)  }, { type: 'stringLength', max: 255 }]"></dx-validator>
    </dx-text-area>
    <dx-text-area *ngIf="tenantSetting?.mandatoryStudentPlaceOfBirth == 0"
                  [(value)]="student.placeOfBirth"
                  [height]="60">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 255 }]"></dx-validator>
    </dx-text-area>
  </div>
</div>

<div class="dx-field" *ngIf="tenantSetting?.mandatoryStudentNotes < 2">
  <div class="dx-field-label">{{ 'TXT_NOTES' | translate }}
    <span *ngIf="tenantSetting?.mandatoryStudentNotes == 1" class="dx-field-item-required-mark"> *</span>
  </div>
  <div class="dx-field-value">
    <dx-text-area *ngIf="tenantSetting?.mandatoryStudentNotes == 1"
                  [(value)]="student.adminNotes"
                  [height]="60">
      <dx-validator [validationRules]="[{ type: 'required', message: ('TXT_ERROR_REQUIRED_TMPL' | translate).f('TXT_NOTES' | translate)  }, { type: 'stringLength', max: 1024 }]"></dx-validator>
    </dx-text-area>
    <dx-text-area *ngIf="tenantSetting?.mandatoryStudentNotes == 0"
                  [(value)]="student.adminNotes"
                  [height]="60">
      <dx-validator [validationRules]="[{ type: 'stringLength', max: 255 }]"></dx-validator>
    </dx-text-area>
  </div>
</div>
