import { Type } from "class-transformer";
import { FormQuestionOption } from './formQuestionOption';

import { FormsQuestionType } from '../enums/formsQuestionType';

export class FormQuestion {
    id: string;
    name: string;
    descriptionHtml: string;

    questionType: FormsQuestionType;

    @Type(() => FormQuestionOption)
    options: FormQuestionOption[];
}