<app-request-login-action-sheet
  [(visible)]="loginAdvertDisplayed"
></app-request-login-action-sheet>
<dx-text-box class="wb-searchbox"
  mode="search"
  placeholder="{{'TXT_SEARCH_PLACEHOLDER' | translate}}"
  [showClearButton]="true"
  (onValueChanged)="searchValueChanged($event)"
  value=""></dx-text-box>
<dx-list class="wb-under-search-list"
  [dataSource]="formDefinitions"
  pullRefreshEnabled="true"
  (onPullRefresh)="onPullRefresh()"
  (onItemClick)="onItemClick($event)">
  <div *dxTemplate="let itemData of 'item'; let i = index"
    class="form-definition-item"
    [ngClass]="{ 'form-definition-item-first': i == 0 }">
    <img *ngIf="!!itemData.pictureUrls && itemData.pictureUrls.length > 0"
      class="forms-list-image"
      src="{{itemData.pictureUrls[0]}}" />
    <img *ngIf="!itemData.pictureUrls || itemData.pictureUrls.length == 0"
      class="forms-list-image"
      src="https://storage.googleapis.com/gaxb7o2ddx-images/Generic/0/default_form.jpg" />
    <h4 class="wordwrap">
      {{itemData.name}}
    </h4>
  </div>
</dx-list>
