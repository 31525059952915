<div class="header dx-card dx-theme-border-color-as-background-color">
  <div class="max-length-text" *ngIf="!!semester">
    <small>{{semester.name}} ({{semester.startDate | date:'shortDate'}} - {{semester.endDate | date:'shortDate'}})</small>
  </div>
</div>
<div class="under-header-list">
  <dx-list
    [dataSource]="activities"
    pullRefreshEnabled="true"
    (onPullRefresh)="onPullRefresh()">
    <div *dxTemplate="let itemData of 'item'; let i = index" class="activity-item" [ngClass]="{ 'activity-item-first': i == 0 }" >

      <div class="row">
        <div class="col-xs-10 activity-name">
          <h4 class="dx-theme-accent-as-text-color">{{itemData.name}}</h4>
        </div>
        <div class="coll-xs-2 picture-gallery-button">
          <dx-button
            icon="image"
            type="default"
            (onClick)="onPictureGalleryClick(itemData.activityID)">
          </dx-button>
        </div>
      </div>
    </div>
  </dx-list>
</div>
