import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';


import {TenantService, UserScheduleService, ClassroomService, LoginService} from '../../shared/services';
import { TenantSummary, UserSchedule } from '../../shared/models';
import { SubjectCategory, AttendanceStatusIndication } from '../../shared/models/enums';

import * as _ from 'lodash';
import * as moment from "moment";

@Component({
  selector: 'app-semester-schedule',
  templateUrl: './semester-schedule.component.html',
  styleUrls: ['./semester-schedule.component.css']
})
export class SemesterScheduleComponent implements OnInit {

  private semesterID: number;
  private compensationGroupID: number;
  private allSchedules: Observable<UserSchedule[]>;
  public daySchedules: UserSchedule[];
  public highlightedDays: _.Dictionary<Date>;

  public SubjectCategory = SubjectCategory;
  public AttendanceStatusIndication = AttendanceStatusIndication;
  public isLoadPanelVisible: boolean = false;



  private classroomIDValue: number;
  get classroomID(): number {
    return this.classroomIDValue;
  }
  set classroomID(value: number) {
    if (this.classroomIDValue !== value) {
      this.classroomIDValue = value;
      if (this.selectedDate && this.classroomID) {
        this.loadSchedules();
      }
    }
  }

  private selectedDateValue: Date = new Date();
  get selectedDate(): Date {
    return this.selectedDateValue;
  }
  set selectedDate(value: Date) {
    if (this.selectedDateValue !== value) {
      this.selectedDateValue = value;
      if (this.selectedDate && this.classroomID) {
        this.loadSchedules();
      }
    }
  }

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private userScheduleService: UserScheduleService,
    private tenantService: TenantService,
    private classroomService: ClassroomService) { }

  ngOnInit() {
    let self = this;

    self.compensationGroupID = +self.route.snapshot.queryParams['compensationGroupID'];

    self.semesterID = +self.route.snapshot.params['semesterId'];
    let cl = +self.route.snapshot.queryParams['classroomID'];
    if (cl) {
      self.classroomID = cl;
    }

    if (!self.classroomID) {
      self.classroomService.getAllActive().subscribe((data) => {
        let first = _.first(data);
        if (!self.classroomID) {
          self.classroomID = first.id;
        }
      });
    }

    let sd = moment(self.route.snapshot.queryParams['selectedDate']);
    if (sd.isValid) {
      self.selectedDate = sd.toDate();
    }

    if (!self.selectedDate) {
      self.selectedDate = new Date();
    }

    self.loadSchedules();
  }

  loadSchedules() {
    let self = this;

    let momentDate = moment(self.selectedDate);

    if (self.classroomID && momentDate.isValid) {
      let url = _.join(_.map(self.route.snapshot.url, 'path'), '/');
      let queryParams = `selectedDate=${momentDate.format("YYYY-MM-DD")}&classroomID=${self.classroomID.toString()}`;
      if (self.compensationGroupID) {
        queryParams = queryParams + `&compensationGroupID=${self.compensationGroupID.toString()}`;
      }
      self.location.replaceState(url, queryParams);

      self.tenantService.getTenantSummary().subscribe((tenant: TenantSummary) => {
        let userID = tenant.loggedInUser.userID;

        self.isLoadPanelVisible = true;
        if (!self.allSchedules) {
          self.allSchedules = self.userScheduleService.getAllForSemester(
            self.semesterID,
            userID,
            self.compensationGroupID,
            new Date(1972, 0, 1),
            new Date(2372, 0, 1)
          );
        }

        if (self.allSchedules) {
          self.allSchedules.subscribe((res: UserSchedule[]) => {
            self.daySchedules = self.filterSchedules(res, momentDate.startOf('day'), self.classroomID);
            self.highlightedDays = _.keyBy(_.map(
              _.filter(res, { classroomID: self.classroomID }), (s) => moment(s.startDate).startOf('day').toDate()));
            self.isLoadPanelVisible = false;
          }, (err) => {
            self.isLoadPanelVisible = false;
          });
        } else {
          self.isLoadPanelVisible = false;
        }

      });
    }
  }

  onScheduleClick(params: any) {
    this.loginService.requestSignIn().subscribe((requested) => {
      if (!requested) {
        this.router.navigate(["/schedules", (<UserSchedule>params.itemData).courseScheduleID]);
      }
    })
  }

  private filterSchedules(allSchedules: UserSchedule[], mdate: moment.Moment, classroomID: number): UserSchedule[] {
    return _.orderBy(_.filter(allSchedules, (s) => {
      let mStartDate = moment(s.startDate).startOf('day');
      let mEndDate = moment(s.endDate).endOf('day');

      return s.classroomID === classroomID &&
        mStartDate.isSameOrBefore(mdate) &&
        mEndDate.isSameOrAfter(mdate);
    }), ['startDate']);
  }

}
