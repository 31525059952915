import { Type } from "class-transformer";
import { FormAnswer } from './formAnswer';
import { NotRegisteredUserInfo } from '../users/notRegisteredUserInfo';

import { TransformDateUtc } from '../../decorators';

export class FormResponse {
        id: string;
        formDefinitionId: string;
        formDefinitionRevision: number;
        userId: string;
        studentId: string;

        @Type(() => FormAnswer)
        answers: FormAnswer[];

        @TransformDateUtc()
        createdAt: Date;

        isNotRegisteredUser: boolean;

        @Type(() => NotRegisteredUserInfo)
        notRegisteredUserInfo: NotRegisteredUserInfo;
}