<dx-action-sheet
    title="{{'TXT_NOT_LOGGED_IN_TITLE' | translate}}"
    cancelText="{{'TXT_CANCEL' | translate}}"
    [items] = "loginOptionItems"
    [(visible)]="visible"
    [showTitle]="true"
    [showCancelButton]="true"
    (onItemClick)="loginOptionClick($event)"
    (onCancelClick)="onCancel($event)"
>
</dx-action-sheet>
<app-sign-in
  [(visible)]="signInVisible">
</app-sign-in>
