import { Exclude } from "class-transformer";

export class CompensationCourse {

    id: number;

    compensationGroupID: number;

    fullName: string;

    amends: string;

    // Non DTO properties
    @Exclude()
    disabled: boolean;

    @Exclude()
    requiresCompOverride: boolean;    
}