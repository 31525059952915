import { TransformDate } from '../decorators';

export class CourseAmortizationScheduleTemplate {
    courseID: number;
    courseAmortizationScheduleTemplateID: number;
    amount: number;
    percentageAmount: number;

    @TransformDate()
    issueAt: Date;

    issueDaysAfterRegistration: number;
}