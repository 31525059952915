<dx-load-panel #loadPanel
  [(visible)]="loading"
  [showIndicator]="true"
  [showPane]="false"
  [shading]="false"
  closeOnOutsideClick="false">
</dx-load-panel>

<div *ngIf="!loading">
  <dx-gallery  *ngIf="hasPictures"
      #gallery
      id="gallery"
      [dataSource]="pictures"
      [width]="'100%'"
      [height]="'auto'"
      [loop]="true"
      [showNavButtons]="true"
      [showIndicator]="true"
  ></dx-gallery>

  <div class="preText padded-content" *ngIf="(action | async)?.subjectDescription">
    {{(action | async)?.subjectDescription}}
  </div>
  <div class="preText padded-content" *ngIf="(action | async)?.description">
    {{(action | async)?.description}}
  </div>
  <div class="wb-button-margin text-center">
    <app-button-with-login
      text="{{'TXT_REGISTER_ON_EVENT' | translate}}"
      type="success"
      [returnUrl]="returnUrl"
      (onClick)="register($event)"
    ></app-button-with-login>
  </div>
</div>
