import { Component, OnInit, Input } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { ReferenceSourceService, TenantService } from '../../services';
import { User, ReferenceSource, TenantSetting, TenantSummary } from '../../models';

import { TranslateService } from '@ngx-translate/core'

import * as _ from 'lodash';

@Component({
  selector: 'app-user-contact-edit-detail',
  templateUrl: './user-contact-edit-detail.component.html',
  styleUrls: ['./user-contact-edit-detail.component.css']
})
export class UserContactEditDetailComponent implements OnInit {

  public referenceSources: ReferenceSource[] = [];
  public referenceSourcesPlaceholder: string;
  public tenantSetting: TenantSetting;
  public isReferenceSourceMandatory: boolean = false;
  public hasSecondContact: boolean = false;

  @Input()
  user: User;

  // Non user info like reference source ...
  @Input()
  showNonUserInfo: boolean;

  @Input()
  showContactFieldsetHeader: boolean;

  personalIdPattern: any = /^(\d\d)(\d\d)(\d\d)(\d\d\d\d?)$/;

  phoneValidationRegEx: any = /\d{3}\d{3}\d{3}\d{3}/;

  constructor(
    private translateService: TranslateService,
    private referenceSourceService: ReferenceSourceService,
    private tenantService: TenantService
  ) {
  }

  ngOnInit() {
    let self = this;

    self.translateService.get('TXT_PLACEHOLDER_REFERENCE_SOURCE').subscribe(res =>
      self.referenceSourcesPlaceholder = res);

    forkJoin([
      self.referenceSourceService.getAll(),
      self.tenantService.getTenantSummary()
    ]).subscribe((res: [ReferenceSource[], TenantSummary]) => {
      self.referenceSources = res[0];
      self.tenantSetting = res[1].tenantSetting;


      self.hasSecondContact =
        (self.tenantSetting.mandatoryEmail2 < 2) ||
        (self.tenantSetting.mandatoryPhone2 < 2) ||
        (self.tenantSetting.mandatoryUserNationality2 < 2) ||
        (self.tenantSetting.mandatoryUserName2 < 2) ||
        (self.tenantSetting.mandatoryUserSurname2 < 2) ||
        (self.tenantSetting.mandatoryUserMiddleName2 < 2) ||
        (self.tenantSetting.mandatoryUserAddress2 < 2) ||
        (self.tenantSetting.mandatoryUserIdentityCardNumber2 < 2) ||
        (self.tenantSetting.mandatoryUserPersonalIDNumber2 < 2) ||
        (self.tenantSetting.mandatoryUserBirthday2 < 2);

      self.isReferenceSourceMandatory =
        self.tenantSetting.allowReferenceSource == 1 &&
        self.referenceSources.length > 0;
    })
  }
}
