import { Pipe, PipeTransform } from '@angular/core';

import { ActivityAttendanceStatus } from '../models/enums';

import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'activityAttendanceStatus'
})
export class ActivityAttendanceStatusPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, args?: any): any {
    let courseAttendanceType: ActivityAttendanceStatus = value;
    switch (courseAttendanceType) {
      case ActivityAttendanceStatus.Present:
        return this.translateService.instant('TXT_STATUS_PRESENT');
      case ActivityAttendanceStatus.NotPresentNotExcused:
        return this.translateService.instant('TXT_STATUS_NOT_PRESENT_NOT_EXCUSED');
      case ActivityAttendanceStatus.NotPresentExcused:
        return this.translateService.instant('TXT_STATUS_NOT_PRESENT_EXCUSED');
      case ActivityAttendanceStatus.NotPresentExcusedNoCompensation:
        return this.translateService.instant('TXT_STATUS_NOT_PRESENT_EXCUSED_NO_COMPENSATION');
      case ActivityAttendanceStatus.NotPresentExcusedWithoutFood:
        return this.translateService.instant('TXT_STATUS_NOT_PRESENT_EXCUSED_NO_FOOD');
    }
    return "";
  }

}
