import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { WindowWrapper } from '../../shared/windowWrapper';
import { TranslateService } from '@ngx-translate/core'

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service'

const STORAGE_KEY = 'my-account-payment-history-index';

import { DuePaymentsService, OnlinePaymentService, TenantService } from '../../shared/services';
import { DuePayment, DuePaymentsByStudent } from '../../shared/models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Observable, forkJoin } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-my-account-detail-due-payments',
  templateUrl: './my-account-detail-due-payments.component.html',
  styleUrls: ['./my-account-detail-due-payments.component.css']
})
export class MyAccountDetailDuePaymentsComponent implements OnInit {

  onlinePaymentConfirmItems: any[] = [];
  onlinePaymentConfirmVisible: boolean = false;

  duePaymentsByStudent: DataSource;
  private selectedDuePayment: DuePayment;
  private onlinePaymentID: number;

  confirmVisible: boolean = false;


  isLoadPanelVisible: boolean = false;
  confirmPaymentMessage: string = '';

  constructor(
    private duePaymentsService: DuePaymentsService,
    private translate: TranslateService,
    private onlinePaymentService: OnlinePaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private _window: WindowWrapper,
    private tenantService: TenantService,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) { }

  ngOnInit() {
    let self = this;

    this.translate.get('TXT_PAY_ONLINE').subscribe((res: string) => {
      this.onlinePaymentConfirmItems.push({ text: this.translate.instant('TXT_PAY_ONLINE') });
    });

    self.onlinePaymentID = +self.route.snapshot.queryParams['onlinePaymentID'];

    self.duePaymentsByStudent = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {

            let dpo = self.duePaymentsService.getAllForCurrentUser(true);
            let tso = self.tenantService.getTenantSummary();
            forkJoin(
              dpo,
              tso
            ).subscribe(data => {
              let result: DuePaymentsByStudent[] = [];

              let filtered = data[1].tenantSetting.showOverpaymentsInClientDuePayments
                ? data[0]
                : _.filter(data[0], o => o.arrear >= 0);

              let sorted = _.orderBy(filtered, ['studentFullName', 'dueDate'], ['asc', 'asc']);
              let grouped = _.groupBy(sorted, function (value) {
                return value.studentFullName;
              });
              _.forEach(grouped, function (value, key) {
                result.push({
                  studentFullName: key,
                  items: value
                });
              });
              resolve(result);
            }, (error) => {
              reject(error);
            });
          })
        }
      })
    });

    if (self.onlinePaymentID) {
      self.onlinePaymentService.getTransactionState(self.onlinePaymentID).subscribe((op) => {
        self.confirmPaymentMessage = self.onlinePaymentService.getOnlinePaymentResultMessage(op);
        self.confirmVisible = true;
      });
    }
  }

  onPullRefresh() {
    this.duePaymentsByStudent.load();
  }

  onlinePaymentConfirmClick() {
    if (this.selectedDuePayment) {
      this.storage.set(STORAGE_KEY, this._window.history.length);

      this.onlinePaymentService.startOnlinePayment(
        [this.selectedDuePayment.invoiceID + ""],
        this.selectedDuePayment.arrearByAmortizationSchedule);
    }
  }

  onDuePaymentClick(param: any) {
    if (param.itemData.supportsOnlinePayment && param.itemData.arrear > 0) {
      this.selectedDuePayment = param.itemData;
      this.onlinePaymentConfirmVisible = true;
    }
  }

  onConfimgPaymentResultClick(param: any) {
    let self = this;
    self.confirmVisible = false;
    self.confirmPaymentMessage = '';

   // let queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
   // queryParams['onlinePaymentID'] = null;

    self.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        'onlinePaymentID': null
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    self.onlinePaymentID = null;

    // // Navigate back in history prior to online payment
    let storedIndex = this.storage.get(STORAGE_KEY) || this._window.history.length;

    let noOfPagesToRemove = storedIndex - this._window.history.length;
    this._window.history.go(noOfPagesToRemove);
  }
}
