import { Component, OnInit } from '@angular/core';
import {ActivityService} from "../../../shared/services";
import {ActivatedRoute} from "@angular/router";
import UploadInfo from "devextreme/file_management/upload_info";
import * as moment from "moment";
import {Location} from "@angular/common";
import {FilesUploadedEvent, ValueChangedEvent} from "devextreme/ui/file_uploader";

@Component({
  selector: 'app-activity-attendance-picture-gallery-upload',
  templateUrl: './activity-attendance-picture-gallery-upload.component.html',
  styleUrls: ['./activity-attendance-picture-gallery-upload.component.css']
})
export class ActivityAttendancePictureGalleryUploadComponent implements OnInit {

  activityID: number;
  semesterID: number;

  galleryDirectory: string;
  galleryDescription: string = "";
  galleryCreatedAtUtc: Date = new Date();

  editDisabled: boolean = true;
  activityName: string = "";
  uploadFinished: boolean = false;

  constructor(private route: ActivatedRoute,
              private activityService: ActivityService,
              private location: Location) { }

  ngOnInit(): void {
    this.activityID = Number(this.route.snapshot.params["activityId"]);
    this.semesterID = Number(this.route.snapshot.params["semesterId"]);
    this.activityService.get(this.activityID)
      .subscribe(activity => {
        this.activityName = activity.name;
        this.editDisabled = false;
      });

  }


  onUploadStarted(event) {
    if (!this.editDisabled) {
      this.editDisabled = true;

      let now = moment(this.galleryCreatedAtUtc).format("YYYYMMDD");
      this.galleryDirectory = `PhotoAlbum/${this.activityName}/${now}`;

      this.activityService.createPhotoAlbumGalleryWithInfo(
        this.activityID,
        this.galleryDirectory,
        this.galleryDescription,
        this.galleryCreatedAtUtc
      ).subscribe(o => {

      });
    }
  }

  // onValueChanged(e: ValueChangedEvent) {
  //   this.uploadFinished = e.value != null && e.value.length != 0;
  // }

  onFilesUploaded(e: FilesUploadedEvent) {
    this.uploadFinished = true;
  }

  backBtnClick(event) {
    this.location.back();
  }

  uploadChunkBindThis() {
    return this.uploadChunk.bind(this);
  }

  uploadChunk(file:File, uploadInfo:UploadInfo) {
    return this.activityService.uploadPhotoChunkIntoPhotoAlbum(this.activityID, file, uploadInfo, this.galleryDirectory);
  }

}
