<h4 class="text-center">{{'TXT_SELECT_DOMAIN' | translate}}</h4>
<dx-tree-list class="wb-under-header-list"
    id="domains"
    [dataSource]="domains"
    itemsExpr="specializations"
    dataStructure="tree"
    [autoExpandAll]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [activeStateEnabled]="true"
    [columnAutoWidth]="true"
    [showColumnHeaders]="false"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onRowPrepared)="onRowPrepared($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxi-column dataField="name" ></dxi-column>
</dx-tree-list>
