import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {TenantService} from "../services";
import {WindowWrapper} from "../windowWrapper";

@Injectable()
export class CanActviateNewDesignGuardGuard implements CanActivate {

  constructor(
    private tenantService: TenantService,
    private router: Router,
    private _window: WindowWrapper,) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let self = this;

    return new Observable<boolean>(observer => {
      self.tenantService.getTenantSummary().subscribe((t) => {
          if (t.tenantSetting.enableNewClientDesign) {
            observer.next(false);
            this._window.location.href = `${this._window.location.protocol}//${this._window.location.host}`;
          } else {
            observer.next(true);
          }
          observer.complete();
        },
        err => {
          observer.error(err);
          self.router.navigate(["/"]);
        });
    });
  }

}
