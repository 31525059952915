<dx-toolbar *ngIf="reload" class="main-tool-bar">
  <dxi-item location="center"
    locateInMenu="never">
    <app-route-title></app-route-title>
  </dxi-item>
  <dxi-item location="before" *ngIf="reloadMenu">
    <dx-button icon="back"
      (onClick)="backBtnClick($event)"></dx-button>
  </dxi-item>
  <dxi-item location="after" *ngIf="reloadMenu">
    <dx-button icon="menu"
      (onClick)="menuBtnClick($event)"></dx-button>
  </dxi-item>
</dx-toolbar>
<dx-drawer
  class="app-drawer"
  [openedStateMode]="'push'"
  [position]="'right'"
  [revealMode]="'expand'"
  template="template"
  [opened]="menuOpened"
  [closeOnOutsideClick]="true">
  <div *dxTemplate="let data of 'template'" style="width: 170px;">
    <dx-list class="panel-list dx-theme-border-color-as-background-color"
      [dataSource]="navigation"
      [hoverStateEnabled]="true"
      [activeStateEnabled]="true"
      [focusStateEnabled]="true"
      [width]="170"
      selectionMode="single"
      (onSelectionChanged)="loadView($event)">
    </dx-list>
  </div>
  <div id="drawer-content" >
    <router-outlet ></router-outlet>
  </div>
</dx-drawer>
<ng-image-fullscreen-view
  [images]="lightBoxAlbum"
  [imageIndex]="lightBoxImageIndex"
  [show]="showLightBox"
  (close)="closeLightBox()"></ng-image-fullscreen-view>
<dx-action-sheet
  title="{{'TXT_LOGOUT_QUESTION' | translate}}"
  cancelText="{{'TXT_CANCEL' | translate}}"
  [items]="logoutConfirmItems"
  [(visible)]="logoutConfirmSheetVisible"
  [showTitle]="true"
  [showCancelButton]="true"
  (onItemClick)="logoutConfirmClick()">
</dx-action-sheet>
<dx-action-sheet
  title="{{'TXT_CHANGE_LANGUAGE' | translate}}"
  cancelText="{{'TXT_CANCEL' | translate}}"
  [items]="languageItems"
  [(visible)]="languageSheetVisible"
  [showTitle]="true"
  [showCancelButton]="true">
</dx-action-sheet>
<app-request-login-action-sheet
  [(visible)]="loginAdvertDisplayed"
  [(signInVisible)]="signInVisible"
></app-request-login-action-sheet>
