import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { LoginService } from '../services';

import * as _ from 'lodash';

@Injectable()
export class BaseGuard {

    constructor(
        protected router: Router,
        protected loginService: LoginService,
    ) {

    }

    protected navigateToHome() {
        this.router.navigate(["/"]);
    }

    protected navigateToLogin(route: ActivatedRouteSnapshot) {
      //this.router.navigate(["/"]);
      //this.loginService.onSingInRequested.emit(null);
       let returnUrl = (<any>route)._routerState.url;
       this.router.navigate(['/'], { queryParams: { signIn: returnUrl } });
    }
}
