import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CourseService, TenantService, ClassroomService } from '../../shared/services';
import { Course, TenantSetting } from '../../shared/models';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.css']
})
export class CoursesListComponent implements OnInit {

  semesterID: Observable<number>;
  courses: DataSource;
  tenantSettings: TenantSetting = new TenantSetting();
  classrooms: {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseService: CourseService,
    private tenantService: TenantService,
    private classroomService: ClassroomService
  ) { }

  ngOnInit() {
    let self = this;

    self.classroomService.getAll().subscribe(c => self.classrooms = _.keyBy(c, 'id'));

    self.semesterID = this.route
      .params
      .pipe(map(params => Number(params['semesterId'])));

    this.tenantService.getTenantSummary().subscribe(ts => self.tenantSettings = ts.tenantSetting);

    self.courses = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: loadOptions => {
          return new Promise((resolve, reject) => {
            self.semesterID.subscribe(id => {
              self.courseService.getAllForSemester(id).subscribe((data) => {
                if (!!loadOptions.searchValue) {
                  resolve(this.applyFilter(data, <string>loadOptions.searchValue));
                } else {
                  resolve(data);
                }
              }, (error) => {
                reject(error);
              });
            });
          })
        }
      })
    });
  }

  onPullRefresh() {
    this.courses.load();
  }

  applyFilter(courses: Course[], filter: string) {

    let self = this;

    return _.filter(courses, function (course) {
      var classroom = self.classrooms[course.classroomID];

      return (!String.prototype.isNullOrWhitespace(course.name) && course.name.indexOf(filter) != -1) ||
        (!String.prototype.isNullOrWhitespace(course.subjectName) && course.subjectName.indexOf(filter) != -1) ||
        (!String.prototype.isNullOrWhitespace(course.shortDescription) && course.shortDescription.indexOf(filter) != -1) ||
        (!!classroom && !String.prototype.isNullOrWhitespace(classroom.name) && classroom.name.indexOf(filter) != -1);
    });
  }

  searchValueChanged(e: any) {
    this.courses.searchValue(e.value);
    this.courses.load();
  }

  onItemClick(params) {
    this.router.navigate(["/courses", (<Course>params.itemData).courseID]);
  }

}
